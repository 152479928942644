import React from "react";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import "./Footer.css";

export default class Footer extends React.Component<{}, {}> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footer-container">
        <Link
          to={`/impressum${
            this.context.clubId ? `/${this.context.clubId}` : ""
          }`}
          className="no-underline footer-links"
        >
          Impressum
        </Link>
        <Link to="/privacy" className="no-underline footer-links">
          Datenschutzerklärung
        </Link>
        {this.context.club?.agb && (
          <a
            href={this.context.club?.agb}
            className="no-underline footer-links"
            target="_blank"
            rel="noreferrer"
          >
            AGB/Widerrufsbelehrung
          </a>
        )}
        {/* <Link to="/terms" className="white no-underline">
          Nutzungsbedinungen
        </Link> */}
      </div>
    );
  }
}
