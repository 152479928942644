import React from "react";
import { MainContext } from "../../contexts/MainContext";
import { Link, RouteComponentProps } from "react-router-dom";
import "./SingleUser.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import { HashLoader } from "react-spinners";
import BackElement from "../../components/BackElement";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import {
  dateTosimpleDateStr,
  firebaseDateToDateObj,
  objectToArray,
  reformatDate,
  reformatDateReverse,
} from "../../functions/utils";
import SubTitle from "../../components/SubTitle";
import MultiSelector from "../../components/MultiSelector";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Title from "../../components/Title";
import { getBookingStatus } from "../../functions/getBookingStatus";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";
import TextCheckbox from "../../components/TextCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseUser } from "@fortawesome/free-solid-svg-icons";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    userId: string;
  }>;

export default class SingleUser extends React.Component<
  ComposedProps,
  {
    clubId: string;
    userId: string;
    club: any;
    clubLoaded: boolean;
    branches: Array<any>;
    userBrancheData: any;
    branchesLoaded: boolean;
    user: any;
    userLoaded: boolean;
    change: boolean;
    errMsgDeleteUser: null | string;
    errMsgSaveUser: null | string;
    updateLoading: boolean;
    removeUserLoading: boolean;
    membershipNumber: string;
    birthDate: string;
    addAdminLoading: boolean;
    addAdminErrMsg: null | string;
    removeAdminLoading: boolean;
    removeAdminErrMsg: null | string;
    showConfirmKick: boolean;
    selectedRoles: Array<string>;
    fName: string;
    lName: string;
    courseBookingTableArr: Array<RowData>;
    subscriptionBookingTableArr: Array<RowData>;
    elementsLoaded: boolean;
    showAllCourseBookings: boolean;
    showAllSubscriptionBookings: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      userId: this.props.match.params.userId,
      club: {},
      clubLoaded: false,
      branches: [],
      userBrancheData: [],
      branchesLoaded: false,
      user: {},
      userLoaded: false,
      change: false,
      errMsgDeleteUser: null,
      errMsgSaveUser: null,
      updateLoading: false,
      removeUserLoading: false,
      membershipNumber: "",
      birthDate: "",
      addAdminLoading: false,
      addAdminErrMsg: null,
      removeAdminLoading: false,
      removeAdminErrMsg: null,
      showConfirmKick: false,
      selectedRoles: [],
      fName: "",
      lName: "",
      courseBookingTableArr: [],
      subscriptionBookingTableArr: [],
      elementsLoaded: false,
      showAllCourseBookings: false,
      showAllSubscriptionBookings: false,
    };
  }

  componentDidMount = async () => {
    await Promise.all([
      this.requestUser(),
      this.requestClub(),
      this.requestBranches(),
    ]).then(this.handleData);
    this.requestUserElements();
  };

  requestUserElements = async () => {
    await fetchRetry(
      "getElementsFromClubUser",
      {
        clubId: this.state.clubId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleUserElements)
      .catch(this.handleError);
  };

  handleUserElements = ({ data }: any) => {
    if (data.success) {
      const courseBookingArr = data.data.courseBookings;
      const courseBookingTableArr = courseBookingArr.map((booking: any) => {
        const bookingActive =
          booking.active &&
          (booking.billing !== "single-payment" ||
            firebaseDateToDateObj(booking?.course?.endTime)?.getTime() >
              new Date().getTime());
        return {
          cells: [
            { value: booking.course.title, sort: booking.course.title },
            {
              value: getBookingStatus(booking),
              sort: getBookingStatus(booking),
              search: bookingActive ? "active" : "",
            },
            {
              value: dateTosimpleDateStr(
                firebaseDateToDateObj(booking.created_at)
              ),
              sort: booking.created_at._seconds,
            },
          ],
          onClick: `/club/${this.state.clubId}/branch/${booking.branch.id}/participants/${booking.course.id}/participant/${booking.id}`,
        };
      });
      const subscriptionBookingArr = data.data.subscriptionBookings;
      const subscriptionBookingTableArr = subscriptionBookingArr.map(
        (booking: any) => {
          const bookingActive = booking.active;
          return {
            cells: [
              {
                value: booking.subscription.title,
                sort: booking.subscription.title,
              },
              {
                value: getBookingStatus(booking),
                sort: getBookingStatus(booking),
                search: bookingActive ? "active" : "",
              },
              {
                value: dateTosimpleDateStr(
                  firebaseDateToDateObj(booking.created_at)
                ),
                sort: booking.created_at._seconds,
              },
            ],
            onClick: `/club/${this.state.clubId}/branch/${booking.branch.id}/subscription-participants/${booking.subscription.id}/participant/${booking.id}`,
          };
        }
      );

      courseBookingArr.sort((a: any, b: any) =>
        a.created_at._seconds > b.created_at._seconds ? -1 : 1
      );
      subscriptionBookingArr.sort((a: any, b: any) =>
        a.created_at._seconds > b.created_at._seconds ? -1 : 1
      );
      this.setState({
        courseBookingTableArr,
        subscriptionBookingTableArr,
        elementsLoaded: true,
      });
    }
  };

  requestClub = async () => {
    await fetchRetry(
      "getClubFromId",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      this.setState({
        club: club,
        clubLoaded: true,
      });
    }
  };

  requestBranches = async () => {
    await fetchRetry("getBranchesFromClub", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleBranches)
      .catch(this.handleError);
  };

  handleBranches = ({ data }: any) => {
    this.setState({ branchesLoaded: true });
    if (data.success) {
      this.setState({ branches: data.data });
    }
  };

  requestUser = async () => {
    await fetchRetry(
      "getUserFromClub",
      {
        clubId: this.state.clubId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleUser)
      .catch(this.handleError);
  };

  branchArrToObject = (array: Array<any>) => {
    const obj: any = {};
    array.forEach((item) => {
      obj[item.branchId] = item;
    });
    return obj;
  };

  handleData = () => {
    // reformating branches
    const userBranchArr: Array<any> = this.state.user?.branches
      ? this.state.user.branches
      : [];
    const branchObj: any = this.branchArrToObject(userBranchArr);

    const branches: any = {};

    this.state.branches.forEach((branch: any) => {
      const currRoles = objectToArray(branch.roles).map((branchRole: any) => {
        const roleId = branchRole.id;
        const role = {
          id: roleId,
          name: branchRole.name,
          color: branchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
          type: branchRole.type,
        };
        return {
          ...role,
          ...{
            selected: !!(
              branchObj[branch.id]?.roles &&
              branchObj[branch.id]?.roles.includes(roleId)
            ),
          },
        };
      });
      branches[branch.id] = {
        roles: currRoles,
        role: branchObj[branch.id]?.role
          ? branchObj[branch.id]?.role
          : "Member",
      };
    });
    this.setState({ userBrancheData: branches }, this.checkChange);
  };

  handleUser = ({ data }: any) => {
    this.setState({ userLoaded: true });
    if (data.success) {
      const user = data.data;
      let selectedRoles: Array<string> = [];
      user.branches.forEach((branch: any) => {
        const currUserBranchRoles = branch.roles || [];
        selectedRoles = [...selectedRoles, ...currUserBranchRoles];
        currUserBranchRoles.sort((a: any, b: any) =>
          a.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        );
      });
      this.setState({
        user,
        membershipNumber: user.membershipNumber,
        birthDate: reformatDateReverse(user?.birthDate),
        selectedRoles,
        fName: user.fName,
        lName: user.lName,
      });
    }
  };

  checkChange = () => {
    // reformating branches
    const userBranchArr: Array<any> = this.state.user?.branches
      ? this.state.user.branches
      : [];
    const oldBranchesObj: any = this.branchArrToObject(userBranchArr);

    let branchChange = false;
    this.state.branches.forEach((branch: any) => {
      const branchId = branch.id;
      const branchObj = this.state.userBrancheData[branchId];
      const oldBranchObj = oldBranchesObj[branchId];

      if (branchObj?.role !== oldBranchObj?.role) {
        branchChange = true;
        return;
      }

      const roles = branchObj?.roles
        .filter((role: any) => role.selected)
        .map((role: any) => role.id);
      if (roles) {
        if (
          roles.length !==
          (oldBranchObj?.roles ? oldBranchObj?.roles?.length : 0)
        ) {
          branchChange = true;
          return;
        }
        for (const role of roles) {
          if (!oldBranchObj?.roles.includes(role)) {
            branchChange = true;
            return;
          }
        }
      }
    });
    this.setState({
      change:
        this.state.user.membershipNumber !== this.state.membershipNumber ||
        this.state.user?.birthDate !== reformatDate(this.state.birthDate) ||
        this.state.user.fName !== this.state.fName ||
        this.state.user.lName !== this.state.lName ||
        branchChange,
    });
  };

  updateUserInClub = () => {
    this.setState({ updateLoading: true });
    const branchArr = this.state.branches.map((branch: any) => {
      const branchId = branch.id;
      const branchObj = this.state.userBrancheData[branchId];
      const roles = branchObj.roles
        .filter((role: any) => role.selected)
        .map((role: any) => role.id);
      return {
        branchId,
        roles,
        role: branchObj.role ? branchObj.role : null,
      };
    });
    fetchRetry(
      "updateUserInClubFNew",
      {
        clubId: this.state.clubId,
        userId: this.state.userId,
        membershipNumber: this.state.membershipNumber,
        birthDate: reformatDate(this.state.birthDate),
        branchArr,
        fName: this.state.fName,
        lName: this.state.lName,
      },
      1,
      5
    )
      .then(this.updateLoadingSuccess)
      .catch(this.handleError);
  };

  updateLoadingSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestUser().then(this.handleData);
    }
  };

  addAdmin = () => {
    this.setState({ addAdminLoading: true });
    fetchRetry(
      "addOwnerToClub",
      { clubId: this.state.clubId, userId: this.state.userId },
      1,
      5
    )
      .then(this.handleAddAdminSuccess)
      .catch(this.handleError);
  };

  handleAddAdminSuccess = ({ data }: any) => {
    this.setState({ addAdminLoading: false });
    if (data.success) {
      this.requestUser();
      this.setState({ addAdminErrMsg: null });
    } else {
      this.setState({ addAdminErrMsg: data.errorMsgDe });
    }
  };

  removeAdmin = () => {
    this.setState({ removeAdminLoading: true });
    fetchRetry(
      "removeOwnerFromClub",
      { clubId: this.state.clubId, userId: this.state.userId },
      1,
      5
    )
      .then(this.handleRemoveAdminSuccess)
      .catch(this.handleError);
  };

  handleRemoveAdminSuccess = ({ data }: any) => {
    this.setState({ removeAdminLoading: false });
    if (data.success) {
      this.requestUser();
      this.setState({ removeAdminErrMsg: null });
    } else {
      this.setState({ removeAdminErrMsg: data.errorMsgDe });
    }
  };

  handleMembershipNumberChange = (val: string) => {
    this.setState({ membershipNumber: val }, this.checkChange);
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value }, this.checkChange);
  };

  toggleRole = (branchId: string, roleId: string, status: boolean) => {
    const userBranchData = this.state.userBrancheData;
    let selectedRoles = this.state.selectedRoles;
    // get the selected branch
    const branch = userBranchData[branchId];
    // get the selected role
    const selectedRole = branch.roles.filter(
      (role: any) => role.id === roleId
    )[0];
    // update selectedRoles
    if (!status) {
      selectedRoles = selectedRoles.filter(
        (currRoleId: string) => currRoleId !== roleId
      );
    } else {
      selectedRoles.push(roleId);
    }
    // update all club roles
    for (const branchId in userBranchData) {
      const currBranch = userBranchData[branchId];
      currBranch.roles.forEach((role: any) => {
        if (role.id === roleId) {
          role.selected = status;
        }
      });
    }

    this.setState(
      { userBrancheData: userBranchData, selectedRoles },
      this.checkChange
    );
  };

  branchRoleSelected = (branchId: string, e: any) => {
    const userBranchData = this.state.userBrancheData;
    const branch = userBranchData[branchId];
    const roleDict = ["Member", "ViewOnly", "Owner"];
    branch.role = roleDict[e.target.options.selectedIndex];
    this.setState({ userBrancheData: userBranchData }, this.checkChange);
  };

  handleKickUser = () => {
    this.setState({ showConfirmKick: true });
  };

  hideConfirmKickModal = () => {
    this.setState({ showConfirmKick: false });
  };

  kickUserFromBranch = () => {
    this.setState({ showConfirmKick: false, removeUserLoading: true });
    fetchRetry(
      "kickUserFromClub",
      {
        clubId: this.state.clubId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleSuccessKick)
      .catch(this.handleError);
  };

  handleSuccessKick = ({ data }: any) => {
    this.setState({ removeUserLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Der Benutzer wurde erfolgreich aus diesem Bereich entfernt.",
        "success",
        4
      );
      this.props.history.push({
        pathname: `/club-settings/${this.state.clubId}/users`,
      });
    } else {
      this.setState({
        errMsgDeleteUser: data.errorMsgDe,
      });
    }
  };

  handlefNameChange = (val: string) => {
    this.setState({ fName: val }, this.checkChange);
  };

  handlelNameChange = (val: string) => {
    this.setState({ lName: val }, this.checkChange);
  };

  handleRedirect = (redirect: string) => {
    this.props.history.push({
      pathname: redirect,
      search: `back=/club-settings/${this.state.clubId}/users/${this.state.userId}`,
    });
  };

  toggleShowAllCourseBookings = () => {
    this.setState({ showAllCourseBookings: !this.state.showAllCourseBookings });
  };

  toggleShowAllSubscriptionBookings = () => {
    this.setState({
      showAllSubscriptionBookings: !this.state.showAllSubscriptionBookings,
    });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (
      !this.state.userLoaded ||
      !this.state.clubLoaded ||
      !this.state.branchesLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmKick}
          title="Benutzer entfernen"
          msg="Bist du sicher das du diesen Nutzer aus dem Verein entfernen willst? Die Einstellungen zu diesem Benutzer werden entgültig gelöscht."
          handleClose={this.hideConfirmKickModal}
          handleConfirm={this.kickUserFromBranch}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <div className="subscreen-bell-container">
              {this.state.user.familyAccountId && (
                <Link
                  to={`/club-settings/${this.state.clubId}/family/${this.state.user.familyAccountId}?back=/club-settings/${this.state.clubId}/users/${this.state.userId}`}
                  className="black"
                >
                  <FontAwesomeIcon
                    icon={faHouseUser}
                    size="lg"
                    onClick={() => {}}
                    className="course-settings-icon"
                  />
                </Link>
              )}
            </div>
            <BackElement
              text="zurück zur Nutzerübersicht"
              to={`/club-settings/${this.state.clubId}/users`}
            />
            <div className="modal-title-container">
              <span className="modal-title">Nutzerdaten bearbeiten</span>
            </div>
            <br />
            <div className="modal-user-container">
              {this.state.club?.settings?.useMembershipNumber && (
                <>
                  <SubTitle title="Mitgliedsnummer" />
                  <Input
                    name="Mitgliedsnummer"
                    placeholder="Mitgliedsnummer"
                    value={this.state.membershipNumber}
                    onChange={this.handleMembershipNumberChange}
                  />
                </>
              )}
              <SubTitle title="Name" />
              <div className="course-time-input-container">
                <Input
                  value={this.state.fName}
                  onChange={this.handlefNameChange}
                  placeholder="Vorname"
                />
                <Input
                  value={this.state.lName}
                  onChange={this.handlelNameChange}
                  placeholder="Nachname"
                />
              </div>
              <SubTitle title="E-Mail" />
              <Input
                name="email"
                placeholder="E-Mail"
                value={this.state.user?.email}
                onChange={() => {}}
                disabled
              />
              <SubTitle title="Adresse" />
              <div className="course-time-input-container">
                <Input
                  value={this.state.user?.address?.street}
                  onChange={() => {}}
                  placeholder="Straße"
                  className="street-input"
                  disabled
                />
                <Input
                  value={this.state.user?.address?.houseNumber}
                  onChange={() => {}}
                  placeholder="Nr."
                  className="house-number-input"
                  disabled
                />
              </div>
              <div className="profile-location-container">
                <Input
                  value={this.state.user?.address?.zipcode}
                  onChange={() => {}}
                  placeholder="PLZ"
                  className="zipcode-input"
                  disabled
                />
                <Input
                  value={this.state.user?.address?.city}
                  onChange={() => {}}
                  placeholder="Stadt"
                  className="city-input"
                  disabled
                />
              </div>
              <SubTitle title="Telefonnummer" />
              <Input
                value={this.state.user.phoneNumber}
                onChange={() => {}}
                placeholder="Telefonnummer"
                disabled
              />
              <SubTitle title="Geburtsdatum" />
              <input
                type="date"
                className="input-container"
                value={this.state.birthDate}
                onChange={this.handleBirthDateChange}
              />
              <SubTitle title="Rechte" />
              <select
                placeholder="Rechte"
                className="input-container"
                value={this.state.user.role === "Owner" ? 1 : 0}
                onChange={() => {}}
                disabled
              >
                <option value="0">Mitglied</option>
                <option value="1">Admin</option>
              </select>
            </div>
            <fieldset className="branch-table-fieldset">
              <legend>Bereiche</legend>
              <table className="user-table inner-branch-table">
                <thead>
                  <tr>
                    <th>Sparte</th>
                    <th>Rechte</th>
                    <th>Rollen</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.branches.map((branch: any) => (
                    <tr>
                      <td>{branch.name}</td>
                      <td className="table-onboarding-selector">
                        <select
                          placeholder="Rechte"
                          className="input-container table-input-margin-top"
                          value={this.state.userBrancheData[branch.id]?.role}
                          onChange={(e: any) => {
                            this.branchRoleSelected(branch.id, e);
                          }}
                        >
                          <option value="Member">Mitglied</option>
                          <option value="ViewOnly">Mitarbeiter</option>
                          <option value="Owner">Admin</option>
                        </select>
                      </td>
                      <td className="table-main-content">
                        <MultiSelector
                          searchTextPlaceholder="Rollen"
                          arr={
                            this.state.userBrancheData[branch.id]?.roles
                              ? this.state.userBrancheData[branch.id].roles
                              : []
                          }
                          onSelect={(roleId: string) => {
                            this.toggleRole(branch.id, roleId, true);
                          }}
                          onUnselect={(roleId: string) => {
                            this.toggleRole(branch.id, roleId, false);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </fieldset>
            <div className="medium-space"></div>
            <ErrorMessage message={this.state.errMsgSaveUser} />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateUserInClub}
              title="Speichern"
            />
            <ErrorMessage message={this.state.errMsgDeleteUser} />
            <SecButton
              change={true}
              color="red"
              loading={this.state.removeUserLoading}
              onClick={this.handleKickUser}
              title="Nutzer entfernen"
            />

            {this.state.user.role === "Owner" ? (
              <>
                <ErrorMessage message={this.state.removeAdminErrMsg} />
                <SecButton
                  change={true}
                  color="red"
                  loading={this.state.removeAdminLoading}
                  onClick={this.removeAdmin}
                  title="Admin Rechte entfernen"
                />
              </>
            ) : (
              <>
                <ErrorMessage message={this.state.addAdminErrMsg} />
                <SecButton
                  change={true}
                  colorHex="#3493ff"
                  loading={this.state.addAdminLoading}
                  onClick={this.addAdmin}
                  title="Zum Admin machen"
                />
              </>
            )}
            <Title title="Kursbuchungen" />
            <TextCheckbox
              text="alle anzeigen"
              val={this.state.showAllCourseBookings}
              onChange={this.toggleShowAllCourseBookings}
            />
            <Table
              header={[
                { title: "Kurs" },
                { title: "Status" },
                { title: "Buchungszeitpunkt" },
              ]}
              data={this.state.courseBookingTableArr}
              loaded={this.state.elementsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="desc"
              defaultSortIndex={2}
              searchValue={this.state.showAllCourseBookings ? "" : "active"}
            />
            <Title title="Mitgliedschaftsbuchungen" />
            <TextCheckbox
              text="alle anzeigen"
              val={this.state.showAllSubscriptionBookings}
              onChange={this.toggleShowAllSubscriptionBookings}
            />
            <Table
              header={[
                { title: "Mitgliedschaft" },
                { title: "Status" },
                { title: "Buchungszeitpunkt" },
              ]}
              data={this.state.subscriptionBookingTableArr}
              loaded={this.state.elementsLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="desc"
              defaultSortIndex={2}
              searchValue={
                this.state.showAllSubscriptionBookings ? "" : "active"
              }
            />
          </div>
        </div>
      </>
    );
  }
}
