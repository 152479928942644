import React from "react";
import "./NavbarElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBurn } from "@fortawesome/free-solid-svg-icons";

export default class LogoNavbarElement extends React.Component<
  {
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <a
        href={"https://getbookable.de"}
        className="navbar-element navbar-element-logo"
        onClick={() => {}}
      >
        <div className="navbar-icon-logo-container">
          <FontAwesomeIcon
            icon={faBurn}
            size="lg"
          />
        </div>
        <div>
          <span>bookable</span>
        </div>
      </a>
    );
  }
}
