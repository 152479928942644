import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./InfoContainer.css";

export default class InfoContainer extends React.Component<
  { active?: boolean; status?: "info" | "error" | "success" | string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {(this.props.active || this.props.active === undefined) && (
          <div className="info-container">
            <div>
              {(this.props.status === "info" || !this.props.status) && (
                <div className="info-circle-icon-container">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="lg"
                    className="info-icon info-info-icon"
                  />
                </div>
              )}
              {this.props.status === "error" && (
                <div className="info-circle-icon-container">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    size="lg"
                    className="info-icon info-error-icon"
                  />
                </div>
              )}
              {this.props.status === "success" && (
                <div className="info-circle-icon-container">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="lg"
                    className="info-icon info-success-icon"
                  />
                </div>
              )}
            </div>
            <div className="info-inner-container">{this.props.children}</div>
          </div>
        )}
      </>
    );
  }
}
