import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PricingProfile.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {
  minTimeToStrLeadingZero,
  strTimeToMin,
  valToArray,
} from "../../functions/utils";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
    pricingProfileId: string;
  }>;

export default class PricingProfile extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    pricingProfileId: string;
    branch: any;
    branchLoaded: boolean;
    pricingProfileName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
    priceArr: Array<any>;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchLoaded: false,
      branchId: this.props.match.params.branchId,
      pricingProfileId: this.props.match.params.pricingProfileId,
      pricingProfileName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
      priceArr: [],
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  componentDidUpdate = () => {
    if (
      this.state.pricingProfileId !== this.props.match.params.pricingProfileId
    ) {
      this.setState(
        { pricingProfileId: this.props.match.params.pricingProfileId },
        this.updatePricingProfileData
      );
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdate = () => {
    this.setState({ updateLoading: false });
    this.props.refreshNavbar();
    this.requestBranch();
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
        },
        this.updatePricingProfileData
      );
    }
  };

  updatePricingProfileData = () => {
    if (
      this.state.pricingProfileId &&
      this.state.branch?.priceModels &&
      this.state.pricingProfileId in this.state.branch.priceModels
    ) {
      const pricingProfile =
        this.state.branch.priceModels[this.state.pricingProfileId];

      let priceArr = valToArray(pricingProfile.prices);
      priceArr = priceArr.map((item: any) => ({
        ...item,
        timeStr: minTimeToStrLeadingZero(item.id),
      }));

      priceArr.sort((a, b) => a.id - b.id);

      this.setState(
        {
          pricingProfileName: pricingProfile.name,
          priceArr,
        },
        this.checkChange
      );
    }
  };

  updatePricingProfile = () => {
    this.setState({ updateLoading: true });
    const prices: any = {};
    this.state.priceArr.forEach((item: any) => {
      prices[item.id] = item.val;
    });
    fetchRetry(
      "updatePriceModel",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        priceModelId: this.state.pricingProfileId,
        name: this.state.pricingProfileName,
        prices,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handlePricingProfileNameChange = (val: any) => {
    this.setState({ pricingProfileName: val }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.pricingProfileName !==
        this.state.branch.priceModels[this.state.pricingProfileId]?.name,
    });
  };

  handleDeletePricingProfile = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handlePricingProfileDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deletePriceModel",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        priceModelId: this.state.pricingProfileId,
      },
      1,
      5
    )
      .then(this.handleSuccessPricingProfileDeleted)
      .catch(this.handleError);
  };

  handleSuccessPricingProfileDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.setState(
        {
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/pricing-profiles`,
        },
        () => {
          this.updatePricingProfile();
          this.props.refreshNavbar();
        }
      );
    }
  };

  updatePrice = (index: number, price: number) => {
    const priceArr = this.state.priceArr;
    priceArr[index].val = price * 100;
    this.setState({ priceArr });
  };

  deletePricing = (index: number) => {
    const priceArr = this.state.priceArr;
    priceArr.splice(index, 1);
    this.setState({ priceArr });
  };

  createPricing = (index: number) => {
    const priceArr = this.state.priceArr;
    const id = priceArr[index].id;
    const val = priceArr[index].val;
    priceArr.push({ timeStr: minTimeToStrLeadingZero(id), id: id, val: val });
    priceArr.sort((a, b) => a.id - b.id);
    this.setState({ priceArr });
  };

  updateTime = (index: number, val: any) => {
    const priceArr = this.state.priceArr;
    const timeStr = val.target.value;
    priceArr[index].id = strTimeToMin(timeStr);
    priceArr[index].timeStr = timeStr;
    priceArr.sort((a, b) => a.id - b.id);
    this.setState({ priceArr }, this.checkChange);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du das Preisprofil "${
            this.state.pricingProfileId &&
            this.state.branch.priceModels[this.state.pricingProfileId]?.name
              ? this.state.branch.priceModels[this.state.pricingProfileId].name
              : "-"
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handlePricingProfileDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.pricingProfileId &&
              Object.keys(this.state.branch.priceModels).length > 0 && (
                <>
                  <div className="subscreen-box-container">
                    <Title title="Einstellungen" />
                    <SubTitle title="Name" />
                    <Input
                      name="Preisprofilname"
                      placeholder="Preisprofilname"
                      value={this.state.pricingProfileName}
                      onChange={this.handlePricingProfileNameChange}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Preise</h3>
                    </div>
                    <table className="pricing-table">
                      <tr>
                        <th></th>
                        <th>Zeit</th>
                        <th>Preis</th>
                        <th></th>
                      </tr>
                      {this.state.priceArr.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="pricing-icon-container">
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              size="lg"
                              className="pricing-icon"
                              onClick={() => {
                                this.deletePricing(index);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="pricing-time-input"
                              name="time"
                              type="time"
                              value={item.timeStr}
                              placeholder="Zeit"
                              onChange={(val: any) => {
                                this.updateTime(index, val);
                              }}
                            />
                          </td>
                          <td>
                            <Input
                              name="title-input"
                              placeholder="Preis in Euro"
                              value={item.val / 100}
                              onChange={(price: number) => {
                                this.updatePrice(index, price);
                              }}
                              className="pricing-price-input"
                              type="number"
                              min={0}
                            />
                          </td>
                          <td className="pricing-icon-container">
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              size="lg"
                              className="pricing-icon"
                              onClick={() => {
                                this.createPricing(index);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </table>

                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updatePricingProfile}
                      title="Speichern"
                    />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.deleteLoading}
                      onClick={this.handleDeletePricingProfile}
                      title="Preisprofil löschen"
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
