import React from "react";
import { RouteComponentProps, Redirect, Link } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import "./Branches.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Input from "../../components/Input";
import Switch from "../../components/Switch";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import ErrorMessage from "../../components/ErrorMessage";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Branches extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    branchId: string;
    branchArr: Array<any>;
    branchName: string;
    change: boolean;
    updateLoading: boolean;
    showCreateBranchModal: boolean;
    createBranchLoading: boolean;
    redirect: null | string;
    branchPublic: boolean;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
    errMsg: null | string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      branchId: this.props.match.params.branchId,
      branchArr: [],
      branchName: "",
      change: false,
      updateLoading: false,
      showCreateBranchModal: false,
      createBranchLoading: false,
      redirect: null,
      branchPublic: false,
      deleteLoading: false,
      showConfirmationModal: false,
      errMsg: null,
    };
  }

  componentDidMount = () => {
    this.requestClub();
  };

  componentDidUpdate = () => {
    if (this.state.branchId !== this.props.match.params.branchId) {
      this.setState(
        { branchId: this.props.match.params.branchId },
        this.updateBranchData
      );
    }
  };

  requestClub = () => {
    fetchRetry(
      "getClubFromId",
      { clubId: this.state.clubId, targetUserId: this.context.selectedUserId },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  updateBranch = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: this.state.branchName,
        public: this.state.branchPublic,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestClub();
      this.props.refreshNavbar();
      this.setState({ errMsg: null });
    } else {
      this.setState({ errMsg: data.errorMsgDe });
    }
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      const branchArr: any = [];
      for (const branchId in club.branches) {
        const branch = club.branches[branchId];
        branch.id = branchId;
        branchArr.push(branch);
      }
      branchArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      this.setState(
        {
          club: club,
          branchArr,
        },
        this.updateBranchData
      );
    }
  };

  updateBranchData = () => {
    if (this.state.branchId in this.state.club?.branches) {
      const branch = this.state.club.branches[this.state.branchId];
      this.setState(
        {
          branchName: branch.name,
          branchPublic: branch.public,
        },
        this.checkChange
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleBranchNameChange = (val: any) => {
    this.setState({ branchName: val }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.branchName !==
          this.state.club.branches[this.state.branchId]?.name ||
        this.state.branchPublic !==
          this.state.club.branches[this.state.branchId]?.public,
    });
  };

  handleDeleteBranch = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleBranchDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleSuccessBranchDeleted)
      .catch(this.handleError);
  };

  handleSuccessBranchDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.setState(
        {
          redirect: `/club-settings/${this.state.clubId}/branches/`,
        },
        this.updateBranchData
      );
      this.props.refreshNavbar();
      this.context.getClub(this.state.clubId);
    }
  };

  handlePublicChange = () => {
    this.setState({ branchPublic: !this.state.branchPublic }, this.checkChange);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.clubLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du den Bereich "${
            this.state.club?.branches &&
            this.state.club?.branches[this.state.branchId]?.name
              ? this.state.club.branches[this.state.branchId].name
              : ""
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleBranchDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.club.branches &&
              Object.keys(this.state.club.branches).length > 0 &&
              this.state.branchId && (
                <>
                  <div className="subscreen-box-container">
                    <div className="branches-title-container">
                      <h1>Einstellungen</h1>
                      <Link
                        to={`/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users`}
                        className="club-settings-container"
                      >
                        Bereich-Einstellungen
                      </Link>
                    </div>
                    <div className="branch-subtitle-container">
                      <h3>Name</h3>
                    </div>
                    <Input
                      name="max-booking-length"
                      value={this.state.branchName}
                      placeholder="Bereich name"
                      onChange={this.handleBranchNameChange}
                    />
                    <div className="branch-subtitle-container">
                      <h3>Öffentlich</h3>
                    </div>
                    <Switch
                      value={this.state.branchPublic}
                      onChange={this.handlePublicChange}
                    />
                    <div className="medium-space"></div>
                    <ErrorMessage message={this.state.errMsg} />
                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updateBranch}
                      title="Speichern"
                    />
                    {this.context.isAdmin && (
                      <SecButton
                        change={true}
                        color="red"
                        loading={this.state.deleteLoading}
                        onClick={this.handleDeleteBranch}
                        title="Bereich löschen"
                      />
                    )}
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
