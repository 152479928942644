import React from "react";
import "./CourseSkeletonLoader.css";
import "./CourseElementSkeleton.css";

export default class CourseSkeletonLoader extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="f-skeleton course-skeleton-loader"></div>;
  }
}
