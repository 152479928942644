import React from "react";
import { weekDaysSection } from "../services/constants";
import "./DateSectionSelector.css";
import DropDown from "./DropDown";
import ErrorMessage from "./ErrorMessage";

export default class DateSectionSelector extends React.Component<
  {
    sectorStartDate: string;
    handleSectorStartDateChange: any;
    sectorEndDate: string;
    handleSectorEndDateChange: any;
    addSector: any;
    removeSector: any;
    editSectorErrMsg: null | string;
    weekDaySection: string;
    handleWeekDaySectionChange: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="sector-select-main-container margin-bottom">
          <div className="sector-select-input-container">
            <div className="input-text-container">
              <div className="to-text-container">jeden</div>
              <DropDown
                options={weekDaysSection}
                onChange={this.props.handleWeekDaySectionChange}
                value={this.props.weekDaySection}
                name="locations-dropdown"
                className="input-container expireDate-container no-margin"
              />
            </div>
            <div className="input-text-container">
              <div className="to-text-container">von</div>
              <input
                type="date"
                className="input-container expireDate-container no-margin"
                value={this.props.sectorStartDate}
                onChange={(val: any) => {
                  this.props.handleSectorStartDateChange(val.target.value);
                }}
              />
            </div>
            <div className="input-text-container">
              <div className="to-text-container">bis</div>
              <input
                type="date"
                className="input-container expireDate-container no-margin"
                value={this.props.sectorEndDate}
                onChange={(val: any) => {
                  this.props.handleSectorEndDateChange(val.target.value);
                }}
              />
            </div>
          </div>
          <div className="select-sector-btn">
            <div
              className="multiple-dates-edit-btn course-add-btn"
              onClick={this.props.addSector}
            >
              Hinzufügen
            </div>
            <div
              className="multiple-dates-edit-btn course-remove-btn"
              onClick={this.props.removeSector}
            >
              Löschen
            </div>
          </div>
        </div>
        <ErrorMessage message={this.props.editSectorErrMsg} />
      </>
    );
  }
}
