import React from "react";
import "./Input.css";
import { Link } from "react-router-dom";
import { FeedEntrys } from "../screens/BranchSettingSubScreens/Feed";

export default class FeedEntry extends React.Component<
  {
    feed: FeedEntrys;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  getLink = () => {
    if (this.props.feed.type.startsWith("course")) {
      return `/club/${this.props.feed.clubId}/branch/${this.props.feed.branchId}/participants/${this.props.feed.courseId}/participant/${this.props.feed.courseBooking}?back=/club/${this.props.feed.clubId}/branch-settings/${this.props.feed.branchId}/feed`;
    } else if (this.props.feed.type.startsWith("membership")) {
      return `/club/${this.props.feed.clubId}/branch/${this.props.feed.branchId}/subscription-participants/${this.props.feed.subscriptionId}/participant/${this.props.feed.courseBooking}?back=/club/${this.props.feed.clubId}/branch-settings/${this.props.feed.branchId}/feed`;
    }
    return "";
  };

  render() {
    return (
      <>
        {this.getLink() ? (
          <Link to={this.getLink()} className="no-underline feed-color">
            <div className="h-full-center">{this.props.feed.message}</div>
          </Link>
        ) : (
          <p>{this.props.feed.message}</p>
        )}
      </>
    );
  }
}
