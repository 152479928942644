import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./SingleCourse.css";
import "../../components/CourseElement.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  addDayToDate,
  calcSubscription,
  dateToFullStr,
  firebaseDateToDateObj,
  getAgeStr,
  getRightSeperator,
  getSubscriptionPriceString,
  makeid,
} from "../../functions/utils";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import ErrorMessage from "../../components/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestMode from "../../components/TestMode";
import Title from "../../components/Title";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import BookButton from "../../components/BookButton";
import SubscriptionTerms from "../../components/SubscriptionTerms";
import qs from "qs";
import InfoContainer from "../../components/InfoContainer";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    subscriptionId: string;
  }>;

export default class SingleSubscription extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    redirectTo: string;
    branchLoaded: boolean;
    subscriptionLoaded: boolean;
    bookSubscriptionLoading: boolean;
    branch: any;
    subscription: any;
    redirect: string | null;
    change: boolean;
    errorMsg: null | string;
    termsAccepted: boolean;
    showAcceptTermsMessage: boolean;
    initialPrice: number;
    sessionId: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      subscriptionId: this.props.match.params.subscriptionId,
      redirectTo: window.location.href.split("/")[5],
      branchLoaded: false,
      subscriptionLoaded: false,
      bookSubscriptionLoading: false,
      branch: {},
      subscription: {},
      redirect: null,
      change: false,
      errorMsg: null,
      termsAccepted: false,
      showAcceptTermsMessage: false,
      initialPrice: 0,
      sessionId: makeid(32),
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestSubscription();
  };

  componentWillUnmount() {
    document.getElementById("paypal-fraud-1")?.remove();
    document.getElementById("paypal-fraud-2")?.remove();
  }

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    if (data.success) {
      const branch = data.data;
      this.setState({
        branchLoaded: true,
        branch,
      });
      const paypalWebsiteId = this.state.branch?.paypalWebsiteId;
      if (paypalWebsiteId) {
        const script = document.createElement("script");
        script.innerHTML = `{
        "f": "${this.state.sessionId}",
        "s": "${paypalWebsiteId}"
     }`;
        script.type = "application/json";
        script.setAttribute(
          "fncls",
          "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99"
        );
        script.setAttribute("id", "paypal-fraud-1");
        const paypalFraudScript = document.createElement("script");

        paypalFraudScript.src = "https://c.paypal.com/da/r/fb.js";
        paypalFraudScript.type = "text/javascript";
        paypalFraudScript.setAttribute("id", "paypal-fraud-2");

        document.body.appendChild(script);
        document.body.appendChild(paypalFraudScript);
      }
    }
  };

  requestSubscription = () => {
    fetchRetry(
      "getSubscriptionFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleSubscriptionSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleSubscriptionSuccess = ({ data }: any) => {
    if (data.success) {
      const subscription = data.data[0];
      this.setState({
        subscriptionLoaded: true,
        subscription,
        initialPrice: calcSubscription(
          subscription.price,
          subscription.billingPeriod,
          subscription.admissionFee
        ),
      });
    }
  };

  bookSubscription = () => {
    if (this.state.termsAccepted) {
      const redirectUrl = `${window.location.origin}/club/${this.state.clubId}/branch/${this.state.branchId}/subscriptionBooking/`;
      if (!this.state.bookSubscriptionLoading) {
        if (this.context.userLoaded) {
          this.setState({ bookSubscriptionLoading: true });
          fetchRetry(
            "bookSubscriptionF",
            {
              targetUserId: this.context.selectedUserId,
              clubId: this.state.clubId,
              branchId: this.state.branchId,
              subscriptionId: this.state.subscriptionId,
              price: this.state.subscription.price,
              priceInitial: this.state.initialPrice,
              redirectUrl: redirectUrl,
              cancellationDays: this.state.subscription.cancellationDays,
              sessionId: this.state.sessionId,
            },
            1,
            5
          )
            .then(this.handleBookSubscriptionSuccess)
            .catch(this.handleError);
        }
      }
    } else {
      this.setState({ showAcceptTermsMessage: true });
    }
  };

  handleBookSubscriptionSuccess = ({ data }: any) => {
    this.setState({ bookSubscriptionLoading: false });
    if (data.success) {
      this.setState({ errorMsg: null });
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      } else {
        window.location.href = `${window.location.origin}/club/${this.state.clubId}/branch/${this.state.branchId}/subscriptionBooking/${data.subscriptionBookingId}/${data.clientKey}`;
        this.requestSubscription();
      }
    } else {
      this.setState({ errorMsg: data.errorMsgDe });
      if (data.code === "user-data-needed") {
        if (this.state.redirectTo === "branchSubscription") {
          const singleSubscriptionUrls = `/club/${this.state.clubId}/branchSubscription/${this.state.branchId}/${this.state.subscriptionId}`;
          this.setState({
            redirect: `/club/${this.state.clubId}/profile?redirect=${singleSubscriptionUrls}&complete=true`,
          });
        } else {
          const singleSubscriptionUrls = `/club/${this.state.clubId}/branch/${this.state.branchId}/subscription/${this.state.subscriptionId}`;
          this.setState({
            redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/profile?redirect=${singleSubscriptionUrls}&complete=true`,
          });
        }
      }
    }
  };

  toggleTermsAccepted = () => {
    if (!this.state.termsAccepted) {
    }
    this.setState({
      termsAccepted: !this.state.termsAccepted,
      showAcceptTermsMessage: this.state.termsAccepted,
    });
  };

  render() {
    if (
      !this.state.branchLoaded ||
      !this.state.subscriptionLoaded ||
      (this.context.user && !this.context.userLoaded)
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const cancellationDate = !this.state.subscription?.cancellationDays
      ? null
      : addDayToDate(
          firebaseDateToDateObj(new Date()),
          -this.state.subscription?.cancellationDays
        );

    const bookableFromDate = this.state.subscription
      ? firebaseDateToDateObj(this.state.subscription.bookableDate)
      : null;
    const bookableFrom = bookableFromDate
      ? bookableFromDate > new Date()
        ? bookableFromDate
        : null
      : null;

    const branchRoleObj = this.state.branch?.roles
      ? this.state.branch.roles
      : {};
    branchRoleObj["default"] = {
      name: "Regulärer Preis",
      priority: -2,
    };
    branchRoleObj["public"] = {
      name: "Registrierter Benutzer Preis",
      priority: -1,
    };

    const alternativePriceArr: Array<any> = (
      this.state.subscription?.priceArr ? this.state.subscription.priceArr : []
    )
      .filter(
        (currPriceElem: any) =>
          (currPriceElem.showAlways ||
            currPriceElem.id === this.state.subscription.priceRole) &&
          branchRoleObj[currPriceElem.id]?.name
      )
      .map((currPriceElem: any) => {
        currPriceElem.roleName = branchRoleObj[currPriceElem.id]?.name;
        currPriceElem.priority = branchRoleObj[currPriceElem.id]?.priority;
        currPriceElem.used =
          currPriceElem.id === this.state.subscription.priceRole;
        return currPriceElem;
      });

    const allowedRoleNameArr: Array<any> = this.state.subscription?.allowedRoles
      .filter((currRole: any) => branchRoleObj[currRole]?.name)
      .map((currRole: any) => {
        return {
          name: branchRoleObj[currRole]?.name,
          useMemApp:
            this.state.branch?.clubSettings?.memAppRoleArr?.includes(currRole),
        };
      });

    alternativePriceArr.sort((a: any, b: any) =>
      a.priority < b.priority ? 1 : -1
    );

    if (!this.state.subscription) {
      return (
        <>
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              <BackElement
                text="zurück zur Mitgliedschaftsauswahl"
                to={
                  this.state.redirectTo === "branchSubscription"
                    ? `/club/${this.state.clubId}/course#${this.state.subscriptionId}`
                    : `/club/${this.state.clubId}/branch/${this.state.branchId}/course?showSub=true#${this.state.subscriptionId}`
                }
              />
              <div className="full-center">
                <span className="not-exist-text">
                  Dieses Angebot existiert nicht oder du darfst es nicht sehen.
                </span>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <>
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              <BackElement
                text="zurück zur Mitgliedschaftsauswahl"
                to={
                  this.state.redirectTo === "branchSubscription"
                    ? `/club/${this.state.clubId}/course#${this.state.subscriptionId}`
                    : `/club/${this.state.clubId}/branch/${this.state.branchId}/course?showSub=true#${this.state.subscriptionId}`
                }
              />
              <Title title="Mitgliedschaft buchen" />
              <TestMode active={this.state.branch?.billing?.mode === "test"} />
              <div className="courseBooking-course-info-container">
                <div>
                  {!this.state.subscription?.imgLowRes ? (
                    <div className="single-course-img">
                      <FontAwesomeIcon
                        icon={faImage}
                        size="6x"
                        className="image-icon"
                      />
                    </div>
                  ) : (
                    <img
                      className="single-course-img"
                      src={this.state.subscription?.imgLowRes}
                      alt="Kursbild"
                    />
                  )}
                </div>
                <div>
                  <h2>{this.state.subscription?.title}</h2>
                  <span>{this.state.subscription?.description}</span>

                  {(this.state.subscription?.minAge ||
                    this.state.subscription?.maxAge) && (
                    <div className="course-text-container">
                      Alter:{" "}
                      <span className="normal-weight">
                        {getAgeStr(
                          this.state.subscription.minAge,
                          this.state.subscription.maxAge
                        )}
                      </span>
                    </div>
                  )}
                  <div className="course-text-container">
                    Preis:{" "}
                    {alternativePriceArr.length <= 1 ? (
                      <span className="normal-weight">
                        {getSubscriptionPriceString(
                          this.state.subscription.price,
                          this.state.subscription.admissionFee,
                          this.state.subscription.billingPeriod
                        )}
                      </span>
                    ) : (
                      <div className="course-text-container">
                        <table className="alternative-price-table">
                          <tbody>
                            {alternativePriceArr.map(
                              (price: any, index: number) => (
                                <tr
                                  key={index}
                                  className={`${
                                    price.used ? "alternative-price-used" : ""
                                  }`}
                                >
                                  <td>
                                    {price.roleName}
                                    {price.used ? " (Dein Preis)" : ""}:
                                  </td>
                                  <td>
                                    {getSubscriptionPriceString(
                                      price.price,
                                      price.admissionFee,
                                      this.state.subscription?.billingPeriod
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  {this.state.subscription.userAllowedToBook ? (
                    <>
                      <div className="medium-space"></div>
                      <InfoContainer>
                        Abrechnung per Lastschrift über Trustly, Kreditkarte
                        oder PayPal.
                      </InfoContainer>
                      {this.context.user && (
                        <>
                          <SubscriptionTerms
                            termsAccepted={this.state.termsAccepted}
                            toggleTermsAccepted={this.toggleTermsAccepted}
                            initialPrice={this.state.initialPrice}
                            price={this.state.subscription?.price}
                            billingPeriod={
                              this.state.subscription?.billingPeriod
                            }
                            admissionFee={this.state.subscription?.admissionFee}
                            startDate={new Date()}
                            noticePeriod={this.state.subscription?.noticePeriod}
                            noticePeriodDateUnit={
                              this.state.subscription?.noticePeriodDateUnit
                            }
                            cancellationDays={
                              this.state.subscription?.cancellationDays
                            }
                            cancellationDate={cancellationDate}
                            showAcceptTermsMessage={
                              this.state.showAcceptTermsMessage
                            }
                          />

                          <ErrorMessage message={this.state.errorMsg} />
                          <BookButton
                            loading={this.state.bookSubscriptionLoading}
                            onClick={this.bookSubscription}
                            title={
                              bookableFrom
                                ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                                : "Abonnement starten"
                            }
                            className={`course-book-btn ${
                              bookableFrom ? " course-book-btn-disabled" : ""
                            }`}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <br />
                      Diese Mitgliedschaft ist nur für diese Gruppen buchbar:
                      <div className="subscription-text-container">
                        <table className="alternative-price-table">
                          <tbody>
                            {allowedRoleNameArr.map(
                              (role: any, index: number) => (
                                <tr
                                  key={index}
                                  className="alternative-price-used"
                                >
                                  <td>
                                    - {role.name}
                                    {role.useMemApp && (
                                      <>
                                        <a
                                          href={`${
                                            this.state.branch?.clubSettings
                                              ?.requestMembershipUrl
                                          }${getRightSeperator(
                                            this.state.branch?.clubSettings
                                              ?.requestMembershipUrl
                                          )}${qs.stringify({
                                            redirect: window.location.href,
                                          })}`}
                                          className="req-memApp-link"
                                        >
                                          Jetzt Mitglied werden
                                        </a>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
