import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import "firebase/performance";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDkriY1LrGnB6iLXtDSmyDE8zDjgvrcdk0",
  // authDomain: "buchungstool-90848.firebaseapp.com",
  authDomain: "auth.getbookable.de",
  projectId: "buchungstool-90848",
  storageBucket: "buchungstool-90848.appspot.com",
  messagingSenderId: "275706421618",
  appId: "1:275706421618:web:e7340000bdb29a3ac63deb",
  measurementId: "G-XT581RKWH2",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export const fc = app.functions("europe-west3");
export const storage = app.storage();
export const firestore = app.firestore();
export default app;
