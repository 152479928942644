import React from "react";
import { FormElementView } from "../../../Types";
import Input from "../../Input";
import MultipleChoise from "../editor/MultipleChoise";
import SwitchChoise from "../editor/SwitchChoise";

export default class ViewFormElement extends React.Component<
  {
    formElem: FormElementView;
    onValueChange: (index: number, value: string) => void;
    onActiveChange: (index: number, isActive: boolean) => void;
    active: boolean;
    value: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  toggleActive = () => {
    this.props.onActiveChange(this.props.formElem.id, !this.props.active);
  };

  render() {
    return (
      <div className="edit-element-container">
        <div className="edit-element-container-inner">
          <strong>{this.props.formElem.label}</strong>
          {this.props.formElem.type === "multiple-choice" &&
            this.props.formElem.options !== null && (
              <MultipleChoise
                id={"elem-" + this.props.formElem.id}
                options={this.props.formElem.options}
                onChange={(value: string) => {
                  this.props.onValueChange(this.props.formElem.id, value);
                }}
              />
            )}

          {this.props.formElem.type === "switch" && (
            <SwitchChoise
              id={"elem-" + this.props.formElem.id}
              onChange={this.toggleActive}
              value={this.props.active}
            />
          )}

          {this.props.formElem.type === "switch-dependent-textinput" && (
            <div className="switch-textbox-wrapper">
              <SwitchChoise
                id={"elem-" + this.props.formElem.id}
                onChange={this.toggleActive}
                value={this.props.active}
              />
              {this.props.active && (
                <div className="flex-1">
                  <Input
                    onChange={(value: string) => {
                      this.props.onValueChange(this.props.formElem.id, value);
                    }}
                    value={this.props.value}
                    className="no-margin"
                  />
                </div>
              )}
            </div>
          )}

          {this.props.formElem.type === "textinput" && (
            <Input
              onChange={(value: string) => {
                this.props.onValueChange(this.props.formElem.id, value);
              }}
              value={this.props.value}
            />
          )}
        </div>
      </div>
    );
  }
}
