import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./MultiStringSelector.css";

export default class MultiStringSelector extends React.Component<
  {
    arr: Array<any>;
    onSelect: any;
    onUnselect: any;
    inputPlaceholder: string;
  },
  { text: string }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      text: "",
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleChangeText = (val: any) => {
    this.setState({ text: val.target.value });
  };

  handleKeyEvent = (e: any) => {
    if (e.key === "Enter" || e.key === " ") {
      this.props.onSelect(this.state.text);
      this.setState({ text: "" });
    } else if (e.key === "Backspace") {
      if (this.state.text.length === 0 && this.props.arr.length > 0) {
        const lastEntry = this.props.arr[this.props.arr.length - 1];
        this.setState({ text: lastEntry.name });
        this.props.onUnselect(lastEntry.id);
      }
    }
  };

  render() {
    return (
      <>
        <div className="multistringselector-container">
          <div className="multiselector-container">
            {this.props.arr.map((item: any) => (
              <div
                className="multiselector-element-container"
                style={{ borderColor: item.color }}
                key={item.id}
              >
                <div
                  className="point"
                  style={{ backgroundColor: item.color }}
                  onClick={() => {
                    this.props.onUnselect(item.id);
                  }}
                >
                  <div className="cross-container">
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </div>
                </div>
                {item.name}
              </div>
            ))}
          </div>
          <input
            type="text"
            value={this.state.text}
            onChange={this.handleChangeText}
            onKeyDown={this.handleKeyEvent}
            placeholder={this.props.inputPlaceholder}
          />
        </div>
      </>
    );
  }
}
