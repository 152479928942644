import { getMonthNum } from "./utils";
import { monthToDate, priceToStr } from "./utils";

export const getPaymentTable = (
  courseBookingData: any,
  manuelly: boolean = false
) => {
  const subscriptionPaymentObj: any = {};
  const paymentArr: Array<any> = [];
  const isActive = courseBookingData.active;
  const isCancelled = courseBookingData.subscriptionCancelled;
  const pausedMonths = courseBookingData.pausedMonthsFlat
    ? courseBookingData.pausedMonthsFlat
    : [];
  if (courseBookingData.billing === "subscription") {
    // get start month
    const startMonth = courseBookingData.startMonth;
    // get end month
    let endMonth = courseBookingData.endMonth;
    courseBookingData.subscriptionPayments.forEach((currPayment: any) => {
      subscriptionPaymentObj[currPayment.month] = currPayment;
    });
    const currentMonth = getMonthNum(new Date());
    if (isActive && !isCancelled) {
      endMonth++;
    }
    if (endMonth < currentMonth + 1 && isActive && !isCancelled) {
      endMonth = currentMonth + 1;
    }

    const lastPaymentMonth =
      parseInt(Object.keys(subscriptionPaymentObj).slice(-1)[0]) || 0;
    paymentArr.push({
      monthStr: monthToDate(startMonth - 1),
      monthNum: startMonth - 1,
      refunded: courseBookingData.refunded,
      refundStatus: courseBookingData.refundStatus,
      hasChargebacks: courseBookingData.hasChargebacks,
      chargebackAmount: courseBookingData.chargebackAmount,
      paymentStatus: courseBookingData.paymentStatus,
      priceStr: `${priceToStr(courseBookingData.initialPrice)} €`,
    });
    for (
      let i = startMonth;
      i <= Math.max(...[endMonth, lastPaymentMonth]);
      i++
    ) {
      const isPaused = pausedMonths.includes(i);
      const monthPayment = subscriptionPaymentObj[i]
        ? subscriptionPaymentObj[i]
        : {
          paymentStatus: isActive
            ? isPaused
              ? "paused"
              : manuelly
                ? "manuelly"
                : "pending"
            : "canceled",
        };
      const price = isPaused
        ? 0
        : monthPayment.price || courseBookingData.price;

      paymentArr.push({
        monthStr: monthToDate(i),
        monthNum: i,
        refunded: monthPayment.refunded,
        refundStatus: monthPayment.refundStatus,
        hasChargebacks: monthPayment.hasChargebacks,
        chargebackAmount: monthPayment.chargebackAmount,
        paymentStatus: monthPayment.paymentStatus,
        priceStr: `${priceToStr(price)} €`,
      });
    }
  }
  return paymentArr;
};
