import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./SingleSelector.css";

export default class SingleSelector extends React.Component<
  {
    arr: Array<any>;
    onSelect: any;
    searchTextPlaceholder?: string;
    className?: string;
    selectedElem: any;
  },
  { showAddUserWindow: boolean; searchText: string }
> {
  private wrapperRef: any;
  constructor(props: any) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      showAddUserWindow: false,
      searchText: "",
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.state.showAddUserWindow) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.hideAddUserWindow();
      }
    }
  }

  showAddUserWindow = () => {
    this.setState({ showAddUserWindow: true });
  };

  hideAddUserWindow = () => {
    this.setState({ showAddUserWindow: false });
  };

  toggleAddUserWindow = () => {
    this.setState({ showAddUserWindow: !this.state.showAddUserWindow });
  };

  handleChangeSearchText = (val: any) => {
    this.setState({ searchText: val.target.value });
  };

  render() {
    return (
      <>
        <div className={this.props.className}>
          <div className="singleselector-container" ref={this.wrapperRef}>
            <div
              className="singleselector-element-container singleselector-add-element-container"
              onClick={this.showAddUserWindow}
            >
              {this.props.searchTextPlaceholder}:{" "}
              {this.props.selectedElem?.name}
            </div>
            <div
              className={`singleselector-add-container${
                this.state.showAddUserWindow
                  ? " singleselector-show-add-container"
                  : ""
              }`}
            >
              <div className="singleselector-add-text-container">
                <input
                  placeholder={this.props.searchTextPlaceholder}
                  value={this.state.searchText}
                  onChange={this.handleChangeSearchText}
                />
                <div className="icon-container">
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </div>
              </div>
              <div className="singleselector-scroll-container">
                {this.props.arr
                  .filter((item: any) =>
                    item.name
                      .toLowerCase()
                      .includes(this.state.searchText.toLowerCase())
                  )
                  .map((item: any) => (
                    <div
                      className="singleselector-select-element-container"
                      onClick={() => {
                        this.props.onSelect(item);
                        this.hideAddUserWindow();
                      }}
                      key={item.id}
                    >
                      <div
                        className="point"
                        style={{ backgroundColor: item.color }}
                      ></div>
                      {item.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
