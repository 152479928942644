import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./CourseNotificationModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SwitchSetting from "../SwitchSetting";
import SecButton from "../SecButton";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import ClipLoader from "react-spinners/ClipLoader";

export default class CourseNotificationModal extends React.Component<
  {
    show: boolean;
    title: string;
    handleClose: any;
    clubId: string;
    branchId: string;
    courseId: string;
    isInfinityCourse: boolean;
  },
  {
    onBooking: boolean;
    onCancellation: boolean;
    onBookingOld: boolean;
    onCancellationOld: boolean;
    updateLoading: boolean;
    loading: boolean;
    change: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      onBooking: false,
      onCancellation: false,
      onBookingOld: false,
      onCancellationOld: false,
      updateLoading: false,
      loading: true,
      change: false,
    };
  }

  componentDidMount = () => {
    this.getNotificationSettings();
  };

  getNotificationSettings = () => {
    fetchRetry(
      "getCourseNotification",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        courseId: this.props.courseId,
      },
      1,
      5
    )
      .then(this.handleNotificationData)
      .catch(this.handleError);
  };

  handleNotificationData = ({ data }: any) => {
    this.setState({ loading: false });
    if (data.success) {
      const retData = data.data;
      this.setState(
        {
          onBooking: retData.notifyOnBooking,
          onCancellation: retData.notifyOnCancellation,
          onBookingOld: retData.notifyOnBooking,
          onCancellationOld: retData.notifyOnCancellation,
        },
        this.checkChange
      );
    } else {
      this.handleError(data.errorMsgDe);
    }
  };

  handleUpdateNotificationSettings = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateCourseNotification",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        courseId: this.props.courseId,
        notifyOnBooking: this.state.onBooking,
        notifyOnCancellation: this.state.onCancellation,
      },
      1,
      5
    )
      .then(this.handleUpdateNotificationSettingsConfirmed)
      .catch(this.handleError);
  };

  handleUpdateNotificationSettingsConfirmed = () => {
    this.setState({ updateLoading: false });
    this.getNotificationSettings();
  };

  handleError = (err: any) => {
    this.showErrorMsg();
    console.error(err);
  };

  showErrorMsg = () => {
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.onBooking !== this.state.onBookingOld ||
        this.state.onCancellation !== this.state.onCancellationOld,
    });
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="confirm"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="confirm-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">{this.props.title}</span>
            </div>
            {this.state.loading ? (
              <div className="modal-loading-container">
                <ClipLoader color={"#6D6D6D"} size={50} loading={true} />
              </div>
            ) : (
              <div>
                <SwitchSetting
                  name="Bei Buchung"
                  value={this.state.onBooking}
                  onChange={() => {
                    this.setState(
                      { onBooking: !this.state.onBooking },
                      this.checkChange
                    );
                  }}
                />
                <SwitchSetting
                  name={
                    this.props.isInfinityCourse
                      ? "Bei Kündigung"
                      : "Bei Stornierung"
                  }
                  value={this.state.onCancellation}
                  onChange={() => {
                    this.setState(
                      { onCancellation: !this.state.onCancellation },
                      this.checkChange
                    );
                  }}
                />
              </div>
            )}
            <div className="large-space"></div>
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.handleUpdateNotificationSettings}
              title="Speichern"
            />
          </div>
        </Modal>
      </>
    );
  }
}
