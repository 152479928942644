import { monthToDate } from "./utils";

export const getBookingStatus = (courseBookingData: any) => {
  if (courseBookingData.billing === "subscription") {
    if (
      courseBookingData.paymentStatus === "expired" ||
      courseBookingData.paymentStatus === "canceled"
    )
      return "Verfallen";
    if (courseBookingData.subscriptionCancelled)
      return `Gekündigt (Letzer Monat: ${monthToDate(
        courseBookingData.endMonth
      )})`;
    if (courseBookingData.active) return "Aktiv";
    return "Nicht Gebucht";
  } else {
    if (courseBookingData.active) return "Gebucht";
    if (courseBookingData.refunded) return "Storniert";
    return "Nicht Gebucht";
  }
};
