import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./BranchModal.css";
import "./EnterMembershipModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageContainer from "../MessageContainer";
import SecButton from "../SecButton";
import Input from "../Input";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SubTitle from "../SubTitle";
import { reformatDate } from "../../functions/utils";

export default class EnterMembershipModal extends React.Component<
  {
    clubId: string;
    show: boolean;
    clubName: string;
    membershipName: string;
    handleClose: any;
    useMembershipnumber: boolean;
    askForMembershipnumber: boolean;
    useBirthDate: boolean;
    useAddress: boolean;
    usePhoneNumber: boolean;
    alreadyMemberBtnText: string;
    redirect?: string | null;
  },
  {
    membershipId: string;
    loading: boolean;
    msg: string;
    msgType: "error" | "success";
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    requestNeeded: boolean;
    branchName: string;
    branchId: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      membershipId: "",
      loading: false,
      msg: "",
      msgType: "success",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      requestNeeded: false,
      branchName: "",
      branchId: null,
    };
  }

  handleMembershipIdChange = (val: string) => {
    this.setState({ membershipId: val });
  };

  handleUpdateUserData = () => {
    this.setState({ loading: true });
    const userAddress = this.context.selectedUserData?.address;
    const gotAddress =
      userAddress?.city &&
      userAddress?.houseNumber &&
      userAddress?.street &&
      userAddress?.zipcode;
    const gotPhoneNumber = !!this.context.selectedUserData?.phoneNumber;
    const gotBirthDate = !!this.context.selectedUserData?.birthDate;
    const updateData: any = {
      targetUserId: this.context.selectedUserId,
    };
    if (this.props.useAddress && !gotAddress) {
      updateData.address = {
        city: this.state.city,
        zipcode: this.state.zipcode,
        street: this.state.street,
        houseNumber: this.state.houseNumber,
      };
    }
    if (this.props.usePhoneNumber && !gotPhoneNumber) {
      updateData.phoneNumber = this.state.phoneNumber;
    }
    if (this.props.useBirthDate && !gotBirthDate) {
      updateData.birthDate = reformatDate(this.state.birthDate);
    }
    fetchRetry("updateUserDataFNew", updateData, 1, 5)
      .then(this.handleSuccessUpdatedUserData)
      .catch(this.handleError);
  };

  handleSuccessUpdatedUserData = ({ data }: any) => {
    if (data.success) {
      this.handleJoinBranch();
    } else {
      // this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleJoinBranch = () => {
    this.setState({ loading: true });
    fetchRetry(
      "updateMembershipNumber",
      {
        clubId: this.props.clubId,
        membershipNumber: this.state.membershipId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleRequestSuccess)
      .catch(this.handleError);
  };

  handleRequestSuccess = ({ data }: any) => {
    this.setState({ loading: false });
    if (data.success) {
      this.context.createInfo(
        "Du hast deine SVF-Mitgliedsnummer erfolgreich hinterlegt.",
        "success",
        4
      );
      this.context.requestClub(this.props.clubId);
      this.props.handleClose();
      if (this.props.redirect) {
        window.location.href = this.props.redirect;
      }
    } else {
      this.setState({
        msg: data.errorMsgDe,
        msgType: "error",
      });
    }
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val });
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val });
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val });
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val });
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val });
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    const userAddress = this.context.selectedUserData?.address;
    const gotAddress =
      userAddress?.city &&
      userAddress?.houseNumber &&
      userAddress?.street &&
      userAddress?.zipcode;
    const gotPhoneNumber = !!this.context.selectedUserData?.phoneNumber;
    const gotBirthDate = !!this.context.selectedUserData?.birthDate;

    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="join Branch"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="branch-modal-inner">
            <div
              className="modal-cross-container"
              onClick={() => {
                this.setState({ branchId: null, branchName: "", msg: "" });
                this.props.handleClose();
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="join-branch-cross" />
            </div>

            <div className="modal-title-container">
              <span className="modal-title">
                <small className="branch-title">{this.props.clubName}</small>
              </span>
            </div>
            <MessageContainer msg={this.state.msg} type={this.state.msgType} />
            <br />
            <div className="margin-bottom">
              <span className="branch-text">
                Um die Vorteile deiner SVF-Mitgliedschaft zu bekommen, klicke
                auf <b>"{this.props.alreadyMemberBtnText}"</b>
                .<br />
                {this.state.requestNeeded && (
                  <>
                    Sobald du vom Administrator akzeptiert wirst, kannst du
                    Buchungen in diesem Bereich vornehmen.
                  </>
                )}
              </span>
            </div>
            {this.props.useAddress && !gotAddress && (
              <>
                <SubTitle title="Adresse" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.street}
                    onChange={this.handleStreetChange}
                    placeholder="Straße"
                    className="street-input"
                  />
                  <Input
                    value={this.state.houseNumber}
                    onChange={this.handleHouseNumberChange}
                    placeholder="Nr."
                    className="house-number-input"
                  />
                </div>
                <div className="profile-location-container">
                  <Input
                    value={this.state.zipcode}
                    onChange={this.handleZipcodeChange}
                    placeholder="PLZ"
                    className="zipcode-input"
                  />
                  <Input
                    value={this.state.city}
                    onChange={this.handleCityChange}
                    placeholder="Stadt"
                    className="city-input"
                  />
                </div>
              </>
            )}
            {this.props.usePhoneNumber && !gotPhoneNumber && (
              <>
                <SubTitle title="Telefonnummer" />
                <Input
                  name="membershipId-input"
                  placeholder="Telefonnummer"
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                />
              </>
            )}
            {this.props.useBirthDate && !gotBirthDate && (
              <>
                <SubTitle title="Geburtsdatum" />
                <input
                  type="date"
                  className="input-container"
                  value={this.state.birthDate}
                  onChange={this.handleBirthDateChange}
                />
              </>
            )}
            {this.props.useMembershipnumber &&
              this.props.askForMembershipnumber && (
                <>
                  <SubTitle title={`${this.props.membershipName}`} />
                  <Input
                    name="membershipId-input"
                    placeholder={`${this.props.membershipName}`}
                    value={this.state.membershipId}
                    onChange={this.handleMembershipIdChange}
                  />
                </>
              )}
            <SecButton
              change={true}
              color="green"
              loading={this.state.loading}
              onClick={this.handleUpdateUserData}
              title={this.props.alreadyMemberBtnText}
            />
          </div>
        </Modal>
      </>
    );
  }
}
