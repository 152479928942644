import React from "react";
import "./MySubscriptions.css";
import "../App.css";
import { dateToFullStr, firebaseDateToDateObj } from "../functions/utils";
import { fetchRetry } from "../functions/request";
import Title from "../components/Title";
import SubscriptionElementOwn from "../components/SubscriptionElementOwn";
import CourseElementSkeleton from "../components/CourseElementSkeleton";
import { MainContext } from "../contexts/MainContext";

export default class MySubscriptions extends React.Component<
  {},
  {
    subscriptionsLoaded: boolean;
    subscriptions: Array<any>;
    showCreateModal: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      subscriptionsLoaded: false,
      subscriptions: [],
      showCreateModal: false,
    };
  }

  componentDidMount = () => {
    this.requestSubscriptions();
  };

  requestSubscriptions = () => {
    fetchRetry(
      "getSubscriptionsOfUserF",
      {
        limit: 50,
        onlyFuture: false,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleSubscriptionsSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleSubscriptionsSuccess = ({ data }: any) => {
    this.setState({ subscriptionsLoaded: true });
    if (data.success) {
      const subscriptions = data.data;
      subscriptions.sort((a: any, b: any) =>
        a.startTime > b.startTime ? 1 : -1
      );
      this.setState({ subscriptions });
      const highlighedSubscriptionBookingId = window.location.href.slice(
        window.location.href.indexOf("#") + 1
      );
      var element = document.getElementById(highlighedSubscriptionBookingId);
      element?.scrollIntoView();
    }
  };

  handleCloseCreateModal = () => {
    this.setState({ showCreateModal: false });
  };

  handleShowCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  render() {
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Meine Mitgliedschaften" />
            {!this.state.subscriptionsLoaded ? (
              <>
                <>
                  <CourseElementSkeleton />
                  <CourseElementSkeleton />
                </>
              </>
            ) : (
              <>
                {this.state.subscriptions.length === 0 && (
                  <div className="no-courses-container">
                    Buche deine erste Mitgliedschaft damit dieser hier angezeigt
                    wird.
                  </div>
                )}
              </>
            )}
            {this.state.subscriptions.map((subscription: any) => (
              <>
                {subscription.bookingArr.map((subscriptionBooking: any) => (
                  <SubscriptionElementOwn
                    clubId={subscription.clubId}
                    branchId={subscription.branchId}
                    subscriptionId={subscription.id}
                    subscriptionBooking={subscriptionBooking}
                    title={subscription.title}
                    description={subscription.description}
                    bookingCreated={dateToFullStr(
                      firebaseDateToDateObj(subscriptionBooking.created_at)
                    )}
                    imgUrl={subscription.imgLowRes}
                    notice={subscription.bookingMailMessage}
                    key={subscription.id}
                    status={
                      subscriptionBooking.active
                        ? "Gebucht"
                        : subscriptionBooking.refunded
                        ? "Storniert"
                        : "Nicht Gebucht"
                    }
                  />
                ))}
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}
