import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./CourseBookingConfirmation.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { generateQRCode } from "../../functions/qr";
import BackElement from "../../components/BackElement";
import Title from "../../components/Title";
import { firestore } from "../../services/firebase";
import { ClimbingBoxLoader } from "react-spinners";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseBookingId: string;
    clientKey: string | undefined;
  }>;

export default class CourseBookingConfirmation extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseBookingLoaded: boolean;
    courseBookingId: string;
    clientKey: string | undefined;
    courseBookingData: any;
    qrCode: string;
    redirect: null | string;
    listenerActive: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseBookingLoaded: false,
      courseBookingId: this.props.match.params.courseBookingId,
      clientKey: this.props.match.params.clientKey,
      courseBookingData: {},
      qrCode: "",
      redirect: null,
      listenerActive: false,
    };
  }

  componentDidMount = () => {
    this.requestCoursesBooking();
  };

  requestCoursesBooking = () => {
    this.setState({ courseBookingLoaded: false });
    fetchRetry(
      "getCourseBookingF",
      {
        courseBookingId: this.state.courseBookingId,
        clientKey: this.state.clientKey,
      },
      1,
      5
    )
      .then(this.requestCoursesBookingSuccess)
      .catch(this.handleError);
  };

  requestCoursesBookingSuccess = async ({ data }: any) => {
    this.setState({ courseBookingLoaded: true });
    if (data.success) {
      const courseBookingData = data.data;
      if (
        ["pending", "authorized", "paid", "free", "open"].includes(
          courseBookingData.paymentStatus
        )
      ) {
        this.setState({
          redirect: `/club/${courseBookingData.club.id}/branch/${
            courseBookingData.branch.id
          }/mycourses/${courseBookingData.courseId}/courseBooking/${
            this.state.courseBookingId
          }${this.state.clientKey ? `/${this.state.clientKey}` : ""}`,
        });
      } else {
        if (!this.state.listenerActive) {
          firestore
            .collection("courses")
            .doc(courseBookingData.courseId)
            .collection("courseBookings")
            .doc(this.state.courseBookingId)
            .onSnapshot(() => {
              this.requestCoursesBooking();
            });
          this.setState({ listenerActive: true });
        }
        this.setState({
          courseBookingData: courseBookingData,
          qrCode: await generateQRCode(courseBookingData.courseBookingSimpleId),
        });
      }
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.courseBookingLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zum Kurs"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/course/${this.state.courseBookingData.courseId}`}
            />
            {this.state.courseBookingData.paymentStatus === "canceled" ||
            this.state.courseBookingData.paymentStatus === "failed" ? (
              <>
                <Title title="Kursbuchung abgebrochen." />
                <p>
                  Deine Kursbuchung wurde abgebrochen. Wenn du den Kurs besuchen
                  willst kannst du ihn "
                  <a
                    href={`/club/${this.state.clubId}/branch/${this.state.branchId}/course/${this.state.courseBookingData.courseId}`}
                  >
                    hier
                  </a>
                  " erneut buchen.
                </p>
              </>
            ) : (
              <div className="bookingData-loading-container">
                <Title title="Ihre Zahlung wird bearbeitet." />
                <div className="bookingData-loading-spinner-container">
                  <ClimbingBoxLoader
                    color={"#c31924"}
                    size={20}
                    loading={this.state.listenerActive}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
