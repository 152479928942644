import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./MembershipApplication.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import {
  dateTosimpleDateStr,
  firebaseDateToDateObj,
} from "../../functions/utils";
import MemAppStatus from "../../components/MemAppStatus";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class MembershipApplication extends React.Component<
  ComposedProps,
  {
    clubId: string;
    memAppsLoaded: boolean;
    memApps: Array<any>;
    memAppTableArr: Array<RowData>;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      memAppsLoaded: false,
      memApps: [],
      memAppTableArr: [],
    };
  }

  componentDidMount = () => {
    this.requestMemApps();
  };

  requestMemApps = () => {
    fetchRetry("getMemApps", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleMemApps)
      .catch(this.handleError);
  };

  handleMemApps = ({ data }: any) => {
    this.setState({ memAppsLoaded: true });
    if (data.success) {
      const memApps = data.data;
      const memAppTableArr = memApps.map((currData: any) => {
        const [[fName, lName]] = currData.data
          .filter((currData: any) => currData.type === "name")
          .map((currData: any) => {
            return [currData.val.fName, currData.val.lName];
          });
        return {
          cells: [
            {
              value: fName,
              sort: fName,
              search: fName,
            },
            {
              value: lName,
              sort: lName,
              search: fName,
            },
            {
              value: <MemAppStatus type={currData.status} />,
              sort: currData.status,
            },
            {
              value: dateTosimpleDateStr(
                firebaseDateToDateObj(currData.created_at)
              ),
              sort: currData.created_at._seconds,
            },
          ],
          onClick: `/club-settings/${this.state.clubId}/membership-applications/${currData.id}`,
        };
      });
      this.setState({
        memAppTableArr,
      });
    }
  };

  handleRedirect = (redirect: string) => {
    this.props.history.push({
      pathname: redirect,
    });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              <div className="branches-title-container">
                <h1>Mitgliedsanfragen</h1>
                <Link
                  to={`/club-settings/${this.state.clubId}/membership-application-settings`}
                  className="club-settings-container"
                >
                  Einstellungen
                </Link>
              </div>
              <Table
                header={[
                  { title: "Vorname" },
                  { title: "Nachname" },
                  { title: "Status", displayWidth: 700 },
                  { title: "Datum" },
                ]}
                data={this.state.memAppTableArr}
                loaded={this.state.memAppsLoaded}
                skeletonRows={10}
                onClick={this.handleRedirect}
                defaultSortDirection="desc"
                defaultSortIndex={3}
                counter
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
