import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { getAgeStr } from "../functions/utils";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { getRightSeperator } from "../functions/utils";
import qs from "qs";

export default class SubscriptionElement extends React.Component<
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    title: string;
    description: string;
    price: string;
    imgUrl: string;
    userAllowedToBook: boolean;
    minAge: number | null;
    maxAge: number | null;
    highlighted: boolean;
    requestMembershipUrl: string;
    redirect: string | null;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={`course-container${
          this.props.highlighted ? " course-container-highlighted" : ""
        }`}
        id={this.props.subscriptionId}
      >
        <div>
          {!this.props.imgUrl ? (
            <div className="course-img">
              <FontAwesomeIcon
                icon={faImage}
                size="6x"
                className="image-icon"
              />
            </div>
          ) : (
            <img
              className="course-img"
              src={this.props.imgUrl}
              alt="Kursbild"
            />
          )}
        </div>
        <div className="course-info-container">
          <div className="course-info-inner-container">
            <div className="course-title-container">{this.props.title}</div>
            <div>{this.props.description}</div>

            {(this.props.minAge || this.props.maxAge) && (
              <div className="course-text-container">
                Alter:{" "}
                <span className="normal-weight">
                  {getAgeStr(this.props.minAge, this.props.maxAge)}
                </span>
              </div>
            )}
            <div className="course-text-container">
              Preis: <span className="normal-weight">{this.props.price}</span>
            </div>
          </div>
          <div className="course-book-outer-container">
            <Link
              to={`/club/${this.props.clubId}/branch/${this.props.branchId}/subscription/${this.props.subscriptionId}`}
              className={`course-book-btn no-underline${
                !this.props.userAllowedToBook ? " course-book-btn-gray" : ""
              }`}
            >
              {!this.props.userAllowedToBook
                ? "Nur für Mitglieder buchbar"
                : "Abonnement Buchen"}
            </Link>
            {!this.props.userAllowedToBook && (
              <div className="get-mem-link">
                <a
                  href={`${this.props.requestMembershipUrl}${getRightSeperator(
                    this.props.requestMembershipUrl
                  )}${qs.stringify({
                    redirect: window.location.href,
                  })}`}
                >
                  Jetzt SVF-Mitglied werden
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
