import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./MembershipApplicationSettings.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";
import ErrorMessage from "../../components/ErrorMessage";
import SubTitle from "../../components/SubTitle";
import MultiSelector from "../../components/MultiSelector";
import {
  arrAreEqual,
  findWithAttr,
  makeid,
  validateEmail,
} from "../../functions/utils";
import MultiStringSelector from "../../components/MultiStringSelector";
import BackElement from "../../components/BackElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input";
import DropDown from "../../components/DropDown";
import SwitchSetting from "../../components/SwitchSetting";
import Switch from "../../components/Switch";
import TextArea from "../../components/TextArea";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class MembershipApplicationSettings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    fullyLoaded: boolean;
    roleArr: Array<any>;
    emailArr: Array<any>;
    emailArrJoinRequest: Array<any>;
    roles: Array<string>;
    emails: Array<string>;
    emailsJoinRequest: Array<string>;
    updateLoading: boolean;
    change: boolean;
    updateErrMsg: string | null;
    departmentArr: Array<any>;
    branchSelectorArr: Array<any>;
    departmentChange: boolean;
    showRequestWindow: boolean;
    bBirthDate: boolean;
    bAddress: boolean;
    bPhoneNumber: boolean;
    alreadyMemberText: string;
    alreadyMemberBtnText: string;
    membershipNumberText: string;
    requestMembershipText: string;
    requestMembershipBtnText: string;
    requestMembershipUrl: string;
    useCustomMemAppUrl: boolean;
    confirmationMailMessage: string;
    oldMailMessage: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      fullyLoaded: false,
      roleArr: [],
      emailArr: [],
      emailArrJoinRequest: [],
      roles: [],
      emails: [],
      emailsJoinRequest: [],
      updateLoading: false,
      change: false,
      updateErrMsg: null,
      departmentArr: [],
      branchSelectorArr: [],
      departmentChange: false,
      showRequestWindow: false,
      bBirthDate: false,
      bAddress: false,
      bPhoneNumber: false,
      alreadyMemberText: "",
      alreadyMemberBtnText: "",
      membershipNumberText: "",
      requestMembershipText: "",
      requestMembershipBtnText: "",
      requestMembershipUrl: "",
      useCustomMemAppUrl: false,
      confirmationMailMessage: "",
      oldMailMessage: "",
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    Promise.all([this.requestClub(), this.requestMemAppSet()]).then(
      this.handleFullyLoaded
    );
  };

  requestClub = async () => {
    await fetchRetry(
      "getClubFromId",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    if (data.success) {
      const club = data.data;
      const branchSelectorArr = Object.keys(club.branches).map(
        (branchId: string) => {
          return {
            id: branchId,
            name: club.branches[branchId].name,
          };
        }
      );
      this.setState({
        club,
        branchSelectorArr,
        departmentChange: false,
        showRequestWindow: !!club.settings?.showRequestClubWindow,
        bBirthDate: !!club.settings?.bBirthDate,
        bAddress: !!club.settings?.bAddress,
        bPhoneNumber: !!club.settings?.bPhoneNumber,
        alreadyMemberText: club.text?.alreadyMemberText || "",
        alreadyMemberBtnText: club.text?.alreadyMemberBtnText || "",
        membershipNumberText: club.text?.membershipNumberText || "",
        requestMembershipText: club.text?.requestMembershipText || "",
        requestMembershipBtnText: club.text?.requestMembershipBtnText || "",
        requestMembershipUrl: club.text?.requestMembershipUrl || "",
        useCustomMemAppUrl:
          club.text?.requestMembershipUrl !==
          `https://${club.nameIdentifier}.getbookable.de/club/${this.state.clubId}/Aufnahmeantrag?regReq=true`,
      });
    }
  };

  requestMemAppSet = async () => {
    await fetchRetry(
      "getMemAppSet",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleMemAppSet)
      .catch(this.handleError);
  };

  handleMemAppSet = ({ data }: any) => {
    if (data.success) {
      const memAppSetData = data.data;
      const roles = memAppSetData.roleArr || [];
      const emails = memAppSetData.emailArr || [];
      const emailsJoinRequest = memAppSetData.emailArrJoinRequest || [];
      const departmentArr = memAppSetData.departmentArr;
      this.setState({
        roles,
        emails,
        emailsJoinRequest,
        departmentArr,
        confirmationMailMessage: memAppSetData.confirmationMailMessage || "",
        oldMailMessage: memAppSetData.confirmationMailMessage || "",
      });
    }
  };

  handleFullyLoaded = () => {
    const roles = this.state.club?.roles || [];
    const roleArr = Object.keys(roles).map((roleId: string) => {
      return {
        id: roleId,
        name: roles[roleId].name,
        selected: this.state.roles?.includes(roleId),
        color: roles[roleId].type === "club" ? "#5c67ee" : "#ee5c5c",
      };
    });
    const emails = this.state.emails || [];
    const emailArr = emails.map((email: string) => {
      return {
        id: makeid(32),
        name: email,
      };
    });
    const emailsJoinRequest = this.state.emailsJoinRequest || [];
    const emailArrJoinRequest = emailsJoinRequest.map((email: string) => {
      return {
        id: makeid(32),
        name: email,
      };
    });
    this.setState(
      {
        roleArr,
        emailArr,
        emailArrJoinRequest,
        fullyLoaded: true,
      },
      this.checkChange
    );
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const roleArr: any = this.state.roleArr;
    const index = findWithAttr(roleArr, "id", bookingTypeId);
    roleArr[index].selected = true;
    this.setState({ roleArr }, this.checkChange);
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const roleArr: any = this.state.roleArr;
    const index = findWithAttr(roleArr, "id", bookingTypeId);
    roleArr[index].selected = false;
    this.setState({ roleArr }, this.checkChange);
  };

  handleEmailSelect = (email: string) => {
    const emailArr: any = this.state.emailArr;
    if (validateEmail(email)) {
      emailArr.push({ id: makeid(32), name: email });
      this.setState({ emailArr }, this.checkChange);
    } else {
      this.context.createInfo("Keine gültige E-mail-Adresse.", "info", 4);
    }
  };

  handleEmailUnselect = (id: string) => {
    const emailArr: any = this.state.emailArr.filter(
      (elem: any) => elem.id !== id
    );
    this.setState({ emailArr }, this.checkChange);
  };

  handleEmailJoinRequestSelect = (email: string) => {
    const emailArrJoinRequest: any = this.state.emailArrJoinRequest;
    if (validateEmail(email)) {
      emailArrJoinRequest.push({ id: makeid(32), name: email });
      this.setState({ emailArrJoinRequest }, this.checkChange);
    } else {
      this.context.createInfo("Keine gültige E-mail-Adresse.", "info", 4);
    }
  };

  handleEmailJoinRequestUnselect = (id: string) => {
    const emailArrJoinRequest: any = this.state.emailArrJoinRequest.filter(
      (elem: any) => elem.id !== id
    );
    this.setState({ emailArrJoinRequest }, this.checkChange);
  };

  updateMemAppSet = () => {
    this.setState({ updateLoading: true });
    const roleArr = this.state.roleArr
      .filter((role: any) => role.selected)
      .map((email: any) => email.id);
    const emailArr = this.state.emailArr.map((email: any) => email.name);
    const emailArrJoinRequest = this.state.emailArrJoinRequest.map(
      (email: any) => email.name
    );
    const departmentArr: Array<any> = this.state.departmentArr.map(
      (department: any) => {
        return {
          id: department.id,
          name: department.name,
          branchId: department.branchId || null,
        };
      }
    );
    fetchRetry(
      "updateMemAppSet",
      {
        clubId: this.state.clubId,
        roleArr,
        emailArr,
        emailArrJoinRequest,
        departmentArr,
        settings: {
          showRequestClubWindow: this.state.showRequestWindow,
          bBirthDate: this.state.bBirthDate,
          bAddress: this.state.bAddress,
          bPhoneNumber: this.state.bPhoneNumber,
        },
        text: {
          alreadyMemberText: this.state.alreadyMemberText,
          alreadyMemberBtnText: this.state.alreadyMemberBtnText,
          membershipNumberText: this.state.membershipNumberText,
          requestMembershipText: this.state.requestMembershipText,
          requestMembershipBtnText: this.state.requestMembershipBtnText,
          requestMembershipUrl: this.state.useCustomMemAppUrl
            ? this.state.requestMembershipUrl
            : `https://${this.state.club.nameIdentifier}.getbookable.de/club/${this.state.clubId}/Aufnahmeantrag?regReq=true`,
        },
        confirmationMailMessage: this.state.confirmationMailMessage,
      },
      1,
      5
    )
      .then(this.handleSuccessMemAppSetUpdate)
      .catch(this.handleError);
  };

  handleSuccessMemAppSetUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.setState({ updateErrMsg: null });
      this.fetchData();
    } else {
      this.setState({ updateErrMsg: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  checkChange = () => {
    const newRoles = this.state.roleArr
      .filter((currRole: any) => currRole.selected)
      .map((currRole: any) => currRole.id);
    const newEmails = this.state.emailArr.map((email: any) => email.name);
    const newEmailsJoinRequest = this.state.emailArrJoinRequest.map(
      (email: any) => email.name
    );
    // check if roles change
    this.setState({
      change:
        !arrAreEqual(newRoles, this.state.roles) ||
        !arrAreEqual(newEmails, this.state.emails) ||
        !arrAreEqual(newEmailsJoinRequest, this.state.emailsJoinRequest) ||
        this.state.bBirthDate !== this.state.club.settings.bBirthDate ||
        this.state.bAddress !== this.state.club.settings.bAddress ||
        this.state.bPhoneNumber !== this.state.club.settings.bPhoneNumber ||
        this.state.showRequestWindow !==
          this.state.club.settings.showRequestClubWindow ||
        this.state.alreadyMemberText !==
          this.state.club.text.alreadyMemberText ||
        this.state.alreadyMemberBtnText !==
          this.state.club.text.alreadyMemberBtnText ||
        this.state.membershipNumberText !==
          this.state.club.text.membershipNumberText ||
        this.state.requestMembershipText !==
          this.state.club.text.requestMembershipText ||
        this.state.requestMembershipBtnText !==
          this.state.club.text.requestMembershipBtnText ||
        (this.state.useCustomMemAppUrl &&
          this.state.requestMembershipUrl !==
            this.state.club.text.requestMembershipUrl) ||
        (!this.state.useCustomMemAppUrl &&
          `https://${this.state.club.nameIdentifier}.getbookable.de/club/${this.state.clubId}/Aufnahmeantrag?regReq=true` !==
            this.state.club.text.requestMembershipUrl) ||
        this.state.departmentChange ||
        this.state.oldMailMessage !== this.state.confirmationMailMessage,
    });
  };

  handleDepartmentUnselect = (id: number) => {
    const departmentArr: any = this.state.departmentArr;
    departmentArr.splice(id, 1);
    this.setState({ departmentArr, departmentChange: true }, this.checkChange);
  };

  createDepartment = () => {
    const departmentArr: any = this.state.departmentArr;
    if (
      departmentArr.length === 0 ||
      departmentArr[departmentArr.length - 1].name !== "" ||
      departmentArr[departmentArr.length - 1].branchId !== ""
    ) {
      departmentArr.push({ name: "", branchId: "", id: makeid(32) });
      this.setState(
        { departmentArr, departmentChange: true },
        this.checkChange
      );
    }
  };

  handleDepartmentChange = (index: number, val: string) => {
    const departmentArr: any = this.state.departmentArr;
    departmentArr[index].name = val;
    this.setState({ departmentArr, departmentChange: true }, this.checkChange);
  };

  handleBranchSelect = (index: number, branchId: string) => {
    const departmentArr: any = this.state.departmentArr;
    departmentArr[index].branchId = branchId;
    this.setState({ departmentArr, departmentChange: true }, this.checkChange);
  };

  handleShowRequestWindowChange = () => {
    this.setState(
      { showRequestWindow: !this.state.showRequestWindow },
      this.checkChange
    );
  };

  handleRBirthDateChange = () => {
    this.setState({ bBirthDate: !this.state.bBirthDate }, this.checkChange);
  };

  handleRAddressChange = () => {
    this.setState({ bAddress: !this.state.bAddress }, this.checkChange);
  };

  handleRPhoneNumberChange = () => {
    this.setState({ bPhoneNumber: !this.state.bPhoneNumber }, this.checkChange);
  };

  handleAlreadyMemberTextChange = (val: string) => {
    this.setState({ alreadyMemberText: val }, this.checkChange);
  };

  handleAlreadyMemberBtnTextChange = (val: string) => {
    this.setState({ alreadyMemberBtnText: val }, this.checkChange);
  };

  handleMembershipNumberTextChange = (val: string) => {
    this.setState({ membershipNumberText: val }, this.checkChange);
  };

  handleRequestMembershipTextChange = (val: string) => {
    this.setState({ requestMembershipText: val }, this.checkChange);
  };

  handleRequestMembershipBtnTextChange = (val: string) => {
    this.setState({ requestMembershipBtnText: val }, this.checkChange);
  };

  handleRequestMembershipUrlChange = (val: string) => {
    this.setState({ requestMembershipUrl: val }, this.checkChange);
  };

  toggleUseCustomMemAppUrl = () => {
    this.setState(
      { useCustomMemAppUrl: !this.state.useCustomMemAppUrl },
      this.checkChange
    );
  };

  handleMailMessageChange = (msg: string) => {
    this.setState({ confirmationMailMessage: msg }, this.checkChange);
  };

  render() {
    if (!this.state.fullyLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              <BackElement
                text="zurück zu den Mitgliedsanfragen"
                to={`/club-settings/${this.state.clubId}/membership-applications`}
              />
              <SubTitle title="diese Rollen automatisch verwalten" />
              <MultiSelector
                searchTextPlaceholder="Rolle"
                onSelect={this.handleRoleSelect}
                onUnselect={this.handleRoleUnselect}
                arr={this.state.roleArr}
                className="margin-bottom"
              />
              <Title title="Beitrittsanfragen" />
              <SubTitle title="E-mail senden an (neue Beitrittsanfrage)" />
              <MultiStringSelector
                arr={this.state.emailArrJoinRequest}
                onSelect={this.handleEmailJoinRequestSelect}
                onUnselect={this.handleEmailJoinRequestUnselect}
                inputPlaceholder="E-mail-Adresse hinzufügen"
              />
              <SwitchSetting
                value={this.state.showRequestWindow}
                onChange={this.handleShowRequestWindowChange}
                name="Beitrittsfenster im Vereinscreen anzeigen"
              />
              <div className="medium-space"></div>
              <SubTitle title="Beitrittsanfrage (bereits Mitglied)" />
              <Input
                name="role-name-input"
                value={this.state.alreadyMemberText}
                placeholder="Du bist bereits ein Mitglied?"
                onChange={this.handleAlreadyMemberTextChange}
              />
              <Input
                name="role-name-input"
                value={this.state.alreadyMemberBtnText}
                placeholder="Beitrittsanfrage senden"
                onChange={this.handleAlreadyMemberBtnTextChange}
              />
              <Input
                name="role-name-input"
                value={this.state.membershipNumberText}
                placeholder="Mitgliedsnummer"
                onChange={this.handleMembershipNumberTextChange}
              />
              <SubTitle title="Aufnahmeantrag (Mitglied werden)" />
              <Input
                name="role-name-input"
                value={this.state.requestMembershipText}
                placeholder="Du willst ein Mitglied werden?"
                onChange={this.handleRequestMembershipTextChange}
              />
              <Input
                name="role-name-input"
                value={this.state.requestMembershipBtnText}
                placeholder="Mitglied werden"
                onChange={this.handleRequestMembershipBtnTextChange}
              />
              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.useCustomMemAppUrl}
                    onChange={this.toggleUseCustomMemAppUrl}
                  />
                </div>
                {this.state.useCustomMemAppUrl ? (
                  <Input
                    name="role-name-input"
                    value={this.state.requestMembershipUrl}
                    placeholder="https://tc-testverein.de/mitglied-werden"
                    onChange={this.handleRequestMembershipUrlChange}
                  />
                ) : (
                  <a
                    className="input-container expireDate-container no-underline"
                    href={`https://${this.state.club.nameIdentifier}.getbookable.de/club/${this.state.clubId}/Aufnahmeantrag?regReq=true`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`https://${this.state.club.nameIdentifier}.getbookable.de/club/${this.state.clubId}/Aufnahmeantrag?regReq=true`}
                  </a>
                )}
              </div>

              <div className="medium-space"></div>
              <SubTitle title="Benötigte Daten - Beitrittsanfrage" />
              <SwitchSetting
                value={this.state.bBirthDate}
                onChange={this.handleRBirthDateChange}
                name="Geburtsdatum"
              />
              <SwitchSetting
                value={this.state.bAddress}
                onChange={this.handleRAddressChange}
                name="Adresse"
              />
              <SwitchSetting
                value={this.state.bPhoneNumber}
                onChange={this.handleRPhoneNumberChange}
                name="Telefonnummer"
              />
              <div className="medium-space"></div>
              <Title title="Aufnahmeanträge" />
              <SubTitle title="E-mail senden an (neuer Aufnahmeantrag)" />
              <MultiStringSelector
                arr={this.state.emailArr}
                onSelect={this.handleEmailSelect}
                onUnselect={this.handleEmailUnselect}
                inputPlaceholder="E-mail-Adresse hinzufügen"
              />
              <SubTitle title="Bestätigungsmail" />
              <TextArea
                value={this.state.confirmationMailMessage}
                onChange={this.handleMailMessageChange}
                placeholder="Mail Nachricht"
              />
              <SubTitle title="Abteilungen" />
              <table className="table-price">
                <thead>
                  <tr>
                    <th></th>
                    <th>Abteilung</th>
                    <th>Bereich</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.departmentArr.map(
                    (department: any, index: number) => (
                      <tr>
                        <td
                          onClick={() => {
                            this.handleDepartmentUnselect(index);
                          }}
                          className="pointer center"
                        >
                          <FontAwesomeIcon icon={faTimes} size="lg" />
                        </td>
                        <td>
                          <div className="flex">
                            <Input
                              value={department.name}
                              onChange={(val: string) => {
                                this.handleDepartmentChange(index, val);
                              }}
                              type="text"
                              placeholder="Abteilung"
                              className="no-margin"
                            />
                          </div>
                        </td>
                        <td>
                          <DropDown
                            options={this.state.branchSelectorArr}
                            value={department.branchId}
                            onChange={(branchId: string) => {
                              this.handleBranchSelect(index, branchId);
                            }}
                            default={"-"}
                            className="no-margin"
                            name="Bereich"
                          />
                        </td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td
                      colSpan={3}
                      className="pointer center bold"
                      onClick={this.createDepartment}
                    >
                      Abteilung hinzufügen
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="medium-space"></div>
              <ErrorMessage message={this.state.updateErrMsg} />
              <SecButton
                change={this.state.change}
                color="green"
                loading={this.state.updateLoading}
                onClick={this.updateMemAppSet}
                title="Speichern"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
