import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import ErrorMessage from "../components/ErrorMessage";
import { fetchRetry } from "../functions/request";
import { MainContext } from "../contexts/MainContext";

type Props = {};
type ComposedProps = Props & RouteComponentProps<{}>;

export default class MollieEndpoint extends React.Component<
  ComposedProps,
  {
    authCode: string | null;
    state: string | null;
    error: string | null;
    error_description: string | null;
    redirect: string | null;
    loading: boolean;
    errorMessage: string | null;
    clubId: string | null;
    branchId: string | null;
    mollieRedirectUrl: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      authCode: "",
      state: "",
      error: "",
      error_description: "",
      redirect: null,
      loading: false,
      errorMessage: null,
      clubId: null,
      branchId: null,
      mollieRedirectUrl: null,
    };
  }

  componentDidMount = () => {
    const cookies = new Cookies();
    const mollieRedirectUrl = cookies.get("mollie_redirect_url");
    this.setState({ mollieRedirectUrl });

    const params = this.props.location.search;
    const authCode = new URLSearchParams(params).get("code");
    const state = new URLSearchParams(params).get("state");
    const error = new URLSearchParams(params).get("error");
    const error_description = new URLSearchParams(params).get(
      "error_description"
    );

    this.setState({
      authCode,
      state,
      error,
      error_description,
    });

    if (!error) {
      this.setState({ loading: true });
      fetchRetry(
        "handleNewAuthCode",
        {
          identifier: state,
          authCode,
        },
        1,
        5
      )
        .then(this.handleSuccess)
        .catch(this.handleError);
    } else {
      this.setState({
        errorMessage: error,
      });
    }

    if (!authCode && !state && !error && !error_description) {
      // redirect to cookie url
      const cookies = new Cookies();
      const mollieRedirectUrl = cookies.get("mollie_redirect_url");
      if (mollieRedirectUrl !== undefined) {
        window.location.href = mollieRedirectUrl;
      }
    }
  };

  handleSuccess = ({ data }: any) => {
    this.setState({ loading: false });
    if (data.success) {
      this.setState({
        branchId: data.branchId,
        clubId: data.clubId,
        errorMessage: null,
      });
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    } else {
      this.setState({
        errorMessage: data.errorMsgDe,
        branchId: data.branchId,
        clubId: data.clubId,
      });
    }
  };

  handleError = (err: any) => {
    this.setState({ loading: false });
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <div className="center-container">
          <ClimbingBoxLoader
            color={"#c31924"}
            size={20}
            loading={this.state.loading}
          />
          <ErrorMessage message={this.state.errorMessage} />
          {this.state.errorMessage && this.state.mollieRedirectUrl && (
            <div
              className="black"
              onClick={() => {
                if (this.state.mollieRedirectUrl) {
                  window.location.href = this.state.mollieRedirectUrl;
                }
              }}
            >
              zurück zum System
            </div>
          )}
        </div>
      </>
    );
  }
}
