import React from "react";
import { Link } from "react-router-dom";
import "./NavbarElement.css";
import { MainContext } from "../../../contexts/MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { objectToArray } from "../../../functions/utils";

export default class UserNavbarElement extends React.Component<
  { to: string },
  {
    showFamilyMembers: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      showFamilyMembers: true,
    };
  }

  render() {
    let permittedAccounts = [];
    if (this.context.user) {
      permittedAccounts = objectToArray(
        this.context.userData?.permittedAccounts
      );
      permittedAccounts = permittedAccounts.filter(
        (account: any) => account.status === "permitted"
      );
      permittedAccounts.push({
        id: this.context.user.uid,
        fName: this.context.userData?.fName,
        lName: this.context.userData?.lName,
      });

      permittedAccounts.sort((a: any, b: any) => {
        if (a.fName < b.fName) return -1;
        if (a.fName > b.fName) return 1;
        return 0;
      });
    }

    return (
      <>
        <div className="navbar-element navbar-element-user">
          <Link
            to={this.props.to}
            className="flex no-underline navbar-element-user-link"
            onClick={() => {}}
          >
            <div className="navbar-icon-container">
              <img
                src="/images/icons/colorUser.svg"
                className="navbar-profile-pic"
                alt="Benutzer Profilbild"
              />
            </div>
            <div className="navbar-element-user-textcontainer">
              <div className="white navbar-element-user-namecontainer">
                {!this.context.user ? (
                  "Nicht angemeldet"
                ) : (
                  <>
                    {!this.context.selectedUserData?.fName &&
                    !this.context.selectedUserData?.lName ? (
                      <div className="name-spaceholder-container">
                        <div className="name-spaceholder name-spaceholder-fName"></div>
                        <div className="name-spaceholder name-spaceholder-lName"></div>
                      </div>
                    ) : (
                      <>
                        {this.context.selectedUserData.fName}{" "}
                        {this.context.selectedUserData.lName}
                      </>
                    )}
                  </>
                )}
                {this.context.user && (
                  <div className="navbar-user-element-usersettings-icon">
                    <FontAwesomeIcon icon={faUserCog} />
                  </div>
                )}
              </div>
              {this.context.user ? (
                <Link
                  className="navbar-element-user-logout"
                  onClick={this.context.logout}
                  to={`/club/${this.context.clubId}`}
                >
                  <span>Abmelden</span>
                </Link>
              ) : (
                <Link
                  className="navbar-element-user-logout"
                  onClick={this.context.logout}
                  to={`/login${
                    this.context.clubId ? `/${this.context.clubId}` : ""
                  }?redirect=${window.location.href}`}
                >
                  <span>Anmelden</span>
                </Link>
              )}
            </div>
          </Link>
          {permittedAccounts.length > 1 && (
            <div
              className="select-family-member unselectable"
              onClick={() => {
                this.setState({
                  showFamilyMembers: !this.state.showFamilyMembers,
                });
              }}
            >
              {this.state.showFamilyMembers ? (
                <FontAwesomeIcon icon={faChevronDown} size="sm" />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} size="sm" />
              )}
              Familienmitglied wechseln
            </div>
          )}
          {this.state.showFamilyMembers && permittedAccounts.length > 1 && (
            <div className="family-member-wrapper unselectable">
              {permittedAccounts.map((permittedAccount: any) => (
                <div
                  onClick={() => {
                    this.context.setSelUserId(permittedAccount.id);
                  }}
                  className={`family-member${
                    this.context.selectedUserId === permittedAccount.id
                      ? " family-member-selected"
                      : ""
                  }`}
                >
                  {permittedAccount.fName} {permittedAccount.lName}
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}
