import React from "react";
import { Link } from "react-router-dom";
import "./ClubSearch.css";
import HashLoader from "react-spinners/HashLoader";
import ClubSearchNachbar from "../components/navbars/ClubSearchNavbar";
import TopMenuBar from "../components/TopMenuBar";
import Input from "../components/Input";
import Footer from "../components/Footer";
import { fetchRetry } from "../functions/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../contexts/MainContext";
import CreateClubModal from "../components/modals/CreateClubModal";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export default class ClubSearch extends React.Component<
  {},
  {
    authenticated: boolean;
    clubs: any;
    filteredClubs: any;
    clubsLoaded: boolean;
    clubSearchName: string;
    showMobileNav: boolean;
    showCreateClubModal: boolean;
    createClubLoading: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      authenticated: false,
      clubs: {},
      filteredClubs: {},
      clubsLoaded: false,
      clubSearchName: "",
      showMobileNav: false,
      showCreateClubModal: false,
      createClubLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestAllClubs();
  };

  requestAllClubs = () => {
    fetchRetry("getAllClubs", {}, 1, 5)
      .then(this.handleClubs)
      .catch(this.handleError);
  };

  handleClubs = ({ data }: any) => {
    this.setState({ clubsLoaded: true });
    if (data.success) {
      this.setState(
        {
          clubs: data.data,
        },
        this.filterClubs
      );
    }
  };

  filterClubs = () => {
    const clubs: any = {};
    let clubSearchName = this.state.clubSearchName;
    clubSearchName = clubSearchName.toLocaleLowerCase();
    clubSearchName = clubSearchName.replace("-", " ");
    for (const clubId in this.state.clubs) {
      const currClub = this.state.clubs[clubId];
      let clubName = currClub.name ? currClub.name : "";
      clubName = clubName.toLowerCase();
      clubName = clubName.replace("-", " ");
      if (clubName.includes(clubSearchName)) {
        clubs[clubId] = currClub;
      }
    }
    this.setState({ filteredClubs: clubs });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleChangeClubSearchName = (val: any) => {
    this.setState({ clubSearchName: val }, this.filterClubs);
  };

  handleShowMobileNav = () => {
    this.setState({ showMobileNav: true });
  };

  handleHideMobileNav = () => {
    this.setState({ showMobileNav: false });
  };

  handleShowCreateClubModal = () => {
    this.setState({ showCreateClubModal: true });
  };

  handleHideCreateClubModal = () => {
    this.setState({ showCreateClubModal: false });
  };

  handleCreateClub = (
    name: string,
    identifier: string,
    branchCount: number
  ) => {
    this.setState({ createClubLoading: true });
    fetchRetry(
      "createClub",
      {
        name,
        nameIdentifier: identifier,
        countBranches: branchCount,
        admins: [],
      },
      1,
      5
    )
      .then(this.handleCreateClubSuccess)
      .catch(this.handleError);
  };

  handleCreateClubSuccess = ({ data }: any) => {
    this.setState({ createClubLoading: false });
    if (data.success) {
      this.setState({ showCreateClubModal: false });
      this.requestAllClubs();
    }
  };

  render() {
    return (
      <>
        <CreateClubModal
          show={this.state.showCreateClubModal}
          handleClose={this.handleHideCreateClubModal}
          handleConfirm={this.handleCreateClub}
          loading={this.state.createClubLoading}
          btnText="Verein erstellen"
          title="Verein erstellen"
        />
        <div className="br-main-container">
          <ClubSearchNachbar
            selectedScreen={"clubsearch"}
            showMobile={this.state.showMobileNav}
            hideMobileNav={this.handleHideMobileNav}
          />
          <div className="br-inner-container" onScroll={this.context.onScroll}>
            <TopMenuBar handleShowMobileNav={this.handleShowMobileNav} />
            <div className="br-padding-container">
              <div className="inner-container">
                <h1>Vereinssuche</h1>

                <Input
                  name="club-search-input"
                  placeholder="Vereinsname"
                  value={this.state.clubSearchName}
                  onChange={this.handleChangeClubSearchName}
                />
                {!this.state.clubsLoaded ? (
                  <>
                    <div className="loading-container">
                      <HashLoader color={"#c31924"} size={100} loading={true} />
                    </div>
                  </>
                ) : (
                  <section className="club-grid">
                    {Object.keys(this.state.filteredClubs).map(
                      (item: any, index: number) => (
                        <Link
                          to={`club/${item}`}
                          className="club-container no-underline"
                          key={index}
                        >
                          <div className="club-banner-container">
                            {this.state.filteredClubs[item].bannerLowRes && (
                              <img
                                className="club-banner"
                                src={
                                  this.state.filteredClubs[item].bannerLowRes
                                }
                                alt="Vereinsbanner"
                              />
                            )}
                          </div>
                          <span className="club-name">
                            {this.state.filteredClubs[item].name}
                          </span>
                        </Link>
                      )
                    )}
                    {this.context.isAdmin && (
                      <div
                        className="club-container add-club-container no-underline"
                        onClick={this.handleShowCreateClubModal}
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          size="lg"
                          onClick={() => {}}
                          className="add-element-icon"
                        />
                        <span className="club-name">Verein erstellen</span>
                      </div>
                    )}
                  </section>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
