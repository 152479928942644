import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Course.css";
import "../../App.css";
import SingleCourseElement from "../../components/SingleCourseElement";
import BlockCourseElement from "../../components/BlockCourseElement";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  getSubscriptionPriceString,
  objectToArray,
  priceToStr,
} from "../../functions/utils";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import JoinBranchModal from "../../components/modals/JoinBranchModal";
import RequestedBranchModal from "../../components/modals/RequestedBranchModal";
import InfinityCourseElement from "../../components/InfinityCourseElement";
import TestMode from "../../components/TestMode";
import Title from "../../components/Title";
import SearchInput from "../../components/SearchInput";
import Tag from "../../components/Tag";
import ReqMemAppMessage from "../../components/ReqMemAppMessage";
import SubscriptionElement from "../../components/SubscriptionElement";
import qs from "qs";
import InfiniteScroll from "react-infinite-scroll-component";
import CourseElementSkeleton from "../../components/CourseElementSkeleton";
import debounce from "lodash.debounce";
import EnterMembershipModal from "../../components/modals/EnterMembershipModal";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Course extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branchLoaded: boolean;
    elementsLoaded: boolean;
    branch: any;
    elements: Array<any>;
    showJoinModal: boolean;
    joinModalLoading: boolean;
    joinModalMsg: null | string;
    joinModalType: "error" | "success";
    showRequestedModal: boolean;
    requestedModalLoading: boolean;
    requestedModalMsg: null | string;
    requestedModalType: "error" | "success";
    courseSearch: string;
    selectedTag: string;
    subscriptions: Array<any>;
    neededRoles: Array<string>;
    totalLoaded: boolean;
    redirect: string | null;
    page: number;
    hasMore: boolean;
    batchSize: number;
    initialLoad: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branchLoaded: false,
      elementsLoaded: false,
      branch: {},
      elements: [],
      showJoinModal: false,
      joinModalLoading: false,
      joinModalMsg: null,
      joinModalType: "error",
      showRequestedModal: false,
      requestedModalLoading: false,
      requestedModalMsg: null,
      requestedModalType: "error",
      courseSearch: "",
      selectedTag: "",
      subscriptions: [],
      neededRoles: (
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).neededRoles + "" || ""
      ).split(","),
      redirect:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).redirect + "" || null,
      totalLoaded: false,
      page: 0,
      hasMore: true,
      batchSize: 10,
      initialLoad: false,
    };
  }

  componentDidMount = async () => {
    await Promise.all([this.requestBranch(), this.requestElements()]).then(
      this.handleData
    );
  };

  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  getNextPage = () => {
    this.setState(
      { page: this.state.page + 1, elementsLoaded: false },
      this.requestElements
    );
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  requestElements = async () => {
    await fetchRetry(
      "getElementsOfBranchAlgoliaF",
      {
        targetUserId: this.context.selectedUserId,
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        query: this.state.courseSearch,
        page: this.state.page,
        hitsPerPage: this.state.batchSize,
        showDeleted: false,
        showPast: false,
        tag:
          this.state.selectedTag !== "subscriptions"
            ? this.state.selectedTag
            : "",
        onlySubscriptions: this.state.selectedTag === "subscriptions",
      },
      1,
      5
    )
      .then(this.handleElementsSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleElementsSuccess = ({ data }: any) => {
    this.setState({ elementsLoaded: true });
    if (data.success) {
      const elements = this.state.elements.concat(data.data);
      this.setState(
        {
          elements,
          hasMore: data.data.length >= this.state.batchSize,
        },
        () => {
          if (!this.state.initialLoad) {
            const highlighedCourseId = window.location.href.slice(
              window.location.href.indexOf("#") + 1
            );
            var element = document.getElementById(highlighedCourseId);
            element?.scrollIntoView();
            this.setState({ initialLoad: true });
          }
        }
      );
    }
  };

  handleData = () => {
    const elements = this.state.elements.map((course: any) => {
      let totalSubscriptionArr: Array<string> = [];
      if (course.userAllowedToBook) {
        return {
          ...course,
          memLink: null,
        };
      }
      const memAppRoleArr =
        this.state.branch?.clubSettings?.memAppRoleArr || [];
      for (const allowedRole of course.allowedRoles) {
        if (memAppRoleArr.includes(allowedRole)) {
          return {
            ...course,
            memLink:
              this.state.branch?.clubSettings?.requestMembershipUrl || null,
          };
        }
        const branchRoles = this.state.branch?.roles || [];
        const subscriptionArr = Object.keys(branchRoles).includes(allowedRole)
          ? branchRoles[allowedRole]?.subscriptions
          : [];
        totalSubscriptionArr = subscriptionArr.concat(totalSubscriptionArr);
      }
      if (totalSubscriptionArr.length === 0) {
        return {
          ...course,
          memLink: null,
        };
      }
      const subscriptionLink = `https://${
        this.context.club.nameIdentifier
      }.getbookable.de/club/${this.state.clubId}/branch/${
        this.state.branchId
      }/course?showSub=true&neededRoles=${totalSubscriptionArr.join(",")}`;
      return {
        ...course,
        memLink: subscriptionLink,
      };
    });
    this.setState({ elements, totalLoaded: true });
  };

  handleChangeCourseSearch = (val: string) => {
    this.setState({ courseSearch: val }, this.debounceRequest);
  };

  debounceRequest = debounce(() => {
    this.newRequest();
  }, 500);

  newRequest = () => {
    this.setState(
      { elements: [], page: 0, elementsLoaded: false, hasMore: true },
      this.requestElements
    );
  };

  handleShowJoinModal = () => {
    this.setState({ showJoinModal: true });
  };

  handleBranchJoinModalClose = () => {
    this.requestBranch();
    this.setState({ showJoinModal: false, joinModalMsg: null });
  };

  handleShowRequestedModal = () => {
    this.setState({ showRequestedModal: true });
  };

  handleBranchRequestedModalClose = () => {
    this.setState({ showRequestedModal: false, requestedModalMsg: null });
  };

  handleTagClicked = (tagId: string) => {
    if (tagId === this.state.selectedTag) {
      this.setState({ selectedTag: "" }, this.newRequest);
    } else {
      this.setState({ selectedTag: tagId }, this.newRequest);
    }
  };

  render() {
    const tags = this.state.branch?.tags ? this.state.branch.tags : {};
    const tagArr = objectToArray(tags);
    tagArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );

    const testMode = this.state.branch?.billing?.mode === "test";

    const club = this.context.club;
    const userRoles: string[] = club?.user?.roles || [];
    const clubRoleIds: string[] = Object.keys(club?.roles || {}) || [];
    const doesUserHasClubRole: boolean = clubRoleIds.some((role) =>
      userRoles.includes(role)
    );

    return (
      <>
        <EnterMembershipModal
          clubId={this.state.clubId}
          show={this.state.showJoinModal}
          clubName={this.context.club?.name}
          membershipName={this.context.club?.text?.membershipNumberText}
          handleClose={this.handleBranchJoinModalClose}
          useMembershipnumber={this.context.club?.settings?.useMembershipNumber}
          askForMembershipnumber={
            this.context.club?.settings?.askForMembershipNumber
          }
          useBirthDate={this.context.club?.settings?.bBirthDate}
          useAddress={this.context.club?.settings?.bAddress}
          usePhoneNumber={this.context.club?.settings?.bPhoneNumber}
          alreadyMemberBtnText={this.context.club?.text?.alreadyMemberBtnText}
        />
        <RequestedBranchModal
          clubId={this.state.clubId}
          branchId={this.state.branchId}
          show={this.state.showRequestedModal}
          clubName={this.state.branch?.club?.name}
          branchName={this.state.branch?.name}
          handleClose={this.handleBranchRequestedModalClose}
          refresh={this.requestBranch}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Kurse" />
            <TestMode active={testMode} />
            <ReqMemAppMessage
              show={
                this.state.branch?.settings?.showRequestWindow &&
                !doesUserHasClubRole
              }
              alreadyMemberBtnText={
                this.state.branch?.clubSettings?.alreadyMemberBtnText
              }
              alreadyMemberText={
                this.state.branch?.clubSettings?.alreadyMemberText
              }
              clubId={this.state.clubId}
              handleShowJoinModal={this.handleShowJoinModal}
              handleShowRequestedModal={this.handleShowRequestedModal}
              hasRequested={this.state.branch?.user?.requested}
              requestMembershipBtnText={
                this.state.branch?.clubSettings?.requestMembershipBtnText
              }
              requestMembershipText={
                this.state.branch?.clubSettings?.requestMembershipText
              }
              requestMembershipUrl={
                this.state.branch?.clubSettings?.requestMembershipUrl
              }
            />
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            <div className="tag-rating-container">
              {this.state.branch?.settings?.useSubscriptions && (
                <Tag
                  item={"Mitgliedschaften"}
                  selected={this.state.selectedTag === "subscriptions"}
                  color={"#c31924"}
                  onClick={() => {
                    this.handleTagClicked("subscriptions");
                  }}
                />
              )}
              {tagArr.map((currTag: any) => (
                <>
                  <Tag
                    item={currTag.name}
                    selected={currTag.id === this.state.selectedTag}
                    color={"#5DADE2"}
                    onClick={() => {
                      this.handleTagClicked(currTag.id);
                    }}
                  />
                </>
              ))}
            </div>

            <>
              {this.state.elements.length === 0 &&
                this.state.elementsLoaded && (
                  <>
                    {this.state.courseSearch === "" &&
                    !this.state.selectedTag ? (
                      <div className="no-courses-container">
                        In diesem Bereich gibt es aktuell keine Kurse.
                      </div>
                    ) : (
                      <div className="no-courses-container">
                        Mit diesem Filter gibt es aktuell keine Kurse.
                      </div>
                    )}
                  </>
                )}
              <InfiniteScroll
                dataLength={this.state.elements.length}
                next={this.getNextPage}
                hasMore={this.state.hasMore}
                loader={<></>}
                scrollableTarget="scroll-container"
              >
                {this.state.elements.map((elem: any) => (
                  <>
                    {elem.courseType === "subscription" && (
                      <SubscriptionElement
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        subscriptionId={elem.id}
                        title={elem.title}
                        description={elem.description}
                        price={getSubscriptionPriceString(
                          elem.price,
                          elem.admissionFee,
                          elem.billingPeriod
                        )}
                        imgUrl={elem.imgLowRes}
                        key={elem.id}
                        userAllowedToBook={elem.userAllowedToBook}
                        minAge={elem.minAge}
                        maxAge={elem.maxAge}
                        highlighted={this.state.neededRoles.includes(elem.id)}
                        requestMembershipUrl={
                          this.state.branch?.clubSettings?.requestMembershipUrl
                        }
                        redirect={this.state.redirect}
                      />
                    )}
                    {elem.courseType === "single-course" && (
                      <SingleCourseElement
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={elem.id}
                        title={elem.title}
                        description={elem.description}
                        startTime={elem.startTime}
                        endTime={elem.endTime}
                        where={elem.location.name}
                        freePlaces={
                          testMode ? elem.freePlacesTest : elem.freePlaces
                        }
                        price={priceToStr(elem.price)}
                        imgUrl={elem.imgLowRes}
                        key={elem.id}
                        bookableFrom={firebaseDateToDateObj(elem.bookableDate)}
                        userAllowedToBook={elem.userAllowedToBook}
                        minAge={elem.minAge}
                        maxAge={elem.maxAge}
                        requestMembershipUrl={
                          this.state.branch?.clubSettings?.requestMembershipUrl
                        }
                        memLink={elem.memLink}
                      />
                    )}
                    {elem.courseType === "block-course" && (
                      <BlockCourseElement
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={elem.id}
                        title={elem.title}
                        description={elem.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(elem.startTime)
                        )}
                        courses={elem.courses}
                        freePlaces={
                          testMode ? elem.freePlacesTest : elem.freePlaces
                        }
                        price={priceToStr(elem.price)}
                        imgUrl={elem.imgLowRes}
                        key={elem.id}
                        bookableFrom={firebaseDateToDateObj(elem.bookableDate)}
                        userAllowedToBook={elem.userAllowedToBook}
                        minAge={elem.minAge}
                        maxAge={elem.maxAge}
                        requestMembershipUrl={
                          this.state.branch?.clubSettings?.requestMembershipUrl
                        }
                        memLink={elem.memLink}
                      />
                    )}
                    {elem.courseType === "infinity-course" && (
                      <InfinityCourseElement
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={elem.id}
                        title={elem.title}
                        description={elem.description}
                        where={elem.location?.name}
                        time={elem.time}
                        duration={elem.duration}
                        weekDay={elem.weekDay}
                        freePlaces={
                          testMode ? elem.freePlacesTest : elem.freePlaces
                        }
                        price={priceToStr(elem.price)}
                        imgUrl={elem.imgLowRes}
                        key={elem.id}
                        bookableFrom={firebaseDateToDateObj(elem.bookableDate)}
                        userAllowedToBook={elem.userAllowedToBook}
                        minAge={elem.minAge}
                        maxAge={elem.maxAge}
                        requestMembershipUrl={
                          this.state.branch?.clubSettings?.requestMembershipUrl
                        }
                        memLink={elem.memLink}
                      />
                    )}
                  </>
                ))}
              </InfiniteScroll>
              {!this.state.elementsLoaded && (
                <>
                  <CourseElementSkeleton />
                  <CourseElementSkeleton />
                  <CourseElementSkeleton />
                  <CourseElementSkeleton />
                </>
              )}
              {this.state.elementsLoaded && this.state.hasMore && (
                <div className="load-more-container" onClick={this.getNextPage}>
                  Mehr laden
                </div>
              )}
            </>
          </div>
        </div>
      </>
    );
  }
}
