import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../contexts/MainContext";
import { Link } from "react-router-dom";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { formatLineBreaks } from "../functions/formatHTML";

export default class SubscriptionElementOwn extends React.Component<
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    subscriptionBooking: any;
    title: string;
    description: string;
    bookingCreated: string;
    imgUrl: string;
    notice: string;
    status: string;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    const subscriptionBookingId =
      this.props.subscriptionBooking.subscriptionBookingId;

    return (
      <div className="course-container" id={this.props.subscriptionId}>
        <div>
          {!this.props.imgUrl ? (
            <div className="course-img">
              <FontAwesomeIcon
                icon={faImage}
                size="6x"
                className="image-icon"
              />
            </div>
          ) : (
            <img
              className="course-img"
              src={this.props.imgUrl}
              alt="Kursbild"
            />
          )}
        </div>
        <div className="course-info-container">
          <div className="course-info-inner-container">
            <div className="course-title-container">{this.props.title}</div>
            <div>{this.props.description}</div>
            <div className="course-text-container">
              Status: <span className="normal-weight">{this.props.status}</span>
            </div>
            <div className="course-text-container">
              Gebucht:{" "}
              <span className="normal-weight">{this.props.bookingCreated}</span>
            </div>
            {this.props.notice && (
              <div className="course-text-container">
                Notiz:{" "}
                <span className="normal-weight">
                  {formatLineBreaks(this.props.notice)}
                </span>
              </div>
            )}
          </div>
          <div>
            <Link
              to={`/club/${this.props.clubId}/branch/${this.props.branchId}/mysubscriptions/${this.props.subscriptionId}/subscriptionBooking/${subscriptionBookingId}`}
              className="course-booking-detail-btn no-underline"
            >
              Details anzeigen
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
