import React from "react";
import ViewFormElement from "./ViewFormElement";
import { FormDataView, FormInput } from "../../../Types";
import "./ViewForm.css";

export default class ViewForm extends React.Component<
  {
    form: FormDataView;
    formInput: FormInput[];
    onChange: (formInput: FormInput[]) => void;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {
      formInput: [],
    };
  }

  handleValueChange = (index: number, value: string) => {
    let formInput = this.props.formInput;
    formInput[index].value = value;
    this.props.onChange(formInput);
  };

  handleActiveChange = (index: number, isActive: boolean) => {
    let formInput = this.props.formInput;
    formInput[index].isActive = isActive;
    this.props.onChange(formInput);
  };

  render() {
    const options = this.props.form?.options || [];
    return (
      <div className="form-wrapper">
        {this.props.form !== null && (
          <>
            {options.map((elem, i) => (
              <ViewFormElement
                formElem={{ ...elem, ...{ id: i } }}
                value={this.props.formInput[i].value}
                active={this.props.formInput[i].isActive}
                onValueChange={this.handleValueChange}
                onActiveChange={this.handleActiveChange}
              />
            ))}
          </>
        )}
      </div>
    );
  }
}
