import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./BranchModal.css";
import "./JoinBranchModal.css";
import "./PaymentMethodsModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecButton from "../SecButton";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ErrorMessage from "../ErrorMessage";
import MandateElem from "../MandateElem";
import InfoContainer from "../InfoContainer";
import { ClipLoader } from "react-spinners";
import { makeid } from "../../functions/utils";

type ConsumerDetails = {
  consumerName: string;
  consumerAccount: string;
  consumerBic: string;
};

export type Mandate = {
  id: string;
  status?: string;
  method?: string;
  details?: ConsumerDetails;
  mandateReference?: null;
  signatureDate?: string;
  createdAt?: string;
};

export default class PaymentMethodsModal extends React.Component<
  {
    clubId: string;
    branchId: string;
    elemId: string;
    elemBookingId: string;
    selectedMandateId: string;
    show: boolean;
    handleClose: any;
    refresh: any;
    type: "course" | "subscription";
  },
  {
    loading: boolean;
    updateLoading: boolean;
    createLoading: boolean;
    errorMessage: string | null;
    mandates: Mandate[];
    selectedMandateId: string;
    showOtherPaymentMethod: boolean;
    sessionId: string;
    branch: any;
    errorMsg: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      updateLoading: false,
      createLoading: false,
      errorMessage: null,
      selectedMandateId: this.props.selectedMandateId,
      mandates: [],
      showOtherPaymentMethod: false,
      sessionId: makeid(32),
      branch: {},
      errorMsg: null,
    };
  }

  componentDidMount = () => {
    this.handleGetCustomerMandates();
    this.requestBranch();
  };

  componentWillUnmount() {
    document.getElementById("paypal-fraud-1")?.remove();
    document.getElementById("paypal-fraud-2")?.remove();
  }

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch,
      });
      const paypalWebsiteId = this.state.branch?.paypalWebsiteId;
      if (paypalWebsiteId) {
        const script = document.createElement("script");
        script.innerHTML = `{
        "f": "${this.state.sessionId}",
        "s": "${paypalWebsiteId}"
     }`;
        script.type = "application/json";
        script.setAttribute(
          "fncls",
          "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99"
        );
        script.setAttribute("id", "paypal-fraud-1");
        const paypalFraudScript = document.createElement("script");

        paypalFraudScript.src = "https://c.paypal.com/da/r/fb.js";
        paypalFraudScript.type = "text/javascript";
        paypalFraudScript.setAttribute("id", "paypal-fraud-2");

        document.body.appendChild(script);
        document.body.appendChild(paypalFraudScript);
      }
    }
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.selectedMandateId !== this.props.selectedMandateId) {
      this.setState({ selectedMandateId: this.props.selectedMandateId });
    }
  }
  handleUpdatePaymentMethod = () => {
    if (this.props.type == "course") {
      this.handleUpdatePaymentMethodCourse();
    } else if (this.props.type == "subscription") {
      this.handleUpdatePaymentMethodSubscription();
    }
  };

  handleUpdatePaymentMethodSubscription = () => {
    this.setState({ updateLoading: true });

    fetchRetry(
      "updateCustomerMandateSubscription",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        targetUserId: this.context.selectedUserId,
        subscriptionId: this.props.elemId,
        subscriptionBookingId: this.props.elemBookingId,
        mandateId: this.state.selectedMandateId,
      },
      1,
      5
    )
      .then(this.handleUpdatePaymentMethodSuccess)
      .catch(this.handleError);
  };

  handleUpdatePaymentMethodCourse = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateCustomerMandate",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        targetUserId: this.context.selectedUserId,
        courseId: this.props.elemId,
        courseBookingId: this.props.elemBookingId,
        mandateId: this.state.selectedMandateId,
      },
      1,
      5
    )
      .then(this.handleUpdatePaymentMethodSuccess)
      .catch(this.handleError);
  };

  handleGetCustomerMandates = () => {
    if (this.props.type == "course") {
      this.handleGetCustomerMandatesCourse();
    } else if (this.props.type == "subscription") {
      this.handleGetCustomerMandatesSubscription();
    }
  };

  handleGetCustomerMandatesCourse = () => {
    this.setState({ loading: true });
    fetchRetry(
      "getCustomerMandates",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        targetUserId: this.context.selectedUserId,
        courseId: this.props.elemId,
        courseBookingId: this.props.elemBookingId,
      },
      1,
      5
    )
      .then(this.handleGetCustomerMandatesSuccess)
      .catch(this.handleError);
  };

  handleGetCustomerMandatesSubscription = () => {
    this.setState({ loading: true });
    fetchRetry(
      "getCustomerMandateSubscription",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        targetUserId: this.context.selectedUserId,
        subscriptionId: this.props.elemId,
        subscriptionBookingId: this.props.elemBookingId,
      },
      1,
      5
    )
      .then(this.handleGetCustomerMandatesSuccess)
      .catch(this.handleError);
  };

  handleGetCustomerMandatesSuccess = ({ data }: any) => {
    if (data.success) {
      const mandates: Mandate[] = data.data;
      this.setState({ mandates, loading: false });
    } else {
      this.setState({ errorMessage: data.errorMsgDe, loading: false });
    }
  };

  handleUpdatePaymentMethodSuccess = ({ data }: any) => {
    if (data.success) {
      this.context.createInfo(
        "Zahlungsmethode erfolgreich geändert.",
        "success",
        4
      );
      this.props.refresh();
      this.props.handleClose();
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleCreateNewPayment = () => {
    if (this.props.type == "course") {
      this.handleCreateNewPaymentCourse();
    } else if (this.props.type == "subscription") {
      this.handleCreateNewPaymentSubscription();
    }
  };

  handleCreateNewPaymentCourse = () => {
    this.setState({ createLoading: true });
    fetchRetry(
      "createNewMandatePayment",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        courseId: this.props.elemId,
        targetUserId: this.context.selectedUserId,
        courseBookingId: this.props.elemBookingId,
        sessionId: this.state.sessionId,
        redirectUrl: window.location.href,
      },
      1,
      5
    )
      .then(this.handleCreateNewPaymentSuccess)
      .catch(this.handleError);
  };

  handleCreateNewPaymentSubscription = () => {
    this.setState({ createLoading: true });
    fetchRetry(
      "createNewMandatePaymentSubscription",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        subscriptionId: this.props.elemId,
        targetUserId: this.context.selectedUserId,
        subscriptionBookingId: this.props.elemBookingId,
        sessionId: this.state.sessionId,
        redirectUrl: window.location.href,
      },
      1,
      5
    )
      .then(this.handleCreateNewPaymentSuccess)
      .catch(this.handleError);
  };

  handleCreateNewPaymentSuccess = ({ data }: any) => {
    this.setState({ createLoading: false });
    if (data.success) {
      window.location.href = data.checkoutUrl;
    } else {
      this.setState({ errorMsg: data.errorMsgDe });
    }
  };

  handleMethodSelect = (methodId: string) => {
    this.setState({ selectedMandateId: methodId });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="join Branch"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="branch-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={faTimes} className="join-branch-cross" />
            </div>
            <div className="modal-title-container">
              <span className="modal-subtitle">Zahlungsmethode ändern</span>
            </div>
            <ErrorMessage message={this.state.errorMessage} />
            <br />
            <div className="manates-wrapper">
              {this.state.loading && (
                <div className="loading-container">
                  <ClipLoader color={"#c31924"} size={100} loading={true} />
                </div>
              )}
              {this.state.mandates.map((mandate: Mandate, index: number) => (
                <MandateElem
                  mandate={mandate}
                  selected={mandate.id == this.state.selectedMandateId}
                  current={mandate.id == this.props.selectedMandateId}
                  onSelect={() => {
                    this.handleMethodSelect(mandate.id);
                  }}
                />
              ))}
            </div>
            {this.state.selectedMandateId != this.props.selectedMandateId && (
              <SecButton
                change={
                  this.state.selectedMandateId != this.props.selectedMandateId
                }
                color="green"
                loading={this.state.updateLoading}
                onClick={this.handleUpdatePaymentMethod}
                title={"Zahlungsmethode ändern"}
              />
            )}
            {!this.state.showOtherPaymentMethod ? (
              <div
                onClick={() => {
                  this.setState({
                    showOtherPaymentMethod: true,
                  });
                }}
                className="add-other-payment-method"
              >
                Andere Zahlungsmethode hinzufügen
              </div>
            ) : (
              <>
                <InfoContainer>
                  Um eine andere Zahlungsmethode zu verwenden, wird die Zahlung,
                  mit der neuen Methode für den nächsten Monat vorgezogen.
                  <br />
                  <br /> Die Karte wird dabei nicht zweimal belastet.
                </InfoContainer>
                <ErrorMessage message={this.state.errorMsg} />
                <div className="add-other-payment-wrapper">
                  <div
                    className={`add-other-payment-method-confirm ${
                      this.state.createLoading &&
                      " add-other-payment-method-disabled"
                    }`}
                    onClick={this.handleCreateNewPayment}
                  >
                    Neue Zahlungsmethode hinzufügen
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }
}
