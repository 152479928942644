import React from "react";
import "./VertNavbar.css";
import "./ClubSettingsNavbar.css";
import { MainContext } from "../../contexts/MainContext";

import ClubSettingsNavbarElement from "./elements/ClubSettingsNavbarElement";
import BranchSelectorNavbarElement from "./elements/BranchSelectorNavbarElement";
import LogoNavbarElement from "./elements/LogoNavbarElement";
import UserNavbarElement from "./elements/UserNavbarElement";
import { Link } from "react-router-dom";
import {
  faCodeBranch,
  faCog,
  faPalette,
  faUserPlus,
  faUsers,
  faUserTag,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

export default class ClubSettingsNavbar extends React.Component<
  {
    selectedScreen: string;
    clubId: string;
    branchArr: Array<any>;
    locationArr: Array<any>;
    roleArr: Array<any>;
    showMobile?: boolean;
    hideMobileNav: any;
    selectedBranch: string;
    onCreateBranch: any;
    selectedObject: string;
    onCreateLocation: any;
    onCreateRole: any;
    useMembershipApplication: boolean;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`nav-background ${
            this.props.showMobile && " show-nav-background"
          }`}
          onClick={this.props.hideMobileNav}
        ></div>
        <nav
          className={`vert-navbar br-navbar ${
            this.props.showMobile && " vert-navbar-mobile"
          }`}
        >
          <LogoNavbarElement />
          <div className="vert-navbar-scrollable">
            <UserNavbarElement to={`/club/${this.props.clubId}/profile`} />
            <ClubSettingsNavbarElement
              title="Benutzer"
              icon={faUsers}
              selected={this.props.selectedScreen === "users"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club-settings/${this.props.clubId}/users`}
            />
            <ClubSettingsNavbarElement
              title="Aussehen"
              icon={faPalette}
              selected={this.props.selectedScreen === "appearance"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club-settings/${this.props.clubId}/appearance`}
            />
            <ClubSettingsNavbarElement
              title="Rollen"
              icon={faUserTag}
              selected={this.props.selectedScreen === "roles"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club-settings/${this.props.clubId}/roles${
                this.props.roleArr.length > 0
                  ? `/${this.props.roleArr[0].id}`
                  : ""
              }`}
            />
            <div
              className={`navbar-objects ${
                this.props.selectedScreen === "roles" &&
                "navbar-objects-selected"
              }`}
            >
              {this.props.roleArr.map((role: any) => (
                <BranchSelectorNavbarElement
                  title={role.name}
                  selected={this.props.selectedObject === role.id}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club-settings/${this.props.clubId}/roles/${role.id}`}
                  key={role.id}
                />
              ))}
              <div
                className={"navbar-element navbar-create-element"}
                onClick={this.props.onCreateRole}
              >
                <span>+</span>
              </div>
            </div>
            <ClubSettingsNavbarElement
              title="Örtlichkeiten"
              icon={faWarehouse}
              selected={this.props.selectedScreen === "locations"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club-settings/${this.props.clubId}/locations${
                this.props.locationArr.length > 0
                  ? `/${this.props.locationArr[0].id}`
                  : ""
              }`}
            />
            <div
              className={`navbar-objects ${
                this.props.selectedScreen === "locations" &&
                "navbar-objects-selected"
              }`}
            >
              {this.props.locationArr.map((location: any) => (
                <BranchSelectorNavbarElement
                  title={location.name}
                  selected={this.props.selectedObject === location.id}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club-settings/${this.props.clubId}/locations/${location.id}`}
                  key={location.id}
                />
              ))}
              <div
                className={"navbar-element navbar-create-element"}
                onClick={this.props.onCreateLocation}
              >
                <span>+</span>
              </div>
            </div>
            <ClubSettingsNavbarElement
              title="Bereiche"
              icon={faCodeBranch}
              selected={this.props.selectedScreen === "branches"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club-settings/${this.props.clubId}/branches/${
                this.props.branchArr.length > 0
                  ? this.props.branchArr[0].id
                  : ""
              }`}
            />
            <div
              className={`navbar-objects ${
                this.props.selectedScreen === "branches" &&
                "navbar-objects-selected"
              }`}
            >
              {this.props.branchArr.map((object: any) => (
                <BranchSelectorNavbarElement
                  title={object.name}
                  selected={this.props.selectedBranch === object.id}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club-settings/${this.props.clubId}/branches/${object.id}`}
                  key={object.id}
                />
              ))}
              {this.context.isAdmin && (
                <div
                  className={"navbar-element navbar-create-element"}
                  onClick={this.props.onCreateBranch}
                >
                  <span>+</span>
                </div>
              )}
            </div>
            {this.props.useMembershipApplication && (
              <ClubSettingsNavbarElement
                title="Mitgliedsanfragen"
                icon={faUserPlus}
                selected={
                  this.props.selectedScreen === "membership-applications"
                }
                onClick={this.props.hideMobileNav}
                linkTo={`/club-settings/${this.props.clubId}/membership-applications`}
              />
            )}
            <ClubSettingsNavbarElement
              title="Einstellungen"
              icon={faCog}
              selected={this.props.selectedScreen === "settings"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club-settings/${this.props.clubId}/settings`}
            />
          </div>
          <Link
            to={`/club/${this.props.clubId}`}
            className={"navbar-element navbar-back-element"}
            onClick={this.props.hideMobileNav}
          >
            zum System
          </Link>
        </nav>
      </>
    );
  }
}
