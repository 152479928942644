import { fc } from "../services/firebase";

function wait(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export async function fetchRetry(
  request: string,
  params: any,
  delay: number,
  tries: number
) {
  function onError(err: any): any {
    let triesLeft = tries - 1;
    if (!triesLeft) {
      throw err;
    }
    console.log(`retrying ${tries}...`);
    return wait(delay).then(() =>
      fetchRetry(request, params, delay, triesLeft)
    );
  }
  return fc.httpsCallable(request)(params).catch(onError);
}
