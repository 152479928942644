import React from "react";
import "./TopMenuBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../contexts/MainContext";
import { fetchRetry } from "../functions/request";
import { faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default class TopMenuBar extends React.Component<
  { handleShowMobileNav: any },
  {
    showNotifications: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      showNotifications: false,
    };
  }

  componentDidMount = () => {};

  handleShowNotifications = () => {
    this.setState({ showNotifications: true });
  };

  handleHideNotifications = () => {
    this.setState({ showNotifications: false });
  };

  handleToggleNotifications = () => {
    this.setState({ showNotifications: !this.state.showNotifications });
  };

  handleMarkNotificationsAsSeen = () => {
    this.context.clearUnreadNotifications();
    fetchRetry(
      "markAllNotificationsAsReadF",
      {
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleMarkNotificationsAsReadSuccess)
      .catch(this.handleError);
  };

  handleMarkNotificationsAsReadSuccess = () => {
    this.context.requestUnreadNotifications();
  };

  handleError = ({ data }: any) => {
    console.error(data);
  };

  getLinkFromNotification = (linkTo: string, path: Array<string>) => {
    if (linkTo === "branchUser") {
      return `/club/${path[0]}/branch-settings/${path[1]}/users/${path[2]}`;
    }
    if (linkTo === "branch") {
      return `/club/${path[0]}/branch/${path[1]}`;
    }
    if (linkTo === "courseBooking") {
      return `/club/${path[0]}/branch/${path[1]}/participants/${path[2]}/participant/${path[3]}`;
    }
    return "";
  };

  render() {
    return (
      <div className="menubar-container">
        <div className="menubar-path-main-container">
          <div className="menubar-container-icon">
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              onClick={this.props.handleShowMobileNav}
              className="menu-icon"
            />
          </div>
          {this.context.path && (
            <div className="menubar-path-container">
              {this.context.path.map((path: any, index: number) => (
                <>
                  <a href={path.link} className="menubar-path-text">
                    {path.name}
                  </a>
                  {this.context.path &&
                    this.context.path.length - 1 > index &&
                    " ▸ "}
                </>
              ))}
            </div>
          )}
        </div>
        <div>
          <div
            className="menubar-bell-icon"
            onClick={this.handleToggleNotifications}
          >
            {this.context.notificationArr.length > 0 && (
              <div className="menubar-bell-new-notification"></div>
            )}
            <FontAwesomeIcon
              icon={faBell}
              size="lg"
              className="menu-bell-icon"
            />
          </div>
          <div
            className={`notifications-container ${
              this.state.showNotifications ? " show-notification" : ""
            }`}
          >
            <div className={"notifications-inner-container"}>
              <div
                className="notification-markallasread"
                onClick={this.handleMarkNotificationsAsSeen}
              >
                Alle als gelesen markieren
              </div>
              {this.context.notificationArr.map(
                (notification: any, index: number) => (
                  <Link
                    to={this.getLinkFromNotification(
                      notification.linkTo,
                      notification.path
                    )}
                    className="notification-inner-container black no-underline"
                    key={index}
                  >
                    <div className="notification-title-container">
                      {notification.subject}
                    </div>
                    {notification.message}
                  </Link>
                )
              )}
              {this.context.notificationArr.length === 0 && (
                <div className="notification-uptodate">
                  Du bist auf dem aktuellen Stand.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
