import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import "./SingleUser.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import { HashLoader } from "react-spinners";
import BackElement from "../../components/BackElement";
import Input from "../../components/Input";
import MultiSelector from "../../components/MultiSelector";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import {
  dateTosimpleDateStr,
  findWithAttr,
  firebaseDateToDateObj,
  getMonthNum,
  reformatDate,
  reformatDateReverse,
} from "../../functions/utils";
import SubTitle from "../../components/SubTitle";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { MainContext } from "../../contexts/MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { getBookingStatus } from "../../functions/getBookingStatus";
import Title from "../../components/Title";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";
import TextCheckbox from "../../components/TextCheckbox";
import qs from "qs";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    userId: string;
  }>;

export default class SingleUser extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    userId: string;
    membershipNumber: string;
    fName: string;
    lName: string;
    newRole: number;
    oldRole: number;
    newStatus: number;
    oldStatus: number;
    statusOptions: any;
    roleOptions: any;
    branch: any;
    branchLoaded: boolean;
    user: any;
    userLoaded: boolean;
    selectedUserRoleObj: Array<any>;
    userRoles: Array<any>;
    change: boolean;
    errMsgDeleteUser: null | string;
    errMsgSaveUser: null | string;
    showConfirmKick: boolean;
    newRoleArr: Array<any>;
    oldRoleArr: Array<any>;
    updateLoading: boolean;
    removeUserLoading: boolean;
    redirect: string | null;
    courseBookingArr: Array<any>;
    subscriptionBookingArr: Array<any>;
    elementsLoaded: boolean;
    courseBookingTableArr: Array<RowData>;
    subscriptionBookingTableArr: Array<RowData>;
    showAllCourseBookings: boolean;
    showAllSubscriptionBookings: boolean;
    roleChange: boolean;
    backLink: string | null | any;
    monthNum: number;
    birthDate: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      userId: this.props.match.params.userId,
      membershipNumber: "",
      fName: "",
      lName: "",
      newRole: 0,
      oldRole: 0,
      newStatus: 0,
      oldStatus: 0,
      statusOptions: [
        { value: 1, label: "Nicht verifiziert" },
        { value: 2, label: "Aktiv" },
      ],
      roleOptions: [
        { value: 1, label: "Mitglied" },
        { value: 2, label: "Besitzer" },
      ],
      branch: {},
      branchLoaded: false,
      user: {},
      userLoaded: false,
      selectedUserRoleObj: [],
      userRoles: [],
      change: false,
      errMsgDeleteUser: null,
      errMsgSaveUser: null,
      showConfirmKick: false,
      newRoleArr: [],
      oldRoleArr: [],
      updateLoading: false,
      removeUserLoading: false,
      redirect: null,
      courseBookingArr: [],
      subscriptionBookingArr: [],
      elementsLoaded: false,
      courseBookingTableArr: [],
      subscriptionBookingTableArr: [],
      showAllCourseBookings: false,
      showAllSubscriptionBookings: false,
      roleChange: false,
      backLink:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).back || null,
      monthNum: getMonthNum(new Date()),
      birthDate: "",
    };
  }

  componentDidMount = () => {
    Promise.all([this.requestUser(), this.requestBranch()]).then(
      this.handleFullyLoaded
    );
    this.requestUserElements();
  };

  requestUserElements = async () => {
    await fetchRetry(
      "getElementsFromBranchUser",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleUserElements)
      .catch(this.handleError);
  };

  handleUserElements = ({ data }: any) => {
    if (data.success) {
      const courseBookingArr = data.data.courseBookings;
      const subscriptionBookingArr = data.data.subscriptionBookings;
      const courseBookingTableArr = courseBookingArr.map((booking: any) => {
        const bookingActive =
          booking.active &&
          (booking.billing !== "single-payment" ||
            firebaseDateToDateObj(booking?.course?.endTime)?.getTime() >
              new Date().getTime());
        return {
          cells: [
            { value: booking?.course?.title, sort: booking?.course?.title },
            {
              value: getBookingStatus(booking),
              sort: getBookingStatus(booking),
              search: bookingActive ? "active" : "",
            },
            {
              value: dateTosimpleDateStr(
                firebaseDateToDateObj(booking.created_at)
              ),
              sort: booking.created_at._seconds,
            },
          ],
          onClick: `/club/${this.state.clubId}/branch/${booking.branch.id}/participants/${booking.course.id}/participant/${booking.id}`,
        };
      });
      const subscriptionBookingTableArr = subscriptionBookingArr.map(
        (booking: any) => {
          const bookingActive =
            booking.active && booking.endMonth >= this.state.monthNum;
          return {
            cells: [
              {
                value: booking?.subscription?.title,
                sort: booking?.subscription?.title,
              },
              {
                value: getBookingStatus(booking),
                sort: getBookingStatus(booking),
                search: bookingActive ? "active" : "",
              },
              {
                value: dateTosimpleDateStr(
                  firebaseDateToDateObj(booking.created_at)
                ),
                sort: booking.created_at._seconds,
              },
            ],
            onClick: `/club/${this.state.clubId}/branch/${this.state.branchId}/subscription-participants/${booking.subscription.id}/participant/${booking.id}`,
          };
        }
      );

      this.setState({
        courseBookingTableArr,
        subscriptionBookingTableArr,
        courseBookingArr: courseBookingArr,
        subscriptionBookingArr: subscriptionBookingArr,
        elementsLoaded: true,
      });
    }
  };

  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.user.uid,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  handleFullyLoaded = () => {
    const roles = this.state.branch.roles || {};
    const userRoles = this.state.user?.roles || [];
    const roleArr = Object.keys(roles).map((roleId: string) => {
      return {
        id: roleId,
        name: roles[roleId].name,
        selected: userRoles.includes(roleId),
        color: roles[roleId].type === "club" ? "#5c67ee" : "#ee5c5c",
        notEditable: roles[roleId].type === "club",
      };
    });
    this.setState(
      {
        newRoleArr: roleArr,
      },
      this.checkChange
    );
  };

  requestUser = async () => {
    await fetchRetry(
      "getUserFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleUser)
      .catch(this.handleError);
  };

  handleUser = ({ data }: any) => {
    this.setState({ userLoaded: true });
    if (data.success) {
      const user = data.data;
      const roleDics: any = { Owner: 2, ViewOnly: 1, Member: 0 };
      this.setState(
        {
          user,
          newStatus: user.accepted ? 1 : 0,
          oldStatus: user.accepted ? 1 : 0,
          newRole: roleDics[user.role],
          oldRole: roleDics[user.role],
          fName: user.fName,
          lName: user.lName,
          membershipNumber: user.membershipNumber,
          birthDate: reformatDateReverse(user.birthDate),
        },
        this.checkChange
      );
    }
  };

  handleStatusSelected = (e: any) => {
    this.setState(
      { newStatus: e.target.options.selectedIndex },
      this.checkChange
    );
  };

  handleRoleSelected = (e: any) => {
    this.setState(
      { newRole: e.target.options.selectedIndex },
      this.checkChange
    );
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.newRole !== this.state.oldRole ||
        this.state.newStatus !== this.state.oldStatus ||
        this.state.fName !== this.state.user.fName ||
        this.state.lName !== this.state.user.lName ||
        this.state.membershipNumber !== this.state.user.membershipNumber ||
        this.state.user?.birthDate !== reformatDate(this.state.birthDate) ||
        this.state.roleChange,
    });
  };

  handleKickUser = () => {
    this.setState({ showConfirmKick: true });
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.newRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ newRoleArr, roleChange: true }, this.checkChange);
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.newRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ newRoleArr, roleChange: true }, this.checkChange);
  };

  hideConfirmKickModal = () => {
    this.setState({ showConfirmKick: false });
  };

  kickUserFromBranch = () => {
    this.setState({ showConfirmKick: false, removeUserLoading: true });
    fetchRetry(
      "kickUserFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        userId: this.state.userId,
      },
      1,
      5
    )
      .then(this.handleSuccessKick)
      .catch(this.handleError);
  };

  handleSuccessKick = ({ data }: any) => {
    this.setState({ removeUserLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Der Benutzer wurde erfolgreich aus diesem Bereich entfernt.",
        "success",
        4
      );
      this.setState({
        redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users`,
      });
    } else {
      this.setState({
        errMsgDeleteUser: data.errorMsgDe,
      });
    }
  };

  updateUserInBranch = () => {
    this.setState({ updateLoading: true });
    const roleArr = this.state.newRoleArr
      .filter((item: any) => item.selected)
      .map((item: any) => {
        return item.id;
      });
    const roleDict = ["Member", "ViewOnly", "Owner"];

    fetchRetry(
      "updateUserInBranchNew",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        userId: this.state.userId,
        roleArr,
        role: roleDict[this.state.newRole],
        accepted: this.state.newStatus === 1,
        fName: this.state.fName,
        lName: this.state.lName,
        membershipNumber: this.state.membershipNumber,
        birthDate: reformatDate(this.state.birthDate),
      },
      1,
      5
    )
      .then(this.handleSuccessUpdateUserInBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdateUserInBranch = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestUser();
      this.setState({ roleChange: false });
    } else {
      this.setState({ errMsgSaveUser: data.errorMsgDe });
    }
  };

  handleMembershipNumberChange = (membershipNumber: string) => {
    this.setState({ membershipNumber }, this.checkChange);
  };

  handlefNameChange = (fName: string) => {
    this.setState({ fName }, this.checkChange);
  };

  handlelNameChange = (lName: string) => {
    this.setState({ lName }, this.checkChange);
  };

  handleRedirect = (redirect: string) => {
    this.props.history.push({
      pathname: redirect,
      search: `back=/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${this.state.userId}`,
    });
  };

  toggleShowAllCourseBookings = () => {
    this.setState({ showAllCourseBookings: !this.state.showAllCourseBookings });
  };

  toggleShowAllSubscriptionBookings = () => {
    this.setState({
      showAllSubscriptionBookings: !this.state.showAllSubscriptionBookings,
    });
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value }, this.checkChange);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded || !this.state.userLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmKick}
          title="Benutzer entfernen"
          msg="Bist du sicher das du diesen Nutzer aus dem Bereich entfernen willst? Die Einstellungen zu diesem Benutzer werden entgültig gelöscht."
          handleClose={this.hideConfirmKickModal}
          handleConfirm={this.kickUserFromBranch}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <div className="subscreen-bell-container">
              {this.state.user.familyAccountId && (
                <Link
                  to={`/club/${this.state.clubId}/branch-settings/${this.state.branchId}/family/${this.state.user.familyAccountId}?back=/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${this.state.userId}`}
                  className="black"
                >
                  <FontAwesomeIcon
                    icon={faHouseUser}
                    size="lg"
                    onClick={() => {}}
                    className="course-settings-icon"
                  />
                </Link>
              )}
              <Link
                to={`/club/${this.state.clubId}/branch-settings/${this.state.branchId}/feed?suserId=${this.state.userId}`}
                className="black"
              >
                <FontAwesomeIcon
                  icon={faAlignLeft}
                  size="lg"
                  onClick={() => {}}
                  className="course-settings-icon"
                />
              </Link>
            </div>

            <BackElement
              text={
                this.state.backLink ? "zurück" : "zurück zur Nutzerübersicht"
              }
              to={
                this.state.backLink ||
                `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users`
              }
            />
            <div className="modal-title-container">
              <span className="modal-title">Nutzerdaten bearbeiten</span>
            </div>
            <div>
              <div className="modal-user-container">
                {this.state.branch?.clubSettings?.useMembershipNumber && (
                  <>
                    <SubTitle title="Mitgliedsnummer" />
                    <Input
                      name="Mitgliedsnummer"
                      placeholder="Mitgliedsnummer"
                      value={this.state.membershipNumber}
                      onChange={this.handleMembershipNumberChange}
                    />
                  </>
                )}
                <SubTitle title="Name" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.fName}
                    onChange={this.handlefNameChange}
                    placeholder="Vorname"
                  />
                  <Input
                    value={this.state.lName}
                    onChange={this.handlelNameChange}
                    placeholder="Nachname"
                  />
                </div>
                <SubTitle title="E-Mail" />
                <Input
                  name="email"
                  placeholder="E-Mail"
                  value={this.state.user?.email}
                  onChange={() => {}}
                  disabled
                />
                <SubTitle title="Adresse" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.user?.address?.street}
                    onChange={() => {}}
                    placeholder="Straße"
                    className="street-input"
                    disabled
                  />
                  <Input
                    value={this.state.user?.address?.houseNumber}
                    onChange={() => {}}
                    placeholder="Nr."
                    className="house-number-input"
                    disabled
                  />
                </div>
                <div className="profile-location-container">
                  <Input
                    value={this.state.user?.address?.zipcode}
                    onChange={() => {}}
                    placeholder="PLZ"
                    className="zipcode-input"
                    disabled
                  />
                  <Input
                    value={this.state.user?.address?.city}
                    onChange={() => {}}
                    placeholder="Stadt"
                    className="city-input"
                    disabled
                  />
                </div>
                <SubTitle title="Telefonnummer" />
                <Input
                  value={this.state.user.phoneNumber}
                  onChange={() => {}}
                  placeholder="Telefonnummer"
                  disabled
                />
                <SubTitle title="Geburtsdatum" />
                <input
                  type="date"
                  className="input-container"
                  value={this.state.birthDate}
                  onChange={this.handleBirthDateChange}
                />
                <SubTitle title="Rechte" />
                <select
                  placeholder="Rechte"
                  className="input-container"
                  value={this.state.newRole}
                  onChange={this.handleRoleSelected}
                >
                  <option value="0">Mitglied</option>
                  <option value="1">Mitarbeiter</option>
                  <option value="2">Admin</option>
                </select>

                <SubTitle title="Rollen" />
                <MultiSelector
                  searchTextPlaceholder="Rolle"
                  onSelect={this.handleRoleSelect}
                  onUnselect={this.handleRoleUnselect}
                  arr={this.state.newRoleArr}
                />
              </div>
            </div>
            <div className="medium-space"></div>
            <ErrorMessage message={this.state.errMsgSaveUser} />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateUserInBranch}
              title="Speichern"
            />
            <ErrorMessage message={this.state.errMsgDeleteUser} />
            <SecButton
              change={true}
              color="red"
              loading={this.state.removeUserLoading}
              onClick={this.handleKickUser}
              title="Nutzer entfernen"
            />
            {this.context.branch?.settings?.useCourse && (
              <>
                <Title title="Kursbuchungen" />
                <TextCheckbox
                  text="alle anzeigen"
                  val={this.state.showAllCourseBookings}
                  onChange={this.toggleShowAllCourseBookings}
                />
                <Table
                  header={[
                    { title: "Kurs" },
                    { title: "Status" },
                    { title: "Buchungszeitpunkt", displayWidth: 600 },
                  ]}
                  data={this.state.courseBookingTableArr}
                  loaded={this.state.elementsLoaded}
                  skeletonRows={3}
                  onClick={this.handleRedirect}
                  defaultSortDirection="desc"
                  defaultSortIndex={2}
                  searchValue={this.state.showAllCourseBookings ? "" : "active"}
                />
              </>
            )}
            {this.context.branch?.settings?.useSubscriptions && (
              <>
                <Title title="Mitgliedschaftsbuchungen" />
                <TextCheckbox
                  text="alle anzeigen"
                  val={this.state.showAllSubscriptionBookings}
                  onChange={this.toggleShowAllSubscriptionBookings}
                />
                <Table
                  header={[
                    { title: "Mitgliedschaft" },
                    { title: "Status" },
                    { title: "Buchungszeitpunkt", displayWidth: 600 },
                  ]}
                  data={this.state.subscriptionBookingTableArr}
                  loaded={this.state.elementsLoaded}
                  skeletonRows={3}
                  onClick={this.handleRedirect}
                  defaultSortDirection="desc"
                  defaultSortIndex={2}
                  searchValue={
                    this.state.showAllSubscriptionBookings ? "" : "active"
                  }
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
