export const molliePaymentStatus: any = {
  paid: { name: "bezahlt", color: "#4cd964", active: true, retryable: false },
  open: { name: "offen", color: "#ffb100", active: true, retryable: false },
  authorized: {
    name: "autorisiert",
    color: "#ffb100",
    active: true,
    retryable: false,
  },
  failed: {
    name: "fehlgeschlagen",
    color: "#ff3b30",
    active: false,
    retryable: true,
  },
  expired: {
    name: "verfallen",
    color: "#b3b3b3",
    active: false,
    retryable: false,
  },
  canceled: {
    name: "abgebrochen",
    color: "#b3b3b3",
    active: false,
    retryable: false,
  },
  free: { name: "gebucht", color: "#4cd964", active: true, retryable: false },
  manuelly: {
    name: "gebucht",
    color: "#ffb100",
    active: true,
    retryable: false,
  },
  manuellyPaid: {
    name: "gebucht",
    color: "#4cd964",
    active: true,
    retryable: false,
  },
  pending: {
    name: "ausstehend",
    color: "#ffb100",
    active: true,
    retryable: true,
  },
  refunded: {
    name: "rückerstattet",
    color: "#07f",
    active: true,
    retryable: false,
  },
  processing: {
    name: "wird rückerstattet",
    color: "#07f",
    active: true,
    retryable: false,
  },
  paused: { name: "pausiert", color: "#07f", active: true, retryable: false },
  hasChargebacks: {
    name: "rückerstattung (kunde)",
    color: "#ff3b30",
    active: false,
    retryable: false,
  },
};

export const memAppStatus: any = {
  processed: { name: "bearbeitet", color: "#07f", active: true },
  open: { name: "offen", color: "#ffb100", active: true },
};

export const month: any = [
  { name: "Januar", shortName: "Jan." },
  { name: "Februar", shortName: "Feb." },
  { name: "März", shortName: "März" },
  { name: "April", shortName: "Apr." },
  { name: "Mai", shortName: "Mai" },
  { name: "Juni", shortName: "Jun." },
  { name: "Juli", shortName: "Jul." },
  { name: "August", shortName: "Aug." },
  { name: "September", shortName: "Sept." },
  { name: "Oktober", shortName: "Okt." },
  { name: "November", shortName: "Nov." },
  { name: "Dezember", shortName: "Dez." },
];

export const weekDays: any = [
  {
    id: "0",
    name: "Montag",
  },
  {
    id: "1",
    name: "Dienstag",
  },
  {
    id: "2",
    name: "Mittwoch",
  },
  {
    id: "3",
    name: "Donnerstag",
  },
  {
    id: "4",
    name: "Freitag",
  },
  {
    id: "5",
    name: "Samstag",
  },
  {
    id: "6",
    name: "Sonntag",
  },
];

export const weekDaysSection: any = [
  {
    id: "7",
    name: "Tag",
  },
  {
    id: "1",
    name: "Montag",
  },
  {
    id: "2",
    name: "Dienstag",
  },
  {
    id: "3",
    name: "Mittwoch",
  },
  {
    id: "4",
    name: "Donnerstag",
  },
  {
    id: "5",
    name: "Freitag",
  },
  {
    id: "6",
    name: "Samstag",
  },
  {
    id: "0",
    name: "Sonntag",
  },
];

export const dateUnits: any = [
  {
    id: "1",
    name: "Tage",
    dbName: "day",
  },
  {
    id: "2",
    name: "Wochen",
    dbName: "week",
  },
  {
    id: "3",
    name: "Monate",
    dbName: "month",
  },
  {
    id: "4",
    name: "Jahre",
    dbName: "year",
  },
];

export const dateUnitsArr: Array<string> = ["day", "week", "month", "year"];

export const courseTypes: any = {
  "block-course": {
    name: "Blockkurs",
    num: 2,
  },
  "single-course": {
    name: "Einmaliger Kurs",
    num: 1,
  },
  "infinity-course": {
    name: "Fortlaufender Kurs",
    num: 3,
  },
};

export const roles: any = {
  Owner: {
    germName: "Admin",
  },
  ViewOnly: {
    germName: "Mitarbeiter",
  },
  Member: {
    germName: "Mitglied",
  },
};

export const mollieStatusArr = [
  {
    text: "Nicht verbunden",
    status: "error",
    showDashoardLink: false,
  },
  {
    text: "Bevor Ihr Zahlungen erhalten können, benötigt Mollie weitere Informationen.",
    status: "info",
    showDashoardLink: true,
  },
  {
    text: "Ihr könnt mit dem Empfang von Zahlungen beginnen. Bevor Mollie an eure Bank auszahlen kann, benötigt Mollie weitere Informationen.",
    status: "info",
    showDashoardLink: true,
  },
  {
    text: "Mollie hat alle erforderlichen Informationen und überprüft eure Angaben.",
    status: "info",
    showDashoardLink: false,
  },
  {
    text: "Ihr können mit dem Empfang von Zahlungen beginnen. Mollie überprüft eure Daten, um Zahlungen an eure Bank zu ermöglichen.",
    status: "info",
    showDashoardLink: false,
  },
  {
    text: "Mollie wurde erfolgreich verbunden.",
    status: "success",
    showDashboardLink: false,
  },
];

export const mollieProfileObj: any = {
  unverified: {
    text: "Das Profil wurde noch nicht verifiziert und kann nur zum Erstellen von Testzahlungen verwendet werden.",
    status: "info",
    showDashoardLink: true,
  },
  verified: {
    text: "Das Profil wurde verifiziert und kann zum Erstellen von Live-Zahlungen verwendet werden.",
    status: "success",
    showDashoardLink: true,
  },
  blocked: {
    text: "Das Profil wird gesperrt und kann somit nicht mehr verwendet oder geändert werden.",
    status: "error",
    showDashoardLink: true,
  },
  "not-connected": {
    text: "Es ist noch kein Profil mit diesem Bereich verknüpft.",
    status: "info",
    showDashoardLink: false,
  },
};

export const statusMsg = {
  permitted: { textDe: "Zugelassen", color: "#4cd964" },
  requested: { textDe: "Angefragt", color: "#ffb100" },
};
