import React from "react";
import InfoContainer from "./InfoContainer";
import "./TestMode.css";

export default class TestMode extends React.Component<{ active: boolean }, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <InfoContainer active={this.props.active}>
          Testmodus aktiv. Es werden keine echten Zahlungen erstellt.
        </InfoContainer>

        {/* {!this.props.active && (
          <div className="mollie-testmode-container">
            <p className="mollie-testmode-title">Testmodus aktiv</p>
            <p>Es werden keine echten Zahlungen erstellt.</p>
          </div>
        )} */}
      </>
    );
  }
}
