import React from "react";
import "./Tag.css";

export default class Title extends React.Component<
  { color: string; selected: boolean; onClick: any; item: string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.color ? this.props.color : "#335ec8",
          opacity: this.props.selected ? 1 : 0.5,
        }}
        className={
          this.props.selected
            ? "tag-container unselectable pointer"
            : "tag-container unselectable tag-container-unselected pointer"
        }
        onClick={this.props.onClick}
      >
        <div>{this.props.item}</div>
      </div>
    );
  }
}
