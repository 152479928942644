import React from "react";
import { molliePaymentStatus } from "../services/constants";
import "./PaymentStatus.css";

export default class PaymentStatus extends React.Component<
  { type: string; paid?: boolean },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {molliePaymentStatus[this.props.type] !== undefined ? (
          <div
            className="payment-status"
            style={{
              backgroundColor:
                this.props.type !== "manuelly"
                  ? molliePaymentStatus[this.props.type]?.color
                  : this.props.paid
                  ? molliePaymentStatus["manuellyPaid"]?.color
                  : molliePaymentStatus["manuelly"]?.color,
            }}
          >
            {molliePaymentStatus[this.props.type]?.name}
          </div>
        ) : null}
      </>
    );
  }
}
