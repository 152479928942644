import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./BookParticipant.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import TestMode from "../../components/TestMode";
import SubTitle from "../../components/SubTitle";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import BookParticipantsType from "../../components/BookParticipantsType";
import { objectToArray, reformatDate } from "../../functions/utils";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    subscriptionId: string;
  }>;

export default class BookSubscriptionParticipant extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    branchLoaded: boolean;
    users: any;
    userArr: Array<any>;
    filteredUsers: Array<any>;
    usersLoaded: boolean;
    branch: any;
    email: string;
    fName: string;
    lName: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    bookLoading: boolean;
    errMsg: null | string;
    showType: number;
    redirect: string | null;
    showConfirmModal: boolean;
    userSearchVal: string;
    selectedUserId: null | string;
    price: number;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      subscriptionId: this.props.match.params.subscriptionId,
      branchLoaded: false,
      branch: {},
      users: {},
      userArr: [],
      filteredUsers: [],
      usersLoaded: false,
      email: "",
      fName: "",
      lName: "",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      bookLoading: false,
      errMsg: null,
      showType: 1,
      redirect: null,
      showConfirmModal: false,
      userSearchVal: "",
      selectedUserId: null,
      price: 0,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestUsersOfBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  requestUsersOfBranch = () => {
    fetchRetry(
      "getUsersFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleUsers)
      .catch(this.handleError);
  };

  handleUsers = ({ data }: any) => {
    this.setState({ usersLoaded: true });
    if (data.success) {
      const userArr = objectToArray(data.data);
      this.setState({
        users: data.data,
        userArr,
        filteredUsers: userArr,
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handlePriceChange = (val: number) => {
    this.setState({ price: Math.floor(val * 100) / 100 });
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val });
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val });
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val });
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val });
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val });
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val });
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val });
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val });
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value });
  };

  selectShowType = (num: number) => {
    this.setState({ showType: num });
  };

  handleBookParticipant = () => {
    this.setState({ bookLoading: true, showConfirmModal: false });
    if (this.state.showType === 1) {
      fetchRetry(
        "subscriptionBookManually",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
          subscriptionId: this.state.subscriptionId,
          targetUserId: null,
          fName: this.state.fName,
          lName: this.state.lName,
          email: this.state.email ? this.state.email : null,
          address: {
            city: this.state.city ? this.state.city : null,
            zipcode: this.state.zipcode ? this.state.zipcode : null,
            street: this.state.street ? this.state.street : null,
            houseNumber: this.state.houseNumber ? this.state.houseNumber : null,
          },
          phoneNumber: this.state.phoneNumber ? this.state.phoneNumber : null,
          birthDate: reformatDate(this.state.birthDate),
          sendMailToTarget: false,
          price: this.state.price ? this.state.price * 100 : null,
        },
        1,
        5
      )
        .then(this.handleBookParticipantSuccess)
        .catch(this.handleError);
    } else {
      fetchRetry(
        "subscriptionBookManually",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
          subscriptionId: this.state.subscriptionId,
          targetUserId: this.state.selectedUserId,
          sendMailToTarget: false,
        },
        1,
        5
      )
        .then(this.handleBookParticipantSuccess)
        .catch(this.handleError);
    }
  };

  handleBookParticipantSuccess = ({ data }: any) => {
    this.setState({ bookLoading: false });
    if (data.success) {
      this.setState({
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/subscription-participants/${this.state.subscriptionId}`,
        errMsg: null,
      });
      this.context.createInfo(
        "Der Nutzer wurde erfolgreich gebucht.",
        "success",
        4
      );
    } else {
      this.setState({ errMsg: data.errorMsgDe });
    }
  };

  closeConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  handleChangeClubSearchVal = (val: any) => {
    this.setState({ userSearchVal: val }, this.filterUsers);
  };

  filterUsers = () => {
    let filterText = this.state.userSearchVal;
    filterText = filterText.toLocaleLowerCase();
    const filteredUsers = this.state.userArr.filter((user: any) => {
      let fName = user.fName ? user.fName : "";
      fName = fName.toLowerCase();
      let lName = user.lName ? user.lName : "";
      lName = lName.toLowerCase();
      let email = user.email ? user.email : "";
      email = email.toLowerCase();
      let role = user.role ? user.role : "";
      role = role.toLowerCase();
      if (
        `${fName} ${lName}`.includes(filterText) ||
        `${lName} ${fName}`.includes(filterText) ||
        email.includes(filterText) ||
        role.includes(filterText)
      ) {
        return true;
      }
      return false;
    });
    this.setState({ filteredUsers });
  };

  selectUser = (userId: string) => {
    this.setState({ selectedUserId: userId });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded || !this.state.usersLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    const selectedUser = this.state.selectedUserId
      ? this.state.users[this.state.selectedUserId]
      : {};
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Teilnehmerliste"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/subscription-participants/${this.state.subscriptionId}`}
            />

            <div className="course-title-print-container">
              <h1>Teilnehmer nachbuchen</h1>
            </div>
            <TestMode active={this.state.branch.billing?.mode === "test"} />
            <BookParticipantsType
              type={this.state.showType}
              onChange={this.selectShowType}
            />
            {this.state.showType === 1 ? (
              <>
                <div className="medium-space"></div>
                <SubTitle title="Name" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.fName}
                    onChange={this.handleFNameChange}
                    placeholder="Vorname"
                  />
                  <Input
                    value={this.state.lName}
                    onChange={this.handleLNameChange}
                    placeholder="Nachname"
                  />
                </div>
                <SubTitle title="E-Mail (optional)" />
                <Input
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  placeholder="E-Mail"
                />
                <SubTitle title="Adresse (optional)" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.street}
                    onChange={this.handleStreetChange}
                    placeholder="Straße"
                    className="street-input"
                  />
                  <Input
                    value={this.state.houseNumber}
                    onChange={this.handleHouseNumberChange}
                    placeholder="Nr."
                    className="house-number-input"
                  />
                </div>
                <div className="profile-location-container">
                  <Input
                    value={this.state.zipcode}
                    onChange={this.handleZipcodeChange}
                    placeholder="PLZ"
                    className="zipcode-input"
                  />
                  <Input
                    value={this.state.city}
                    onChange={this.handleCityChange}
                    placeholder="Stadt"
                    className="city-input"
                  />
                </div>
                <SubTitle title="Telefonnummer (optional)" />
                <Input
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                  placeholder="Telefonnummer"
                />
                <SubTitle title="Geburtsdatum (optional)" />
                <input
                  type="date"
                  className="input-container"
                  value={this.state.birthDate}
                  onChange={this.handleBirthDateChange}
                />{" "}
                <SubTitle title="Preis (in €)" />
                <Input
                  value={this.state.price}
                  onChange={this.handlePriceChange}
                  type="number"
                  placeholder="Preis"
                />
              </>
            ) : (
              <>
                <Input
                  name="max-booking-length"
                  value={this.state.userSearchVal}
                  placeholder="Nutzersuche"
                  onChange={this.handleChangeClubSearchVal}
                />

                <table className="user-table margin-bottom">
                  <thead>
                    <tr>
                      <th>Vorname</th>
                      <th>Nachname</th>
                      <th className="user-table-email">E-Mail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.filteredUsers.map((userData, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          this.selectUser(userData.id);
                        }}
                      >
                        <td>{userData.fName}</td>
                        <td>{userData.lName}</td>
                        <td className="user-table-email">{userData.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {this.state.selectedUserId && (
                  <>
                    <h2>Ausgewähltes Mitglied</h2>
                    <div className="selected-member-container">
                      <img
                        src="/images/icons/user.png"
                        className="navbar-profile-pic"
                        alt="Benutzer Profilbild"
                      />
                      <div>{`${selectedUser.fName} ${selectedUser.lName} - ${selectedUser.email}`}</div>
                    </div>
                  </>
                )}
              </>
            )}

            <ErrorMessage message={this.state.errMsg} />
            <div className="modal-btn-container">
              <SecButton
                title={"Teilnehmer buchen"}
                onClick={() => {
                  this.handleBookParticipant();
                }}
                color="green"
                change={
                  this.state.showType === 1
                    ? this.state.fName !== "" && this.state.lName !== ""
                    : !!this.state.selectedUserId
                }
                loading={this.state.bookLoading}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
