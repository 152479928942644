import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./BranchModal.css";
import "./TextInputModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Input";
import SecButton from "../../components/SecButton";
import ErrorMessage from "../ErrorMessage";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default class TextInputModal extends React.Component<
  {
    show: boolean;
    handleClose: any;
    handleConfirm: any;
    loading: boolean;
    title: string;
    description?: string;
    textBoxPlaceholder: string;
    btnText: string;
    errorMsg?: null | string;
  },
  { branchName: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      branchName: "",
    };
  }

  handleBranchNameChange = (val: any) => {
    this.setState({ branchName: val });
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="create Branch"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="user-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">{this.props.title}</span>
            </div>
            {this.props.description && (
              <div className="small-space">{this.props.description}</div>
            )}
            <Input
              name="text-input"
              placeholder={this.props.textBoxPlaceholder}
              value={this.state.branchName}
              onChange={this.handleBranchNameChange}
            />
            <ErrorMessage message={this.props.errorMsg} />
            <SecButton
              change={this.state.branchName.length !== 0}
              color="green"
              loading={this.props.loading}
              onClick={() => {
                this.props.handleConfirm(this.state.branchName);
              }}
              title={this.props.btnText}
            />
          </div>
        </Modal>
      </>
    );
  }
}
