import React from "react";
import { MainContext } from "../contexts/MainContext";
import "./CourseTerms.css";

export default class BranchCourseTerms extends React.Component<
  {
    showAcceptTermsMessage: boolean;
    agb: string;
    termsAccepted: boolean;
    toggleTermsAccepted: any;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.agb && (
          <div
            className={`accept-container ${
              this.props.showAcceptTermsMessage && !this.props.termsAccepted
                ? "accept-container-glowing"
                : ""
            }`}
          >
            <div>
              <input
                type="checkbox"
                className="accept-container-checkbox"
                checked={this.props.termsAccepted}
                onClick={this.props.toggleTermsAccepted}
              />
            </div>
            <div>
              Ich akzeptiere die{" "}
              <a href={this.props.agb} target="_blank" rel="noreferrer">
                Nutzungsbedingungen und Hallenregeln
              </a>
              .
            </div>
          </div>
        )}
      </>
    );
  }
}
