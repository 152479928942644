import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./AccountTransfer.css";
import { MainContext } from "../contexts/MainContext";
import Title from "../components/Title";
import { fetchRetry } from "../functions/request";
import { TransferData } from "../Types";
import HashLoader from "react-spinners/HashLoader";
import InfoContainer from "../components/InfoContainer";
import MainBtn from "../components/MainBtn";
import ConfirmationModal from "../components/modals/ConfirmationModal";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    transferId: string;
    clientKey: string;
  }>;

export default class AccountTransfer extends React.Component<
  ComposedProps,
  {
    clubId: string;
    transferId: string;
    clientKey: string;
    transferLoaded: boolean;
    transferData: TransferData | null;
    showConfirmationModal: boolean;
    userIdNoMatch: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      transferId: this.props.match.params.transferId,
      clientKey: this.props.match.params.clientKey,
      transferLoaded: false,
      transferData: null,
      showConfirmationModal: false,
      userIdNoMatch: false,
    };
  }

  componentDidMount = () => {
    this.requestTransfer();
  };

  requestTransfer = () => {
    fetchRetry(
      "getAccountTransfer",
      {
        transferId: this.state.transferId,
        clientKey: this.state.clientKey,
      },
      1,
      5
    )
      .then(this.handleTransferData)
      .catch(this.handleError);
  };

  handleTransferData = ({ data }: any) => {
    this.setState({ transferLoaded: true });
    if (data.success) {
      this.setState({ transferData: data.data });
    } else {
      if (data.code === "userId-no-match") {
        this.setState({ userIdNoMatch: true });
      }
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleConfirmed = () => {
    this.hideConfirmationModal();
    fetchRetry(
      "acceptAccountTransfer",
      {
        transferId: this.state.transferId,
        clientKey: this.state.clientKey,
      },
      1,
      5
    )
      .then(this.handleAcceptData)
      .catch(this.handleError);
  };

  handleAcceptData = ({ data }: any) => {
    if (data.success) {
      this.props.history.push("/");
      this.context.createInfo("Account erfolgreich übertragen", "success", 4);
      this.context.logout();
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  onChangeAccount = () => {
    this.context.logout();
    const message =
      "Bitte melde dich mit der E-Mail-Adresse an, auf der du den Link erhalten hast.";
    this.props.history.push(
      `/login?redirect=${window.location.href}&customMessage=${message}`
    );
  };

  btnClick = () => {
    this.setState({ showConfirmationModal: true });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg="Willst du deinen Account wirklich? Dieser Vorgang kann nicht rückgängig gemacht werden."
          handleConfirm={this.handleConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Übernahme Anfrage (Familien Account)" />
            {!this.state.transferLoaded ? (
              <>
                <div className="loading-container">
                  <HashLoader color={"#c31924"} size={100} loading={true} />
                </div>
              </>
            ) : (
              <>
                {this.state.userIdNoMatch || !this.context.user ? (
                  <>
                    <InfoContainer status="info">
                      Bitte melde dich mit der E-Mail-Adresse an, auf der du den
                      Link erhalten hast und öffne erneut den Link.
                    </InfoContainer>
                    <MainBtn onClick={this.onChangeAccount}>
                      Mit anderem Account anmelden
                    </MainBtn>
                  </>
                ) : (
                  <>
                    <InfoContainer status="info">
                      Sollte diese Anfrage nicht von dir kommen, dann gebe diese
                      Übernahme NICHT frei. Nach der Freigabe, kann der Nutzer
                      Einstellungen und Kursbuchungen im Namen dieses Accountes
                      vornehmen.
                    </InfoContainer>
                    <p>
                      <b>
                        {this.state.transferData?.mainUser.fName}{" "}
                        {this.state.transferData?.mainUser.lName} (
                        {this.state.transferData?.mainUser.email})
                      </b>{" "}
                      möchte deinen Account übernehmen. Diese Funktion ist für
                      Familien gedacht, die ihre Familie über einen Account
                      verwalten möchte.
                    </p>
                    <div className="small-space"></div>
                    <p>
                      Nach der Freigabe wird es nicht mehr möglich sein sich mit
                      der E-Mail-Adresse <b>{this.context.userData?.email}</b>{" "}
                      anzumelden. Stattdessen kann der Account mit der
                      E-Mail-Adresse{" "}
                      <b>{this.state.transferData?.mainUser.email}</b> Buchungen
                      im Namen dieses Accountes vornehmen und Einstellungen
                      verwalten.
                    </p>
                    <div className="medium-space"></div>
                    <MainBtn onClick={this.btnClick}>ACCOUNT FREIGEBEN</MainBtn>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
