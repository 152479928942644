import React from "react";
import "./FormEditElement.css";

export default class MultipleChoise extends React.Component<
  {
    id: string;
    options: string[];
    onChange: (e: any) => void;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="multi-select-container"
        onChange={(val: any) => {
          this.props.onChange(val.target.value);
        }}
        id={this.props.id}
      >
        {this.props.options.map((option, index) => (
          <div className="multi-select-inner-container">
            <input type="radio" name={this.props.id} value={option} />
            <label>{option}</label>
          </div>
        ))}
      </div>
    );
  }
}
