import React from "react";
import Switch from "../../Switch";
import "./FormEditElement.css";

export default class EditMultipleChoise extends React.Component<
  {
    id: string;
    onChange: (e: any) => void;
    value: boolean;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return <Switch onChange={this.props.onChange} value={this.props.value} />;
  }
}
