import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./SingleMembershipApplication.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import MemAppStatus from "../../components/MemAppStatus";
import BackElement from "../../components/BackElement";
import SecButton from "../../components/SecButton";
import ErrorMessage from "../../components/ErrorMessage";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    applicationId: string;
  }>;

export default class SingleMembershipApplication extends React.Component<
  ComposedProps,
  {
    clubId: string;
    applicationId: string;
    club: any;
    clubLoaded: boolean;
    memAppLoaded: boolean;
    memApp: any;
    tableData: Array<any>;
    updateLoading: boolean;
    markAsProcessedError: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      applicationId: this.props.match.params.applicationId,
      club: {},
      clubLoaded: false,
      memAppLoaded: false,
      memApp: {},
      tableData: [],
      updateLoading: false,
      markAsProcessedError: null,
    };
  }

  componentDidMount = () => {
    this.requestClub();
    this.requestMemApp();
  };

  requestClub = () => {
    fetchRetry(
      "getClubFromId",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      this.setState({
        club,
      });
    }
  };

  requestMemApp = () => {
    fetchRetry(
      "getMemAppFromId",
      { clubId: this.state.clubId, applicationId: this.state.applicationId },
      1,
      5
    )
      .then(this.handleMemApp)
      .catch(this.handleError);
  };

  handleMemApp = ({ data }: any) => {
    this.setState({ memAppLoaded: true });
    if (data.success) {
      const tableArr: Array<any> = [];
      data.data.data.forEach((currInfo: any) => {
        if (currInfo.type === "name") {
          tableArr.push({
            name: "Vorname",
            val: currInfo.val.fName,
          });
          tableArr.push({
            name: "Nachname",
            val: currInfo.val.lName,
          });
        }
        if (currInfo.type === "address") {
          tableArr.push({
            name: "Straße",
            val: currInfo.val.street,
          });
          tableArr.push({
            name: "Hausnummer",
            val: currInfo.val.houseNumber,
          });
          tableArr.push({
            name: "Postleitzahl",
            val: currInfo.val.zipcode,
          });
          tableArr.push({
            name: "Stadt",
            val: currInfo.val.city,
          });
        }
        if (currInfo.type === "clubnews") {
          tableArr.push({
            name: currInfo.name,
            val: currInfo.val.length > 0 ? currInfo.val.join(", ") : "-",
          });
        }
        if (currInfo.type === "sepa") {
          tableArr.push({
            name: "IBAN",
            val: currInfo.val.iban,
          });
          tableArr.push({
            name: "Kreditinstitut",
            val: currInfo.val.creditInstitution,
          });
          tableArr.push({
            name: "BIC",
            val: currInfo.val.bic,
          });
          tableArr.push({
            name: "Kontoinhaber",
            val: currInfo.val.accountOwner,
          });
        }
        if (currInfo.type === "branch") {
          tableArr.push({
            name: currInfo.name,
            val: currInfo.val.length > 0 ? currInfo.val.join(", ") : "-",
          });
        }
        if (currInfo.type === "text") {
          tableArr.push({
            name: currInfo.name,
            val: currInfo.val,
          });
        }
        if (
          ["email", "gender", "phonenumber", "date"].includes(currInfo.type)
        ) {
          tableArr.push({
            name: currInfo.name,
            val: currInfo.val,
          });
        }
      });
      this.setState({
        memApp: data.data,
        tableData: tableArr,
      });
    }
  };

  handleMarkAsProcessed = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "markMemAppAsProcessed",
      { clubId: this.state.clubId, applicationId: this.state.applicationId },
      1,
      5
    )
      .then(this.handleMarkAsProcessedSuccess)
      .catch(this.handleError);
  };

  handleMarkAsProcessedSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestMemApp();
    } else {
      this.setState({ markAsProcessedError: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (!this.state.clubLoaded || !this.state.memAppLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              <BackElement
                text="zurück zu den Mitgliedsanfragen"
                to={`/club-settings/${this.state.clubId}/membership-applications`}
              />
              <div className="branches-title-container">
                <h1>Mitgliedsanfrage</h1>
              </div>
              <table className="booking-information-table">
                <tbody>
                  <tr className="no-border">
                    <td>Status</td>
                    <td>
                      <MemAppStatus type={this.state.memApp.status} />
                    </td>
                  </tr>
                  {this.state.tableData.map((currInfo: any) => (
                    <tr>
                      <td>{currInfo.name}</td>
                      <td>{currInfo.val}</td>
                    </tr>
                  ))}
                  {this.state.memApp.userId && (
                    <tr>
                      <td colSpan={2}>
                        <Link
                          to={`/club-settings/${this.state.clubId}/users/${this.state.memApp.userId}`}
                          className="black"
                        >
                          Konto bearbeiten
                        </Link>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.state.memApp.status === "open" && (
                <>
                  <ErrorMessage message={this.state.markAsProcessedError} />
                  <SecButton
                    title="Als bearbeitet markieren"
                    onClick={this.handleMarkAsProcessed}
                    colorHex="#3493ff"
                    change={true}
                    loading={this.state.updateLoading}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
