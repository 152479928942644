import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Course.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  priceToStr,
} from "../../functions/utils";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import Title from "../../components/Title";
import SingleCourseElementClub from "../../components/SingleCourseElementClub";
import BlockCourseElementClub from "../../components/BlockCourseElementClub";
import InfinityCourseElementClub from "../../components/InfinityCourseElementClub";
import SearchInput from "../../components/SearchInput";
import InfiniteScroll from "react-infinite-scroll-component";
import CourseElementSkeleton from "../../components/CourseElementSkeleton";
import debounce from "lodash.debounce";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Course extends React.Component<
  ComposedProps,
  {
    clubId: string;
    coursesLoaded: boolean;
    courses: Array<any>;
    showJoinModal: boolean;
    joinModalLoading: boolean;
    joinModalMsg: null | string;
    joinModalType: "error" | "success";
    showRequestedModal: boolean;
    requestedModalLoading: boolean;
    requestedModalMsg: null | string;
    requestedModalType: "error" | "success";
    courseSearch: string;
    batchSize: number;
    page: number;
    hasMore: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      coursesLoaded: false,
      courses: [],
      showJoinModal: false,
      joinModalLoading: false,
      joinModalMsg: null,
      joinModalType: "error",
      showRequestedModal: false,
      requestedModalLoading: false,
      requestedModalMsg: null,
      requestedModalType: "error",
      courseSearch: "",
      batchSize: 10,
      page: 0,
      hasMore: true,
    };
  }

  componentDidMount = () => {
    this.requestCourses();
  };

  requestCourses = () => {
    fetchRetry(
      "getCoursesOfClubAlgoliaF",
      {
        clubId: this.state.clubId,
        query: this.state.courseSearch,
        page: this.state.page,
        hitsPerPage: this.state.batchSize,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCoursesSuccess = ({ data }: any) => {
    this.setState({ coursesLoaded: true });
    if (data.success) {
      const courses = this.state.courses.concat(data.data);
      this.setState({
        courses,
        hasMore: data.data.length >= this.state.batchSize,
      });
      this.setState({ courses });
    }
    const highlighedCourseId = window.location.href.slice(
      window.location.href.indexOf("#") + 1
    );
    var element = document.getElementById(highlighedCourseId);
    element?.scrollIntoView();
  };

  handleChangeCourseSearch = (val: string) => {
    this.setState({ courseSearch: val }, this.debounceRequest);
  };

  debounceRequest = debounce(() => {
    this.newRequest();
  }, 500);

  newRequest = () => {
    this.setState(
      { courses: [], page: 0, coursesLoaded: false, hasMore: true },
      this.requestCourses
    );
  };

  getNextPage = () => {
    this.setState(
      { page: this.state.page + 1, coursesLoaded: false },
      this.requestCourses
    );
  };

  render() {
    if (!true) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Kurse" />
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            {this.state.courses.length === 0 && this.state.coursesLoaded && (
              <>
                {this.state.courseSearch !== "" ? (
                  <div className="no-courses-container">
                    Mit diesem Filter gibt es aktuell keine Kurse.
                  </div>
                ) : (
                  <div className="no-courses-container">
                    Hier gibts es aktuell keine Kurse.
                  </div>
                )}
              </>
            )}

            <InfiniteScroll
              dataLength={this.state.courses.length}
              next={this.getNextPage}
              hasMore={this.state.hasMore}
              loader={<></>}
              scrollableTarget="scroll-container"
            >
              {this.state.courses.map((course: any, index: number) => (
                <>
                  {course.courseType === "single-course" && (
                    <SingleCourseElementClub
                      clubId={this.state.clubId}
                      branchId={course.branchId}
                      courseId={course.id}
                      title={course.title}
                      description={course.description}
                      startTime={course.startTime}
                      endTime={course.endTime}
                      where={course.location.name}
                      freePlaces={
                        course.testmode
                          ? course.freePlacesTest
                          : course.freePlaces
                      }
                      price={priceToStr(course.price)}
                      imgUrl={course.imgLowRes}
                      key={course.id}
                      bookableFrom={firebaseDateToDateObj(course.bookableDate)}
                      userAllowedToBook={course.userAllowedToBook}
                      minAge={course.minAge}
                      maxAge={course.maxAge}
                    />
                  )}
                  {course.courseType === "block-course" && (
                    <BlockCourseElementClub
                      clubId={this.state.clubId}
                      branchId={course.branchId}
                      courseId={course.id}
                      title={course.title}
                      description={course.description}
                      when={dateToFullStr(
                        firebaseDateToDateObj(course.startTime)
                      )}
                      courses={course.courses}
                      freePlaces={
                        course.testmode
                          ? course.freePlacesTest
                          : course.freePlaces
                      }
                      price={priceToStr(course.price)}
                      imgUrl={course.imgLowRes}
                      key={course.id}
                      bookableFrom={firebaseDateToDateObj(course.bookableDate)}
                      userAllowedToBook={course.userAllowedToBook}
                      minAge={course.minAge}
                      maxAge={course.maxAge}
                    />
                  )}
                  {course.courseType === "infinity-course" && (
                    <InfinityCourseElementClub
                      clubId={this.state.clubId}
                      branchId={course.branchId}
                      courseId={course.id}
                      title={course.title}
                      description={course.description}
                      where={course.location?.name}
                      time={course.time}
                      duration={course.duration}
                      weekDay={course.weekDay}
                      freePlaces={
                        course.testmode
                          ? course.freePlacesTest
                          : course.freePlaces
                      }
                      price={priceToStr(course.price)}
                      imgUrl={course.imgLowRes}
                      key={course.id}
                      bookableFrom={firebaseDateToDateObj(course.bookableDate)}
                      userAllowedToBook={course.userAllowedToBook}
                      minAge={course.minAge}
                      maxAge={course.maxAge}
                    />
                  )}
                </>
              ))}
            </InfiniteScroll>
            {!this.state.coursesLoaded && (
              <>
                <CourseElementSkeleton />
                <CourseElementSkeleton />
                <CourseElementSkeleton />
                <CourseElementSkeleton />
              </>
            )}
            {this.state.coursesLoaded && this.state.hasMore && (
              <div className="load-more-container" onClick={this.getNextPage}>
                Mehr laden
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
