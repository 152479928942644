import React from "react";
import "./Input.css";

export default class Input extends React.Component<
  {
    value: string | number;
    onChange: any;
    name?: string;
    placeholder?: string;
    type?: "text" | "number";
    min?: number;
    max?: number;
    className?: string;
    step?: number;
    disabled?: boolean;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  handleChange = (val: any) => {
    if (this.props.type === "number") {
      this.props.onChange(parseFloat(val.target.value));
    } else {
      this.props.onChange(val.target.value);
    }
  };

  render() {
    return (
      <>
        <input
          className={`input-container ${this.props.className}`}
          name={this.props.name ? this.props.name : "input"}
          type={this.props.type ? this.props.type : "text"}
          value={this.props.value}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          onChange={this.handleChange}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}
