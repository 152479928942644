import React from "react";
import "./Selector.css";

export default class Selector extends React.Component<
  {
    options: Array<any>;
    placeholder: string;
    value: string | number;
    onSelect: any;
    default: null | string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  onSelect = (e: any) => {
    this.props.onSelect(
      this.props.options[
        e.target.options.selectedIndex - (this.props.default ? 1 : 0)
      ].id
    );
  };

  render() {
    return (
      <select
        placeholder={this.props.placeholder}
        className="input-container"
        value={this.props.value}
        onChange={this.onSelect}
      >
        {this.props.default && (
          <option value="" disabled selected hidden>
            {this.props.default}
          </option>
        )}
        {this.props.options.map((option: any) => (
          <option
            // selected={option.id === this.props.value}
            value={option.id}
            key={option.id}
          >
            {option.name}
          </option>
        ))}
      </select>
    );
  }
}
