import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Cookies from "universal-cookie";
import "./Billing.css";
import "../../App.css";
import { HashLoader, ClipLoader } from "react-spinners";
import { MainContext } from "../../contexts/MainContext";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import Selector from "../../components/Selector";
import SecButton from "../../components/SecButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoContainer from "../../components/InfoContainer";
import {
  faChevronDown,
  faChevronUp,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
import { getCookieDomain } from "../../functions/utils";
import MollieLogo from "../../components/MollieLogo";
import SubTitle from "../../components/SubTitle";
import ErrorMessage from "../../components/ErrorMessage";
import Input from "../../components/Input";
import { mollieProfileObj, mollieStatusArr } from "../../services/constants";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Billing extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    requestNeeded: boolean;
    branch: any;
    branchLoaded: boolean;
    change: boolean;
    updateLoading: boolean;
    mollieConnectLoading: boolean;
    mollieProfileLoading: boolean;
    mollieIdentifier: string;
    mollieData: any;
    mollieLoaded: boolean;
    profileArr: Array<any>;
    selectedProfileId: string;
    profilesLoaded: boolean;
    updateProfileLoading: boolean;
    lockLoading: boolean;
    showMoreProfile: boolean;
    email: string;
    phoneNumber: string;
    connectProfileErr: string | null;
    selectedProfile: any;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      requestNeeded: false,
      branch: {},
      branchLoaded: false,
      change: false,
      updateLoading: false,
      mollieConnectLoading: false,
      mollieProfileLoading: false,
      mollieIdentifier: "",
      mollieData: {
        canReceivePayments: false,
        canReceiveSettlements: false,
        name: "",
        status: "",
      },
      mollieLoaded: false,
      profileArr: [],
      selectedProfileId: "",
      profilesLoaded: false,
      updateProfileLoading: false,
      lockLoading: false,
      showMoreProfile: false,
      email: "",
      phoneNumber: "",
      connectProfileErr: null,
      selectedProfile: {},
    };
  }

  componentDidMount = async () => {
    this.setRedirectCookie();
    this.requestData();
  };

  requestData = () => {
    Promise.all([
      this.requestBranch(),
      this.requestMollieStatus(),
      this.requestProfiles(),
    ]).then(() => {
      this.getSelectedProfile(this.state.branch?.billing?.profileId);
    });
  };

  getSelectedProfile = (profileId: string) => {
    const selectedProfile = this.state.profileArr.find(
      (currProfile: any) => currProfile.id === profileId
    );
    this.setState({ selectedProfile });
  };

  setRedirectCookie = () => {
    const cookies = new Cookies();
    cookies.set("mollie_redirect_url", window.location.href, {
      path: "/",
      maxAge: 86400,
      domain: getCookieDomain(),
    });
  };

  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
          requestNeeded: branch.settings.requestNeeded,
        },
        this.checkChange
      );
    }
  };

  requestProfiles = async () => {
    this.setState({ profilesLoaded: false });
    await fetchRetry(
      "getMollieProfiles",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleProfiles)
      .catch(this.handleError);
  };

  handleProfiles = ({ data }: any) => {
    this.setState({ profilesLoaded: true });
    if (data.success) {
      const profileArr = data.data;
      this.setState(
        {
          profileArr,
        },
        this.checkChange
      );
    }
  };

  updateProfile = () => {
    this.setState({ updateProfileLoading: true });
    fetchRetry(
      "updateMollieProfile",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        profileId: this.state.selectedProfileId,
      },
      1,
      5
    )
      .then(this.handleProfileUpdatedSuccess)
      .catch(this.handleError);
  };

  handleProfileUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateProfileLoading: false });
    if (data.success) {
      this.requestData();
    }
  };

  handleError = (err: any) => {
    console.error(err);
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
  };

  checkChange = () => {
    this.setState({
      change: false,
    });
  };

  updateBranch = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBranch",
      { clubId: this.state.clubId, branchId: this.state.branchId },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    this.requestBranch();
  };

  handleMollieConnect = () => {
    this.setState({ mollieConnectLoading: true });
    fetchRetry(
      "connectMollie",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        redirectUrl: window.location.href,
      },
      1,
      5
    )
      .then(this.handleSuccessMollieConnect)
      .catch(this.handleError);
  };

  requestMollieStatus = async () => {
    await fetchRetry(
      "getMollieStatus",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleMollieStatus)
      .catch(this.handleMollieStatusError);
  };

  handleMollieStatusError = (err: any) => {
    this.handleError(err);
    this.setState({ mollieLoaded: true });
  };

  handleMollieStatus = ({ data }: any) => {
    this.setState({ mollieLoaded: true });
    if (data.success) {
      this.setState({
        mollieData: data.data,
        selectedProfileId: data.data.profileId ? data.data.profileId : "",
      });
    }
  };

  handleSuccessMollieConnect = ({ data }: any) => {
    this.setState({ mollieConnectLoading: false });
    if (data.success) {
      const identifier = data.identifier;
      this.setState({
        mollieIdentifier: identifier,
      });
      window.location.href = `https://www.mollie.com/oauth2/authorize?client_id=app_Wh5q287Hnut8G2bb9nmCbShd&state=${identifier}&scope=payments.read payments.write refunds.read refunds.write customers.read customers.write onboarding.write onboarding.read subscriptions.read subscriptions.write mandates.read mandates.write profiles.read profiles.write organizations.write&response_type=code&approval_prompt=auto`;
    }
  };

  handleProfilSelected = (profileId: string) => {
    this.setState({ selectedProfileId: profileId });
  };

  unlockBilling = () => {
    this.setState({ lockLoading: true });
    fetchRetry(
      "setBillingLock",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        locked: false,
      },
      1,
      5
    )
      .then(this.handleLockSuccess)
      .catch(this.handleError);
  };

  lockBilling = () => {
    this.setState({ lockLoading: true });
    fetchRetry(
      "setBillingLock",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        locked: true,
      },
      1,
      5
    )
      .then(this.handleLockSuccess)
      .catch(this.handleError);
  };

  handleLockSuccess = ({ data }: any) => {
    this.setState({ lockLoading: false });
    if (data.success) {
      this.requestMollieStatus();
    }
  };

  handleMollieConnectProfile = () => {
    this.setState({ mollieProfileLoading: true });
    fetchRetry(
      "connectMollieProfile",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
      },
      1,
      5
    )
      .then(this.handleSuccessConnectProfile)
      .catch(this.handleErrorConnectProfile);
  };

  handleErrorConnectProfile = (err: any) => {
    this.handleError(err);
    this.setState({ mollieProfileLoading: false });
  };

  handleSuccessConnectProfile = ({ data }: any) => {
    this.setState({ mollieProfileLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Das Mollie Profil wurde erfolgreich verbunden.",
        "success",
        4
      );
      this.setState({ email: "", phoneNumber: "", connectProfileErr: null });
      this.requestData();
    } else {
      this.setState({
        connectProfileErr: data.errorMsgDe,
      });
    }
  };

  toggleShowMoreProfile = () => {
    this.setState({ showMoreProfile: !this.state.showMoreProfile });
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val });
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val });
  };

  render() {
    if (
      !this.state.branchLoaded ||
      !this.state.mollieLoaded ||
      !this.state.profilesLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    const mollieStatus =
      this.state.mollieData.status === "open"
        ? mollieStatusArr[0]
        : this.state.mollieData.status === "needs-data"
        ? !this.state.mollieData.canReceivePayments ===
          !this.state.mollieData.canReceiveSettlements
          ? mollieStatusArr[1]
          : mollieStatusArr[2]
        : this.state.mollieData.status === "in-review"
        ? !this.state.mollieData.canReceivePayments &&
          !this.state.mollieData.canReceiveSettlements
          ? mollieStatusArr[3]
          : mollieStatusArr[4]
        : mollieStatusArr[5];

    const mollieProfileStatus = mollieProfileObj[
      this.state.selectedProfile?.status
    ]
      ? mollieProfileObj[this.state.selectedProfile?.status]
      : mollieProfileObj["not-connected"];

    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <div className="title-icon-container">
              <Title title="Abrechnung" className="no-margin" />
              <div className="flex">
                {this.state.lockLoading && (
                  <div className="lock-loading-container">
                    <ClipLoader color={"#6D6D6D"} size={10} loading={true} />
                  </div>
                )}
                {this.state.mollieData.locked ? (
                  <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    onClick={this.unlockBilling}
                    className="lock-icon"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faLockOpen}
                    size="lg"
                    onClick={this.lockBilling}
                    className="lock-icon"
                  />
                )}
              </div>
            </div>
            <InfoContainer
              active={this.state.mollieData.locked}
            >{`Die Zahlungs-Einstellungen können aus Sicherheitsgründen nur von ${this.state.mollieData.lockedUser?.fName} ${this.state.mollieData.lockedUser?.lName} geändert werden. Nur  ${this.state.mollieData.lockedUser?.fName} ${this.state.mollieData.lockedUser?.lName} kann die Zahlungs-Einstellungen wieder freigeben.`}</InfoContainer>
            {/* <TestMode active={this.state.mollieData.mode !== "live"} /> */}
            <Title title="1. Schritt - Mollie verbinden" />
            <div
              onClick={this.handleMollieConnect}
              className="mollie-connect-container"
            >
              <span className="connect-text">
                <MollieLogo /> verbinden
              </span>
              {this.state.mollieConnectLoading && (
                <div className="mollie-loading-container">
                  <ClipLoader color={"white"} size={30} loading={true} />
                </div>
              )}
            </div>
            <InfoContainer status={mollieStatus.status}>
              {mollieStatus.text}
              {mollieStatus.showDashoardLink && (
                <>
                  {" "}
                  <a href={this.state.mollieData.dashboardLink}>
                    Mollie Dashboard
                  </a>
                </>
              )}
            </InfoContainer>
            <Title title="2. Schritt - Profil verknüpfen" />
            <SubTitle
              title="Email"
              helpText="Die mit dem Handelsnamen oder der Marke des Profils verknüpfte E-Mail-Adresse."
            />
            <Input
              value={this.state.email}
              onChange={this.handleEmailChange}
              placeholder="Email"
            />
            <SubTitle
              title="Telefonnummer"
              helpText="Die mit dem Handelsnamen oder der Marke des Profils verknüpfte Telefonnummer. Muss im E.164-Format vorliegen. Zum Beispiel +31208202070."
            />
            <Input
              value={this.state.phoneNumber}
              onChange={this.handlePhoneNumberChange}
              placeholder="Telefonnummer"
            />
            <div
              onClick={this.handleMollieConnectProfile}
              className="mollie-connect-container"
            >
              <span className="connect-text">
                Profil automatisch verbinden (empfohlen)
              </span>
              {this.state.mollieProfileLoading && (
                <div className="mollie-loading-container">
                  <ClipLoader color={"white"} size={30} loading={true} />
                </div>
              )}
            </div>
            <ErrorMessage message={this.state.connectProfileErr} />
            <InfoContainer status={mollieProfileStatus.status}>
              {mollieProfileStatus.text}
              {mollieProfileStatus.showDashoardLink && (
                <>
                  {" "}
                  Das Profil, die Zahlungsmethoden und das Check-out können{" "}
                  <a href={this.state.selectedProfile?.dashboardUrl}>
                    "hier"
                  </a>{" "}
                  eingestellt werden.
                </>
              )}
            </InfoContainer>
            <div className="or-container">oder</div>
            <div
              className="more-info-container"
              onClick={this.toggleShowMoreProfile}
            >
              <div className="more-info-inner-container">
                {!this.state.showMoreProfile ? (
                  <FontAwesomeIcon icon={faChevronDown} size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} size="sm" />
                )}
              </div>
              <SubTitle title="Profil manuell auswählen" />
            </div>
            {this.state.showMoreProfile && (
              <>
                {" "}
                <Selector
                  placeholder="Molli Profil"
                  options={this.state.profileArr}
                  value={this.state.selectedProfileId}
                  onSelect={this.handleProfilSelected}
                  default="Hier auswählen"
                />
                <div className="medium-space"></div>
                <SecButton
                  change={
                    this.state.selectedProfileId !==
                    this.state.mollieData.profileId
                  }
                  color="green"
                  loading={this.state.updateProfileLoading}
                  onClick={this.updateProfile}
                  title="Speichern"
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
