import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./CourseParticipants.css";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactToPrint from "react-to-print";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import CourseParticipantsType from "../../components/CourseParticipantsType";
import {
  addressToStr,
  dateTosimpleDateStr,
  dateToStr,
  dateToTimeStr,
  firebaseDateToDateObj,
  getMonthNum,
  priceToFullStr,
  strToDate,
} from "../../functions/utils";
import { CSVLink } from "react-csv";
import TestMode from "../../components/TestMode";
import SecButton from "../../components/SecButton";
import TextArea from "../../components/TextArea";
import SubTitle from "../../components/SubTitle";
import ErrorMessage from "../../components/ErrorMessage";
import { MainContext } from "../../contexts/MainContext";
import {
  faCheckCircle,
  faFileCsv,
  faPlusCircle,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import MonthSelector from "../../components/MonthSelector";
import qs from "qs";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";
import PaymentStatus from "../../components/PaymentStatus";
import { molliePaymentStatus } from "../../services/constants";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
  }>;

export default class CourseParticipants extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseMembers: Array<any>;
    courseMembersCSV: Array<any>;
    courseMembersLoaded: boolean;
    branchLoaded: boolean;
    branch: any;
    showType: number;
    courseLoaded: boolean;
    course: any;
    emailStr: string;
    emailsCopied: boolean;
    sendMailLoading: boolean;
    mailMessage: string;
    mailSendErrMsg: string | null;
    totalPriceTracked: number;
    totalPriceConfirmed: number;
    totalPriceAll: number;
    monthNum: number;
    courseStartMonth: number;
    courseMemberTableArr: Array<RowData>;
    courseBookingTableArr: Array<RowData>;
    fullyLoaded: boolean;
    printTableColumn: number;
    printTableSortDirection: "asc" | "desc";
  }
> {
  private printRef: any;
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      courseMembers: [],
      courseMembersCSV: [],
      courseMembersLoaded: false,
      branchLoaded: false,
      branch: {},
      showType: 1,
      courseLoaded: false,
      course: {},
      emailStr: "",
      emailsCopied: false,
      sendMailLoading: false,
      mailMessage: "",
      mailSendErrMsg: null,
      totalPriceTracked: 0,
      totalPriceConfirmed: 0,
      totalPriceAll: 0,
      monthNum: getMonthNum(new Date()),
      courseStartMonth: getMonthNum(new Date()) - 10,
      courseMemberTableArr: [],
      courseBookingTableArr: [],
      fullyLoaded: false,
      printTableColumn: 1,
      printTableSortDirection: "asc",
    };
  }

  componentDidMount = async () => {
    await this.getUrlProps();
    await Promise.all([this.requestUsersOfCourse(), this.requestCourse()]).then(
      this.handleFullyLoaded
    );
    this.requestBranch();
  };

  getUrlProps = async () => {
    const pageProps: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).page;
    const monthNumProps: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).monthNum;
    return this.setState({
      showType: pageProps ? parseInt(pageProps) : this.state.showType,
      monthNum: monthNumProps ? parseInt(monthNumProps) : this.state.monthNum,
    });
  };

  requestUsersOfCourse = async () => {
    this.setState({ courseMembersLoaded: false });
    await fetchRetry(
      "getUsersOfCourse",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        month: this.state.monthNum || 1,
      },
      1,
      5
    )
      .then(this.handleUsersOfCourse)
      .catch(this.handleError);
  };

  requestCourse = async () => {
    await fetchRetry(
      "getCourseFromIdFNew",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        targetUserId: this.context.selectedUserId,
        includeForm: true,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  handleCourseSuccess = ({ data }: any) => {
    this.setState({ courseLoaded: true });
    if (data.success) {
      const courseData = data.data[0];
      const courseTitle = courseData.title;
      this.context.setCustomPath({
        link: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}`,
        name: courseTitle,
      });
      const courseStartMonth = getMonthNum(
        firebaseDateToDateObj(courseData.startTime)
      );
      this.setState({
        course: courseData,
        courseStartMonth: courseStartMonth,
      });
      if (courseStartMonth > this.state.monthNum) {
        this.setState(
          { monthNum: courseStartMonth, courseMembersLoaded: false },
          this.requestUsersOfCourse
        );
      }
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  handleError = (err: any) => {
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
    console.error(err);
  };

  handleUsersOfCourse = ({ data }: any) => {
    this.setState({ courseMembersLoaded: true });
    if (data.success) {
      const courseMembers = data.data;
      courseMembers.sort((a: any, b: any) =>
        a.created_at._seconds > b.created_at._seconds ? -1 : 1
      );
      if (this.state.course.courseType === "infinity-course") {
        courseMembers.forEach((courseMember: any) => {
          const pausedMonths = courseMember.pausedMonthsFlat || [];
          const isPaused = pausedMonths.includes(this.state.monthNum);
          const isManuel = courseMember.method === "manuell";
          courseMember.active =
            courseMember.active &&
            ((courseMember.startMonth <= this.state.monthNum + 1 &&
              courseMember.endMonth >= this.state.monthNum) ||
              isManuel);
          courseMember.activeParticipant =
            courseMember.active &&
            ((courseMember.startMonth <= this.state.monthNum + 1 &&
              courseMember.endMonth >= this.state.monthNum) ||
              isManuel) &&
            !isPaused;
        });
      } else {
        courseMembers.forEach((courseMember: any) => {
          courseMember.activeParticipant = courseMember.active;
        });
      }
      const courseMemberTableArr = courseMembers
        .filter((currUser: any) => currUser.activeParticipant)
        .map((user: any) => {
          const fName = user.bookForFamily
            ? user.familyUser?.fName
            : user.user.fName;
          const lName = user.bookForFamily
            ? user.familyUser?.lName
            : user.user.lName;
          return {
            cells: [
              {
                value: fName,
                sort: fName,
              },
              {
                value: lName,
                sort: lName,
              },
              {
                value: user.user.email,
                sort: user.user.email,
              },
              {
                value: addressToStr(user.user.address),
                sort: addressToStr(user.user.address),
              },
              {
                value: user.user.phoneNumber,
                sort: user.user.phoneNumber,
              },
              {
                value: user.user.birthDate,
                sort: user?.user?.birthDate
                  ? strToDate(user?.user?.birthDate).getTime()
                  : 0,
              },
            ],
            onClick: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}/participant/${user.courseBookingId}`,
          };
        });

      const courseBookingTableArr = courseMembers.map((user: any) => {
        const fName = user.bookForFamily
          ? user.familyUser?.fName
          : user.user.fName;
        const lName = user.bookForFamily
          ? user.familyUser?.lName
          : user.user.lName;
        const activePayment =
          molliePaymentStatus[user.paymentStatus]?.active && !user.refunded;
        return {
          cells: [
            {
              value: user.userId ? "Konto" : "Gast",
              sort: user.userId ? "Konto" : "Gast",
            },
            {
              value: fName,
              sort: fName,
            },
            {
              value: lName,
              sort: lName,
            },
            {
              value: user.user.email,
              sort: user.user.email,
            },
            {
              value: user.method,
              sort: user.method,
            },
            {
              value: (
                <>
                  <PaymentStatus type={user.paymentStatus} paid={user.paid} />
                  {user.refunded && (
                    <div className="margin-left">
                      <PaymentStatus type={user.refundStatus} />
                    </div>
                  )}
                  {user.hasChargebacks && (
                    <div className="margin-left">
                      <PaymentStatus type={"hasChargebacks"} />
                    </div>
                  )}
                </>
              ),
              sort: user.paymentStatus,
            },
            {
              value: dateTosimpleDateStr(
                firebaseDateToDateObj(user.created_at)
              ),
              sort: user.created_at._seconds,
            },
          ],
          opacity: activePayment ? 1 : 0.5,
          onClick: `/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}/participant/${user.courseBookingId}`,
        };
      });

      courseMembers.sort((a: any, b: any) => (a.active > b.active ? -1 : 1));

      const emailStr = courseMembers
        .filter((member: any) => member.activeParticipant)
        .map((member: any) => member.user.email)
        .join(";");

      const totalPriceAll = courseMembers
        .filter((member: any) => member.activeParticipant)
        .reduce((acc: any, member: any) => {
          return acc + member.price;
        }, 0);
      const totalPriceTracked = courseMembers
        .filter(
          (member: any) =>
            member.active &&
            ((member.method === "manuell" && !member.paid) ||
              (member.method !== "manuell" && member.paymentStatus !== "paid"))
        )
        .reduce((acc: any, member: any) => {
          return acc + member.price;
        }, 0);
      const totalPriceConfirmed = courseMembers
        .filter(
          (member: any) =>
            member.active &&
            ((member.method === "manuell" && member.paid) ||
              (member.method !== "manuell" && member.paymentStatus === "paid"))
        )
        .reduce((acc: any, member: any) => {
          return acc + member.price;
        }, 0);

      this.setState({
        courseMemberTableArr,
        courseBookingTableArr,
        courseMembers,
        emailStr,
        totalPriceAll,
        totalPriceTracked,
        totalPriceConfirmed,
      });
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  getDisplayValue = (
    useIsActive: boolean,
    isActive: boolean,
    value: string
  ) => {
    return `${useIsActive ? (isActive ? "Ja" : "Nein") : ""}${
      useIsActive && value ? ": " : ""
    }${value}`;
  };

  handleFullyLoaded = () => {
    const formData = this.state.course.formData;
    const formOptions = formData?.options || [];
    const courseMembers = this.state.courseMembers;
    const courseMembersCSV = courseMembers
      .filter((member: any) => member.activeParticipant)
      .map((member: any, index: number) => {
        const customFormData: any = {};
        const userFormData = member.formData || [];
        formOptions.forEach((option: any) => {
          const cufD = userFormData.find((x: any) => x.id === option.id);
          customFormData[option.label] =
            cufD !== undefined
              ? this.getDisplayValue(
                  cufD.useIsActive,
                  cufD.isActive,
                  cufD.value
                )
              : "";
        });
        return {
          ...{
            "#": index + 1,
            Vorname: member.user.fName,
            Nachname: member.user.lName,
            Email: member.user.email,
            Adresse: addressToStr(member.user.address),
            Telefonnummer: member.user.phoneNumber,
            Geburtsdatum: member.user.birthDate,
          },
          ...customFormData,
        };
      });
    this.setState({ courseMembersCSV, fullyLoaded: true });
  };

  selectShowType = (num: number) => {
    this.updateUrlProps(num, this.state.monthNum);
    this.setState({ showType: num });
  };

  sendMail = () => {
    this.setState({ sendMailLoading: true });
    fetchRetry(
      "sendParticipantsMail",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        message: this.state.mailMessage,
      },
      1,
      5
    )
      .then(this.handleSendParticipantsMailSuccess)
      .catch(this.handleError);
  };

  handleSendParticipantsMailSuccess = ({ data }: any) => {
    this.setState({ sendMailLoading: false });
    if (data.success) {
      this.setState({
        mailMessage: "",
        mailSendErrMsg: null,
      });
      this.context.createInfo("Mail wurde erfolgreich gesendet.", "success", 4);
    } else {
      this.setState({ mailSendErrMsg: data.errorMsgDe });
    }
  };

  updateUrlProps = (page: number, monthNum: number) => {
    this.props.history.push({
      search: qs.stringify({ page, monthNum }),
    });
  };

  handleMailMessageChange = (val: string) => {
    this.setState({ mailMessage: val });
  };

  handleColumnClick = (column: number) => {
    if (column === this.state.printTableColumn) {
      this.setState({
        printTableSortDirection:
          this.state.printTableSortDirection === "asc" ? "desc" : "asc",
      });
    } else {
      this.setState({
        printTableColumn: column,
        printTableSortDirection: "asc",
      });
    }
  };

  handleRedirect = (redirect: string) => {
    this.props.history.push({
      pathname: redirect,
    });
  };

  render() {
    const isAdmin =
      this.context.isAdmin || this.state.branch?.user?.role === "Owner";
    const isInfinityCourse = this.state.course.courseType === "infinity-course";
    const currMonth = getMonthNum(new Date());

    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Kursauswahl"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course#${this.state.courseId}`}
            />

            <div className="course-title-print-container">
              <h1>Teilnehmer</h1>
              <ReactToPrint
                trigger={() => {
                  return (
                    <FontAwesomeIcon
                      icon={faPrint}
                      size="lg"
                      className="course-print-icon"
                    />
                  );
                }}
                content={() => this.printRef}
              />
              <CSVLink
                data={this.state.courseMembersCSV}
                filename={`Teilnehmerliste_${this.state.branch?.name}_${
                  this.state.course?.title
                }_${dateToStr(new Date())}_${dateToTimeStr(new Date()).replace(
                  ":",
                  "."
                )}.csv`}
              >
                <FontAwesomeIcon
                  icon={faFileCsv}
                  size="lg"
                  className="course-print-icon"
                />
              </CSVLink>
            </div>
            <TestMode active={this.state.branch?.billing?.mode === "test"} />
            {isInfinityCourse && (
              <div className="month-selector-outer-container">
                <MonthSelector
                  minMonthNum={this.state.courseStartMonth-3}
                  maxMonthNum={
                    Math.max(currMonth, this.state.courseStartMonth) + 1
                  }
                  value={this.state.monthNum}
                  onSelect={(monthNum: number) => {
                    this.updateUrlProps(this.state.showType, monthNum);
                    this.setState({ monthNum }, this.requestUsersOfCourse);
                  }}
                />
              </div>
            )}
            {isAdmin && (
              <CourseParticipantsType
                type={this.state.showType}
                onChange={this.selectShowType}
                firstName="Kursteilnehmer"
                secondName="Kursbuchungen"
              />
            )}
            <div>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(this.state.emailStr);
                  this.setState({ emailsCopied: true });
                }}
                className={`email-copy-container ${
                  this.state.emailsCopied ? "email-copy-container-copied" : ""
                }`}
              >
                <div className="flex">
                  E-Mail Adressen kopieren
                  {this.state.emailsCopied && (
                    <div className="email-copy-check-container">
                      <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(this.state.branch?.user?.role === "Owner" || isAdmin) && (
              <div className="inline-block">
                <Link
                  className="create-btn"
                  to={`/club/${this.state.clubId}/branch/${this.state.branchId}/participants/${this.state.courseId}/book-participant`}
                >
                  <div className="create-icon-container">
                    <FontAwesomeIcon icon={faPlusCircle} size="1x" />
                  </div>
                  Mitglied nachbuchen
                </Link>
              </div>
            )}
            <div className="print-container" ref={(el) => (this.printRef = el)}>
              <h1 className="print-title">Teilnehmerliste</h1>
              <div className="print-title-container">
                <div>{this.state.branch?.name}</div>
                <div>-</div>
                <div>{this.state.course?.title}</div>
                <div>-</div>
                <div>
                  Stand: {dateToStr(new Date())} {dateToTimeStr(new Date())}
                </div>
              </div>
              {/* <CourseParticipantsTablePrint
                participants={this.state.courseMembers}
                printMode
                clubId={this.state.clubId}
                branchId={this.state.branchId}
                courseId={this.state.courseId}
              /> */}
              <Table
                header={[
                  { title: "Vorname" },
                  { title: "Nachname" },
                  { title: "E-Mail" },
                  { title: "Adresse" },
                  { title: "Telefonnummer" },
                  { title: "Geburtsdatum" },
                ]}
                data={this.state.courseMemberTableArr}
                loaded={this.state.courseMembersLoaded}
                skeletonRows={5}
                onClick={this.handleRedirect}
                defaultSortDirection={this.state.printTableSortDirection}
                defaultSortIndex={this.state.printTableColumn}
                counter
                className="table-course-user-printmode"
              />
            </div>
            {this.state.showType === 1 ? (
              <>
                <Table
                  header={[
                    { title: "Vorname" },
                    { title: "Nachname" },
                    { title: "E-Mail", displayWidth: 1200 },
                    { title: "Adresse", displayWidth: 800 },
                    { title: "Telefonnummer", displayWidth: 600 },
                    { title: "Geburtsdatum", displayWidth: 450 },
                  ]}
                  data={this.state.courseMemberTableArr}
                  loaded={this.state.fullyLoaded}
                  skeletonRows={5}
                  onClick={this.handleRedirect}
                  defaultSortDirection="asc"
                  defaultSortIndex={1}
                  counter
                  onColumnClick={this.handleColumnClick}
                />
                <div className="medium-space"></div>
                {this.state.branch?.user?.role === "Owner" && (
                  <>
                    <SubTitle title="Mail an Teilnehmer senden" />
                    <TextArea
                      value={this.state.mailMessage}
                      onChange={this.handleMailMessageChange}
                      placeholder="Mail Nachricht"
                    />
                    <ErrorMessage message={this.state.mailSendErrMsg} />
                    <SecButton
                      change={!!this.state.mailMessage}
                      color="green"
                      loading={this.state.sendMailLoading}
                      onClick={this.sendMail}
                      title="Mail senden"
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <Table
                  header={[
                    { title: "Konto", displayWidth: 1000 },
                    { title: "Vorname" },
                    { title: "Nachname" },
                    { title: "E-Mail", displayWidth: 800 },
                    { title: "Methode", displayWidth: 700 },
                    { title: "Status" },
                    { title: "Datum", displayWidth: 450 },
                  ]}
                  data={this.state.courseBookingTableArr}
                  loaded={this.state.fullyLoaded}
                  skeletonRows={5}
                  onClick={this.handleRedirect}
                  defaultSortDirection="desc"
                  defaultSortIndex={6}
                  counter
                  key={1}
                />
                <div className="medium-space"></div>
                <SubTitle title="Einnahmen" />
                <div className="earnings-main-container">
                  <div className="earnings-inner-container">
                    <div className="earnings-title">Bestätigt </div>
                    <div className="earnings-price">
                      {priceToFullStr(this.state.totalPriceConfirmed)}€
                    </div>
                  </div>
                  <div className="earnings-inner-container">
                    <div className="earnings-title">Ausstehend </div>
                    <div className="earnings-price">
                      {priceToFullStr(this.state.totalPriceTracked)}€
                    </div>
                  </div>
                  <div className="earnings-inner-container">
                    <div className="earnings-title">Gesamt </div>
                    <div className="earnings-price">
                      {priceToFullStr(this.state.totalPriceAll)}€
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
