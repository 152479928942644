import React from "react";
import "./MyCourses.css";
import "../App.css";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  inPast,
  priceToStr,
} from "../functions/utils";
import SingleCourseElementOwn from "../components/SingleCourseElementOwn";
import BlockCourseElementOwn from "../components/BlockCourseElementOwn";
import InfinityCourseElementOwn from "../components/InfinityCourseElementOwn";
import { fetchRetry } from "../functions/request";
import Title from "../components/Title";
import CourseElementSkeleton from "../components/CourseElementSkeleton";
import { MainContext } from "../contexts/MainContext";
import SearchInput from "../components/SearchInput";

export default class MyCourses extends React.Component<
  {},
  {
    coursesLoaded: boolean;
    courses: Array<any>;
    filteredCourses: Array<any>;
    showCreateModal: boolean;
    courseSearch: string;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      coursesLoaded: false,
      courses: [],
      filteredCourses: [],
      showCreateModal: false,
      courseSearch: "",
    };
  }

  componentDidMount = () => {
    this.requestCourses();
  };

  requestCourses = () => {
    fetchRetry(
      "getCoursesOfUserF",
      {
        targetUserId: this.context.selectedUserId,
        limit: 50,
        onlyFuture: false,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCoursesSuccess = ({ data }: any) => {
    this.setState({ coursesLoaded: true });
    if (data.success) {
      const courses = data.data;
      courses.sort((a: any, b: any) => (a.startTime > b.startTime ? 1 : -1));
      this.setState({ courses }, () => {
        this.filterCourses(this.state.courseSearch);
      });
      const highlighedCourseBookingId = window.location.href.slice(
        window.location.href.indexOf("#") + 1
      );
      var element = document.getElementById(highlighedCourseBookingId);
      element?.scrollIntoView();
    }
  };

  handleCloseCreateModal = () => {
    this.setState({ showCreateModal: false });
  };

  handleShowCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleChangeCourseSearch = (val: string) => {
    this.filterCourses(val);
    this.setState({ courseSearch: val });
  };

  filterCourses = (filter: string) => {
    const lowerFilter = filter.toLowerCase();
    const filteredCourses = this.state.courses.filter((course: any) => {
      return course.title.toLowerCase().includes(lowerFilter);
    });
    this.setState({ filteredCourses });
  };

  render() {
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Meine Kurse" />
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            {!this.state.coursesLoaded ? (
              <>
                <CourseElementSkeleton />
                <CourseElementSkeleton />
                <CourseElementSkeleton />
              </>
            ) : (
              <>
                {this.state.courses.length === 0 && (
                  <div className="no-courses-container">
                    Buche deinen ersten Kurs damit dieser hier angezeigt wird.
                  </div>
                )}
              </>
            )}

            {this.state.filteredCourses.map((course: any) => (
              <>
                {course.bookingArr.map((courseBooking: any) => (
                  <>
                    {course.courseType === "single-course" && (
                      <SingleCourseElementOwn
                        clubId={course.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        courseBooking={courseBooking}
                        title={course.title}
                        description={course.description}
                        startTime={course.startTime}
                        endTime={course.endTime}
                        bookingCreated={dateToFullStr(
                          firebaseDateToDateObj(courseBooking.created_at)
                        )}
                        where={course.location.name}
                        freePlaces={course.freePlaces}
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        inPast={inPast(firebaseDateToDateObj(course.startTime))}
                        notice={course.bookingMailMessage}
                        disabled={
                          courseBooking.refunded || !courseBooking.active
                        }
                        status={
                          courseBooking.active
                            ? "Gebucht"
                            : courseBooking.refunded
                            ? "Storniert"
                            : "Nicht Gebucht"
                        }
                      />
                    )}
                    {course.courseType === "block-course" && (
                      <BlockCourseElementOwn
                        clubId={course.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        courseBooking={courseBooking}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        bookingCreated={dateToFullStr(
                          firebaseDateToDateObj(courseBooking.created_at)
                        )}
                        courses={course.courses}
                        freePlaces={course.freePlaces}
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        inPast={inPast(firebaseDateToDateObj(course.startTime))}
                        notice={course.bookingMailMessage}
                        key={course.id}
                        disabled={
                          courseBooking.refunded || !courseBooking.active
                        }
                        status={
                          courseBooking.active
                            ? "Gebucht"
                            : courseBooking.refunded
                            ? "Storniert"
                            : "Nicht Gebucht"
                        }
                      />
                    )}
                    {course.courseType === "infinity-course" && (
                      <InfinityCourseElementOwn
                        clubId={course.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        courseBooking={courseBooking}
                        title={course.title}
                        description={course.description}
                        time={course.time}
                        duration={course.duration}
                        bookingCreated={dateToFullStr(
                          firebaseDateToDateObj(courseBooking.created_at)
                        )}
                        where={course.location?.name}
                        weekDay={course.weekDay}
                        freePlaces={course.freePlaces}
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        notice={course.bookingMailMessage}
                        key={course.id}
                        disabled={
                          courseBooking.refunded || !courseBooking.active
                        }
                        status={
                          courseBooking.active
                            ? "Gebucht"
                            : courseBooking.refunded
                            ? "Storniert"
                            : "Nicht Gebucht"
                        }
                      />
                    )}
                  </>
                ))}
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}
