import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./BranchModal.css";
import "./RequestedBranchModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessageContainer from "../MessageContainer";
import SecButton from "../SecButton";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default class RequestedBranchModal extends React.Component<
  {
    clubId: string;
    branchId: string;
    show: boolean;
    branchName: string;
    clubName: string;
    handleClose: any;
    refresh: any;
  },
  {
    membershipId: string;
    loading: boolean;
    msg: null | string;
    msgType: "error" | "success";
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      membershipId: "",
      loading: false,
      msg: "",
      msgType: "success",
    };
  }

  handleBranchRequestedBtn = () => {
    this.setState({ loading: true });
    fetchRetry(
      "revokeRequestToJoinBranchF",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleRevokeSuccess)
      .catch(this.handleError);
  };

  handleRevokeSuccess = ({ data }: any) => {
    this.setState({ loading: false });
    if (data.success) {
      this.context.createInfo(
        "Anfrage erfolgreich zurückgezogen.",
        "success",
        4
      );
      this.props.refresh();
      this.props.handleClose();
    } else {
      this.setState({
        msg: data.errorMsgDe,
        msgType: "error",
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="requested Branch"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="branch-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={faTimes} className="join-branch-cross" />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">
                {this.props.branchName}{" "}
                <small className="branch-title"> | {this.props.clubName}</small>
              </span>
            </div>
            <MessageContainer msg={this.state.msg} type={this.state.msgType} />
            <br />
            <div className="margin-bottom">
              <span className="branch-text">
                Du hast diesem Bereich bereits eine Beitrittsanfrage gesendet.
                <br />
                Klicke auf <b>"Anfrage zurückziehen"</b> um deine
                Beitrittsanfrage zurückzuziehen.
                <br />
                Beachte, dass du eine neue Beitrittsanfrage senden musst,
                solltest du diesem Bereich doch beitreten wollen.
              </span>
            </div>
            <SecButton
              change={true}
              color="red"
              loading={this.state.loading}
              onClick={this.handleBranchRequestedBtn}
              title="Anfrage zurückziehen"
            />
          </div>
        </Modal>
      </>
    );
  }
}
