import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./SecButton.css";

export default class SecButton extends React.Component<
  {
    onClick: any;
    title: string;
    change?: boolean;
    loading?: boolean;
    color?: "red" | "green";
    colorHex?: string;
    className?: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <button
          className={`${this.props.className ? this.props.className : "btn"} ${
            !this.props.change && " btn-disabled"
          } ${this.props.loading && " btn-loading"} ${
            this.props.color === "red" && " btn-red"
          }`}
          style={{ backgroundColor: this.props.colorHex }}
          onClick={this.props.onClick}
        >
          <div className="btn-inner-container">
            <div>{this.props.title}</div>
            {this.props.loading && (
              <div className="btn-loading-container">
                <ClipLoader color={"lightgray"} size={30} loading={true} />
              </div>
            )}
          </div>
        </button>
      </>
    );
  }
}
