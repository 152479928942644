import React from "react";
import "./VertNavbar.css";
import "./ClubSearchNavbar.css";

import ClubSettingsNavbarElement from "./elements/ClubSettingsNavbarElement";
import LogoNavbarElement from "./elements/LogoNavbarElement";
import UserNavbarElement from "./elements/UserNavbarElement";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default class ClubSearchNavbar extends React.Component<
  {
    selectedScreen: string;
    showMobile?: boolean;
    hideMobileNav: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`nav-background ${
            this.props.showMobile && " show-nav-background"
          }`}
          onClick={this.props.hideMobileNav}
        ></div>
        <nav
          className={`vert-navbar br-navbar ${
            this.props.showMobile && " vert-navbar-mobile"
          }`}
        >
          <LogoNavbarElement />
          <div className="vert-navbar-scrollable">
            <UserNavbarElement to={`/profile`} />
            <ClubSettingsNavbarElement
              title="Vereinssuche"
              icon={faSearch}
              selected={this.props.selectedScreen === "clubsearch"}
              onClick={this.props.hideMobileNav}
              linkTo="/clubsearch"
            />
          </div>
        </nav>
      </>
    );
  }
}
