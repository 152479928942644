import React from "react";
import "./VertNavbar.css";
import "./BranchNavbar.css";

import BranchNavbarElement from "./elements/BranchNavbarElement";
import LogoNavbarElement from "./elements/LogoNavbarElement";
import UserNavbarElement from "./elements/UserNavbarElement";
import { MainContext } from "../../contexts/MainContext";

import { Link } from "react-router-dom";
import {
  faBookmark,
  faBoxOpen,
  faCalendarAlt,
  faCalendarPlus,
  faCogs,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";

export default class BranchNavbar extends React.Component<
  {
    selectedScreen: string;
    clubId: string;
    branchId: string;
    isAdmin: boolean;
    isViewOnly: boolean;
    showMobile: boolean;
    hideMobileNav: any;
    useBooking: boolean;
    useCourse: boolean;
    useSubscriptions: boolean;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`nav-background ${
            this.props.showMobile && " show-nav-background"
          }`}
          onClick={this.props.hideMobileNav}
        ></div>
        <nav
          className={`vert-navbar br-navbar ${
            this.props.showMobile && " vert-navbar-mobile"
          }`}
        >
          <LogoNavbarElement />
          <div className="vert-navbar-scrollable">
            <UserNavbarElement
              to={`/club/${this.props.clubId}/branch/${this.props.branchId}/profile`}
            />
            {this.props.useBooking && (
              <BranchNavbarElement
                title="Buchungen"
                icon={faCalendarAlt}
                selected={this.props.selectedScreen === "booking"}
                onClick={this.props.hideMobileNav}
                linkTo={`/club/${this.props.clubId}/branch/${this.props.branchId}/booking`}
              />
            )}
            {this.props.useCourse && (
              <>
                <BranchNavbarElement
                  title="Kurse"
                  icon={faCalendarAlt}
                  selected={this.props.selectedScreen === "course"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch/${this.props.branchId}/course`}
                />
                {(this.props.isAdmin || this.props.isViewOnly) && (
                  <BranchNavbarElement
                    title="Kurse verwalten"
                    icon={faCalendarPlus}
                    selected={this.props.selectedScreen === "manage-course"}
                    onClick={this.props.hideMobileNav}
                    linkTo={`/club/${this.props.clubId}/branch/${this.props.branchId}/manage-course`}
                  />
                )}
                {this.context.user && (
                  <BranchNavbarElement
                    title="Meine Kurse"
                    icon={faBoxOpen}
                    selected={this.props.selectedScreen === "mycourses"}
                    onClick={this.props.hideMobileNav}
                    linkTo={`/club/${this.props.clubId}/branch/${this.props.branchId}/mycourses`}
                  />
                )}
                {(this.props.isAdmin || this.props.isViewOnly) && (
                  <BranchNavbarElement
                    title="QR-Code Scanner"
                    icon={faQrcode}
                    selected={this.props.selectedScreen === "qr-scanner"}
                    onClick={this.props.hideMobileNav}
                    linkTo={`/club/${this.props.clubId}/branch/${this.props.branchId}/qr-scanner`}
                  />
                )}
              </>
            )}
            {this.props.useSubscriptions && (
              <>
                {this.context.user && (
                  <BranchNavbarElement
                    title="Meine Abonnements"
                    icon={faBookmark}
                    selected={this.props.selectedScreen === "mysubscriptions"}
                    onClick={this.props.hideMobileNav}
                    linkTo={`/club/${this.props.clubId}/branch/${this.props.branchId}/mysubscriptions`}
                  />
                )}
              </>
            )}
            {this.props.isAdmin && (
              <BranchNavbarElement
                title="Einstellungen"
                icon={faCogs}
                selected={this.props.selectedScreen === "settings"}
                onClick={this.props.hideMobileNav}
                linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/users`}
              />
            )}
          </div>
          <Link
            to={`/club/${this.props.clubId}`}
            className={"navbar-element navbar-back-element"}
            onClick={this.props.hideMobileNav}
          >
            zum Verein
          </Link>
        </nav>
      </>
    );
  }
}
