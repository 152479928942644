import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./Users.css";
import "../../App.css";
import { addIdToObject } from "../../functions/utils";
import MultiSelector from "../../components/MultiSelector";
import { fetchRetry } from "../../functions/request";
import { roles } from "../../services/constants";
import SearchInput from "../../components/SearchInput";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";
import { MainContext } from "../../contexts/MainContext";
import CourseSkeletonLoader from "../../components/CourseSkeletonLoader";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Users extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branch: any;
    branchId: string;
    userSearchVal: string;
    users: any;
    userArr: Array<any>;
    userTableArr: Array<RowData>;
    fullyLoaded: boolean;
    redirect: string | null;
    rolesSelected: boolean;
    coursesRequested: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchId: this.props.match.params.branchId,
      userSearchVal: "",
      users: {},
      userArr: [],
      userTableArr: [],
      fullyLoaded: false,
      redirect: null,
      rolesSelected: true,
      coursesRequested: false,
    };
  }

  componentDidMount = async () => {
    await Promise.all([this.requestBranch(), this.requestUsers()]).then(
      this.handleCombindUsers
    );
  };

  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
        },
        this.requestUsers
      );
    }
  };

  requestUsers = async () => {
    await fetchRetry(
      "getUsersFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleUsers)
      .catch(this.handleError);
  };

  handleUsers = ({ data }: any) => {
    if (data.success) {
      const users = addIdToObject(data.data);
      this.setState({ users: users });
    }
  };

  generateUserTableArr = (
    userArr: Array<any>,
    courseBookings: any,
    courseBookingsLoaded: boolean
  ) => {
    return userArr.map((user: any) => {
      const userCourses = courseBookings[user.id]?.courses || [];
      const userCoursesFormatted = userCourses
        .map((course: any) => {
          return {
            id: course.id,
            name: course.title,
            selected: true,
            color: "#5c67ee",
            notEditable: true,
          };
        })
        .sort((a: any, b: any) => (a.name < b.name ? 1 : -1));
      return {
        cells: [
          {
            value: user.membershipNumber,
            sort: user.membershipNumber,
            search: user.membershipNumber,
          },
          {
            value: user.fName,
            sort: user.fName,
            search: `${user.fName} ${user.lName}`,
          },
          {
            value: user.lName,
            sort: user.lName,
            search: user.lName,
          },
          {
            value: user.email,
            sort: user.email,
            search: user.email,
          },
          {
            value: roles[user.role]?.germName,
            sort: roles[user.role]?.germName,
            search: roles[user.role]?.germName,
          },
          {
            value: (
              <MultiSelector
                viewOnly
                onSelect={() => {}}
                onUnselect={() => {}}
                arr={user.roleArr}
              />
            ),
            sort: "",
          },
          {
            value: (
              <>
                {courseBookingsLoaded ? (
                  <MultiSelector
                    viewOnly
                    onSelect={() => {}}
                    onUnselect={() => {}}
                    arr={userCoursesFormatted}
                  />
                ) : (
                  <CourseSkeletonLoader />
                )}
              </>
            ),
            sort: "",
            search: userCoursesFormatted
              .map((course: any) => course.name)
              .join(" "),
          },
        ],
        onClick: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${user.id}`,
      };
    });
  };

  handleCombindUsers = () => {
    const userData = this.state.users;
    const userArr = [];
    for (const userId in userData) {
      const user = userData[userId];
      const userRoles = user.roles || [];
      user.id = userId;
      const roles = this.state.branch.roles || {};
      user.roleArr = userRoles
        .filter((roleId: string) => roles[roleId])
        .map((roleId: string) => {
          return {
            id: roleId,
            name: roles[roleId].name,
            selected: true,
            color: roles[roleId].type === "club" ? "#5c67ee" : "#ee5c5c",
            notEditable: roles[roleId].type === "club",
          };
        })
        .sort((a: any, b: any) => (a.name < b.name ? 1 : -1));
      userArr.push(user);
    }
    userArr.sort((a: any, b: any) => (a.lName < b.lName ? 1 : -1));
    userArr.sort((a: any, b: any) => (a.fName < b.fName ? 1 : -1));
    userArr.sort((a: any, b: any) => (a.accepted > b.accepted ? 1 : -1));

    const userTableArr = this.generateUserTableArr(userArr, {}, false);

    this.setState({
      userArr: userArr,
      userTableArr,
      fullyLoaded: true,
    });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleChangeClubSearchVal = (val: any) => {
    this.setState({ userSearchVal: val });
  };

  handleRedirect = (redirect: string) => {
    this.props.history.push({
      pathname: redirect,
    });
  };

  toggleMode = () => {
    this.setState({ rolesSelected: !this.state.rolesSelected });
    if (!this.state.coursesRequested) {
      this.setState({ coursesRequested: true });
      fetchRetry(
        "getCourseBookingsOfBranchUsers",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
        },
        1,
        5
      )
        .then(this.handleCourseBookings)
        .catch(this.handleError);
    }
  };

  handleCourseBookings = ({ data }: any) => {
    if (data.success) {
      const courseBookings = data.data;
      const userTableArr = this.generateUserTableArr(
        this.state.userArr,
        courseBookings,
        true
      );
      this.setState({ userTableArr });
    }
  };

  handleUserClick = (userId: any) => {
    this.props.history.push({
      pathname: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${userId}`,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <SearchInput
              name="course-search-input"
              placeholder="Nutzersuche"
              value={this.state.userSearchVal}
              onChange={this.handleChangeClubSearchVal}
              className="no-margin"
            />
            <div className="medium-space"></div>
            <Table
              header={[
                { title: "Mitgliedsnummer", displayWidth: 450 },
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 950 },
                { title: "Rechte", displayWidth: 600 },
                {
                  title: (
                    <>
                      Rollen
                      <span className="light-gray">/Kursbuchungen</span>
                    </>
                  ),
                  disableSort: true,
                  displayWidth: 1200,
                  onClick: this.toggleMode,
                  hide: !this.state.rolesSelected,
                },
                {
                  title: (
                    <>
                      Kursbuchungen
                      <span className="light-gray">/Rollen</span>
                    </>
                  ),
                  disableSort: true,
                  displayWidth: 1200,
                  onClick: this.toggleMode,
                  hide: this.state.rolesSelected,
                },
              ]}
              data={this.state.userTableArr}
              loaded={this.state.fullyLoaded}
              skeletonRows={10}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              searchValue={this.state.userSearchVal}
              counter
            />
          </div>
        </div>
      </>
    );
  }
}
