import React from "react";
import { Link } from "react-router-dom";
import "./Switch.css";
import { MainContext } from "../contexts/MainContext";

export default class ReqMemAppMessage extends React.Component<
  {
    show: boolean;
    clubId: string;
    alreadyMemberText: string;
    requestMembershipText: string;
    requestMembershipUrl: string;
    requestMembershipBtnText: string;
    alreadyMemberBtnText: string;
    hasRequested: boolean;
    handleShowJoinModal: any;
    handleShowRequestedModal: any;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.show && (
          <>
            {!this.context.user ? (
              <div className="member-container">
                <div className="member-align-container">
                  <div className="member-inner-container">
                    {this.props.alreadyMemberText}
                    <Link
                      to={`/sign-up/${this.props.clubId}?redirect=${window.location.href}&joinBranch=true`}
                      className="no-underline"
                    >
                      <div className="member-login-btn">Registrieren</div>
                    </Link>
                  </div>
                  <div className="member-inner-container">
                    {this.props.requestMembershipText}
                    <a
                      href={this.props.requestMembershipUrl}
                      className="no-underline"
                    >
                      <div className="member-login-btn">
                        {this.props.requestMembershipBtnText}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="member-container">
                  <div className="member-align-container">
                    <div className="member-inner-container">
                      {this.props.alreadyMemberText}
                      <div
                        onClick={this.props.handleShowJoinModal}
                        className="member-login-btn"
                      >
                        {!this.context.isAdmin
                          ? this.props.alreadyMemberBtnText
                          : "Bereich beitreten"}
                      </div>
                    </div>
                    <div className="member-inner-container">
                      {this.props.requestMembershipText}
                      <a
                        href={this.props.requestMembershipUrl}
                        className="no-underline"
                      >
                        <div className="member-login-btn">
                          {this.props.requestMembershipBtnText}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
