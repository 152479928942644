import React from "react";
import { memAppStatus } from "../services/constants";
import "./MemAppStatus.css";

export default class MemAppStatus extends React.Component<
  { type: string; paid?: boolean },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {memAppStatus[this.props.type] !== undefined ? (
          <div
            className="payment-status"
            style={{
              backgroundColor:
                this.props.type !== "manuelly"
                  ? memAppStatus[this.props.type]?.color
                  : this.props.paid
                  ? memAppStatus["manuellyPaid"]?.color
                  : memAppStatus["manuelly"]?.color,
            }}
          >
            {memAppStatus[this.props.type]?.name}
          </div>
        ) : null}
      </>
    );
  }
}
