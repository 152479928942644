import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./Forms.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";
import SubTitle from "../../components/SubTitle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormElementT } from "../../Types";
import FormEditElement from "../../components/form/editor/FormEditElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
    formId: string;
  }>;

export default class Forms extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    form: any;
    formId: string;
    formLoaded: boolean;
    formName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
    elements: FormElementT[];
    formChange: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      formLoaded: false,
      form: {},
      branchId: this.props.match.params.branchId,
      formId: this.props.match.params.formId,
      formName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
      elements: [],
      formChange: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount = () => {
    this.requestForm();
  };

  componentDidUpdate = () => {
    const propsFormId = this.props.match.params.formId;
    if (this.state.formId !== propsFormId) {
      this.setState({ formId: propsFormId }, this.requestForm);
    }
  };

  requestForm = () => {
    fetchRetry(
      "getFormFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        formId: this.state.formId,
      },
      1,
      5
    )
      .then(this.handleForm)
      .catch(this.handleError);
  };

  handleForm = ({ data }: any) => {
    if (data.success) {
      const form = data.data;
      const formElements: FormElementT[] = form.options.map((o: any) => {
        const options = o.options || [];
        return {
          id: o.id,
          label: o.label,
          type: o.type,
          edit: false,
          options: options,
        };
      });

      this.setState({
        formChange: false,
        elements: formElements,
        formLoaded: true,
        formName: form.name,
      });
    }
  };

  handleSuccessUpdate = () => {
    this.setState({ updateLoading: false });
    this.requestForm();
    this.props.refreshNavbar();
  };

  updateForm = () => {
    this.setState({ updateLoading: true });
    const options = this.state.elements.map((element) => {
      return {
        id: parseInt(element.id),
        label: element.label,
        type: element.type,
        options: element.options,
      };
    });
    fetchRetry(
      "updateForm",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        formId: this.state.formId,
        name: this.state.formName,
        options,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleFormNameChange = (val: any) => {
    this.setState({ formName: val }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change: this.state.formName !== this.state.form.name,
    });
  };

  handleDeleteForm = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleFormDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteForm",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        formId: this.state.formId,
      },
      1,
      5
    )
      .then(this.handleSuccessFormDeleted)
      .catch(this.handleError);
  };

  handleSuccessFormDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Das Formular wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState(
        {
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/forms`,
        },
        () => {
          this.props.refreshNavbar();
        }
      );
    }
  };

  reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const elements = this.reorder(
      this.state.elements,
      result.source.index,
      result.destination.index
    );

    this.setState({
      elements: elements,
      formChange: true,
    });
  }

  onToggleEdit = (index: number) => {
    const elements = this.state.elements;
    elements[index].edit = !elements[index].edit;
    this.setState({ elements: elements, formChange: true });
  };

  onLableChange = (index: number, value: string) => {
    const elements = this.state.elements;
    elements[index].label = value;
    this.setState({ elements: elements, formChange: true });
  };

  onTypeChange = (index: number, value: any) => {
    const elements = this.state.elements;
    elements[index].type = value;
    this.setState({ elements: elements, formChange: true });
  };

  onOptionsChange = (index: number, value: any) => {
    const elements = this.state.elements;
    elements[index].options = value;
    this.setState({ elements: elements, formChange: true });
  };

  addElement = () => {
    const elements = this.state.elements;
    elements.push({
      id: Math.floor(Math.random() * 100000) + "",
      label: "",
      type: "switch",
      edit: true,
    });
    this.setState({ elements: elements, formChange: true });
  };

  deleteElement = (index: number) => {
    const elements = this.state.elements;
    elements.splice(index, 1);
    this.setState({ elements: elements, formChange: true });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.formLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du das Formular "${
            this.state.formId && this.state.formName
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleFormDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.formId && (
              <>
                <div className="subscreen-box-container">
                  <Title title="Einstellungen" />
                  <SubTitle title="Name" />
                  <Input
                    name="role-name-input"
                    value={this.state.formName}
                    placeholder="Formularname"
                    onChange={this.handleFormNameChange}
                  />

                  <SubTitle title="Layout" />
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          className="roles-priority-container"
                          ref={provided.innerRef}
                        >
                          {this.state.elements.map((elem, index: number) => (
                            <Draggable
                              key={elem.id + ""}
                              draggableId={elem.id + ""}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  draggable="true"
                                  className="form-elem-container"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <FormEditElement
                                    elem={elem}
                                    onChange={() => {}}
                                    onLableChange={(val: string) => {
                                      this.onLableChange(index, val);
                                    }}
                                    onToggleEdit={() => {
                                      this.onToggleEdit(index);
                                    }}
                                    onTypeChange={(val) => {
                                      this.onTypeChange(index, val);
                                    }}
                                    onDelete={() => {
                                      this.deleteElement(index);
                                    }}
                                    onOptionsChange={(val) => {
                                      this.onOptionsChange(index, val);
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div className="add-form-btn-wrapper">
                    <button className="add-form-btn" onClick={this.addElement}>
                      <FontAwesomeIcon icon={faPlus} size="1x" />
                    </button>
                  </div>
                  <SecButton
                    change={this.state.change || this.state.formChange}
                    color="green"
                    loading={this.state.updateLoading}
                    onClick={this.updateForm}
                    title="Speichern"
                  />
                  <SecButton
                    change={true}
                    color="red"
                    loading={this.state.deleteLoading}
                    onClick={this.handleDeleteForm}
                    title="Formular löschen"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
