import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./Tags.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
    tagId: string;
  }>;

export default class Tags extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    tagId: string;
    branch: any;
    branchLoaded: boolean;
    tagName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchLoaded: false,
      branchId: this.props.match.params.branchId,
      tagId: this.props.match.params.tagId,
      tagName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  componentDidUpdate = () => {
    const propsTagId = this.props.match.params.tagId;
    if (this.state.tagId !== propsTagId) {
      if (propsTagId in this.state.branch.tags) {
        this.setState({ tagId: propsTagId }, this.updateTagData);
      } else {
        this.setState({ tagId: propsTagId }, this.requestBranch);
      }
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleSuccessUpdate = () => {
    this.setState({ updateLoading: false });
    this.props.refreshNavbar();
    this.requestBranch();
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
        },
        this.updateTagData
      );
    }
  };

  updateTagData = () => {
    if (
      this.state.tagId &&
      this.state.tagId in this.state.branch.tags
    ) {
      const tag = this.state.branch.tags[this.state.tagId];
      this.setState(
        {
          tagName: tag.name,
        },
        this.checkChange
      );
    }
  };

  updateTag = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateTag",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        tagId: this.state.tagId,
        name: this.state.tagName,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleTagNameChange = (val: any) => {
    this.setState({ tagName: val }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.tagName !==
        this.state.branch.tags[this.state.tagId].name,
    });
  };

  handleDeleteTag = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleTagDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteTag",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        tagId: this.state.tagId,
      },
      1,
      5
    )
      .then(this.handleSuccessTagDeleted)
      .catch(this.handleError);
  };

  handleSuccessTagDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Der Tag wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState(
        {
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/tags`,
        },
        () => {
          this.updateTagData();
          this.props.refreshNavbar();
        }
      );
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du das Tag "${
            this.state.tagId &&
            this.state.branch?.tags &&
            this.state.branch?.tags[this.state.tagId]?.name
              ? this.state.branch.tags[this.state.tagId].name
              : "-"
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleTagDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.tagId &&
              this.state.branch?.tags &&
              Object.keys(this.state.branch.tags).length > 0 && (
                <>
                  <div className="subscreen-box-container">
                    <Title title="Einstellungen" />
                    <div className="branch-subtitle-container">
                      <h3>Name</h3>
                    </div>
                    <Input
                      name="role-name-input"
                      value={this.state.tagName}
                      placeholder="Tag name"
                      onChange={this.handleTagNameChange}
                    />

                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updateTag}
                      title="Speichern"
                    />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.deleteLoading}
                      onClick={this.handleDeleteTag}
                      title="Tag löschen"
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
