import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./UnderlinedTitle.css";

export default class UnderlinedTitle extends React.Component<
  { title: string; helpText?: string; className?: string | undefined },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={`underlined-title-container${
          this.props.className ? ` ${this.props.className}` : ""
        }`}
      >
        <h3>{this.props.title}</h3>
        {this.props.helpText && (
          <div className="info-icon-container">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size="1x"
              onClick={() => {}}
              className="back-element-icon"
            />
            <div className="info-text-container">
              <div className="info-text-triangle"></div>
              <div className="info-text-inner-container">
                {this.props.helpText}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
