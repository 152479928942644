import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./CreateSubscription.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { findWithAttr } from "../../functions/utils";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import MultiSelector from "../../components/MultiSelector";
import DropDown from "../../components/DropDown";
import SubTitle from "../../components/SubTitle";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../../contexts/MainContext";
import { dateUnits } from "../../services/constants";
import Switch from "../../components/Switch";
import ErrorMessage from "../../components/ErrorMessage";
import Title from "../../components/Title";
import InfoContainer from "../../components/InfoContainer";
import TextCheckbox from "../../components/TextCheckbox";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import TextArea from "../../components/TextArea";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class CreateSubscription extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branchLoaded: boolean;
    createLoading: boolean;
    branch: any;
    subscriptionTitle: string;
    subscriptionDescription: string;
    subscriptionBookingNote: string;
    roleArr: Array<any>;
    rolePriceArr: Array<any>;
    infinityEndDate: string;
    noticePeriod: number;
    errorMessage: string | null;
    redirect: null | string;
    showAddRolePricing: boolean;
    searchTextPriceRole: string;
    hasMinAge: boolean;
    hasMaxAge: boolean;
    minAge: number;
    maxAge: number;
    dateUnit: string;
    hasExpireDate: boolean;
    branchRoleArr: Array<any>;
    billingPeriod: number;
  }
> {
  static contextType = MainContext;
  private wrapperRef: any;

  constructor(props: ComposedProps) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branchLoaded: false,
      createLoading: false,
      branch: {},
      subscriptionTitle: "",
      subscriptionDescription: "",
      subscriptionBookingNote: "",
      roleArr: [],
      rolePriceArr: [],
      infinityEndDate: "",
      noticePeriod: 1,
      errorMessage: null,
      redirect: null,
      showAddRolePricing: false,
      searchTextPriceRole: "",
      hasMinAge: false,
      hasMaxAge: false,
      minAge: 0,
      maxAge: 0,
      dateUnit: "3",
      hasExpireDate: false,
      branchRoleArr: [],
      billingPeriod: 1,
    };
  }

  componentDidMount = async () => {
    this.requestBranch().then(() => this.handleRoleArr());
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  handleClickOutside(event: any) {
    if (this.state.showAddRolePricing) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.hideAddRolePricingWindow();
      }
    }
  }

  hideAddRolePricingWindow = () => {
    this.setState({ showAddRolePricing: false });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  showErrorMsg = () => {
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
  };

  handleError = (err: any) => {
    this.showErrorMsg();
    console.error(err);
  };

  createSubscription = () => {
    if (!this.state.createLoading) {
      const priceArr = this.state.rolePriceArr
        .filter((rolePrice: any) => rolePrice.selected)
        .map((rolePrice: any) => {
          return {
            id: rolePrice.id,
            price: rolePrice.val * 100,
            admissionFee: rolePrice.admissionFee * 100,
            showAlways: rolePrice.showAlways,
          };
        });
      const roleIds: Array<string> = this.state.roleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      const manageRoleIds: Array<string> = this.state.branchRoleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      this.setState({ createLoading: true });
      fetchRetry(
        "createSubscription",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
          title: this.state.subscriptionTitle,
          description: this.state.subscriptionDescription,
          bookingMailMessage: this.state.subscriptionBookingNote,
          allowedRoles: roleIds,
          manageRoles: manageRoleIds,
          priceArr,
          minAge: this.state.hasMinAge ? this.state.minAge : null,
          maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
          noticePeriod: this.state.noticePeriod,
          noticePeriodDateUnit:
            dateUnits[parseInt(this.state.dateUnit) - 1].dbName,
          billingPeriod: this.state.billingPeriod,
        },
        1,
        5
      )
        .then(this.handleCreateSubscriptionSuccess)
        .catch(this.handleError);
    }
  };

  handleCreateSubscriptionSuccess = ({ data }: any) => {
    this.setState({ createLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Mitgliedschaft wurde erfolgreich erstellt.",
        "success",
        4
      );
      this.setState({
        subscriptionTitle: "",
        subscriptionDescription: "",
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course?showSub=true#${data.subscriptionId}`,
      });
      this.setState({ errorMessage: null });
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleRoleArr = () => {
    if (this.state.branchLoaded) {
      const branchRoleArr = Object.keys(this.state.branch.roles)
        .filter(
          (roleId: string) => this.state.branch?.roles[roleId].type !== "club"
        )
        .map((roleId: string) => {
          const currBranchRole = this.state.branch?.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: false,
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        });
      const roleArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: false,
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      roleArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        color: "#ffd025",
        selected: false,
      });
      roleArr.push({
        custom: false,
        default: false,
        id: "guest",
        name: "Jeder",
        selected: true,
        color: "#ffd025",
      });

      const rolePriceArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: false,
              val: 0,
              admissionFee: 0,
              showAlways: false,
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      rolePriceArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        selected: false,
        color: "#ffd025",
        val: 0,
        admissionFee: 0,
        priority: -1,
        showAlways: false,
      });

      rolePriceArr.push({
        custom: false,
        default: true,
        id: "default",
        name: "Basis",
        selected: true,
        color: "#9e9e9e",
        val: 0,
        admissionFee: 0,
        priority: -3,
        showAlways: false,
      });
      rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
      this.setState({
        roleArr,
        rolePriceArr,
        branchRoleArr,
      });
    }
  };

  handleChangeSearchTextPriceRole = (val: any) => {
    this.setState({ searchTextPriceRole: val.target.value });
  };

  handlePriceRoleChange = (id: number, val: any) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].val = Math.floor(val * 100) / 100;
    this.setState({ rolePriceArr });
  };

  handleAdmissionFeeRoleChange = (id: number, val: any) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].admissionFee = Math.floor(val * 100) / 100;
    this.setState({ rolePriceArr });
  };

  handleShowAlwaysChange = (id: number) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].showAlways = !rolePriceArr[index].showAlways;
    this.setState({ rolePriceArr });
  };

  handleAddPriceRole = () => {
    this.setState({ showAddRolePricing: true });
  };

  handleRolePriceSelect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = true;
    rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
    this.setState({ rolePriceArr });
  };

  handleRolePriceUnselect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = false;
    this.setState({ rolePriceArr });
  };

  handleUpdateTitle = (val: string) => {
    this.setState({ subscriptionTitle: val });
  };

  handleUpdateDescription = (val: string) => {
    this.setState({ subscriptionDescription: val });
  };

  handleBookingNoteChange = (val: any) => {
    this.setState({ subscriptionBookingNote: val });
  };

  handleInfinityEndDateChange = (date: string) => {
    this.setState({ infinityEndDate: date });
  };

  handleDateUnitsChange = (val: any) => {
    this.setState({ dateUnit: val });
  };

  handleNoticePeriodChange = (val: any) => {
    this.setState({ noticePeriod: val });
  };

  handleBranchRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ branchRoleArr: newRoleArr });
  };

  handleBranchRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ branchRoleArr: newRoleArr });
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ roleArr: newRoleArr });
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ roleArr: newRoleArr });
  };

  toggleMinAge = () => {
    this.setState({ hasMinAge: !this.state.hasMinAge });
  };

  toggleMaxAge = () => {
    this.setState({ hasMaxAge: !this.state.hasMaxAge });
  };

  handleMaxAgeChange = (val: number) => {
    this.setState({ maxAge: val });
  };

  handleMinAgeChange = (val: number) => {
    this.setState({ minAge: val });
  };

  toggleHasExpireDate = () => {
    this.setState({ hasExpireDate: !this.state.hasExpireDate });
  };

  handleBillingPeriodChange = (val: number) => {
    this.setState({ billingPeriod: val });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    const selectedRolesWithDefault =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "guest" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) => currRole.id !== "guest" && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    const selectedRolesWithMemberOfBranch =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "public" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) =>
                !["public", "guest"].includes(currRole.id) && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Kursverwaltung"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course?showSub=true`}
            />

            <Title title="Mitgliedschaft erstellen" />
            <div className="subscription-edit-container">
              <SubTitle
                title="Name"
                helpText="Der Mitgliedschaftsname wird jedem der diese Mitgliedschaft sieht angezeigt."
              />
              <Input
                name="subscription-name-input"
                value={this.state.subscriptionTitle}
                placeholder="Mitgliedschaftsname"
                onChange={this.handleUpdateTitle}
              />
              <SubTitle
                title="Beschreibung"
                helpText="Die Beschreibung wird jedem der diese Mitgliedschaft sieht angezeigt."
              />
              <Input
                name="subscription-description-input"
                value={this.state.subscriptionDescription}
                placeholder="Mitgliedschaftsbeschreibung"
                onChange={this.handleUpdateDescription}
              />
              <SubTitle
                title="Erlaubte Rollen"
                helpText={`Die ausgewählten Rollen können diese Mitgliedschaft buchen. Um das Buchen von unregistrierten Benutzern zu erlauben, wähle "Jeder".`}
              />
              <MultiSelector
                searchTextPlaceholder="Rolle"
                arr={this.state.roleArr}
                onSelect={this.handleRoleSelect}
                onUnselect={this.handleRoleUnselect}
              />
              <InfoContainer
                active={selectedRolesWithDefault.length > 0}
              >{`Die Einstellung "Jeder" beinhaltet die ${
                selectedRolesWithDefault.length === 1 ? "Rolle" : "Rollen"
              } "${selectedRolesWithDefault
                .slice(
                  0,
                  selectedRolesWithDefault.length === 1
                    ? 1
                    : selectedRolesWithDefault.length - 1
                )
                .join('", "')}${
                selectedRolesWithDefault.length > 1
                  ? `" und "${
                      selectedRolesWithDefault[
                        selectedRolesWithDefault.length - 1
                      ]
                    }`
                  : ""
              }". Daher ${
                selectedRolesWithDefault.length === 1 ? "muss" : "müssen"
              } sie nicht extra ausgewählt werden.`}</InfoContainer>
              <InfoContainer
                active={selectedRolesWithMemberOfBranch.length > 0}
              >{`Die Einstellung "Registrierte Benutzer" beinhaltet die ${
                selectedRolesWithMemberOfBranch.length === 1
                  ? "Rolle"
                  : "Rollen"
              } "${selectedRolesWithMemberOfBranch
                .slice(
                  0,
                  selectedRolesWithMemberOfBranch.length === 1
                    ? 1
                    : selectedRolesWithMemberOfBranch.length - 1
                )
                .join('", "')}${
                selectedRolesWithMemberOfBranch.length > 1
                  ? `" und "${
                      selectedRolesWithMemberOfBranch[
                        selectedRolesWithMemberOfBranch.length - 1
                      ]
                    }`
                  : ""
              }". Daher ${
                selectedRolesWithMemberOfBranch.length === 1 ? "muss" : "müssen"
              } sie nicht extra ausgewählt werden.`}</InfoContainer>
              {/* <SubTitle
                title="Abrechungszeitraum (in Monaten)"
                helpText="Alle x Monate wird der eingestellte Betrag eingezogen."
              />

              <Input
                value={this.state.billingPeriod}
                onChange={this.handleBillingPeriodChange}
                type="number"
                min={1}
              /> */}
              <SubTitle
                title="Preis pro Abrechnungszeitraum"
                helpText="Der Preis, der pro Zeiteiheit abgerechnet wird."
              />
              <table className="table-price">
                <thead>
                  <tr>
                    <th>Löschen</th>
                    <th>Rolle</th>
                    <th>Preis (in €)</th>
                    <th>Aufnahmegebühr (in €)</th>
                    <th>Immer anzeigen</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rolePriceArr
                    .filter((role: any) => role.selected)
                    .map((role: any) => (
                      <tr>
                        {role.id !== "default" ? (
                          <td
                            onClick={() => {
                              this.handleRolePriceUnselect(role.id);
                            }}
                            className="pointer center"
                          >
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>
                          <div className="flex">
                            {role.name}{" "}
                            {role.id === "default" && (
                              <div className="info-icon-container margin-left">
                                <FontAwesomeIcon
                                  icon={faQuestionCircle}
                                  size="1x"
                                  onClick={() => {}}
                                  className="back-element-icon"
                                />
                                <div className="info-text-container">
                                  <div className="info-text-triangle"></div>
                                  <div className="info-text-inner-container">
                                    Der Preis, wenn das Mitglied keine der
                                    ausgewählten Rollen hat. Auch der Preis für
                                    Gäste und nicht Mitglieder.
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <Input
                            value={role.val}
                            onChange={(val: number) => {
                              this.handlePriceRoleChange(role.id, val);
                            }}
                            type="number"
                            placeholder="Preis"
                            className="no-margin"
                          />
                        </td>
                        <td>
                          <Input
                            value={role.admissionFee}
                            onChange={(val: number) => {
                              this.handleAdmissionFeeRoleChange(role.id, val);
                            }}
                            type="number"
                            placeholder="Preis"
                            className="no-margin"
                          />
                        </td>
                        <td>
                          <TextCheckbox
                            val={role.showAlways}
                            onChange={() => {
                              this.handleShowAlwaysChange(role.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  <tr className="bold">
                    <td
                      className="center pointer"
                      onClick={this.handleAddPriceRole}
                    >
                      +
                      <div
                        className={`multiselector-add-container${
                          this.state.showAddRolePricing
                            ? " multiselector-show-add-container"
                            : ""
                        }`}
                        ref={this.wrapperRef}
                      >
                        <div className="multiselector-add-text-container">
                          <input
                            placeholder="Rolle"
                            value={this.state.searchTextPriceRole}
                            onChange={this.handleChangeSearchTextPriceRole}
                          />
                          <div className="icon-container">
                            <FontAwesomeIcon icon={faSearch} size="1x" />
                          </div>
                        </div>
                        <div className="multiselector-scroll-container">
                          {this.state.rolePriceArr
                            .filter(
                              (item: any) =>
                                !item.selected &&
                                item.name
                                  .toLowerCase()
                                  .includes(
                                    this.state.searchTextPriceRole.toLowerCase()
                                  )
                            )
                            .map((item: any) => (
                              <div
                                className="multiselector-select-element-container"
                                onClick={() => {
                                  this.handleRolePriceSelect(item.id);
                                }}
                                key={item.id}
                              >
                                <div
                                  className="point"
                                  style={{ backgroundColor: item.color }}
                                ></div>
                                {item.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                </tbody>
              </table>
              <SubTitle
                title="Buchungsnotiz"
                helpText="Text der bei einer erfolgreichen Buchung angezeigt und per E-Mail mitgesendet wird."
              />
              <TextArea
                value={this.state.subscriptionBookingNote}
                onChange={this.handleBookingNoteChange}
                placeholder="Buchungsnotiz"
              />
              <SubTitle
                title="Mindestalter"
                helpText="Ab diesem Alter kann die Mitgliedschaft gebucht werden. (Dieses Alter kann die Mitgliedschaft buchen)"
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasMinAge}
                    onChange={this.toggleMinAge}
                  />
                </div>
                {this.state.hasMinAge ? (
                  <Input
                    value={this.state.minAge}
                    onChange={this.handleMinAgeChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Mindestalter
                  </div>
                )}
              </div>
              <SubTitle
                title="Maximalalter"
                helpText="Nach diesem Alter kann die Mitgliedschaft nicht mehr gebucht werden. (Dieses Alter kann die Mitgliedschaft buchen)"
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasMaxAge}
                    onChange={this.toggleMaxAge}
                  />
                </div>
                {this.state.hasMaxAge ? (
                  <Input
                    value={this.state.maxAge}
                    onChange={this.handleMaxAgeChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Maximalalter
                  </div>
                )}
              </div>
              <div className="small-space"></div>
              {/* <SubTitle
                title="Auslaufdatum"
                helpText="An diesem Tag wird die Mitgliedschaft auslaufen (Dieser Tag findet noch statt). Ein angebrochener Monat wird als vollständig abgerechnet."
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasExpireDate}
                    onChange={this.toggleHasExpireDate}
                  />
                </div>
                {this.state.hasExpireDate ? (
                  <input
                    type="date"
                    className="input-container expireDate-container"
                    value={this.state.infinityEndDate}
                    onChange={(val: any) => {
                      this.handleInfinityEndDateChange(val.target.value);
                    }}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Auslaufdatum
                  </div>
                )}
              </div> */}

              <SubTitle
                title="Kündigungsfrist"
                helpText="Frist bis zum Wirksamwerden der Kündigung."
              />
              <div className="flex two-input-container">
                <DropDown
                  options={dateUnits}
                  onChange={this.handleDateUnitsChange}
                  value={this.state.dateUnit}
                  name="locations-dropdown"
                />
                <Input
                  value={this.state.noticePeriod}
                  onChange={this.handleNoticePeriodChange}
                  type="number"
                  min={0}
                  step={1}
                />
              </div>
              <SubTitle title="diese Rollen automatisch verwalten" />
              <MultiSelector
                searchTextPlaceholder="Rolle"
                onSelect={this.handleBranchRoleSelect}
                onUnselect={this.handleBranchRoleUnselect}
                arr={this.state.branchRoleArr}
                className="margin-bottom"
              />
              <ErrorMessage message={this.state.errorMessage} />
              <SecButton
                change={true}
                color="green"
                loading={this.state.createLoading}
                onClick={this.createSubscription}
                title="Erstellen"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
