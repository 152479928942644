import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./Course.css";
import "./QrCodeScanner.css";
import "../../App.css";
import { MainContext } from "../../contexts/MainContext";
import QrReader from "react-qr-reader";
import Title from "../../components/Title";
import { fetchRetry } from "../../functions/request";
import {
  dateToFullStr,
  dateToSmallStr,
  firebaseDateToDateObj,
  priceToStr,
} from "../../functions/utils";
import PaymentStatus from "../../components/PaymentStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseBooking: string;
  }>;

export default class QrCodeScanner extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    delay: number;
    result: string;
    courseBooking: any;
    courseBookingLoading: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      delay: 100,
      result: "",
      courseBooking: null,
      courseBookingLoading: false,
    };
  }

  componentDidMount = () => {};

  handleScan = (data: any) => {
    if (data) {
      if (this.state.result !== data) {
        // request code
        this.requestCourseBookings(data);
      }
      this.setState({
        result: data,
      });
    }
  };

  requestCourseBookings = (simpleCourseBookingId: string) => {
    this.setState({ courseBookingLoading: true, courseBooking: null });
    fetchRetry(
      "getCourseBookingFromSimpleId",
      {
        simpleId: simpleCourseBookingId,
      },
      1,
      5
    )
      .then(this.handleCourseBookingSuccess)
      .catch(this.handleError);
  };

  handleCourseBookingSuccess = ({ data }: any) => {
    if (data.success) {
      const courseBooking = data.data;
      this.setState({
        courseBooking: courseBooking,
        courseBookingLoading: false,
      });
    } else {
      this.context.createInfo("Ungültiger QR-Code.", "info", 4);
      this.setState({
        courseBookingLoading: false,
      });
    }
  };

  unselectCourseBooking = () => {
    this.setState({ courseBooking: null, result: "" });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="QR-Code Scanner" />
            <div className="inner-qr-code-container">
              <div className="qr-code-center-container">
                <div className="qr-code-scanner-container">
                  <QrReader
                    delay={this.state.delay}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    className="qr-code-scanner"
                  />
                </div>
              </div>
              <table
                className={`booking-information-table${
                  !this.state.courseBooking
                    ? " booking-information-table-disabled"
                    : ""
                }`}
              >
                {!this.state.courseBooking && (
                  <div className="table-disabled-overlay">
                    {this.state.courseBookingLoading ? (
                      <ClipLoader
                        color={"lightgray"}
                        size={30}
                        loading={true}
                      />
                    ) : (
                      <span>Bitte scanne einen Buchungs-QR-Code.</span>
                    )}
                  </div>
                )}
                <tbody>
                  <tr className="no-border">
                    <td>Gebucht von</td>
                    <td className="table-field-icon-outer-container">
                      {this.state.courseBooking?.user?.fName}{" "}
                      {this.state.courseBooking?.user?.lName}
                      {this.state.courseBooking && (
                        <div
                          className="table-close-icon-container"
                          onClick={this.unselectCourseBooking}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      )}
                    </td>
                  </tr>
                  {this.state.courseBooking?.bookForFamily && (
                    <tr>
                      <td>Gebucht für</td>
                      <td className="table-field-icon-outer-container">
                        {this.state.courseBooking?.familyUser?.fName}{" "}
                        {this.state.courseBooking?.familyUser?.lName}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Status</td>
                    <td>
                      {this.state.courseBooking?.active
                        ? "Gebucht"
                        : this.state.courseBooking?.refunded
                        ? "Storniert"
                        : "Nicht Gebucht"}
                    </td>
                  </tr>
                  <tr>
                    <td>Methode</td>
                    <td>
                      {this.state.courseBooking?.method
                        ? this.state.courseBooking?.method
                        : "-"}
                    </td>
                  </tr>
                  {this.state.courseBooking?.price !== null && (
                    <tr>
                      <td>Preis</td>
                      <td>{priceToStr(this.state.courseBooking?.price)}€</td>
                    </tr>
                  )}
                  {this.state.courseBooking?.bookerId && (
                    <tr>
                      <td>Eingetragen von</td>
                      <td>{`${this.state.courseBooking?.booker?.fName} ${this.state.courseBooking?.booker?.lName}`}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Bezahl Status</td>
                    <td>
                      <div className="flex">
                        <PaymentStatus
                          type={this.state.courseBooking?.paymentStatus}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Buchungszeitpunkt</td>
                    <td>
                      {dateToSmallStr(
                        firebaseDateToDateObj(
                          this.state.courseBooking?.created_at
                        )
                      )}
                    </td>
                  </tr>
                  {this.state.courseBooking?.refunded ? (
                    <>
                      <tr>
                        <td>Stornierungs Status</td>
                        <td>
                          <div className="flex">
                            <PaymentStatus
                              type={this.state.courseBooking?.refundStatus}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Stornierungszeitpunkt</td>
                        <td>
                          {dateToSmallStr(
                            firebaseDateToDateObj(
                              this.state.courseBooking?.refundCreatedAt
                            )
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {this.state.courseBooking?.cancellationDays != null && (
                        <>
                          <tr>
                            <td>Stornierungsfrist</td>
                            <td>
                              {" bis "}
                              {dateToFullStr(
                                firebaseDateToDateObj(
                                  this.state.courseBooking?.cancellationDate
                                )
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                  <tr>
                    <td>Konto</td>
                    <td>
                      {this.state.courseBooking?.userId
                        ? "Registriert"
                        : "Gast"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <Link
                        to={`/club/${this.state.courseBooking?.club?.id}/branch/${this.state.courseBooking?.branch?.id}/participants/${this.state.courseBooking?.courseId}/participant/${this.state.courseBooking?.courseBookingId}`}
                        className="black no-underline"
                      >
                        Details anzeigen
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
