import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import "./CourseParticipant.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import TestMode from "../../components/TestMode";
import SubTitle from "../../components/SubTitle";
import Input from "../../components/Input";
import {
  dateToFullStr,
  dateToSmallStr,
  firebaseDateToDateObj,
  getMonthNum,
  makeid,
  monthToDate,
  priceToStr,
  reformatDate,
  reformatDateReverse,
} from "../../functions/utils";
import Title from "../../components/Title";
import PaymentStatus from "../../components/PaymentStatus";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { MainContext } from "../../contexts/MainContext";
import { getPaymentTable } from "../../functions/getPaymentTable";
import MonthSelector from "../../components/MonthSelector";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBookingStatus } from "../../functions/getBookingStatus";
import ClipLoader from "react-spinners/ClipLoader";
import qs from "qs";
import { molliePaymentStatus } from "../../services/constants";
import PaymentMethod from "../../components/PaymentMethod";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    subscriptionId: string;
    subscriptionParticipantId: string;
  }>;

export default class SubscriptionParticipant extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    subscriptionBookingId: string;
    branchLoaded: boolean;
    branch: any;
    subscriptionBookingLoaded: boolean;
    subscriptionBookingData: any;
    email: string;
    fName: string;
    lName: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    redirect: null | string;
    errorMsgCancellation: null | string;
    cancellationLoading: boolean;
    refundLoading: boolean;
    showConfirmationModalCancel: boolean;
    showConfirmationModalRefund: boolean;
    showConfirmationModalRetry: boolean;
    refundNote: string;
    paymentArr: Array<any>;
    startMonthNum: number;
    endMonthNum: number;
    updatePauseLoading: boolean;
    pausedMonths: any;
    cancelMonth: number;
    refundMonth: number;
    retryMonth: number;
    userId: string;
    backLink: string | null | any;
    errorMessageUserData: null | string;
    updateLoadingUserData: boolean;
    changeUserData: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      subscriptionId: this.props.match.params.subscriptionId,
      subscriptionBookingId: this.props.match.params.subscriptionParticipantId,
      branchLoaded: false,
      branch: {},
      subscriptionBookingLoaded: false,
      subscriptionBookingData: {},
      email: "",
      fName: "",
      lName: "",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      redirect: null,
      errorMsgCancellation: null,
      cancellationLoading: false,
      refundLoading: false,
      showConfirmationModalCancel: false,
      showConfirmationModalRefund: false,
      showConfirmationModalRetry: false,
      refundNote: "",
      paymentArr: [],
      startMonthNum: getMonthNum(new Date()) + 1,
      endMonthNum: getMonthNum(new Date()) + 1,
      updatePauseLoading: false,
      pausedMonths: {},
      cancelMonth: 0,
      refundMonth: 0,
      retryMonth: 0,
      userId: "",
      backLink:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).back || null,
      errorMessageUserData: null,
      updateLoadingUserData: false,
      changeUserData: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestSubscriptionBooking();
    this.requestSubscription();
  };

  requestSubscription = () => {
    fetchRetry(
      "getSubscriptionFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleSubscriptionSuccess)
      .catch(this.handleError);
  };

  handleSubscriptionSuccess = ({ data }: any) => {
    if (data.success) {
      const subscriptionData = data.data[0];
      const subscriptionTitle = subscriptionData.title;
      this.context.setCustomPath({
        link: `/club/${this.state.clubId}/branch/${this.state.branchId}/subscription-participants/${this.state.subscriptionId}/participant/${this.state.subscriptionBookingId}`,
        name: subscriptionTitle,
      });
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  requestSubscriptionBooking = () => {
    fetchRetry(
      "getSubscriptionBookingF",
      {
        subscriptionBookingId: this.state.subscriptionBookingId,
      },
      1,
      5
    )
      .then(this.requestSubscriptionsBookingSuccess)
      .catch(this.handleError);
  };

  requestSubscriptionsBookingSuccess = async ({ data }: any) => {
    this.setState({ subscriptionBookingLoaded: true });
    if (data.success) {
      const subscriptionBookingData = data.data;
      // createPayment list
      // reformat subscription Payments
      const paymentArr: Array<any> = getPaymentTable(subscriptionBookingData);

      this.setState(
        {
          paymentArr,
          subscriptionBookingData: subscriptionBookingData,
          fName: subscriptionBookingData?.user?.fName,
          lName: subscriptionBookingData?.user?.lName,
          email: subscriptionBookingData?.user?.email
            ? subscriptionBookingData.user.email
            : "",
          street: subscriptionBookingData?.user?.address?.street
            ? subscriptionBookingData.user.address.street
            : "",
          city: subscriptionBookingData?.user?.address?.city
            ? subscriptionBookingData.user.address.city
            : "",
          houseNumber: subscriptionBookingData?.user?.address?.houseNumber
            ? subscriptionBookingData.user.address.houseNumber
            : "",
          zipcode: subscriptionBookingData?.user?.address?.zipcode
            ? subscriptionBookingData.user.address.zipcode
            : "",
          birthDate: reformatDateReverse(
            subscriptionBookingData?.user?.birthDate
          ),
          phoneNumber: subscriptionBookingData?.user?.phoneNumber
            ? subscriptionBookingData.user.phoneNumber
            : "",
          pausedMonths: subscriptionBookingData.pausedMonths,
          userId: subscriptionBookingData.userId,
        },
        this.checkChangeUserData
      );
    }
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  handleRefundNoteChange = (val: any) => {
    this.setState({ refundNote: val });
  };

  handleCancellateSubscription = (cancelMonth: number) => {
    this.setState({
      showConfirmationModalCancel: true,
      cancelMonth,
    });
  };

  handleCancellationConfirmed = () => {
    this.setState({
      cancellationLoading: true,
      showConfirmationModalCancel: false,
    });
    fetchRetry(
      "cancelSubscriptionAdmin",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        subscriptionBookingId: this.state.subscriptionBookingId,
        cancelNote: this.state.refundNote,
        cancelMonth: this.state.cancelMonth,
      },
      1,
      5
    )
      .then(this.createCancellationSuccess)
      .catch(this.handleError);
  };

  handleRefund = (month: number) => {
    this.setState({
      showConfirmationModalRefund: true,
      refundMonth: month,
    });
  };

  handleRetry = (month: number) => {
    this.setState({
      showConfirmationModalRetry: true,
      retryMonth: month,
    });
  };

  createCancellationSuccess = ({ data }: any) => {
    this.setState({ cancellationLoading: false });
    if (data.success) {
      this.requestSubscriptionBooking();
      this.setState({ errorMsgCancellation: null });
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  handleRefundConfirmed = () => {
    this.setState({
      refundLoading: true,
      showConfirmationModalRefund: false,
    });
    fetchRetry(
      "createSubscriptionMonthRefund",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        subscriptionBookingId: this.state.subscriptionBookingId,
        month: this.state.refundMonth,
      },
      1,
      5
    )
      .then(this.handleRefundSuccess)
      .catch(this.handleError);
  };

  handleRefundSuccess = ({ data }: any) => {
    this.setState({ refundLoading: false });
    if (data.success) {
      this.requestSubscriptionBooking();
      this.setState({ errorMsgCancellation: null });
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  handleRetryConfirmed = () => {
    this.setState({
      refundLoading: true,
      showConfirmationModalRetry: false,
    });
    fetchRetry(
      "retrySubscriptionMonth",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        subscriptionBookingId: this.state.subscriptionBookingId,
        month: this.state.retryMonth,
      },
      1,
      5
    )
      .then(this.handleRetrySuccess)
      .catch(this.handleError);
  };

  handleRetrySuccess = ({ data }: any) => {
    this.setState({ refundLoading: false });
    if (data.success) {
      this.requestSubscriptionBooking();
      this.setState({ errorMsgCancellation: null });
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  hideConfirmationModalCancel = () => {
    this.setState({ showConfirmationModalCancel: false });
  };

  hideConfirmationModalRefund = () => {
    this.setState({ showConfirmationModalRefund: false });
  };

  hideConfirmationModalRetry = () => {
    this.setState({ showConfirmationModalRetry: false });
  };

  handleAddPause = () => {
    this.setState({ updatePauseLoading: true });
    const pausedMonths = { ...this.state.pausedMonths };
    pausedMonths[makeid(32)] = {
      from: this.state.startMonthNum,
      to: this.state.endMonthNum,
    };
    fetchRetry(
      "updateSubscriptionBooking",
      {
        subscriptionBookingId: this.state.subscriptionBookingId,
        pausedMonths,
      },
      1,
      5
    )
      .then(this.updatePauseSuccess)
      .catch(this.handleAddPauseError);
  };

  removePausedMonths = (pausedMonthKey: string) => {
    this.setState({ updatePauseLoading: true });
    const pausedMonths = this.state.pausedMonths;
    pausedMonths[pausedMonthKey] = null;
    fetchRetry(
      "updateSubscriptionBooking",
      {
        subscriptionBookingId: this.state.subscriptionBookingId,
        pausedMonths,
      },
      1,
      5
    )
      .then(this.updatePauseSuccess)
      .catch(this.handleAddPauseError);
  };

  handleAddPauseError = (err: any) => {
    this.setState({ updatePauseLoading: false });
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
    this.handleError(err);
  };

  removePauseSuccess = ({ data }: any) => {
    if (data.success) {
      this.requestSubscriptionBooking();
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  updatePauseSuccess = ({ data }: any) => {
    this.setState({ updatePauseLoading: false });
    if (data.success) {
      this.requestSubscriptionBooking();
    } else {
      this.context.createInfo(data.errorMsgDe, "error", 4);
    }
  };

  updateSubscriptionBookingUserData = () => {
    this.setState({ updateLoadingUserData: true });
    fetchRetry(
      "updateSubscriptionBookingUser",
      {
        subscriptionBookingId: this.state.subscriptionBookingId,
        fName: this.state.fName,
        lName: this.state.lName,
        address: {
          city: this.state.city,
          houseNumber: this.state.houseNumber,
          street: this.state.street,
          zipcode: this.state.zipcode,
        },
        birthDate: reformatDate(this.state.birthDate),
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
      },
      1,
      5
    )
      .then(this.requestSubscriptionBookingUserDataUpdatedSuccess)
      .catch(this.handleError);
  };

  requestSubscriptionBookingUserDataUpdatedSuccess = ({ data }: any) => {
    this.setState({ updateLoadingUserData: false });
    if (data.success) {
      this.requestSubscriptionBooking();
      this.setState({ errorMessageUserData: null });
    } else {
      this.setState({ errorMessageUserData: data.errorMsgDe });
    }
  };

  checkChangeUserData = () => {
    /* eslint eqeqeq: 0 */
    this.setState({
      changeUserData:
        this.state.subscriptionBookingData.user?.fName != this.state.fName ||
        this.state.subscriptionBookingData.user?.lName != this.state.lName ||
        this.state.subscriptionBookingData.user?.email != this.state.email ||
        reformatDateReverse(
          this.state.subscriptionBookingData.user?.birthDate
        ) != this.state.birthDate ||
        this.state.subscriptionBookingData.user?.phoneNumber !=
          this.state.phoneNumber ||
        this.state.subscriptionBookingData.user?.address?.city !=
          this.state.city ||
        this.state.subscriptionBookingData.user?.address?.houseNumber !=
          this.state.houseNumber ||
        this.state.subscriptionBookingData.user?.address?.street !=
          this.state.street ||
        this.state.subscriptionBookingData.user?.address?.zipcode !=
          this.state.zipcode,
    });
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val }, this.checkChangeUserData);
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val }, this.checkChangeUserData);
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val }, this.checkChangeUserData);
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val }, this.checkChangeUserData);
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val }, this.checkChangeUserData);
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val }, this.checkChangeUserData);
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val }, this.checkChangeUserData);
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val }, this.checkChangeUserData);
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value }, this.checkChangeUserData);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded || !this.state.subscriptionBookingLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    const canEdit =
      this.state.branch?.user?.role === "Owner" || this.context.isAdmin;
    const nextMonth = getMonthNum(new Date()) + 1;
    const isCustom = this.state.subscriptionBookingData?.method === "manuell";
    const hasAccount =
      !!this.state.subscriptionBookingData?.userId ||
      this.state.branch?.user?.role !== "Owner";
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModalCancel}
          handleClose={this.hideConfirmationModalCancel}
          title="Bist du sicher?"
          msg={`Willst du diese Mitgliedschaftsbuchung wirklich kündigen (Letzer Monat: ${monthToDate(
            this.state.cancelMonth
          )})? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleCancellationConfirmed}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModalRefund}
          handleClose={this.hideConfirmationModalRefund}
          title="Bist du sicher?"
          msg={`Willst du die Zahlung (Monat: ${monthToDate(
            this.state.refundMonth
          )}) wirklich stornieren?`}
          handleConfirm={this.handleRefundConfirmed}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModalRetry}
          handleClose={this.hideConfirmationModalRetry}
          title="Bist du sicher?"
          msg={`Willst du die Zahlung (Monat: ${monthToDate(
            this.state.retryMonth
          )}) wirklich erneut anstoßen?`}
          handleConfirm={this.handleRetryConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text={
                this.state.backLink ? "zurück" : "zurück zur Teilnehmerliste"
              }
              to={
                this.state.backLink ||
                `/club/${this.state.clubId}/branch/${this.state.branchId}/subscription-participants/${this.state.subscriptionId}`
              }
            />

            <TestMode active={this.state.branch.billing?.mode === "test"} />
            <Title title="Buchungsinformationen" />
            <div>
              <table className="booking-information-table">
                <tbody>
                  <tr className="no-border">
                    <td>Status</td>
                    <td>
                      {getBookingStatus(this.state.subscriptionBookingData)}
                    </td>
                  </tr>
                  {this.state.subscriptionBookingData
                    ?.subscriptionCancelledAt && (
                    <tr>
                      <td>Gekündigt am</td>
                      <td>
                        {dateToFullStr(
                          firebaseDateToDateObj(
                            this.state.subscriptionBookingData
                              .subscriptionCancelledAt
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Methode</td>
                    <td>
                      <PaymentMethod
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={this.state.subscriptionId}
                        courseBookingId={this.state.subscriptionBookingId}
                        method={this.state.subscriptionBookingData.method}
                        refresh={() => {}}
                        selectedMandateId={
                          this.state.subscriptionBookingData?.mandateId
                        }
                        type="subscription"
                      />
                    </td>
                  </tr>
                  {this.state.subscriptionBookingData.price !== null && (
                    <tr>
                      <td>Preis pro Monat</td>
                      <td>
                        {priceToStr(
                          this.state.subscriptionBookingData.montlyPrice
                        )}
                        €
                      </td>
                    </tr>
                  )}
                  {this.state.subscriptionBookingData.bookerId && (
                    <tr>
                      <td>Eingetragen von</td>
                      <td>{`${this.state.subscriptionBookingData.booker?.fName} ${this.state.subscriptionBookingData.booker?.lName}`}</td>
                    </tr>
                  )}

                  <tr>
                    <td>Buchungszeitpunkt</td>
                    <td>
                      {dateToSmallStr(
                        firebaseDateToDateObj(
                          this.state.subscriptionBookingData.created_at
                        )
                      )}
                    </td>
                  </tr>

                  <>
                    {this.state.subscriptionBookingData.cancellationDays !=
                      null && (
                      <>
                        <tr>
                          <td>Kündigungsfrist</td>
                          <td>
                            {" bis "}
                            {dateToFullStr(
                              firebaseDateToDateObj(
                                this.state.subscriptionBookingData
                                  .cancellationDate
                              )
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                  <tr>
                    <td>Konto</td>
                    <td>
                      {this.state.subscriptionBookingData.userId ? (
                        <Link
                          to={`/club/${this.state.clubId}/branch-settings/${this.state.branchId}/users/${this.state.userId}`}
                        >
                          Registriert
                        </Link>
                      ) : (
                        "Gast"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <SubTitle title="Zahlungsstatus" />
              <ErrorMessage message={this.state.errorMsgCancellation} />
              <Input
                value={this.state.refundNote}
                onChange={this.handleRefundNoteChange}
                placeholder="Kündigungsnachricht"
              />
              <table className="course-booking-information-table">
                <thead>
                  <tr>
                    <th>Monat</th>
                    <th>Zahlungsstatus</th>
                    <th>Preis</th>
                    {canEdit && (
                      <th>
                        <div className="table-loading-container">
                          <ClipLoader
                            color={"gray"}
                            size={20}
                            loading={
                              this.state.cancellationLoading ||
                              this.state.refundLoading
                            }
                          />
                        </div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.paymentArr.map((currPayment: any) => (
                    <tr>
                      <td>{currPayment.monthStr}</td>
                      <td>
                        <div>
                          <PaymentStatus type={currPayment.paymentStatus} />
                          {currPayment.refunded && (
                            <div className="margin-left">
                              <PaymentStatus type={currPayment.refundStatus} />
                            </div>
                          )}
                          {currPayment.hasChargebacks && (
                            <div className="margin-left">
                              <PaymentStatus type={"hasChargebacks"} />
                            </div>
                          )}
                        </div>
                      </td>
                      <td>{currPayment.priceStr}</td>
                      {canEdit && (
                        <td className="course-booking-information-btn">
                          {currPayment.monthNum >= nextMonth ? (
                            (!this.state.subscriptionBookingData
                              .subscriptionCancelled ||
                              this.state.subscriptionBookingData.endMonth >=
                                currPayment.monthNum) && (
                              <SecButton
                                className="coursebooking-btn-container cancel-coursebooking-container"
                                onClick={() => {
                                  this.handleCancellateSubscription(
                                    currPayment.monthNum - 1
                                  );
                                }}
                                title="Buchung kündigen"
                              />
                            )
                          ) : (
                            <>
                              {!isCustom && (
                                <>
                                  {molliePaymentStatus[
                                    currPayment.paymentStatus
                                  ].retryable ? (
                                    <SecButton
                                      className="coursebooking-btn-container retry-coursebooking-container"
                                      onClick={() => {
                                        this.handleRetry(currPayment.monthNum);
                                      }}
                                      title="Erneut anstoßen"
                                    />
                                  ) : (
                                    <SecButton
                                      className="coursebooking-btn-container refund-coursebooking-container"
                                      onClick={() => {
                                        this.handleRefund(currPayment.monthNum);
                                      }}
                                      title="Zahlung stornieren"
                                    />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <SubTitle title="Pausierungen" />
              <table className="course-booking-information-table pause-table">
                <thead>
                  <tr>
                    {canEdit && <th>Löschen</th>}
                    <th colSpan={4} style={{ width: "100%" }}>
                      Zeitraum
                    </th>
                    {canEdit && (
                      <th>
                        <div className="table-loading-container">
                          <ClipLoader
                            color={"gray"}
                            size={20}
                            loading={this.state.updatePauseLoading}
                          />
                        </div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(this.state.pausedMonths).map((pauseKey: any) => (
                    <>
                      {this.state.pausedMonths[pauseKey] && (
                        <tr>
                          {canEdit && (
                            <td>
                              <div
                                onClick={() => {
                                  this.removePausedMonths(pauseKey);
                                }}
                                className="pointer"
                              >
                                <FontAwesomeIcon icon={faTimes} size="lg" />
                              </div>
                            </td>
                          )}
                          <td>von </td>
                          <td>
                            {monthToDate(
                              this.state.pausedMonths[pauseKey].from
                            )}
                          </td>
                          <td>bis </td>
                          <td colSpan={2}>
                            {monthToDate(this.state.pausedMonths[pauseKey].to)}
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  {canEdit && (
                    <tr>
                      <td></td>
                      <td>von</td>
                      <td>
                        <MonthSelector
                          minMonthNum={nextMonth}
                          maxMonthNum={nextMonth + 12}
                          value={this.state.startMonthNum}
                          onSelect={(monthNum: number) => {
                            this.setState({ startMonthNum: monthNum });
                            if (monthNum > this.state.endMonthNum) {
                              this.setState({ endMonthNum: monthNum });
                            }
                          }}
                        />
                      </td>
                      <td>bis</td>
                      <td>
                        <MonthSelector
                          minMonthNum={this.state.startMonthNum}
                          maxMonthNum={nextMonth + 12}
                          value={this.state.endMonthNum}
                          onSelect={(monthNum: number) => {
                            this.setState({ endMonthNum: monthNum });
                          }}
                        />
                      </td>
                      {canEdit && (
                        <td>
                          <SecButton
                            className="pause-course-container"
                            onClick={this.handleAddPause}
                            title="Mitgliedschaftsbuchung pausieren"
                          />
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Title title="Teilnehmer Informationen" />
            <div>
              <SubTitle title={"Name"} />
              <div className="course-time-input-container">
                <Input
                  value={this.state.fName}
                  onChange={this.handleFNameChange}
                  placeholder="Vorname"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.lName}
                  onChange={this.handleLNameChange}
                  placeholder="Nachname"
                  disabled={hasAccount}
                />
              </div>
              <SubTitle title={`E-Mail ${isCustom ? "(optional)" : ""}`} />
              <Input
                value={this.state.email}
                onChange={this.handleEmailChange}
                placeholder="E-Mail"
                disabled={hasAccount}
              />
              <SubTitle title={`Adresse ${isCustom ? "(optional)" : ""}`} />
              <div className="course-time-input-container">
                <Input
                  value={this.state.street}
                  onChange={this.handleStreetChange}
                  placeholder="Straße"
                  className="street-input"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.houseNumber}
                  onChange={this.handleHouseNumberChange}
                  placeholder="Nr."
                  className="house-number-input"
                  disabled={hasAccount}
                />
              </div>
              <div className="profile-location-container">
                <Input
                  value={this.state.zipcode}
                  onChange={this.handleZipcodeChange}
                  placeholder="PLZ"
                  className="zipcode-input"
                  disabled={hasAccount}
                />
                <Input
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  placeholder="Stadt"
                  className="city-input"
                  disabled={hasAccount}
                />
              </div>
              <SubTitle
                title={`Telefonnummer ${isCustom ? "(optional)" : ""}`}
              />
              <Input
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumberChange}
                placeholder="Telefonnummer"
                disabled={hasAccount}
              />
              <SubTitle
                title={`Geburtsdatum ${isCustom ? "(optional)" : ""}`}
              />
              <input
                type="date"
                className="input-container"
                value={this.state.birthDate}
                onChange={this.handleBirthDateChange}
                disabled={hasAccount}
              />
              {!hasAccount && isCustom && canEdit && (
                <>
                  <ErrorMessage message={this.state.errorMessageUserData} />
                  <SecButton
                    change={this.state.changeUserData}
                    color="green"
                    loading={this.state.updateLoadingUserData}
                    onClick={this.updateSubscriptionBookingUserData}
                    title="Speichern"
                    className="btn save-courseBooking-btn"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
