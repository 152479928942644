import React from "react";
import "./CourseTypeSelector.css";

export default class CourseTypeSelector extends React.Component<
  { courseType: number; onChange: any },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="course-type-container">
          <div className={`course-type-background course-type-background-${this.props.courseType}`}></div>
          <div className="course-type-inner-container">
            <div
              onClick={() => {
                this.props.onChange(1);
              }}
              className={
                this.props.courseType === 1
                  ? "course-type-selected course-type"
                  : "course-type"
              }
            >
              Einmaliger Kurs
            </div>
            <div
              onClick={() => {
                this.props.onChange(2);
              }}
              className={
                this.props.courseType === 2
                  ? "course-type course-type-selected"
                  : "course-type"
              }
            >
              Blockkurs
            </div>
            <div
              onClick={() => {
                this.props.onChange(3);
              }}
              className={
                this.props.courseType === 3
                  ? "course-type course-type-selected"
                  : "course-type"
              }
            >
              Fortlaufender Kurs
            </div>
          </div>
        </div>
      </>
    );
  }
}
