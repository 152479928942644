import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import {
  dateDurationToFullStr,
  dateToFullStr,
  firebaseDateToDateObj,
  getAgeStr,
} from "../functions/utils";

export default class BlockCourseElement extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    title: string;
    description: string;
    when: string;
    courses: Array<any>;
    freePlaces: number;
    price: string;
    imgUrl: string;
    bookableFrom: any;
    userAllowedToBook: boolean;
    minAge: number | null;
    maxAge: number | null;
    requestMembershipUrl: string;
    memLink: string | null;
  },
  { showMore: boolean }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    const bookableFrom = this.props.bookableFrom
      ? this.props.bookableFrom > new Date()
        ? this.props.bookableFrom
        : null
      : null;
    const hasFreePlaces = this.props.freePlaces > 0;
    return (
      <div className={"course-container"} id={this.props.courseId}>
        <div>
          {!this.props.imgUrl ? (
            <div className="course-img">
              <FontAwesomeIcon
                icon={faImage}
                size="6x"
                className="image-icon"
              />
            </div>
          ) : (
            <img
              className="course-img"
              src={this.props.imgUrl}
              alt="Kursbild"
            />
          )}
        </div>
        <div className="course-info-container">
          <div className="course-info-inner-container">
            <div className="course-title-container">{this.props.title}</div>
            <div>{this.props.description}</div>
            <div className="course-text-container">
              Termine:{" "}
              <span className="normal-weight">{this.props.courses.length}</span>
            </div>
            <div className="course-text-container">
              Wann:{" "}
              <span className="normal-weight">
                {this.props.courses
                  .slice(0, this.state.showMore ? this.props.courses.length : 3)
                  .map((course: any, index: number) => (
                    <div key={index}>
                      -{" "}
                      {dateDurationToFullStr(
                        firebaseDateToDateObj(course.startTime),
                        firebaseDateToDateObj(course.endTime)
                      )}
                    </div>
                  ))}
              </span>
              {this.props.courses.length > 3 && (
                <div
                  className="more-info-container"
                  onClick={this.toggleShowMore}
                >
                  <div className="more-info-inner-container">
                    {!this.state.showMore ? (
                      <FontAwesomeIcon icon={faChevronDown} size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faChevronUp} size="sm" />
                    )}
                  </div>
                  {this.state.showMore ? "weniger anzeigen" : "mehr anzeigen"}
                </div>
              )}
            </div>
            <div className="course-text-container">
              Freie Plätze:{" "}
              <span className="normal-weight">
                {this.props.freePlaces > 0 ? this.props.freePlaces : 0}
              </span>
            </div>
            {(this.props.minAge || this.props.maxAge) && (
              <div className="course-text-container">
                Alter:{" "}
                <span className="normal-weight">
                  {getAgeStr(this.props.minAge, this.props.maxAge)}
                </span>
              </div>
            )}
            <div className="course-text-container">
              Preis: <span className="normal-weight">{this.props.price}€</span>
            </div>
          </div>
          <div className="course-book-outer-container">
            <Link
              to={`/club/${this.props.clubId}/branch/${this.props.branchId}/course/${this.props.courseId}`}
              className={`course-book-btn no-underline${
                bookableFrom || !this.props.userAllowedToBook || !hasFreePlaces
                  ? " course-book-btn-gray"
                  : ""
              }`}
            >
              {!this.props.userAllowedToBook
                ? "Nur für Mitglieder buchbar"
                : bookableFrom
                ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                : hasFreePlaces
                ? this.context.userData
                  ? "Angebot buchen"
                  : "Angebot als Gast buchen"
                : "Ausgebucht"}
            </Link>
            {this.props.memLink && (
              <div className="get-mem-link">
                <a href={this.props.memLink}>Jetzt Mitglied werden</a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
