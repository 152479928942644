import React from "react";
import "./VertNavbar.css";
import "./ClubNavbar.css";

import ClubSettingsNavbarElement from "./elements/ClubSettingsNavbarElement";
import LogoNavbarElement from "./elements/LogoNavbarElement";
import UserNavbarElement from "./elements/UserNavbarElement";
import BranchNavbarElement from "./elements/BranchNavbarElement";
import { MainContext } from "../../contexts/MainContext";
import {
  faBoxOpen,
  faCalendarAlt,
  faCogs,
  faDiceD20,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default class ClubNavbar extends React.Component<
  {
    selectedScreen: string;
    showMobile?: boolean;
    hideMobileNav: any;
    clubId: string;
    isAdmin: boolean;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`nav-background ${
            this.props.showMobile && " show-nav-background"
          }`}
          onClick={this.props.hideMobileNav}
        ></div>
        <nav
          className={`vert-navbar br-navbar ${
            this.props.showMobile && " vert-navbar-mobile"
          }`}
        >
          <LogoNavbarElement />
          <div className="vert-navbar-scrollable">
            <UserNavbarElement to={`/club/${this.props.clubId}/profile`} />
            <ClubSettingsNavbarElement
              title="Verein"
              icon={faDiceD20}
              selected={this.props.selectedScreen === "club"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}`}
            />
            <BranchNavbarElement
              title="Kurse"
              icon={faCalendarAlt}
              selected={this.props.selectedScreen === "course"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/course`}
            />
            {this.context.user && (
              <BranchNavbarElement
                title="Meine Kurse"
                icon={faBoxOpen}
                selected={this.props.selectedScreen === "mycourses"}
                onClick={this.props.hideMobileNav}
                linkTo={`/club/${this.props.clubId}/mycourses`}
              />
            )}
            {this.props.isAdmin && (
              <BranchNavbarElement
                title="Einstellungen"
                icon={faCogs}
                selected={this.props.selectedScreen === "settings"}
                onClick={this.props.hideMobileNav}
                linkTo={`/club-settings/${this.props.clubId}/users`}
              />
            )}
          </div>
          {this.context.isAdmin && (
            <Link
              to={`/clubsearch`}
              className={"navbar-element navbar-back-element"}
              onClick={this.props.hideMobileNav}
            >
              zur Vereinsübersicht
            </Link>
          )}
        </nav>
      </>
    );
  }
}
