import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { storage } from "../../services/firebase";
import "./EditSubscription.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  dateToStr,
  dateToStrInput,
  findWithAttr,
  firebaseDateToDateObj,
} from "../../functions/utils";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import MultiSelector from "../../components/MultiSelector";
import DropDown from "../../components/DropDown";
import SubTitle from "../../components/SubTitle";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../../contexts/MainContext";
import { dateUnits, dateUnitsArr } from "../../services/constants";
import Switch from "../../components/Switch";
import ErrorMessage from "../../components/ErrorMessage";
import Title from "../../components/Title";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import InfoContainer from "../../components/InfoContainer";
import TextCheckbox from "../../components/TextCheckbox";
import SubTitleTwo from "../../components/SubTitleTwo";
import { faImage, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import TextArea from "../../components/TextArea";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    subscriptionId: string;
  }>;

export default class EditSubscription extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    branchLoaded: boolean;
    subscriptionLoaded: boolean;
    updateLoading: boolean;
    branch: any;
    subscription: any;
    subscriptionTitle: string;
    subscriptionDescription: string;
    subscriptionBookingNote: string;
    change: boolean;
    roleArr: Array<any>;
    branchRoleArr: Array<any>;
    rolePriceArr: Array<any>;
    subscriptionImgProgress: number;
    previewUrl: null | string;
    subscriptionImgRemoved: boolean;
    infinityEndDate: string;
    noticePeriod: number;
    errorMessage: string | null;
    errorMessageDelete: null | string;
    deleteLoading: boolean;
    redirect: null | string;
    showAddRolePricing: boolean;
    searchTextPriceRole: string;
    showConfirmationModal: boolean;
    cancellationDays: number;
    negativeDates: Array<any>;
    sectorEndDate: any;
    sectorStartDate: any;
    editSectorErrMsg: string | null;
    selectedDates: Array<string>;
    setFollowUpImage: boolean;
    setFollowUpName: boolean;
    setFollowUpDescription: boolean;
    setFollowUpLocation: boolean;
    setFollowUpPlaces: boolean;
    setFollowUpRoles: boolean;
    setFollowUpPrice: boolean;
    setFollowUpBookingnote: boolean;
    setFollowUpCancellation: boolean;
    setBookableFrom: boolean;
    setVisibleFrom: boolean;
    setTags: boolean;
    setAllowBookForFamily: boolean;
    updateInRowLoading: boolean;
    errorMessageUpdateSubscriptionsInRow: string | null;
    hasVisibleFromAllowed: boolean;
    visibleDays: number;
    hasBookableFromAllowed: boolean;
    bookableDays: number;
    showConfirmModalDeleteInRow: boolean;
    deleteSubscriptionsArr: Array<any>;
    hasMinAge: boolean;
    hasMaxAge: boolean;
    minAge: number;
    maxAge: number;
    setMinAge: boolean;
    setMaxAge: boolean;
    showNotificationModal: boolean;
    updateNotoficationsLoading: boolean;
    allowBookForFamily: boolean;
    dateUnit: string;
    hasExpireDate: boolean;
    billingPeriod: number;
  }
> {
  static contextType = MainContext;
  private wrapperRef: any;

  constructor(props: ComposedProps) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      subscriptionId: this.props.match.params.subscriptionId,
      branchLoaded: false,
      subscriptionLoaded: false,
      updateLoading: false,
      branch: {},
      subscription: {},
      subscriptionTitle: "",
      subscriptionDescription: "",
      subscriptionBookingNote: "",
      change: false,
      roleArr: [],
      branchRoleArr: [],
      rolePriceArr: [],
      subscriptionImgProgress: 0,
      previewUrl: null,
      subscriptionImgRemoved: false,
      infinityEndDate: "",
      noticePeriod: 1,
      errorMessage: null,
      errorMessageDelete: null,
      deleteLoading: false,
      redirect: null,
      showAddRolePricing: false,
      searchTextPriceRole: "",
      showConfirmationModal: false,
      cancellationDays: 0,
      negativeDates: [],
      sectorEndDate: null,
      sectorStartDate: null,
      editSectorErrMsg: null,
      selectedDates: [],
      setFollowUpImage: false,
      setFollowUpName: false,
      setFollowUpDescription: false,
      setFollowUpLocation: false,
      setFollowUpPlaces: false,
      setFollowUpRoles: false,
      setFollowUpPrice: false,
      setFollowUpBookingnote: false,
      setFollowUpCancellation: false,
      setBookableFrom: false,
      setVisibleFrom: false,
      setTags: false,
      setAllowBookForFamily: false,
      updateInRowLoading: false,
      errorMessageUpdateSubscriptionsInRow: null,
      hasVisibleFromAllowed: false,
      visibleDays: 0,
      hasBookableFromAllowed: false,
      bookableDays: 0,
      showConfirmModalDeleteInRow: false,
      deleteSubscriptionsArr: [],
      hasMinAge: false,
      hasMaxAge: false,
      minAge: 0,
      maxAge: 0,
      setMinAge: false,
      setMaxAge: false,
      showNotificationModal: false,
      updateNotoficationsLoading: false,
      allowBookForFamily: false,
      dateUnit: "3",
      hasExpireDate: false,
      billingPeriod: 1,
    };
  }

  componentDidMount = async () => {
    await Promise.all([this.requestBranch(), this.requestSubscription()]).then(
      () => {
        this.handleRoleArr();
      }
    );
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  handleClickOutside(event: any) {
    if (this.state.showAddRolePricing) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.hideAddRolePricingWindow();
      }
    }
  }

  hideAddRolePricingWindow = () => {
    this.setState({ showAddRolePricing: false });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  requestSubscription = async () => {
    await fetchRetry(
      "getSubscriptionFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleSubscriptionSuccess)
      .catch(this.handleError);
  };

  handleSubscriptionSuccess = ({ data }: any) => {
    this.setState({ subscriptionLoaded: true });
    if (data.success) {
      const subscriptionData = data.data[0];
      const subscriptionsSelectedDays = subscriptionData.subscriptions
        ? subscriptionData.subscriptions.map((subscription: any) => {
            return dateToStr(firebaseDateToDateObj(subscription.startTime));
          })
        : [];

      this.setState(
        {
          subscription: subscriptionData,
          subscriptionTitle: subscriptionData.title,
          subscriptionDescription: subscriptionData.description,
          subscriptionBookingNote: subscriptionData.bookingMailMessage,
          selectedDates: subscriptionsSelectedDays,
          infinityEndDate: subscriptionData.endTime
            ? dateToStrInput(firebaseDateToDateObj(subscriptionData.endTime))
            : "",
          noticePeriod: subscriptionData.noticePeriod,
          dateUnit:
            dateUnitsArr.indexOf(subscriptionData.noticePeriodDateUnit) +
            1 +
            "",
          cancellationDays:
            subscriptionData.cancellationDays !== null
              ? subscriptionData.cancellationDays
              : 0,
          visibleDays:
            subscriptionData.visibleDays !== null
              ? subscriptionData.visibleDays
              : 0,
          hasVisibleFromAllowed: subscriptionData.visibleDays !== null,
          bookableDays:
            subscriptionData.bookableDays !== null
              ? subscriptionData.bookableDays
              : 0,
          hasBookableFromAllowed: subscriptionData.bookableDays !== null,
          negativeDates: subscriptionData.negativeDates
            ? subscriptionData.negativeDates.slice()
            : [],
          hasMinAge: subscriptionData.minAge !== null,
          hasMaxAge: subscriptionData.maxAge !== null,
          minAge: subscriptionData.minAge ? subscriptionData.minAge : 0,
          maxAge: subscriptionData.maxAge ? subscriptionData.maxAge : 0,
          allowBookForFamily: !!subscriptionData.allowBookForFamily,
          billingPeriod: subscriptionData.billingPeriod,
        },
        this.checkChange
      );
    }
  };

  showErrorMsg = () => {
    this.context.createInfo(
      "Ein unerwarteter Fehler ist aufgetreten.",
      "error",
      4
    );
  };

  handleError = (err: any) => {
    this.showErrorMsg();
    console.error(err);
  };

  deleteSubscriptionImg = () => {
    fetchRetry(
      "removeSubscriptionImg",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
      },
      1,
      5
    )
      .then(this.handleRemoveSubscriptionImgSuccess)
      .catch(this.handleError);
  };

  handleRemoveSubscriptionImgSuccess = ({ data }: any) => {
    if (data.success) {
      this.setState({ subscriptionImgRemoved: true });
    }
  };

  updateSubscription = () => {
    if (!this.state.updateLoading) {
      const priceArr = this.state.rolePriceArr
        .filter((rolePrice: any) => rolePrice.selected)
        .map((rolePrice: any) => {
          return {
            id: rolePrice.id,
            price: rolePrice.val * 100,
            admissionFee: rolePrice.admissionFee * 100,
            showAlways: rolePrice.showAlways,
          };
        });
      const roleIds: Array<string> = this.state.roleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      const manageRoleIds: Array<string> = this.state.branchRoleArr
        .filter((item: any) => item.selected)
        .map((item: any) => item.id);
      this.setState({ updateLoading: true });
      fetchRetry(
        "updateSubscription",
        {
          clubId: this.state.clubId,
          branchId: this.state.branchId,
          subscriptionId: this.state.subscriptionId,
          subscriptionType: "single-subscription",
          title: this.state.subscriptionTitle,
          description: this.state.subscriptionDescription,
          bookingMailMessage: this.state.subscriptionBookingNote,
          allowedRoles: roleIds,
          manageRoles: manageRoleIds,
          priceArr,
          visibleDays: this.state.hasVisibleFromAllowed
            ? this.state.visibleDays
            : null,
          bookableDays: this.state.hasBookableFromAllowed
            ? this.state.bookableDays
            : null,
          minAge: this.state.hasMinAge ? this.state.minAge : null,
          maxAge: this.state.hasMaxAge ? this.state.maxAge : null,
          noticePeriodDateUnit:
            dateUnits[parseInt(this.state.dateUnit) - 1].dbName,
          noticePeriod: this.state.noticePeriod,
          billingPeriod: this.state.billingPeriod,
        },
        1,
        5
      )
        .then(this.handleUpdateSubscriptionSuccess)
        .catch(this.handleError);
    }
  };

  handleUpdateSubscriptionSuccess = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.setState({ errorMessage: null });
      this.requestSubscription();
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleDeleteSubscription = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleDeleteSubscriptionConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteSubscription",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        force: true,
      },
      1,
      5
    )
      .then(this.handleDeleteSubscriptionSuccess)
      .catch(this.handleError);
  };

  handleDeleteSubscriptionSuccess = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Mitgliedschaft wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState({
        errorMessageDelete: null,
        redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course?showSub=true`,
      });
    } else {
      this.setState({
        errorMessageDelete: data.errorMsgDe,
      });
    }
  };

  handleRoleArr = () => {
    if (this.state.branchLoaded && this.state.subscriptionLoaded) {
      const branchRoleArr = Object.keys(this.state.branch.roles)
        .filter(
          (roleId: string) => this.state.branch?.roles[roleId].type !== "club"
        )
        .map((roleId: string) => {
          const currBranchRole = this.state.branch?.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: this.state.subscription?.manageRoles?.includes(roleId),
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        });
      const roleArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch?.roles[roleId];
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: this.state.subscription?.allowedRoles?.includes(roleId),
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      roleArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        color: "#ffd025",
        selected: this.state.subscription.allowedRoles.includes("public"),
      });
      roleArr.push({
        custom: false,
        default: false,
        id: "guest",
        name: "Jeder",
        selected: this.state.subscription.allowedRoles.includes("guest"),
        color: "#ffd025",
      });

      const rolePriceArr = Object.keys(this.state.branch.roles).map(
        (roleId: string) => {
          const currBranchRole = this.state.branch.roles[roleId];
          const index = this.state.subscription.priceArr.findIndex(
            (role: any) => role.id === roleId
          );
          return {
            ...currBranchRole,
            ...{
              id: roleId,
              selected: index !== -1,
              val:
                index !== -1
                  ? this.state.subscription.priceArr[index].price / 100
                  : 0,
              admissionFee:
                index !== -1
                  ? this.state.subscription.priceArr[index].admissionFee / 100
                  : 0,
              showAlways:
                index !== -1
                  ? this.state.subscription.priceArr[index].showAlways
                  : false,
              color: currBranchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
            },
          };
        }
      );
      const indexPublic = this.state.subscription.priceArr.findIndex(
        (role: any) => role.id === "public"
      );
      rolePriceArr.push({
        custom: false,
        default: false,
        id: "public",
        name: "Registrierte Benutzer",
        selected: indexPublic !== -1,
        color: "#ffd025",
        val:
          indexPublic !== -1
            ? this.state.subscription.priceArr[indexPublic].price / 100
            : 0,
        admissionFee:
          indexPublic !== -1
            ? this.state.subscription.priceArr[indexPublic].admissionFee / 100
            : 0,
        priority: -1,
        showAlways:
          indexPublic !== -1
            ? this.state.subscription.priceArr[indexPublic].showAlways
            : false,
      });

      const indexDefault = this.state.subscription.priceArr.findIndex(
        (role: any) => role.id === "default"
      );
      rolePriceArr.push({
        custom: false,
        default: true,
        id: "default",
        name: "Basis",
        selected: indexDefault !== -1,
        color: "#9e9e9e",
        val:
          indexDefault !== -1
            ? this.state.subscription.priceArr[indexDefault].price / 100
            : 0,
        admissionFee:
          indexDefault !== -1
            ? this.state.subscription.priceArr[indexDefault].admissionFee / 100
            : 0,
        priority: -3,
        showAlways:
          indexDefault !== -1
            ? this.state.subscription.priceArr[indexDefault].showAlways
            : false,
      });
      rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
      this.setState(
        {
          branchRoleArr,
          roleArr,
          rolePriceArr,
        },
        this.checkChange
      );
    }
  };

  handleChangeSearchTextPriceRole = (val: any) => {
    this.setState({ searchTextPriceRole: val.target.value });
  };

  handlePriceRoleChange = (id: number, val: any) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].val = Math.floor(val * 100) / 100;
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleAdmissionFeeRoleChange = (id: number, val: any) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].admissionFee = Math.floor(val * 100) / 100;
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleShowAlwaysChange = (id: number) => {
    const rolePriceArr = this.state.rolePriceArr;
    const index = rolePriceArr.findIndex((role: any) => role.id === id);
    rolePriceArr[index].showAlways = !rolePriceArr[index].showAlways;
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleAddPriceRole = () => {
    this.setState({ showAddRolePricing: true });
  };

  handleRolePriceSelect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = true;
    rolePriceArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleRolePriceUnselect = (id: string) => {
    const rolePriceArr: any = this.state.rolePriceArr;
    const index = findWithAttr(rolePriceArr, "id", id);
    rolePriceArr[index].selected = false;
    this.setState({ rolePriceArr }, this.checkChange);
  };

  handleUpdateTitle = (val: string) => {
    this.setState({ subscriptionTitle: val }, this.checkChange);
  };

  handleUpdateDescription = (val: string) => {
    this.setState({ subscriptionDescription: val }, this.checkChange);
  };

  handleBookingNoteChange = (val: any) => {
    this.setState({ subscriptionBookingNote: val }, this.checkChange);
  };

  handleInfinityEndDateChange = (date: string) => {
    this.setState({ infinityEndDate: date }, this.checkChange);
  };

  handleDateUnitsChange = (val: any) => {
    this.setState({ dateUnit: val }, this.checkChange);
  };

  handleNoticePeriodChange = (val: any) => {
    this.setState({ noticePeriod: val }, this.checkChange);
  };

  handleBillingPeriodChange = (val: number) => {
    this.setState({ billingPeriod: val }, this.checkChange);
  };

  checkChange = () => {
    // check if the valid roles have changed
    let validRolesArrChange = false;
    const oldValidRolesArr = this.state.subscription.allowedRoles;
    const newValidRolesObj = this.state.roleArr.filter(
      (item: any) => item.selected
    );
    if (newValidRolesObj.length !== oldValidRolesArr.length) {
      validRolesArrChange = true;
    } else {
      newValidRolesObj.forEach((item: any) => {
        if (!oldValidRolesArr.includes(item.id)) {
          validRolesArrChange = true;
        }
      });
    }
    ///
    // check if the managed roles have changed
    let validManageRolesArrChange = false;
    const oldValidManagedRolesArr = this.state.subscription.manageRoles;
    const newValidManagedRolesObj = this.state.branchRoleArr.filter(
      (item: any) => item.selected
    );
    if (newValidManagedRolesObj?.length !== oldValidManagedRolesArr?.length) {
      validManageRolesArrChange = true;
    } else {
      newValidManagedRolesObj.forEach((item: any) => {
        if (!oldValidManagedRolesArr.includes(item.id)) {
          validManageRolesArrChange = true;
        }
      });
    }
    ///

    // check if the price has changed
    let priceChange = false;
    const priceArrActive = this.state.rolePriceArr.filter(
      (priceRole: any) => priceRole.selected
    );
    const priceArrIds = priceArrActive.map((currPrice: any) => currPrice.id);
    if (priceArrActive.length !== this.state.subscription.priceArr.length) {
      priceChange = true;
    } else {
      this.state.subscription.priceArr.forEach((currPrice: any) => {
        const roleId = currPrice.id;
        if (!priceArrIds.includes(roleId)) {
          priceChange = true;
        } else {
          const index = priceArrActive.findIndex(
            (role: any) => role.id === roleId
          );
          if (
            currPrice.price !== priceArrActive[index].val * 100 ||
            currPrice.admissionFee !==
              priceArrActive[index].admissionFee * 100 ||
            currPrice.showAlways !== priceArrActive[index].showAlways
          ) {
            priceChange = true;
          }
        }
      });
    }
    ///

    this.setState({
      change:
        this.state.subscriptionTitle !== this.state.subscription.title ||
        this.state.subscriptionDescription !==
          this.state.subscription.description ||
        this.state.subscriptionBookingNote !==
          this.state.subscription.bookingMailMessage ||
        this.state.hasVisibleFromAllowed !==
          (this.state.subscription.visibleDays !== null) ||
        (this.state.hasVisibleFromAllowed &&
          this.state.visibleDays !== this.state.subscription.visibleDays) ||
        this.state.hasBookableFromAllowed !==
          (this.state.subscription.bookableDays !== null) ||
        (this.state.hasBookableFromAllowed &&
          this.state.bookableDays !== this.state.subscription.bookableDays) ||
        dateUnits[parseInt(this.state.dateUnit) - 1].dbName !==
          this.state.subscription.noticePeriodDateUnit ||
        this.state.noticePeriod !== this.state.subscription.noticePeriod ||
        this.state.billingPeriod !== this.state.subscription.billingPeriod ||
        this.state.hasMinAge !==
          (this.state.subscription.minAge !== null &&
            this.state.subscription.minAge !== undefined) ||
        this.state.hasMaxAge !==
          (this.state.subscription.maxAge !== null &&
            this.state.subscription.maxAge !== undefined) ||
        (this.state.hasMinAge &&
          this.state.minAge !== this.state.subscription.minAge) ||
        (this.state.hasMaxAge &&
          this.state.maxAge !== this.state.subscription.maxAge) ||
        validRolesArrChange ||
        validManageRolesArrChange ||
        priceChange,
    });
  };

  handleBranchRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ branchRoleArr: newRoleArr }, this.checkChange);
  };

  handleBranchRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.branchRoleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ branchRoleArr: newRoleArr }, this.checkChange);
  };

  handleRoleSelect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = true;
    this.setState({ roleArr: newRoleArr }, this.checkChange);
  };

  handleRoleUnselect = (bookingTypeId: string) => {
    const newRoleArr: any = this.state.roleArr;
    const index = findWithAttr(newRoleArr, "id", bookingTypeId);
    newRoleArr[index].selected = false;
    this.setState({ roleArr: newRoleArr }, this.checkChange);
  };

  handleImageUpload = (e: any) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file);
    this.setState({ previewUrl });
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      console.error("the filetype is invalid, valid fileTypes are png/jpg");
    } else {
      const fileEnding = file.type === "image/png" ? ".png" : ".jpg";
      var storageRef = storage.ref(
        `images/users/${this.context.user?.uid}/subscriptions/${this.state.subscriptionId}/subscriptionImg${fileEnding}`
      );
      // Uplaod file
      const task = storageRef.put(file);
      // Update progress bar
      task.on(
        "state_changed",
        (snapshot) => {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ subscriptionImgProgress: percentage });
        },
        (error) => {
          console.error("Error: ");
          console.error(error);
        },
        () => {
          this.setState({ subscriptionImgRemoved: false });
        }
      );
    }
  };

  toggleMinAge = () => {
    this.setState({ hasMinAge: !this.state.hasMinAge }, this.checkChange);
  };

  toggleMaxAge = () => {
    this.setState({ hasMaxAge: !this.state.hasMaxAge }, this.checkChange);
  };

  handleMaxAgeChange = (val: number) => {
    this.setState({ maxAge: val }, this.checkChange);
  };

  handleMinAgeChange = (val: number) => {
    this.setState({ minAge: val }, this.checkChange);
  };

  toggleHasExpireDate = () => {
    this.setState({ hasExpireDate: !this.state.hasExpireDate });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (!this.state.branchLoaded || !this.state.subscriptionLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    const selectedRolesWithDefault =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "guest" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) => currRole.id !== "guest" && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    const selectedRolesWithMemberOfBranch =
      this.state.roleArr.filter(
        (currRole: any) => currRole.id === "public" && currRole.selected
      ).length !== 0
        ? this.state.roleArr
            .filter(
              (currRole: any) =>
                !["public", "guest"].includes(currRole.id) && currRole.selected
            )
            .map((currRole: any) => currRole.name)
        : [];

    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg="Willst du diese Mitgliedschaft wirklick löschen? Diese Aktion kann nicht rückgängig gemacht werden."
          handleConfirm={this.handleDeleteSubscriptionConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Kursverwaltung"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/manage-course?showSub=true#${this.state.subscriptionId}`}
            />

            <Title title="Mitgliedschaft bearbeiten" />
            <div className="upload-main-container margin-bottom">
              <div className="image-container">
                <label className="image-upper-container">
                  <FontAwesomeIcon
                    icon={faImage}
                    size="4x"
                    className="image-icon"
                  />
                  <input
                    type="file"
                    id="uploadSubscriptionImg"
                    className="fileInput"
                    onChange={this.handleImageUpload}
                  />
                  <span>Mitgliedschaftsbild auswählen.</span>
                  <progress
                    value={this.state.subscriptionImgProgress}
                    max="100"
                    id="uploaderBanner"
                    className="progress-bar"
                  />
                </label>
                <div className="image-lower-container">
                  <button
                    id="delete-subscriptionImg"
                    className="delete-button"
                    onClick={this.deleteSubscriptionImg}
                  >
                    Mitgliedschaftsbild löschen
                  </button>
                </div>
              </div>
              <div className="upload-logo-container">
                {(!this.state.subscription.imgLowRes &&
                  !this.state.previewUrl) ||
                this.state.subscriptionImgRemoved ? (
                  <div className="background-container">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="4x"
                      className="image-icon"
                    />
                    Kein Mitgliedschaftsbild
                  </div>
                ) : (
                  <img
                    src={
                      this.state.previewUrl &&
                      this.state.subscriptionImgProgress >= 100
                        ? this.state.previewUrl
                        : this.state.subscription.imgLowRes
                    }
                    alt="Mitgliedschaftsbild"
                  />
                )}
              </div>
            </div>
            <SubTitleTwo title="Allgemeine Einstellungen" />
            <div className="subscription-edit-container">
              <SubTitle
                title="Name"
                helpText="Der Mitgliedschaftname wird jedem der diese Mitgliedschaft sieht angezeigt."
              />
              <Input
                name="subscription-name-input"
                value={this.state.subscriptionTitle}
                placeholder="Mitgliedschaftsname"
                onChange={this.handleUpdateTitle}
              />
              <SubTitle
                title="Beschreibung"
                helpText="Die Beschreibung wird jedem der diese Mitgliedschaft sieht angezeigt."
              />
              <Input
                name="subscription-description-input"
                value={this.state.subscriptionDescription}
                placeholder="Mitgliedschaftbeschreibung"
                onChange={this.handleUpdateDescription}
              />
              <SubTitle
                title="Erlaubte Rollen"
                helpText={`Die ausgewählten Rollen können diese Mitgliedschaft buchen. Um das Buchen von unregistrierten Benutzern zu erlauben, wähle "Jeder".`}
              />
              <MultiSelector
                searchTextPlaceholder="Rolle"
                arr={this.state.roleArr}
                onSelect={this.handleRoleSelect}
                onUnselect={this.handleRoleUnselect}
              />
              <InfoContainer
                active={selectedRolesWithDefault.length > 0}
              >{`Die Einstellung "Jeder" beinhaltet die ${
                selectedRolesWithDefault.length === 1 ? "Rolle" : "Rollen"
              } "${selectedRolesWithDefault
                .slice(
                  0,
                  selectedRolesWithDefault.length === 1
                    ? 1
                    : selectedRolesWithDefault.length - 1
                )
                .join('", "')}${
                selectedRolesWithDefault.length > 1
                  ? `" und "${
                      selectedRolesWithDefault[
                        selectedRolesWithDefault.length - 1
                      ]
                    }`
                  : ""
              }". Daher ${
                selectedRolesWithDefault.length === 1 ? "muss" : "müssen"
              } sie nicht extra ausgewählt werden.`}</InfoContainer>
              <InfoContainer
                active={selectedRolesWithMemberOfBranch.length > 0}
              >{`Die Einstellung "Registrierte Benutzer" beinhaltet die ${
                selectedRolesWithMemberOfBranch.length === 1
                  ? "Rolle"
                  : "Rollen"
              } "${selectedRolesWithMemberOfBranch
                .slice(
                  0,
                  selectedRolesWithMemberOfBranch.length === 1
                    ? 1
                    : selectedRolesWithMemberOfBranch.length - 1
                )
                .join('", "')}${
                selectedRolesWithMemberOfBranch.length > 1
                  ? `" und "${
                      selectedRolesWithMemberOfBranch[
                        selectedRolesWithMemberOfBranch.length - 1
                      ]
                    }`
                  : ""
              }". Daher ${
                selectedRolesWithMemberOfBranch.length === 1 ? "muss" : "müssen"
              } sie nicht extra ausgewählt werden.`}</InfoContainer>
              {/* <SubTitle
                title="Abrechungszeitraum (in Monaten)"
                helpText="Alle x Monate wird der eingestellte Betrag eingezogen."
              />

              <Input
                value={this.state.billingPeriod}
                onChange={this.handleBillingPeriodChange}
                type="number"
                min={1}
              /> */}
              <SubTitle
                title={"Preis pro Abrechungszeitraum"}
                helpText={
                  "Dieser Preis wird jeden Abrechungszeitraum eingezogen."
                }
              />
              <table className="table-price">
                <thead>
                  <tr>
                    <th>Löschen</th>
                    <th>Rolle</th>
                    <th>Preis (in €)</th>
                    <th>Aufnahmegebühr (in €)</th>
                    <th>Immer anzeigen</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rolePriceArr
                    .filter((role: any) => role.selected)
                    .map((role: any) => (
                      <tr>
                        {role.id !== "default" ? (
                          <td
                            onClick={() => {
                              this.handleRolePriceUnselect(role.id);
                            }}
                            className="pointer center"
                          >
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>
                          <div className="flex">
                            {role.name}{" "}
                            {role.id === "default" && (
                              <div className="info-icon-container margin-left">
                                <FontAwesomeIcon
                                  icon={faQuestionCircle}
                                  size="1x"
                                  onClick={() => {}}
                                  className="back-element-icon"
                                />
                                <div className="info-text-container">
                                  <div className="info-text-triangle"></div>
                                  <div className="info-text-inner-container">
                                    Der Preis, wenn das Mitglied keine der
                                    ausgewählten Rollen hat. Auch der Preis für
                                    Gäste und nicht Mitglieder.
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <Input
                            value={role.val}
                            onChange={(val: number) => {
                              this.handlePriceRoleChange(role.id, val);
                            }}
                            type="number"
                            placeholder="Preis"
                            className="no-margin"
                          />
                        </td>
                        <td>
                          <Input
                            value={role.admissionFee}
                            onChange={(val: number) => {
                              this.handleAdmissionFeeRoleChange(role.id, val);
                            }}
                            type="number"
                            placeholder="Preis"
                            className="no-margin"
                          />
                        </td>
                        <td>
                          <TextCheckbox
                            val={role.showAlways}
                            onChange={() => {
                              this.handleShowAlwaysChange(role.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  <tr className="bold">
                    <td
                      className="center pointer"
                      onClick={this.handleAddPriceRole}
                    >
                      +
                      <div
                        className={`multiselector-add-container${
                          this.state.showAddRolePricing
                            ? " multiselector-show-add-container"
                            : ""
                        }`}
                        ref={this.wrapperRef}
                      >
                        <div className="multiselector-add-text-container">
                          <input
                            placeholder="Rolle"
                            value={this.state.searchTextPriceRole}
                            onChange={this.handleChangeSearchTextPriceRole}
                          />
                          <div className="icon-container">
                            <FontAwesomeIcon icon={faSearch} size="1x" />
                          </div>
                        </div>
                        <div className="multiselector-scroll-container">
                          {this.state.rolePriceArr
                            .filter(
                              (item: any) =>
                                !item.selected &&
                                item.name
                                  .toLowerCase()
                                  .includes(
                                    this.state.searchTextPriceRole.toLowerCase()
                                  )
                            )
                            .map((item: any) => (
                              <div
                                className="multiselector-select-element-container"
                                onClick={() => {
                                  this.handleRolePriceSelect(item.id);
                                }}
                                key={item.id}
                              >
                                <div
                                  className="point"
                                  style={{ backgroundColor: item.color }}
                                ></div>
                                {item.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </td>
                    <td colSpan={3}></td>
                  </tr>
                </tbody>
              </table>
              <SubTitle
                title="Buchungsnotiz"
                helpText="Text der bei einer erfolgreichen Buchung angezeigt und per E-Mail mitgesendet wird."
              />

              <TextArea
                value={this.state.subscriptionBookingNote}
                onChange={this.handleBookingNoteChange}
                placeholder="Buchungsnotiz"
              />
              <SubTitle
                title="Mindestalter"
                helpText="Ab diesem Alter kann die Mitgliedschaft gebucht werden. (Dieses Alter kann die Mitgliedschaft buchen)"
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasMinAge}
                    onChange={this.toggleMinAge}
                  />
                </div>
                {this.state.hasMinAge ? (
                  <Input
                    value={this.state.minAge}
                    onChange={this.handleMinAgeChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Mindestalter
                  </div>
                )}
              </div>
              <SubTitle
                title="Maximalalter"
                helpText="Nach diesem Alter kann die Mitgliedschaft nicht mehr gebucht werden. (Dieses Alter kann die Mitgliedschaft buchen)"
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasMaxAge}
                    onChange={this.toggleMaxAge}
                  />
                </div>
                {this.state.hasMaxAge ? (
                  <Input
                    value={this.state.maxAge}
                    onChange={this.handleMaxAgeChange}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Maximalalter
                  </div>
                )}
              </div>
              <div className="small-space"></div>
              <SubTitle
                title="Auslaufdatum"
                helpText="An diesem Tag wird die Mitgliedschaft auslaufen (Dieser Tag findet noch statt). Ein angebrochener Monat wird als vollständig abgerechnet."
              />

              <div className="course-time-input-container">
                <div className="margin-right">
                  <Switch
                    value={this.state.hasExpireDate}
                    onChange={this.toggleHasExpireDate}
                  />
                </div>
                {this.state.hasExpireDate ? (
                  <input
                    type="date"
                    className="input-container expireDate-container"
                    value={this.state.infinityEndDate}
                    onChange={(val: any) => {
                      this.handleInfinityEndDateChange(val.target.value);
                    }}
                  />
                ) : (
                  <div className="input-container expireDate-container">
                    Kein Auslaufdatum
                  </div>
                )}
              </div>

              <SubTitle
                title="Kündigungsfrist"
                helpText="Frist bis zum Wirksamwerden der Kündigung."
              />
              <div className="flex two-input-container">
                <DropDown
                  options={dateUnits}
                  onChange={this.handleDateUnitsChange}
                  value={this.state.dateUnit}
                  name="locations-dropdown"
                />
                <Input
                  value={this.state.noticePeriod}
                  onChange={this.handleNoticePeriodChange}
                  type="number"
                  min={0}
                  step={1}
                />
              </div>
              <SubTitle title="diese Rollen automatisch verwalten" />
              <MultiSelector
                searchTextPlaceholder="Rolle"
                onSelect={this.handleBranchRoleSelect}
                onUnselect={this.handleBranchRoleUnselect}
                arr={this.state.branchRoleArr}
                className="margin-bottom"
              />
              <ErrorMessage message={this.state.errorMessage} />
              <SecButton
                change={this.state.change}
                color="green"
                loading={this.state.updateLoading}
                onClick={this.updateSubscription}
                title="Speichern"
              />
              <ErrorMessage message={this.state.errorMessageDelete} />
              <SecButton
                change={true}
                color="red"
                loading={this.state.deleteLoading}
                onClick={this.handleDeleteSubscription}
                title="Mitgliedschaft löschen"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
