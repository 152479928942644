import React from "react";
import "./MandateElem.css";
import { Mandate } from "./modals/PaymentMethodsModal";

export default class MandateElem extends React.Component<
  {
    mandate: Mandate;
    selected: boolean;
    current: boolean;
    onSelect: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={`method ${this.props.selected ? "method-selected" : ""}`}
        onClick={this.props.onSelect}
      >
        <img
          src={`images/icons/paymentMethods/${this.props.mandate.method}.svg`}
          alt="mandate-method-img"
          className="mandate-method-img"
        />
        <div className="payment-details">
          <span>{this.props.mandate.method}</span>
          <span className="payment-account">
            {this.props.mandate.details?.consumerName
              ? `${this.props.mandate.details?.consumerName} - `
              : ""}
            {this.props.mandate.details?.consumerAccount}
          </span>
        </div>
        {this.props.current && (
          <div className="current-payment-method">Aktuell</div>
        )}
      </div>
    );
  }
}
