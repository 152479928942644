import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./Login.css";
import "./ConfirmEmail.css";
import { MainContext } from "../contexts/MainContext";
import { fetchRetry } from "../functions/request";
import HashLoader from "react-spinners/HashLoader";
import { firestore } from "../services/firebase";
import ClipLoader from "react-spinners/ClipLoader";
import qs from "qs";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    onboardingId: string;
  }>;

export default class ConfirmEmail extends React.Component<
  ComposedProps,
  {
    userLoaded: boolean;
    clubId: string | undefined;
    resendLoading: boolean;
    nextMailSendDate: Date;
    nextMailSeconds: number;
  }
> {
  static contextType = MainContext;

  constructor(props: any) {
    super(props);
    this.state = {
      userLoaded: false,
      clubId: this.props.match.params.clubId,
      resendLoading: false,
      nextMailSendDate: new Date(),
      nextMailSeconds: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.requestEmailVerification(false);
    }, 2000);
    setInterval(
      () =>
        this.setState({
          nextMailSeconds: Math.round(
            (this.state.nextMailSendDate.getTime() - new Date().getTime()) /
              1000
          ),
        }),
      1000
    );
    const userId = this.context.user?.uid;
    firestore
      .collection("users")
      .doc(userId)
      .onSnapshot((snapshot: any) => {
        const newUserData = snapshot.data();
        if (newUserData?.emailVerified) {
          this.context.requestOwnUserData();
          this.context.requestClub(this.context.clubId);
          this.handleRedirect();
        }
      });
    this.requestUserData();
  }

  handleRedirect = () => {
    const props = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (props.r) {
      const newUrl = props.r + "";
      window.location.href = newUrl;
    } else {
      this.props.history.push("/");
    }
  };

  requestUserData = () => {
    fetchRetry("getOwnUser", {}, 1, 5)
      .then(this.handleUserData)
      .catch(this.handleError);
  };

  handleUserData = ({ data }: any) => {
    if (data.success) {
      if (data.data?.emailVerified) {
        this.handleRedirect();
      }
    } else {
      if (data.code === "not-authenticated") {
        this.handleRedirect();
      }
    }
  };

  requestEmailVerification = (forceNew: boolean) => {
    this.setState({ resendLoading: true });
    fetchRetry(
      "sendEmailConfirmationMail",
      {
        clubId: this.state.clubId,
        forceNew,
      },
      1,
      5
    )
      .then(this.handleSuccessRequestEmailVerification)
      .catch(this.handleError);
  };

  handleSuccessRequestEmailVerification = ({ data }: any) => {
    this.setState({ resendLoading: false });
    if (data.nextMailSendDate) {
      const nextMailSendDate = new Date(data.nextMailSendDate);
      this.setState({ nextMailSendDate });
    }
    if (data.success) {
      this.context.createInfo(
        "Bestätigungsmail wurde erfolgreich gesendet.",
        "success",
        4
      );
    }
  };

  handleRequestNewVerificationMail = () => {
    this.requestEmailVerification(true);
  };

  handleError = (error: any) => {
    console.error(error);
  };

  render() {
    if (
      !this.context.userLoaded ||
      (!this.context.clubLoaded && this.state.clubId)
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="main-login-screen">
          <div className="custom-shape-divider-bottom-1633269866">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
          {this.context.club.logoLowRes && (
            <div className="login-img-container">
              <img
                className="login-logo"
                src={this.context.club.logoLowRes}
                alt="Vereinslogo"
              />
            </div>
          )}
          <div className="signUpField">
            <div className="textContainer">
              <h1>Bestätigungsmail gesendet!</h1>
            </div>
            <span>
              Bitte bestätige deine E-Mail-Adresse. Überprüfe dazu deinen
              Posteingang und folge den Anweisungen der E-Mail. Sollte die Mail
              nicht angekommen sein, überprüfe auch den Spam-Ordner. E-Mail
              wurde gesendet an:
            </span>
            <div className="confirmation-mail-container">
              {this.context.user?.email}
            </div>
            <div>
              <button
                className="login-btn login-btn-outline flex-btn"
                onClick={this.handleRequestNewVerificationMail}
              >
                <div>
                  erneut senden
                  {this.state.nextMailSeconds > 0
                    ? ` in ${this.state.nextMailSeconds} Sekunden`
                    : ""}
                </div>
                {this.state.resendLoading && (
                  <div className="resendmail-btn-loading-container">
                    <ClipLoader color={"#c31924"} size={20} loading={true} />
                  </div>
                )}
              </button>
            </div>

            <div className="small-space">
              <h1 className="small-text right" onClick={this.context.logout}>
                <Link to={`/login/${this.context.clubId}`} className="black">
                  Abmelden
                </Link>
              </h1>
            </div>
          </div>
        </div>
      </>
    );
  }
}
