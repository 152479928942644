import React from "react";
import "./Module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Module extends React.Component<
  {
    icon: any;
    iconColor: string;
    title: string;
    description: string;
    isBeta: boolean;
    isPlanned: boolean;
    active: boolean;
    onToggle: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="module-container">
          <div className="module-title-container">
            <div
              className="module-icon-container"
              style={{ backgroundColor: this.props.iconColor }}
            >
              <FontAwesomeIcon icon={this.props.icon} />
            </div>
            {this.props.title}
            {this.props.isBeta && (
              <div className="module-title-info module-title-beta">beta</div>
            )}
            {this.props.isPlanned && (
              <div className="module-title-info module-title-planned">
                geplant
              </div>
            )}
          </div>
          <div className="module-description-container">
            <span>{this.props.description}</span>
          </div>

          <div className="module-button-main-container">
            <div
              className={`module-button-container module-button-activate-container ${
                this.props.isPlanned && "module-button-container-disabled"
              }`}
              onClick={this.props.onToggle}
            >
              {this.props.active ? "Deaktivieren" : "Aktivieren"}
            </div>
            <div className="module-button-container module-button-moreinfo-container">
              Mehr Informationen
            </div>
          </div>
        </div>
      </>
    );
  }
}
