import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./SubscriptionBookingConfirmation.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { generateQRCode } from "../../functions/qr";
import BackElement from "../../components/BackElement";
import Title from "../../components/Title";
import { firestore } from "../../services/firebase";
import { ClimbingBoxLoader } from "react-spinners";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    subscriptionBookingId: string;
    clientKey: string | undefined;
  }>;

export default class SubscriptionBookingConfirmation extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    subscriptionBookingLoaded: boolean;
    subscriptionBookingId: string;
    clientKey: string | undefined;
    subscriptionBookingData: any;
    qrCode: string;
    redirect: null | string;
    listenerActive: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      subscriptionBookingLoaded: false,
      subscriptionBookingId: this.props.match.params.subscriptionBookingId,
      clientKey: this.props.match.params.clientKey,
      subscriptionBookingData: {},
      qrCode: "",
      redirect: null,
      listenerActive: false,
    };
  }

  componentDidMount = () => {
    this.requestSubscriptionsBooking();
  };

  requestSubscriptionsBooking = () => {
    this.setState({ subscriptionBookingLoaded: false });
    fetchRetry(
      "getSubscriptionBookingF",
      {
        subscriptionBookingId: this.state.subscriptionBookingId,
        clientKey: this.state.clientKey,
      },
      1,
      5
    )
      .then(this.requestSubscriptionsBookingSuccess)
      .catch(this.handleError);
  };

  requestSubscriptionsBookingSuccess = async ({ data }: any) => {
    this.setState({ subscriptionBookingLoaded: true });
    if (data.success) {
      const subscriptionBookingData = data.data;
      if (
        ["pending", "authorized", "paid", "free", "open"].includes(
          subscriptionBookingData.paymentStatus
        )
      ) {
        this.setState({
          redirect: `/club/${subscriptionBookingData.club.id}/branch/${
            subscriptionBookingData.branch.id
          }/mysubscriptions/${
            subscriptionBookingData.subscriptionId
          }/subscriptionBooking/${this.state.subscriptionBookingId}${
            this.state.clientKey ? `/${this.state.clientKey}` : ""
          }`,
        });
      } else {
        if (!this.state.listenerActive) {
          firestore
            .collection("subscriptions")
            .doc(subscriptionBookingData.subscriptionId)
            .collection("subscriptionBookings")
            .doc(this.state.subscriptionBookingId)
            .onSnapshot(() => {
              this.requestSubscriptionsBooking();
            });
          this.setState({ listenerActive: true });
        }
        this.setState({
          subscriptionBookingData: subscriptionBookingData,
          qrCode: await generateQRCode(
            subscriptionBookingData.subscriptionBookingSimpleId
          ),
        });
      }
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.subscriptionBookingLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Mitgliedschaft"
              to={`/club/${this.state.clubId}/branch/${this.state.branchId}/subscription/${this.state.subscriptionBookingData.subscriptionId}`}
            />
            {this.state.subscriptionBookingData.paymentStatus === "canceled" ||
            this.state.subscriptionBookingData.paymentStatus === "failed" ? (
              <>
                <Title title="Mitgliedschaftsbuchung abgebrochen." />
                <p>
                  Deine Mitgliedschaftsbuchung wurde abgebrochen. Wenn du die
                  Mitgliedschaft buchen willst kannst du sie "
                  <a
                    href={`/club/${this.state.clubId}/branch/${this.state.branchId}/subscription/${this.state.subscriptionBookingData.subscriptionId}`}
                  >
                    hier
                  </a>
                  " erneut buchen.
                </p>
              </>
            ) : (
              <div className="bookingData-loading-container">
                <Title title="Ihre Zahlung wird bearbeitet." />
                <div className="bookingData-loading-spinner-container">
                  <ClimbingBoxLoader
                    color={"#c31924"}
                    size={20}
                    loading={this.state.listenerActive}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
