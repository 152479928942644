import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { HeaderCell, RowData, sortDirection } from "../../Types";
import RowSkeleton from "./elements/RowSkeleton";
import "./Table.css";

export default class Table extends React.Component<
  {
    header: Array<HeaderCell>;
    data: Array<RowData>;
    loaded: boolean;
    skeletonRows: number;
    onClick: any;
    defaultSortIndex?: number;
    defaultSortDirection?: sortDirection;
    searchValue?: string;
    counter?: boolean;
    useLoader?: boolean;
    updateLoading?: boolean;
    onColumnClick?: any;
    className?: string;
  },
  {
    sortedData: Array<RowData>;
    sortIndex?: number;
    sortDirection?: sortDirection | undefined;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      sortedData: this.props.data,
      sortIndex: this.props.defaultSortIndex,
      sortDirection: this.props.defaultSortDirection,
    };
  }

  componentDidMount() {
    this.sortTable(
      this.state.sortIndex,
      this.state.sortDirection,
      this.props.data
    );
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.data !== this.props.data) {
      this.sortTable(
        this.state.sortIndex,
        this.state.sortDirection,
        nextProps.data
      );
    }
    if (nextProps.searchValue !== this.props.searchValue) {
      this.filterTable(nextProps.searchValue, nextProps.data);
    }
    if (
      nextProps.defaultSortIndex !== this.props.defaultSortIndex ||
      nextProps.defaultSortDirection !== this.props.defaultSortDirection
    ) {
      this.sortTable(
        nextProps.defaultSortIndex,
        nextProps.defaultSortDirection,
        nextProps.data
      );
    }
    return null;
  }

  filterTable = (searchValue: string, fullData: Array<RowData>) => {
    const filteredData = fullData.filter((currRow: RowData) => {
      return currRow.cells.some((currCell) => {
        if (!searchValue) return true;
        if (!currCell.search) return false;
        const searchable = currCell.search.toLowerCase();
        return searchable?.toLowerCase().includes(searchValue.toLowerCase());
      });
    });
    this.setState({ sortedData: filteredData });
  };

  sortTable = (
    sortIndex: number | undefined,
    sortDirection: sortDirection | undefined,
    fullData: Array<RowData>
  ) => {
    if (sortIndex !== undefined && sortDirection !== undefined) {
      fullData.sort((a: RowData, b: RowData) => {
        if (a.cells[sortIndex]?.sort < b.cells[sortIndex]?.sort) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (a.cells[sortIndex]?.sort > b.cells[sortIndex]?.sort) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    this.setState({ sortIndex, sortDirection });
    this.filterTable(this.props.searchValue || "", fullData);
  };

  render() {
    return (
      <table className={`table ${this.props.className || ""}`}>
        <thead>
          <tr>
            {this.props.counter && <th>#</th>}
            {this.props.header.map((currHeader: HeaderCell, index: number) => (
              <th
                key={index}
                onClick={() => {
                  if (currHeader.onClick) {
                    currHeader.onClick();
                  }
                  if (!currHeader.disableSort) {
                    if (this.props.onColumnClick) {
                      this.props.onColumnClick(index);
                    }
                    this.sortTable(
                      index,
                      this.state.sortIndex === index
                        ? this.state.sortDirection === "asc"
                          ? "desc"
                          : "asc"
                        : "asc",
                      this.state.sortedData
                    );
                  }
                }}
                className={`${
                  !currHeader.disableSort || currHeader.onClick ? "pointer" : ""
                } ${currHeader.hide ? "display-none" : ""} ${
                  this.props.header[index].displayWidth
                    ? `max-width-${this.props.header[index].displayWidth}`
                    : ""
                }`}
              >
                {currHeader.title}{" "}
                {this.state.sortIndex === index &&
                  (this.state.sortDirection === "asc" ? (
                    <FontAwesomeIcon icon={faChevronDown} size="sm" />
                  ) : (
                    <FontAwesomeIcon icon={faChevronUp} size="sm" />
                  ))}
              </th>
            ))}
            {this.props.useLoader && (
              <th>
                <div className="table-loading-container">
                  <ClipLoader
                    color={"gray"}
                    size={20}
                    loading={this.props.updateLoading}
                  />
                </div>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {!this.props.loaded && (
            <>
              {[...Array(this.props.skeletonRows)].map((x, i) => (
                <RowSkeleton
                  columns={
                    this.props.header.filter((h) => !h.hide).length +
                    (this.props.counter ? 1 : 0)
                  }
                  noise={i}
                  key={i}
                />
              ))}
            </>
          )}
          {this.state.sortedData.map(
            (currRow: RowData, columnIndex: number) => (
              <tr
                key={columnIndex}
                onClick={() => {
                  this.props.onClick(currRow.onClick);
                }}
                style={{
                  opacity: currRow.opacity !== undefined ? currRow.opacity : 1,
                }}
                className={currRow.onClick ? "pointer" : ""}
              >
                {this.props.counter && <td>{columnIndex + 1}</td>}
                {currRow.cells.map((currCell: any, rowIndex: number) => (
                  <td
                    key={rowIndex}
                    className={`${currRow.onClick ? "pointer" : ""} ${
                      this.props.header[rowIndex].hide ? "display-none" : ""
                    } ${
                      this.props.header[rowIndex].displayWidth
                        ? `max-width-${this.props.header[rowIndex].displayWidth}`
                        : ""
                    }`}
                  >
                    {currCell.value}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  }
}
