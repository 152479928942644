import React from "react";
import { Route, RouteComponentProps, Redirect } from "react-router-dom";
import "./BranchSettings.css";
import BranchSettingsNavbar from "../components/navbars/BranchSettingsNavbar";
import TextInputModal from "../components/modals/TextInputModal";
import TopMenuBar from "../components/TopMenuBar";
import Users from "./BranchSettingSubScreens/Users";
import Billing from "./BranchSettingSubScreens/Billing";
import Objects from "./BranchSettingSubScreens/Objects";
import Settings from "./BranchSettingSubScreens/Settings";
import Modules from "./BranchSettingSubScreens/Modules";
import Booking from "./BranchSettingSubScreens/Booking";
import Course from "./BranchSettingSubScreens/Course";
import Roles from "./BranchSettingSubScreens/Roles";
import Locations from "./BranchSettingSubScreens/Locations";
import BookingType from "./BranchSettingSubScreens/BookingType";
import { objectToArray } from "../functions/utils";
import PricingProfile from "./BranchSettingSubScreens/PricingProfile";
import PricingProfiles from "./BranchSettingSubScreens/PricingProfiles";
import Footer from "../components/Footer";
import { fetchRetry } from "../functions/request";
import { MainContext } from "../contexts/MainContext";
import SingleUser from "./BranchSettingSubScreens/SingleUser";
import Tags from "./BranchSettingSubScreens/Tags";
import UnpaidBookings from "./BranchSettingSubScreens/UnpaidBookings";
import Feed from "./BranchSettingSubScreens/Feed";
import Family from "./BranchSettingSubScreens/Family";
import HashLoader from "react-spinners/HashLoader";
import Forms from "./BranchSettingSubScreens/Forms";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    site: string;
    objectId: string;
  }>;

export default class ClubSettings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    objectId: string;
    showMobileNav: boolean;
    showCreateObjectModal: boolean;
    createObjectLoading: boolean;
    showCreateRoleModal: boolean;
    showCreateTagModal: boolean;
    showCreateFormModal: boolean;
    createRoleLoading: boolean;
    showCreateBookingTypeModal: boolean;
    createBookingTypeLoading: boolean;
    redirect: null | string;
    showCreatePricingProfileModal: boolean;
    pricingProfileArr: Array<any>;
    createPricingProfileLoading: boolean;
    createLocationLoading: boolean;
    createTagLoading: boolean;
    showCreateLocationModal: boolean;
    createObjectErrorMsg: null | string;
    createRoleErrorMsg: null | string;
    createBookingTypeErrorMsg: null | string;
    createPricingProfileErrorMsg: null | string;
    createLocationErrorMsg: null | string;
    createTagErrorMsg: null | string;
    createFormErrorMsg: null | string;
    paymentConflicts: number;
    createFormLoading: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      objectId: this.props.match.params.objectId,
      showMobileNav: false,
      showCreateObjectModal: false,
      showCreateTagModal: false,
      showCreateFormModal: false,
      createObjectLoading: false,
      showCreateRoleModal: false,
      createRoleLoading: false,
      createBookingTypeLoading: false,
      showCreateBookingTypeModal: false,
      redirect: null,
      showCreatePricingProfileModal: false,
      pricingProfileArr: [],
      createPricingProfileLoading: false,
      createLocationLoading: false,
      createTagLoading: false,
      showCreateLocationModal: false,
      createObjectErrorMsg: null,
      createRoleErrorMsg: null,
      createBookingTypeErrorMsg: null,
      createPricingProfileErrorMsg: null,
      createLocationErrorMsg: null,
      createTagErrorMsg: null,
      createFormErrorMsg: null,
      paymentConflicts: 0,
      createFormLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestPaymentConflicts();
  };

  componentDidUpdate = () => {
    if (this.state.redirect) {
      this.setState({ redirect: null });
    }
  };

  requestBranch = () => {
    this.context.requestClub(this.state.clubId);
    this.context.requestBranch(this.state.clubId, this.state.branchId);
  };

  requestPaymentConflicts = () => {
    fetchRetry(
      "getPaymentConflictsOfBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleUnpaidNotifications)
      .catch(this.handleError);
  };

  handleUnpaidNotifications = ({ data }: any) => {
    if (data.success) {
      this.setState({
        paymentConflicts:
          data.unpaidBookingArr.length +
          data.uncancelledBookingArr.length +
          data.scannedWarnings.length,
      });
    }
  };

  handleCreateObject = (name: string) => {
    this.setState({ createObjectLoading: true });
    fetchRetry(
      "createObject",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessObjectCreated)
      .catch(this.handleError);
  };

  handleSuccessObjectCreated = ({ data }: any) => {
    this.setState({ createObjectLoading: false });
    if (data.success) {
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.setState(
        {
          showCreateObjectModal: false,
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/objects/${data.objectId}`,
          createObjectErrorMsg: null,
        },
        this.requestBranch
      );
    } else {
      this.setState({ createObjectErrorMsg: data.errorMsgDe });
    }
  };

  handleCreateBookingType = (name: string) => {
    this.setState({ createBookingTypeLoading: true });
    fetchRetry(
      "createBookingType",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessBookingTypeCreated)
      .catch(this.handleError);
  };

  handleSuccessBookingTypeCreated = ({ data }: any) => {
    this.setState({ createBookingTypeLoading: false });
    if (data.success) {
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.setState(
        {
          showCreateBookingTypeModal: false,
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/booking-type/${data.bookingTypeId}`,
          createBookingTypeErrorMsg: null,
        },
        this.requestBranch
      );
    } else {
      this.setState({ createBookingTypeErrorMsg: data.errorMsgDe });
    }
  };

  handleCreateRole = (name: string) => {
    this.setState({ createRoleLoading: true });
    fetchRetry(
      "createRole",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessRoleCreated)
      .catch(this.handleError);
  };

  handleSuccessRoleCreated = ({ data }: any) => {
    this.setState({ createRoleLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Rolle wurde erfolgreich erstellt.",
        "success",
        4
      );
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.setState(
        {
          showCreateRoleModal: false,
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/roles/${data.roleId}`,
          createRoleErrorMsg: null,
        },
        this.requestBranch
      );
    } else {
      this.setState({ createRoleErrorMsg: data.errorMsgDe });
    }
  };

  handleCreatePricingProfile = (name: string) => {
    this.setState({ createPricingProfileLoading: true });
    fetchRetry(
      "createPriceModel",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessPricingProfileCreated)
      .catch(this.handleError);
  };

  handleSuccessPricingProfileCreated = ({ data }: any) => {
    this.setState({ createPricingProfileLoading: false });
    if (data.success) {
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.setState(
        {
          showCreatePricingProfileModal: false,
          createPricingProfileErrorMsg: null,
        },
        this.requestBranch
      );
    } else {
      this.setState({ createPricingProfileErrorMsg: data.errorMsgDe });
    }
  };

  handleCreateLocation = (name: string) => {
    this.setState({ createLocationLoading: true });
    fetchRetry(
      "createLocation",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessLocationCreated)
      .catch(this.handleError);
  };

  handleCreateTag = (name: string) => {
    this.setState({ createTagLoading: true });
    fetchRetry(
      "createTag",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessTagCreated)
      .catch(this.handleError);
  };

  handleSuccessTagCreated = ({ data }: any) => {
    this.setState({ createTagLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Das Tag wurde erfolgreich erstellt.",
        "success",
        4
      );
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.setState(
        {
          showCreateTagModal: false,
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/tags/${data.tagId}`,
          createTagErrorMsg: null,
        },
        this.requestBranch
      );
    }
  };

  handleCreateForm = (name: string) => {
    this.setState({ createFormLoading: true });
    fetchRetry(
      "createForm",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessFormCreated)
      .catch(this.handleError);
  };

  handleSuccessFormCreated = ({ data }: any) => {
    this.setState({ createFormLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Das Formular wurde erfolgreich erstellt.",
        "success",
        4
      );
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.setState(
        {
          showCreateFormModal: false,
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/forms/${data.formId}`,
          createFormErrorMsg: null,
        },
        this.requestBranch
      );
    }
  };

  handleSuccessLocationCreated = ({ data }: any) => {
    this.setState({ createLocationLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Örtlichkeit wurde erfolgreich erstellt.",
        "success",
        4
      );
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.setState(
        {
          showCreateLocationModal: false,
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/locations/${data.locationId}`,
          createLocationErrorMsg: null,
        },
        this.requestBranch
      );
    } else {
      this.setState({ createLocationErrorMsg: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleShowMobileNav = () => {
    this.setState({ showMobileNav: true });
  };

  handleHideMobileNav = () => {
    this.setState({ showMobileNav: false });
  };

  handleShowCreateBranchModal = () => {
    this.setState({ showCreateObjectModal: true, showMobileNav: false });
  };

  handleHideCreateObjectModal = () => {
    this.setState({ showCreateObjectModal: false });
  };

  handleShowCreateRoleModal = () => {
    this.setState({ showCreateRoleModal: true, showMobileNav: false });
  };

  handleHideCreateRoleModal = () => {
    this.setState({ showCreateRoleModal: false });
  };

  handleShowCreateBookingTypeModal = () => {
    this.setState({ showCreateBookingTypeModal: true, showMobileNav: false });
  };

  handleHideCreateBookingTypeModal = () => {
    this.setState({ showCreateBookingTypeModal: false });
  };

  handleShowCreateLocationModal = () => {
    this.setState({ showCreateLocationModal: true, showMobileNav: false });
  };

  handleHideCreateLocation = () => {
    this.setState({ showCreateLocationModal: false });
  };

  handleHideCreateTag = () => {
    this.setState({ showCreateTagModal: false });
  };

  handleHideCreateForm = () => {
    this.setState({ showCreateFormModal: false });
  };

  handleShowCreatePricingProfile = () => {
    this.setState({
      showCreatePricingProfileModal: true,
      showMobileNav: false,
    });
  };

  handleShowCreateTag = () => {
    this.setState({
      showCreateTagModal: true,
      showMobileNav: false,
    });
  };

  handleShowCreateForm = () => {
    this.setState({ showCreateFormModal: true, showMobileNav: false });
  };

  handleHideCreatePricingProfile = () => {
    this.setState({ showCreatePricingProfileModal: false });
  };

  render() {
    const objectArr: any = objectToArray(this.context.branch.objects);
    const roleArr: any = objectToArray(this.context.branch.roles);
    const locationArr: any = objectToArray(this.context.branch.locations);
    const tagArr: any = objectToArray(this.context.branch.tags);
    const bookingTypeArr: any = objectToArray(this.context.branch.bookingTypes);
    const formArr: any = objectToArray(this.context.branch.forms);
    const pricingProfileArr: any = objectToArray(
      this.context.branch.priceModels
    );
    objectArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    roleArr.sort((a: any, b: any) => (a.priority > b.priority ? -1 : 1));
    locationArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    tagArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    formArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    bookingTypeArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    pricingProfileArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <div className="br-main-container">
          <TextInputModal
            show={this.state.showCreateObjectModal}
            handleClose={this.handleHideCreateObjectModal}
            handleConfirm={this.handleCreateObject}
            loading={this.state.createObjectLoading}
            btnText="Objekt erstellen"
            textBoxPlaceholder="Objektname"
            title="Objekt erstellen"
            errorMsg={this.state.createObjectErrorMsg}
          />
          <TextInputModal
            show={this.state.showCreateRoleModal}
            handleClose={this.handleHideCreateRoleModal}
            handleConfirm={this.handleCreateRole}
            loading={this.state.createRoleLoading}
            btnText="Rolle erstellen"
            textBoxPlaceholder="Rollenname"
            title="Rolle erstellen"
            errorMsg={this.state.createRoleErrorMsg}
          />
          <TextInputModal
            show={this.state.showCreateBookingTypeModal}
            handleClose={this.handleHideCreateBookingTypeModal}
            handleConfirm={this.handleCreateBookingType}
            loading={this.state.createBookingTypeLoading}
            btnText="Buchungsart erstellen"
            textBoxPlaceholder="Buchunsartname"
            title="Buchungsart erstellen"
            errorMsg={this.state.createBookingTypeErrorMsg}
          />
          <TextInputModal
            show={this.state.showCreatePricingProfileModal}
            handleClose={this.handleHideCreatePricingProfile}
            handleConfirm={this.handleCreatePricingProfile}
            loading={this.state.createPricingProfileLoading}
            btnText="Preisprofil erstellen"
            textBoxPlaceholder="Preisprofilname"
            title="Preisprofil erstellen"
            errorMsg={this.state.createPricingProfileErrorMsg}
          />
          <TextInputModal
            show={this.state.showCreateLocationModal}
            handleClose={this.handleHideCreateLocation}
            handleConfirm={this.handleCreateLocation}
            loading={this.state.createLocationLoading}
            btnText="Örtlichkeit erstellen"
            textBoxPlaceholder="Örtlichkeit"
            title="Örtlichkeit erstellen"
            errorMsg={this.state.createLocationErrorMsg}
          />
          <TextInputModal
            show={this.state.showCreateTagModal}
            handleClose={this.handleHideCreateTag}
            handleConfirm={this.handleCreateTag}
            loading={this.state.createTagLoading}
            btnText="Tag erstellen"
            textBoxPlaceholder="Tag"
            title="Tag erstellen"
            errorMsg={this.state.createTagErrorMsg}
          />
          <TextInputModal
            show={this.state.showCreateFormModal}
            handleClose={this.handleHideCreateForm}
            handleConfirm={this.handleCreateForm}
            loading={this.state.createFormLoading}
            btnText="Formular erstellen"
            textBoxPlaceholder="Formularname"
            title="Formular erstellen"
            errorMsg={this.state.createFormErrorMsg}
          />
          <BranchSettingsNavbar
            selectedScreen={this.props.match.params.site}
            clubId={this.state.clubId}
            branchId={this.state.branchId}
            objectArr={objectArr}
            roleArr={roleArr}
            locationArr={locationArr}
            bookingTypeArr={bookingTypeArr}
            tagArr={tagArr}
            formArr={formArr}
            pricingProfileArr={pricingProfileArr}
            showMobile={this.state.showMobileNav}
            hideMobileNav={this.handleHideMobileNav}
            selectedObject={this.props.match.params.objectId}
            selectedTag={this.props.match.params.objectId}
            onCreateBranch={this.handleShowCreateBranchModal}
            onCreateRole={this.handleShowCreateRoleModal}
            onCreateBookingType={this.handleShowCreateBookingTypeModal}
            onCreateLocation={this.handleShowCreateLocationModal}
            onCreatePricingProfile={this.handleShowCreatePricingProfile}
            onCreateTag={this.handleShowCreateTag}
            onCreateForm={this.handleShowCreateForm}
            useBooking={this.context.branch?.settings?.useBooking}
            useCourse={this.context.branch?.settings?.useCourse}
            useBilling={this.context.branch?.settings?.useBilling}
            useForm={this.context.branch?.settings?.useForm}
            paymentConflicts={this.state.paymentConflicts}
          />
          <div className="br-inner-container" onScroll={this.context.onScroll}>
            <TopMenuBar handleShowMobileNav={this.handleShowMobileNav} />
            <div className="br-padding-container">
              {!this.context.branchLoaded ? (
                <>
                  <div className="loading-container">
                    <HashLoader color={"#c31924"} size={100} loading={true} />
                  </div>
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/family/:familyId"
                    component={Family}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/users"
                    component={Users}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/users/:userId"
                    component={SingleUser}
                  />
                  {/* <Route
                exact
                path="/club/:clubId/branch-settings/:branchId/roles"
                component={Roles}
              /> */}
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/roles/:roleId"
                    render={(props) => (
                      <Roles {...props} refreshNavbar={this.requestBranch} />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/payment-conflicts"
                    render={(props) => (
                      <UnpaidBookings
                        {...props}
                        refreshUnpaidBookings={this.requestPaymentConflicts}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/locations/:locationId"
                    render={(props) => (
                      <Locations
                        {...props}
                        refreshNavbar={this.requestBranch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/tags/:tagId"
                    render={(props) => (
                      <Tags {...props} refreshNavbar={this.requestBranch} />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/forms/:formId"
                    render={(props) => (
                      <Forms {...props} refreshNavbar={this.requestBranch} />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/course"
                    render={(props) => <Course {...props} />}
                  />
                  {/* <Route
                exact
                path="/club/:clubId/branch-settings/:branchId/booking-type"
                render={(props) => <BookingType {...props}/>}
                // component={BookingType}
              /> */}
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/booking-type/:bookingTypeId"
                    render={(props) => (
                      <BookingType
                        {...props}
                        refreshNavbar={this.requestBranch}
                      />
                    )}
                  />
                  {/* <Route
                exact
                path="/club/:clubId/branch-settings/:branchId/objects"
                component={Objects}
              /> */}
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/objects/:objectId"
                    render={(props) => (
                      <Objects {...props} refreshNavbar={this.requestBranch} />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/pricing-profiles/:pricingProfileId"
                    render={(props) => (
                      <PricingProfile
                        {...props}
                        refreshNavbar={this.requestBranch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/pricing-profiles"
                    render={(props) => (
                      <PricingProfiles
                        {...props}
                        refreshNavbar={this.requestBranch}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/settings"
                    component={Settings}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/feed"
                    component={Feed}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/modules"
                    render={(props) => (
                      <Modules {...props} refreshNavbar={this.requestBranch} />
                    )}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/booking"
                    component={Booking}
                  />
                  <Route
                    exact
                    path="/club/:clubId/branch-settings/:branchId/billing"
                    component={Billing}
                  />
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
