import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./Roles.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";
import ErrorMessage from "../../components/ErrorMessage";
import SubTitle from "../../components/SubTitle";
import Switch from "../../components/Switch";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    roleId: string;
  }>;

export default class Roles extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    roleId: string;
    roleName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
    errMsgDeleteRole: null | string;
    errMsgSaveRole: null | string;
    adjustPriceRetro: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      roleId: this.props.match.params.roleId,
      roleName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
      errMsgDeleteRole: null,
      errMsgSaveRole: null,
      adjustPriceRetro: true,
    };
  }

  componentDidMount = () => {
    this.requestClub();
  };

  componentDidUpdate = () => {
    const propsRoleId = this.props.match.params.roleId;
    if (this.state.roleId !== propsRoleId) {
      if (this.state.club.roles && propsRoleId in this.state.club.roles) {
        this.setState({ roleId: propsRoleId }, this.updateRoleData);
      } else {
        this.setState({ roleId: propsRoleId }, this.requestClub);
      }
    }
  };

  requestClub = () => {
    fetchRetry(
      "getClubFromId",
      {
        clubId: this.state.clubId,
      },
      1,
      5
    )
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestClub();
      this.props.refreshNavbar();
    } else {
      this.setState({ errMsgSaveRole: data.errorMsgDe });
    }
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      this.setState(
        {
          club,
        },
        this.updateRoleData
      );
    }
  };

  updateRoleData = () => {
    if (this.state.roleId && this.state.roleId in this.state.club.roles) {
      const role = this.state.club.roles[this.state.roleId];
      this.setState(
        {
          roleName: role.name,
        },
        this.checkChange
      );
    }
  };

  updateRole = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateClubRoleNew",
      {
        clubId: this.state.clubId,
        roleId: this.state.roleId,
        name: this.state.roleName,
        adjustPriceRetro: this.state.adjustPriceRetro,
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleRoleNameChange = (val: any) => {
    this.setState({ roleName: val }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.roleName !== this.state.club.roles[this.state.roleId].name ||
        this.state.adjustPriceRetro !==
          this.state.club.roles[this.state.roleId].adjustPriceRetro,
    });
  };

  handleDeleteRole = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleRoleDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteClubRole",
      {
        clubId: this.state.clubId,
        roleId: this.state.roleId,
      },
      1,
      5
    )
      .then(this.handleSuccessRoleDeleted)
      .catch(this.handleError);
  };

  handleSuccessRoleDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.context.createInfo(
        "Die Rolle wurde erfolgreich gelöscht.",
        "success",
        4
      );
      this.setState(
        {
          redirect: `/club-settings/${this.state.clubId}/roles`,
        },
        () => {
          this.updateRoleData();
          this.props.refreshNavbar();
        }
      );
    } else {
      this.setState({ errMsgDeleteRole: data.errorMsgDe });
    }
  };

  handleAdjustPriceRetro = () => {
    this.setState(
      { adjustPriceRetro: !this.state.adjustPriceRetro },
      this.checkChange
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.clubLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du die Rolle "${
            this.state.roleId &&
            this.state.club?.roles &&
            this.state.club?.roles[this.state.roleId]?.name
              ? this.state.club.roles[this.state.roleId].name
              : "-"
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleRoleDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {this.state.roleId &&
              this.state.club?.roles &&
              Object.keys(this.state.club.roles).length > 0 && (
                <>
                  <div className="subscreen-box-container">
                    <Title title="Einstellungen" />
                    <div className="branch-subtitle-container">
                      <h3>Name</h3>
                    </div>
                    <Input
                      name="role-name-input"
                      value={this.state.roleName}
                      placeholder="Rolle name"
                      onChange={this.handleRoleNameChange}
                    />
                    <SubTitle title="Preis rückwirkend anpassen" />
                    <Switch
                      onChange={this.handleAdjustPriceRetro}
                      value={this.state.adjustPriceRetro}
                    />
                    <div className="medium-space"></div>
                    <ErrorMessage message={this.state.errMsgSaveRole} />
                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updateRole}
                      title="Speichern"
                    />
                    <ErrorMessage message={this.state.errMsgDeleteRole} />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.deleteLoading}
                      onClick={this.handleDeleteRole}
                      title="Rolle löschen"
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
