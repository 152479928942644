import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./Appearance.css";
import "../../App.css";
import { storage } from "../../services/firebase";
import HashLoader from "react-spinners/HashLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchRetry } from "../../functions/request";
import SwitchSetting from "../../components/SwitchSetting";
import SecButton from "../../components/SecButton";
import { MainContext } from "../../contexts/MainContext";
import { faImage } from "@fortawesome/free-solid-svg-icons";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Appearance extends React.Component<
  ComposedProps,
  {
    redirect: null | string;
    clubId: string;
    club: any;
    clubLoaded: boolean;
    clubLogoProgress: number;
    clubBannerProgress: number;
    logoPreview: null | string;
    bannerPreview: null | string;
    clubLogoRemoved: boolean;
    clubBannerRemoved: boolean;
    updateLoading: boolean;
    change: boolean;
    showLogoInBanner: boolean;
    deleteBannerLoading: boolean;
    deleteLogoLoading: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      redirect: null,
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      clubLogoProgress: 0,
      clubBannerProgress: 0,
      logoPreview: null,
      bannerPreview: null,
      clubLogoRemoved: false,
      clubBannerRemoved: false,
      updateLoading: false,
      change: false,
      showLogoInBanner: false,
      deleteBannerLoading: false,
      deleteLogoLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestClub();
  };

  requestClub = () => {
    fetchRetry("getClubFromId", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleDeleteLogo = () => {
    this.setState({ deleteLogoLoading: true });
    fetchRetry("removeClubLogo", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleRemoveLogoSuccess)
      .catch(this.handleError);
  };

  handleDeleteBanner = () => {
    this.setState({ deleteBannerLoading: true });
    fetchRetry("removeClubBanner", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleRemoveBannerSuccess)
      .catch(this.handleError);
  };

  handleRemoveBannerSuccess = ({ data }: any) => {
    this.setState({
      deleteBannerLoading: false,
    });
    if (data.success) {
      this.context.requestClub(this.state.clubId);
      this.setState({ clubBannerRemoved: true });
    }
  };

  handleRemoveLogoSuccess = ({ data }: any) => {
    this.setState({ deleteLogoLoading: false });
    if (data.success) {
      this.context.requestClub(this.state.clubId);
      this.setState({ clubLogoRemoved: true });
    }
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      if (club) {
        this.setState(
          {
            club: club,
            showLogoInBanner: club.settings?.showLogoInBanner,
          },
          this.checkChange
        );
      } else {
        this.setState({ redirect: "/" });
      }
    }
  };

  handleBannerUpload = (e: any) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file);
    this.setState({ bannerPreview: previewUrl });
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      console.error("the filetype is invalid, valid fileTypes are png/jpg");
    } else {
      const fileEnding = file.type === "image/png" ? ".png" : ".jpg";
      var storageRef = storage.ref(
        `images/banners/clubs/${this.state.clubId}/banner${fileEnding}`
      );
      // Uplaod file
      const task = storageRef.put(file);
      // Update progress bar
      task.on(
        "state_changed",
        (snapshot) => {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ clubBannerProgress: percentage });
        },
        (error) => {
          console.error("Error: ");
          console.error(error);
        },
        () => {
          this.setState({ clubBannerRemoved: false });
          this.context.createInfo(
            "Du hast das Banner erfolgreich hochgeladen.",
            "success",
            4
          );
        }
      );
    }
  };

  handleLogoUpload = (e: any) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file);
    this.setState({ logoPreview: previewUrl });
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      console.error("the filetype is invalid, valid fileTypes are png/jpg");
    } else {
      const fileEnding = file.type === "image/png" ? ".png" : ".jpg";
      var storageRef = storage.ref(
        `images/logos/clubs/${this.state.clubId}/logo${fileEnding}`
      );
      // Uplaod file
      const task = storageRef.put(file);
      // Update progress bar
      task.on(
        "state_changed",
        (snapshot) => {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ clubLogoProgress: percentage });
        },
        (error) => {
          console.error("Error: ");
          console.error(error);
        },
        () => {
          this.setState({ clubLogoRemoved: false });
          this.context.createInfo(
            "Du hast das Logo erfolgreich hochgeladen.",
            "success",
            4
          );
        }
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleShowLogoInBannerChange = () => {
    this.setState(
      { showLogoInBanner: !this.state.showLogoInBanner },
      this.checkChange
    );
  };

  updateClub = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateClub",
      {
        clubId: this.state.clubId,
        showLogoInBanner: this.state.showLogoInBanner,
      },
      1,
      5
    )
      .then(this.handleSuccessClubUpdate)
      .catch(this.handleError);
  };

  handleSuccessClubUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestClub();
      this.context.requestClub(this.state.clubId);
    }
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.showLogoInBanner !==
        this.state.club?.settings?.showLogoInBanner,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.clubLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <div className="upload-main-container">
              <div className="image-container">
                <label className="image-upper-container">
                  <FontAwesomeIcon
                    icon={faImage}
                    size="4x"
                    className="image-icon"
                  />
                  <input
                    type="file"
                    id="uploadBanner"
                    className="fileInput"
                    onChange={this.handleLogoUpload}
                    accept="image/png, image/jpeg"
                  />
                  <span>Vereinslogo auswählen.</span>
                  <progress
                    value={this.state.clubLogoProgress}
                    max="100"
                    id="uploaderBanner"
                    className="progress-bar"
                  />
                </label>
                <div className="image-lower-container">
                  <SecButton
                    className="delete-button"
                    loading={this.state.deleteLogoLoading}
                    onClick={this.handleDeleteLogo}
                    title="Vereinslogo löschen"
                  />
                </div>
              </div>
              <div className="upload-logo-container">
                {(!this.state.club.logoHighRes && !this.state.logoPreview) ||
                this.state.clubLogoRemoved ? (
                  <div className="background-container">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="4x"
                      className="image-icon"
                    />
                    Kein Logo
                  </div>
                ) : (
                  <img
                    src={
                      this.state.logoPreview &&
                      this.state.clubLogoProgress >= 100
                        ? this.state.logoPreview
                        : this.state.club.logoHighRes
                    }
                    alt="Vereinslogo"
                  />
                )}
              </div>
            </div>
            <div className="upload-main-container">
              <div className="image-container">
                <label className="image-upper-container">
                  <FontAwesomeIcon
                    icon={faImage}
                    size="4x"
                    className="image-icon"
                  />
                  <input
                    type="file"
                    id="uploadBanner"
                    className="fileInput"
                    onChange={this.handleBannerUpload}
                    accept="image/png, image/jpeg"
                  />
                  <span>Vereinsbanner auswählen.</span>
                  <progress
                    value={this.state.clubBannerProgress}
                    max="100"
                    id="uploaderBanner"
                    className="progress-bar"
                  />
                </label>
                <div className="image-lower-container">
                  <SecButton
                    className="delete-button"
                    loading={this.state.deleteBannerLoading}
                    onClick={this.handleDeleteBanner}
                    title="Vereinsbanner löschen"
                  />
                </div>
              </div>
              <div className="upload-logo-container">
                {(!this.state.club.bannerHighRes &&
                  !this.state.bannerPreview) ||
                this.state.clubBannerRemoved ? (
                  <div className="background-container">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="4x"
                      className="image-icon"
                    />
                    Kein Banner
                  </div>
                ) : (
                  <img
                    src={
                      this.state.bannerPreview &&
                      this.state.clubBannerProgress >= 100
                        ? this.state.bannerPreview
                        : this.state.club.bannerHighRes
                    }
                    alt="Vereinsbanner"
                  />
                )}
              </div>
            </div>
            <SwitchSetting
              value={this.state.showLogoInBanner}
              onChange={this.handleShowLogoInBannerChange}
              name="Vereinslogo im Vereinsbanner anzeigen"
            />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateClub}
              title="Speichern"
            />
          </div>
        </div>
      </>
    );
  }
}
