import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import app from "./services/firebase";
import Cookies from "universal-cookie";
import "./App.css";
import "./Fonts.css";
import Home from "./screens/Home";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import ClubSearch from "./screens/ClubSearch";
import Club from "./screens/Club";
import ClubSettings from "./screens/ClubSettings";
import Branch from "./screens/Branch";
import BranchSettings from "./screens/BranchSettings";
import MollieEndpoint from "./screens/MollieEndpoint";
import Impressum from "./screens/Impressum";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";
import { MainProvider } from "./contexts/MainContext";
import ForgotPassword from "./screens/ForgotPassword";
import setAdmin from "./screens/setAdmin";
import ConfirmEmail from "./screens/ConfirmEmail";
import ConfirmEmailEndpoint from "./screens/ConfirmEmailEndpoint";
import * as Sentry from "@sentry/react";
import { getCookieDomain } from "./functions/utils";

export default class App extends React.Component<
  {},
  { showCookieBanner: boolean; showScrollToTop: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showCookieBanner: false,
      showScrollToTop: false,
    };
  }

  getSubdomain = () => {
    const hostname = window.location.hostname;
    const hostNameArr = hostname.split(".");
    if (hostNameArr.length >= 2) {
      return hostNameArr[0];
    } else {
      return null;
    }
  };

  componentDidMount = () => {
    // check if the cookie exists
    const cookies = new Cookies();
    const cookiesAllowed = cookies.get("cookies");
    if (cookiesAllowed === undefined) {
      // show cookie banner
      this.setState({ showCookieBanner: true });
    } else {
      if (cookiesAllowed) {
        // cookies are allowed
        this.activateAnalytics();
      } else {
        // cookies are not allowed
      }
    }

    let subdomain = this.getSubdomain();
    const manifestUrl = `manifest${subdomain ? `/${subdomain}` : ""}`;
    document
      .querySelector("#manifest-placeholder")
      ?.setAttribute("href", manifestUrl);
  };

  denyCookies = () => {
    this.setState({ showCookieBanner: false });
    const cookies = new Cookies();
    cookies.set("cookies", false, {
      path: "/",
      maxAge: 31536000,
      domain: getCookieDomain(),
    });
  };

  acceptCookies = () => {
    this.setState({ showCookieBanner: false });
    const cookies = new Cookies();
    cookies.set("cookies", true, {
      path: "/",
      maxAge: 31536000,
      domain: getCookieDomain(),
    });
    this.activateAnalytics();
  };

  activateAnalytics = () => {
    app.performance();
    app.analytics();
    Sentry.init({
      dsn: "https://6581875602fc46e2bead9e83c1b99808@o1199705.ingest.sentry.io/6323548",
      tracesSampleRate: 1.0,
    });
  };

  render() {
    return (
      <>
        <Router>
          <MainProvider>
            <div className="main-inner-container">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/forgotPassword"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/forgotPassword/:clubId"
                  component={ForgotPassword}
                />
                <Route exact path="/confirmEmail" component={ConfirmEmail} />
                <Route
                  exact
                  path="/confirmEmail/:clubId"
                  component={ConfirmEmail}
                />
                <Route
                  exact
                  path="/verify-email/:verificationId"
                  component={ConfirmEmailEndpoint}
                />
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/:clubId" component={Login} />
                <Route
                  exact
                  path="/login/:clubId/:onboardingId"
                  component={Login}
                />
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/sign-up/:clubId" component={SignUp} />
                <Route
                  exact
                  path="/sign-up/:clubId/:onboardingId"
                  component={SignUp}
                />
                <Route exact path="/clubsearch" component={ClubSearch} />
                <Route exact path="/setAdmin" component={setAdmin} />
                <Route
                  path="/club/:clubId/branch/:branchId/:site"
                  component={Branch}
                />
                <Route
                  path="/club/:clubId/branch/:branchId/"
                  component={Branch}
                />
                <Route
                  path="/club/:clubId/branch-settings/:branchId/:site/:objectId"
                  component={BranchSettings}
                />
                <Route
                  path="/club/:clubId/branch-settings/:branchId/:site"
                  component={BranchSettings}
                />
                <Route path="/club/:clubId/:site" component={Club} />
                <Route path="/club/:clubId" component={Club} />
                <Route
                  path="/club-settings/:clubId/:site/:branchId"
                  component={ClubSettings}
                />
                <Route
                  path="/club-settings/:clubId/:site"
                  component={ClubSettings}
                />
                <Route path="/mollie-endpoint" component={MollieEndpoint} />
                <Route path="/impressum/:clubId" component={Impressum} />
                <Route path="/impressum" component={Impressum} />
                <Route path="/terms" component={Terms} />
                <Route path="/privacy" component={Privacy} />
              </Switch>
            </div>
          </MainProvider>
        </Router>
        {this.state.showCookieBanner && (
          <div className="cookie-banner">
            <h2>Diese Seite verwendet Cookies.</h2>
            <p>
              Wir verwenden Cookies, um die Webseite zu verbessern. Dazu werden
              Cookies verwendet, die für unsere kommerziellen Unternehmensziele
              notwendig sind. Diese dienen lediglich für anonymen Statistiken.
            </p>
            <div className="cookie-btn-container">
              <div className="cookie-deny-btn" onClick={this.denyCookies}>
                ablehnen
              </div>
              <div className="cookie-accept-btn" onClick={this.acceptCookies}>
                Akzeptieren
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
