import React from "react";
import { FormElementT } from "../../../Types";
import "./FormEditElement.css";
import EditMultipleChoise from "./MultipleChoise";
import SwitchChoise from "./SwitchChoise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Input from "../../Input";
import DropDown from "../../DropDown";
import InfoContainer from "../../InfoContainer";

export default class FormEditElement extends React.Component<
  {
    elem: FormElementT;
    onChange: (e: any) => void;
    onLableChange: (e: any) => void;
    onToggleEdit: (e: any) => void;
    onTypeChange: (e: any) => void;
    onDelete: (e: any) => void;
    onOptionsChange: (e: any) => void;
  },
  {
    active: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      active: false,
    };
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  onOptionsChange = (val: string) => {
    this.props.onOptionsChange(val.split(";"));
  };

  render() {
    const inputTypes = [
      { name: "Multiple Choise", id: "multiple-choice" },
      { name: "Switch", id: "switch" },
      { name: "Switch mit Textfeld", id: "switch-dependent-textinput" },
      { name: "Textfeld", id: "textinput" },
    ];

    const optionString = this.props.elem.options?.join(";") || "";

    return (
      <div
        className={`edit-element-container ${
          !this.props.elem.edit ? "no-select" : ""
        }`}
      >
        <div className="element-container-edit-icon-container">
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            onClick={this.props.onDelete}
            className="course-settings-icon pointer"
          />
          <FontAwesomeIcon
            icon={faEdit}
            size="lg"
            onClick={this.props.onToggleEdit}
            className="course-settings-icon pointer"
          />
        </div>
        <div className="edit-element-container-inner">
          {this.props.elem.edit ? (
            <div className="edit-element-edit-container">
              <h5>Titel</h5>
              <Input
                onChange={this.props.onLableChange}
                value={this.props.elem.label}
              />
              <h5>Typ</h5>
              <DropDown
                name={this.props.elem.id}
                onChange={this.props.onTypeChange}
                options={inputTypes}
                value={this.props.elem.type}
              />
              {this.props.elem.type === "multiple-choice" && (
                <>
                  <h5>Auswahlmöglichkeiten</h5>
                  <InfoContainer>
                    Bitte gebe die Auswahlmöglichkeiten nacheinander mit einem
                    Semikolon (;) getrennt ein.
                  </InfoContainer>
                  <Input onChange={this.onOptionsChange} value={optionString} />
                </>
              )}
            </div>
          ) : (
            <>
              <strong>{this.props.elem.label}</strong>
              {this.props.elem.type === "multiple-choice" &&
                this.props.elem.options !== undefined && (
                  <EditMultipleChoise
                    id={this.props.elem.id}
                    options={this.props.elem.options}
                    onChange={() => {}}
                  />
                )}

              {this.props.elem.type === "switch" && (
                <SwitchChoise
                  id={this.props.elem.id}
                  onChange={this.toggleActive}
                  value={this.state.active}
                />
              )}

              {this.props.elem.type === "switch-dependent-textinput" && (
                <div className="switch-textbox-wrapper">
                  <SwitchChoise
                    id={this.props.elem.id}
                    onChange={this.toggleActive}
                    value={this.state.active}
                  />
                  {this.state.active && (
                    <Input
                      onChange={() => {}}
                      value={""}
                      className="no-margin"
                    />
                  )}
                </div>
              )}

              {this.props.elem.type === "textinput" && (
                <Input onChange={() => {}} value={""} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
