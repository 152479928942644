import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./BookModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Selector from "../Selector";
import {
  dateToStr,
  minTimeToStr,
  objectToArray,
  strTimeToMin,
} from "../../functions/utils";
import SecButton from "../../components/SecButton";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import { fetchRetry } from "../../functions/request";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default class ConfirmationModal extends React.Component<
  {
    show: boolean;
    handleClose: any;
    clubId: string;
    branchId: string;
    selectedObject: any;
    selectedTimeInMin: number;
    date: Date;
    refreshBookings: any;
  },
  {
    startTimeArr: Array<any>;
    endTimeArr: Array<any>;
    selectedStartTimeInMin: number;
    selectedEndTimeInMin: number;
    selectedBookingType: string;
    startTimeInMin: number;
    endTimeInMin: number;
    bookingInterval: number;
    bookingTypeArr: Array<any>;
    currScreen: string;
    userArr: Array<any>;
    users: any;
    usersLoaded: boolean;
    partnerArr: Array<string>;
    bookingLoading: boolean;
    bookingTitle: string;
    bookingDescription: string;
    errorMessage: null | string;
    dailyRepeat: number;
    weeklyRepeat: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      startTimeArr: [],
      endTimeArr: [],
      selectedStartTimeInMin: this.props.selectedTimeInMin,
      selectedEndTimeInMin:
        this.props.selectedTimeInMin +
        this.props.selectedObject.settings.bookingInterval,
      selectedBookingType: this.sortBookingTypeArr(
        objectToArray(this.props.selectedObject.bookingTypes)
      )[0]?.id,
      startTimeInMin: strTimeToMin(
        this.props.selectedObject.settings.startTime
      ),
      endTimeInMin: strTimeToMin(this.props.selectedObject.settings.stopTime),
      bookingInterval: this.props.selectedObject.settings.bookingInterval,
      bookingTypeArr: this.sortBookingTypeArr(
        objectToArray(this.props.selectedObject.bookingTypes)
      ),
      currScreen: "bookingtype",
      userArr: [],
      users: {},
      usersLoaded: false,
      partnerArr: [],
      bookingLoading: false,
      bookingTitle: "",
      bookingDescription: "",
      errorMessage: null,
      weeklyRepeat: 0,
      dailyRepeat: 0,
    };
  }

  sortBookingTypeArr = (bookingTypeArr: Array<any>) => {
    bookingTypeArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    return bookingTypeArr;
  };

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.selectedTimeInMin !== this.props.selectedTimeInMin ||
      prevProps.selectedObject !== this.props.selectedObject
    ) {
      this.setState(
        {
          selectedStartTimeInMin: this.props.selectedTimeInMin,
          selectedEndTimeInMin:
            this.props.selectedTimeInMin +
            this.props.selectedObject.settings.bookingInterval,
          selectedBookingType: this.sortBookingTypeArr(
            objectToArray(this.props.selectedObject.bookingTypes)
          )[0]?.id,
          startTimeInMin: strTimeToMin(
            this.props.selectedObject.settings.startTime
          ),
          endTimeInMin: strTimeToMin(
            this.props.selectedObject.settings.stopTime
          ),
          bookingInterval: this.props.selectedObject.settings.bookingInterval,
          bookingTypeArr: this.sortBookingTypeArr(
            objectToArray(this.props.selectedObject.bookingTypes)
          ),
        },
        this.generateTimeArr
      );
    }
  }

  componentDidMount = () => {
    this.generateTimeArr();
  };

  generateTimeArr = () => {
    const startTimeArr: Array<any> = [];
    const endTimeArr: Array<any> = [];
    // const selectedBookingType = this.state.selectedBookingType
    //   ? this.props.selectedObject.role[this.state.selectedBookingType]
    //   : null;
    for (
      var i = this.state.startTimeInMin;
      i < this.state.endTimeInMin;
      i = i + this.state.bookingInterval
    ) {
      startTimeArr.push({ id: i, name: `${minTimeToStr(i)} Uhr` });
      if (i > this.state.selectedStartTimeInMin) {
        endTimeArr.push({ id: i, name: `${minTimeToStr(i)} Uhr` });
      }
    }
    this.setState({
      startTimeArr,
      endTimeArr,
      selectedStartTimeInMin: this.props.selectedTimeInMin,
      selectedEndTimeInMin:
        this.props.selectedTimeInMin + this.state.bookingInterval,
    });
  };

  handleStartTimeSelected = (val: number) => {
    this.setState(
      {
        selectedStartTimeInMin: val,
      },
      this.generateTimeArr
    );
  };

  handleEndTimeSelected = (val: number) => {
    this.setState({
      selectedEndTimeInMin: val,
    });
  };

  handleBookingTypeSelected = (bookingTypeId: string) => {
    this.setState({ selectedBookingType: bookingTypeId }, this.generateTimeArr);
  };

  handleContinueBooking = () => {
    const selectedBookingType =
      this.props.selectedObject.bookingTypes[this.state.selectedBookingType];
    if (this.state.currScreen === "bookingtype") {
      if (
        selectedBookingType.allowPartners &&
        selectedBookingType.maxPartners > 0
      ) {
        this.handleGetUsers();
      }
      this.setState({ currScreen: "time", userArr: [] });
    } else if (this.state.currScreen === "time") {
      if (
        !selectedBookingType.allowPartners &&
        selectedBookingType.maxPartners === 0
      ) {
        if (
          selectedBookingType.allowTitle ||
          selectedBookingType.allowDescription
        ) {
          this.setState({ currScreen: "info" });
        } else if (
          selectedBookingType.allowDailyRepeat ||
          selectedBookingType.allowWeeklyRepeat
        ) {
          this.setState({ currScreen: "repeat" });
        } else {
          this.setState({ currScreen: "overview" });
        }
      } else {
        this.setState({ currScreen: "partners" });
      }
    } else if (this.state.currScreen === "partners") {
      if (
        selectedBookingType.allowTitle ||
        selectedBookingType.allowDescription
      ) {
        this.setState({ currScreen: "info" });
      } else if (
        selectedBookingType.allowDailyRepeat ||
        selectedBookingType.allowWeeklyRepeat
      ) {
        this.setState({ currScreen: "repeat" });
      } else {
        this.setState({ currScreen: "overview" });
      }
    } else if (this.state.currScreen === "info") {
      if (
        selectedBookingType.allowDailyRepeat ||
        selectedBookingType.allowWeeklyRepeat
      ) {
        this.setState({ currScreen: "repeat" });
      } else {
        this.setState({ currScreen: "overview" });
      }
    } else if (this.state.currScreen === "repeat") {
      this.setState({ currScreen: "overview" });
    }
  };

  handleGetUsers = () => {
    this.setState({ usersLoaded: false });
    fetchRetry(
      "getUsersToBook",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        bookingTypeId: this.state.selectedBookingType,
      },
      1,
      5
    )
      .then(this.handleUsersToBookSuccess)
      .catch(this.handleError);
  };

  handleUsersToBookSuccess = ({ data }: any) => {
    this.setState({ usersLoaded: true });
    if (data.success) {
      const userArr = objectToArray(data.data).map((obj: any) => {
        obj.name = `${obj.fName} ${obj.lName}`;
        return obj;
      });
      this.setState({ userArr, users: data.data });
    }
  };

  handleClose = () => {
    this.setState({ currScreen: "bookingtype" });
    this.props.handleClose();
  };

  handleBook = () => {
    this.setState({ bookingLoading: true });
    fetchRetry(
      "book",
      {
        clubId: this.props.clubId,
        branchId: this.props.branchId,
        objectId: this.props.selectedObject.id,
        bookingTypeId: this.state.selectedBookingType,
        title: this.state.bookingTitle,
        description: this.state.bookingDescription,
        partners: this.state.partnerArr,
        date: dateToStr(this.props.date),
        startTime: minTimeToStr(this.state.selectedStartTimeInMin),
        endTime: minTimeToStr(this.state.selectedEndTimeInMin),
        dailyRepeat: this.state.dailyRepeat + 1,
        weeklyRepeat: this.state.weeklyRepeat + 1,
        redirectUrl: "https://google.de",
      },
      1,
      5
    )
      .then(this.handleBookSuccess)
      .catch(this.handleError);
  };

  handleBookSuccess = ({ data }: any) => {
    this.setState({ bookingLoading: false });
    if (data.success) {
      this.setState({ errorMessage: null });
      this.props.refreshBookings();
      this.handleClose();
    } else {
      this.setState({ errorMessage: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  selectPartner = (index: number, userId: string) => {
    const userArr = this.state.partnerArr;
    userArr[index] = userId;
    this.setState({ partnerArr: userArr });
  };

  handleBookingTitleChange = (val: any) => {
    this.setState({ bookingTitle: val });
  };

  handleBookingDescriptionChange = (val: any) => {
    this.setState({ bookingDescription: val });
  };

  handleDailyRepeatChange = (val: any) => {
    this.setState({ dailyRepeat: val });
  };

  handleWeeklyRepeatChange = (val: any) => {
    this.setState({ weeklyRepeat: val });
  };

  render() {
    const selectedBookingType = this.state.selectedBookingType
      ? this.props.selectedObject.bookingTypes[this.state.selectedBookingType]
      : { maxPartners: 0 };
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="book"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          {/* {this.state.selectedTimeInMin} */}
          <div className="confirm-modal-inner">
            <div className="modal-cross-container" onClick={this.handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {this.state.currScreen === "bookingtype" && (
              <>
                <div className="modal-title-container">
                  <span className="modal-title">
                    Buchungsart
                    <small className="branch-title">
                      {" "}
                      | {this.props.selectedObject.name} -{" "}
                      {dateToStr(this.props.date)}
                    </small>
                  </span>
                </div>
                <div className="branch-small-label">
                  <span>Buchungsart:</span>
                </div>
                <Selector
                  placeholder="Buchungsart"
                  options={this.state.bookingTypeArr}
                  value={this.state.selectedBookingType}
                  onSelect={this.handleBookingTypeSelected}
                  default={null}
                />
                <button
                  className={"modal-btn"}
                  onClick={this.handleContinueBooking}
                >
                  weiter
                </button>
              </>
            )}
            {this.state.currScreen === "time" && (
              <>
                <div className="modal-title-container">
                  <span className="modal-title">
                    Buchungszeit
                    <small className="branch-title">
                      {" "}
                      | {this.props.selectedObject.name} -{" "}
                      {dateToStr(this.props.date)}
                    </small>
                  </span>
                </div>
                <div className="branch-small-label">
                  <span>Start:</span>
                </div>
                <Selector
                  placeholder="Startzeit"
                  options={this.state.startTimeArr}
                  value={this.state.selectedStartTimeInMin}
                  onSelect={this.handleStartTimeSelected}
                  default={null}
                />
                <div className="branch-small-label">
                  <span>Ende:</span>
                </div>
                <Selector
                  placeholder="Endzeit"
                  options={this.state.endTimeArr}
                  value={this.state.selectedEndTimeInMin}
                  onSelect={this.handleEndTimeSelected}
                  default={null}
                />
                <button
                  className={"modal-btn"}
                  onClick={this.handleContinueBooking}
                >
                  weiter
                </button>
              </>
            )}
            {this.state.currScreen === "partners" && (
              <>
                <div className="modal-title-container">
                  <span className="modal-title">
                    Buchungspartner
                    <small className="branch-title">
                      {" "}
                      | {this.props.selectedObject.name} -{" "}
                      {dateToStr(this.props.date)}
                    </small>
                  </span>
                </div>
                {[...Array(selectedBookingType.maxPartners)].map(
                  (x, i: number) => (
                    <>
                      <div className="branch-small-label" key={i}>
                        <span>{i + 1}. Partner auswählen:</span>
                      </div>
                      <Selector
                        placeholder={`${i + 1}. Partner`}
                        options={this.state.userArr}
                        value={this.state.partnerArr[i]}
                        onSelect={(userId: string) => {
                          this.selectPartner(i, userId);
                        }}
                        default={"-- Partner auswählen --"}
                      />
                    </>
                  )
                )}
                <button
                  className={"modal-btn"}
                  onClick={this.handleContinueBooking}
                >
                  weiter
                </button>
              </>
            )}
            {this.state.currScreen === "info" && (
              <>
                <div className="modal-title-container">
                  <span className="modal-title">
                    Info
                    <small className="branch-title">
                      {" "}
                      | {this.props.selectedObject.name} -{" "}
                      {dateToStr(this.props.date)}
                    </small>
                  </span>
                </div>
                {selectedBookingType.allowTitle && (
                  <>
                    <div className="branch-small-label">
                      <span>Titel:</span>
                    </div>
                    <Input
                      name="title-input"
                      placeholder="Titel"
                      value={this.state.bookingTitle}
                      onChange={this.handleBookingTitleChange}
                      className="book-modal-input"
                    />
                  </>
                )}
                {selectedBookingType.allowDescription && (
                  <>
                    <div className="branch-small-label">
                      <span>Beschreibung:</span>
                    </div>
                    <Input
                      name="title-input"
                      placeholder={"Beschreibung"}
                      value={this.state.bookingDescription}
                      onChange={this.handleBookingDescriptionChange}
                      className="book-modal-input"
                    />
                  </>
                )}

                <button
                  className={"modal-btn"}
                  onClick={this.handleContinueBooking}
                >
                  weiter
                </button>
              </>
            )}
            {this.state.currScreen === "repeat" && (
              <>
                <div className="modal-title-container">
                  <span className="modal-title">
                    Wiederholung
                    <small className="branch-title">
                      {" "}
                      | {this.props.selectedObject.name} -{" "}
                      {dateToStr(this.props.date)}
                    </small>
                  </span>
                </div>
                {selectedBookingType.allowDailyRepeat && (
                  <>
                    <div className="branch-small-label">
                      <span>Tägliche Wiederholung:</span>
                    </div>
                    <Input
                      name="title-input"
                      placeholder="Tägliche Wiederholung"
                      value={this.state.dailyRepeat}
                      onChange={this.handleDailyRepeatChange}
                      className="book-modal-input"
                      type="number"
                      min={0}
                      max={6}
                    />
                  </>
                )}
                {selectedBookingType.allowWeeklyRepeat && (
                  <>
                    <div className="branch-small-label">
                      <span>Wochentliche Wiederholung:</span>
                    </div>
                    <Input
                      name="title-input"
                      placeholder={"Wöchentliche Wiederholung"}
                      value={this.state.weeklyRepeat}
                      onChange={this.handleWeeklyRepeatChange}
                      className="book-modal-input"
                      type="number"
                      min={0}
                      max={51}
                    />
                  </>
                )}

                <button
                  className={"modal-btn"}
                  onClick={this.handleContinueBooking}
                >
                  weiter
                </button>
              </>
            )}
            {this.state.currScreen === "overview" && (
              <>
                <div className="modal-title-container">
                  <span className="modal-title">
                    Übersicht
                    <small className="branch-title">
                      {" "}
                      | {this.props.selectedObject.name} -{" "}
                      {dateToStr(this.props.date)}
                    </small>
                  </span>
                </div>
                <ErrorMessage message={this.state.errorMessage} />
                <table className="book-modal-table-list">
                  <tbody>
                    <tr>
                      <td>Buchungsart:</td>
                      <td>
                        {this.props.selectedObject.bookingTypes[
                          this.state.selectedBookingType
                        ]?.name
                          ? this.props.selectedObject.bookingTypes[
                              this.state.selectedBookingType
                            ].name
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Objekt:</td>
                      <td>{this.props.selectedObject.name}</td>
                    </tr>
                    <tr>
                      <td>Datum:</td>
                      <td>{dateToStr(this.props.date)}</td>
                    </tr>
                    <tr>
                      <td>Start:</td>
                      <td>
                        {minTimeToStr(this.state.selectedStartTimeInMin)} Uhr
                      </td>
                    </tr>
                    <tr>
                      <td>Ende:</td>
                      <td>
                        {minTimeToStr(this.state.selectedEndTimeInMin)} Uhr
                      </td>
                    </tr>
                    {this.state.partnerArr.map(
                      (partnerId: string, index: number) => (
                        <tr key={index}>
                          <td>{index + 1}. Partner: </td>
                          <td>
                            {this.state.users[partnerId].fName}{" "}
                            {this.state.users[partnerId].lName}
                          </td>
                        </tr>
                      )
                    )}
                    {selectedBookingType.allowTitle && (
                      <tr>
                        <td>Titel:</td>
                        <td>{this.state.bookingTitle}</td>
                      </tr>
                    )}
                    {selectedBookingType.allowDescription && (
                      <tr>
                        <td>Beschreibung:</td>
                        <td>{this.state.bookingDescription}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <SecButton
                  change={true}
                  color="green"
                  loading={this.state.bookingLoading}
                  onClick={this.handleBook}
                  title="Buchen"
                />
                <button
                  className={"modal-btn modal-btn-red"}
                  onClick={this.handleClose}
                >
                  Abbrechen
                </button>
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }
}
