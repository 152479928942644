import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";
import { MainContext } from "../../contexts/MainContext";
import BackElement from "../../components/BackElement";
import qs from "qs";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    familyId: string;
  }>;

export default class Family extends React.Component<
  ComposedProps,
  {
    clubId: string;
    familyId: string;
    users: any;
    userTableArr: Array<RowData>;
    fullyLoaded: boolean;
    backLink: string | null | any;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      familyId: this.props.match.params.familyId,
      users: {},
      userTableArr: [],
      fullyLoaded: false,
      backLink:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).back || null,
    };
  }

  componentDidMount = async () => {
    await this.requestFamilyAccount();
  };

  requestFamilyAccount = async () => {
    await fetchRetry(
      "getFamilyMembersClub",
      {
        clubId: this.state.clubId,
        familyAccountId: this.state.familyId,
      },
      1,
      5
    )
      .then(this.handleFamilyAccount)
      .catch(this.handleError);
  };

  handleFamilyAccount = ({ data }: any) => {
    if (data.success) {
      const userArr = data.data;
      const userTableArr = userArr.map((user: any) => {
        return {
          cells: [
            {
              value: user.fName,
              sort: user.fName,
              search: `${user.fName} ${user.lName}`,
            },
            {
              value: user.lName,
              sort: user.lName,
              search: user.lName,
            },
            {
              value: user.email,
              sort: user.email,
              search: user.email,
            },
            {
              value: user.isClubMember ? "Ja" : "Nein",
            },
          ],
          onClick: user.isClubMember
            ? `/club-settings/${this.state.clubId}/users/${user.id}`
            : null,
        };
      });

      this.setState({
        userTableArr,
        fullyLoaded: true,
      });

      this.setState({ users: userArr });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleRedirect = (redirect: string) => {
    if (redirect) {
      this.props.history.push({
        pathname: redirect,
      });
    }
  };

  handleUserClick = (userId: any) => {
    this.props.history.push({
      pathname: `/club-settings/${this.state.clubId}/users/${userId}`,
    });
  };

  render() {
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text={
                this.state.backLink ? "zurück" : "zurück zur Nutzerübersicht"
              }
              to={
                this.state.backLink ||
                `/club-settings/${this.state.clubId}/users`
              }
            />

            <div className="modal-title-container">
              <span className="modal-title">Familienaccount</span>
            </div>
            <Table
              header={[
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 950 },
                { title: "Ist Vereinsmitglied" },
              ]}
              data={this.state.userTableArr}
              loaded={this.state.fullyLoaded}
              skeletonRows={3}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={0}
              counter
            />
          </div>
        </div>
      </>
    );
  }
}
