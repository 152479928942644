import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import "./Login.css";
import { auth } from "../services/firebase";
import ErrorMessage from "../components/ErrorMessage";
import { MainContext } from "../contexts/MainContext";
import { firebaseErrors } from "../services/firebaseErrorCodes";
import HashLoader from "react-spinners/HashLoader";
import qs from "qs";
import { getRightSeperator } from "../functions/utils";
import InfoContainer from "../components/InfoContainer";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    onboardingId: string;
  }>;

export default class Login extends React.Component<
  ComposedProps,
  {
    clubId: string;
    onboardingId: string;
    authenticated: boolean;
    userLoaded: boolean;
    redirect: string | null;
    email: string;
    password: string;
    errorMessage: string | null;
    customMessage: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      onboardingId: this.props.match.params.onboardingId,
      authenticated: false,
      userLoaded: false,
      redirect: null,
      email: "",
      password: "",
      errorMessage: null,
      customMessage:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).customMessage as string | null,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      this.setState({ userLoaded: true });
      if (user) {
        this.setState({
          authenticated: true,
          redirect: "/",
        });
      } else {
        this.setState({
          authenticated: false,
        });
      }
    });
    if (this.state.clubId) {
      this.context.requestClub(this.state.clubId);
    }
  }

  handleLoginGoogle = () => {
    this.context.signInWithGoogle();
  };

  handleLogin = () => {
    this.context
      .login(this.state.email, this.state.password)
      .then(this.handleSuccess)
      .catch(this.handleError);
  };

  handleSuccess = (user: any) => {
    this.setState({
      password: "",
      errorMessage: null,
    });
    const redirectProps: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).redirect;
    const joinBranchProps: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).joinBranch;
    const nextRedirect: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).nextRedirect;
    if (!redirectProps) {
      this.setState({ redirect: "/" });
    } else {
      const params = qs.stringify({
        joinBranch: joinBranchProps,
        redirect: nextRedirect,
      });
      window.location.href = `${redirectProps}${
        params ? `${getRightSeperator(redirectProps)}${params}` : ""
      }`;
    }
  };

  handleError = (error: any) => {
    const errorMsg: any = firebaseErrors[error.code] || error.message;
    this.setState({
      errorMessage: errorMsg,
    });
  };

  handleChangePassword = (val: any) => {
    this.setState({ password: val.target.value });
  };

  handleChangeEmail = (val: any) => {
    this.setState({ email: val.target.value });
  };

  getRedirect = () => {
    const redirectProps: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).redirect;
    const joinBranchProps: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).joinBranch;
    const nextRedirect: any = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).nextRedirect;
    const params = qs.stringify({
      redirect: redirectProps,
      nextRedirect: nextRedirect,
      joinBranch: joinBranchProps,
    });
    if (params) return `?${params}`;
    return "";
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.context.clubLoaded && this.state.clubId) {
      return (
        <div className="loading-container">
          <HashLoader color={"#c31924"} size={100} loading={true} />
        </div>
      );
    }
    return (
      <>
        <div className="main-login-screen">
          <div className="custom-shape-divider-bottom-1633269866 hide-mobile">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
          {this.context.club.logoLowRes && (
            <div className="login-img-container hide-mobile">
              <img
                className="login-logo"
                src={this.context.club.logoLowRes}
                alt="Vereinslogo"
              />
            </div>
          )}
          <div className="signUpField">
            {this.context.club.logoLowRes && (
              <div className="login-img-container-mobile show-mobile">
                <img
                  className="login-logo"
                  src={this.context.club.logoLowRes}
                  alt="Vereinslogo"
                />
              </div>
            )}
            <div className="textContainer">
              <h1>Login</h1>
            </div>
            <InfoContainer status="info" active={!!this.state.customMessage}>
              {this.state.customMessage}
            </InfoContainer>
            <div className="input-field">
              <input
                className="text-input"
                name="email"
                type="email"
                value={this.state.email}
                placeholder=" "
                onChange={this.handleChangeEmail}
              />
              <label className="input-label">E-Mail</label>
            </div>
            <div className="input-field">
              <input
                className="text-input"
                name="password"
                type="password"
                value={this.state.password}
                placeholder=" "
                onChange={this.handleChangePassword}
              />
              <label className="input-label">Passwort</label>
            </div>

            <button className="login-btn" onClick={this.handleLogin}>
              Anmelden
            </button>
            <Link
              to={`/forgotPassword${
                this.state.clubId ? `/${this.state.clubId}` : ""
              }`}
              className="password-forgotten small-text no-underline"
            >
              Passwort vergessen?
            </Link>

            <ErrorMessage message={this.state.errorMessage} />
            <div className="small-space">
              <h1 className="small-text right">
                Noch nicht registriert?{" "}
                <Link
                  to={`/sign-up${
                    this.state.clubId ? `/${this.state.clubId}` : ""
                  }${this.getRedirect()}`}
                  className="black"
                >
                  Registrieren
                </Link>
              </h1>
            </div>
            <br />
            <h4 className="center">ODER</h4>

            {/* <br />
            <button
              id="google-auth-button"
              type="button"
              className="google-button"
              onClick={this.handleLoginGoogle}
            >
              <span className="helper"></span>
              <img
                src="images/icons/google.svg"
                alt="google-icon"
                className="google-img"
              />
              <span className="google-text">Mit Google anmelden</span>
            </button> */}
            <Link to="/" className="google-button black no-underline">
              <span className="google-text">Als Gast fortfahren</span>
            </Link>
          </div>
        </div>
      </>
    );
  }
}
