import React from "react";
import { RouteComponentProps } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Feed.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import {
  addIdToObject,
  dateTosimpleDateStr,
  firebaseDateToDateObj,
  objectToArray,
} from "../../functions/utils";
import qs from "qs";
import SingleSelector from "../../components/SingleSelector";
import { MainContext } from "../../contexts/MainContext";
import FeedEntry from "../../components/FeedEntry";

type ComposedProps = RouteComponentProps<{
  clubId: string;
  branchId: string;
}>;

type FeedTypes =
  | "course-booked"
  | "course-canceled"
  | "course-refunded"
  | "membership-booked"
  | "membership-canceled"
  | "membership-refunded";

export type FeedEntrys = {
  id: string;
  createdAt: Date;
  clubId: string;
  branchId: string;
  courseBooking: string;
  userId: string | null;
  message: string;
  courseId: string | null;
  subscriptionId: string | null;
  tagArr: Array<string>;
  type: FeedTypes;
};

export default class Feed extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    feedEntrys: Array<FeedEntrys>;
    feedLoaded: boolean;
    batchSize: number;
    hasMore: boolean;
    selectedUserId: string | null | any;
    selectedElementId: string | null | any;
    selectedType: string | null | any;
    selectedEventTypeElem: any;
    usersLoaded: boolean;
    userOptions: Array<any>;
    selectedUserElem: any;
    subscriptionsLoaded: boolean;
    selectedElement: any;
    elementOptions: Array<any>;
    subscriptions: Array<any>;
    courses: Array<any>;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      feedEntrys: [],
      feedLoaded: false,
      batchSize: 15,
      hasMore: true,
      selectedUserId:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).suserId || null,
      selectedElementId:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).selemId || null,
      selectedType:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).stype || null,
      selectedEventTypeElem: { name: "alle", value: null },
      usersLoaded: false,
      userOptions: [],
      selectedUserElem: { name: "alle", value: null },
      subscriptionsLoaded: false,
      selectedElement: { name: "alle", value: null },
      elementOptions: [],
      subscriptions: [],
      courses: [],
    };
  }

  componentDidMount = () => {
    this.requestFeed();
    this.requestUsers();
    Promise.all([this.requestCourses(), this.requestSubscriptions()]).then(
      this.handleFormatCourseSubscription
    );
    // this.requestCourses();
    this.requestSubscriptions();
  };

  requestFeed = () => {
    this.setState({ feedLoaded: false });
    fetchRetry(
      "getBranchFeed",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        startAfterDocId:
          this.state.feedEntrys.length > 0
            ? this.state.feedEntrys[this.state.feedEntrys.length - 1].id
            : null,
        userId: this.state.selectedUserId,
        elemId: this.state.selectedElementId,
        type: this.state.selectedType,
        limit: this.state.batchSize,
      },
      1,
      5
    )
      .then(this.handleBranchFeed)
      .catch(this.handleError);
  };

  requestUsers = () => {
    fetchRetry(
      "getUsersFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleUsers)
      .catch(this.handleError);
  };

  requestSubscriptions = async () => {
    await fetchRetry(
      "getSubscriptionsOfBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        withDeleted: false,
        limit: 50,
      },
      1,
      5
    )
      .then(this.handleSubscriptionsSuccess)
      .catch(this.handleError);
  };

  requestCourses = async () => {
    await fetchRetry(
      "getCoursesOfBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        limit: 500,
        onlyFuture: false,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleCoursesSuccess = ({ data }: any) => {
    if (data.success) {
      const courses = data.data;
      this.setState({ courses });
    }
  };

  handleSubscriptionsSuccess = ({ data }: any) => {
    this.setState({ subscriptionsLoaded: true });
    if (data.success) {
      const subscriptions = data.data;
      this.setState({ subscriptions });
    }
  };

  handleFormatCourseSubscription = () => {
    const elementOptions = this.state.subscriptions
      .map((subscription: any) => {
        return {
          name: subscription.title,
          value: subscription.id,
          color: "#5c67ee",
        };
      })
      .concat(
        this.state.courses.map((course: any) => {
          return {
            name: course.title,
            value: course.id,
            color: "#ee5c5c",
          };
        })
      );
    elementOptions.unshift({ name: "alle", value: null, color: "#ffd025" });
    elementOptions.forEach((option: any) => {
      if (option.value === this.state.selectedElementId) {
        this.setState({ selectedElement: option });
      }
    });
    this.setState({ elementOptions });
  };

  handleUsers = ({ data }: any) => {
    this.setState({ usersLoaded: true });
    if (data.success) {
      const users = addIdToObject(data.data);
      const userArr = objectToArray(users);
      const userOptions = userArr.map((user: any) => {
        return {
          name: `${user.fName} ${user.lName}`,
          value: user.id,
          color: "#ee5c5c",
        };
      });
      userOptions.unshift({ name: "alle", value: null, color: "#ffd025" });
      userOptions.forEach((option: any) => {
        if (option.value === this.state.selectedUserId) {
          this.setState({ selectedUserElem: option });
        }
      });
      this.setState({ userOptions });
    }
  };

  handleBranchFeed = ({ data }: any) => {
    if (data.success) {
      const feedEntrys = this.state.feedEntrys;
      const feedIds = feedEntrys.map((entry: any) => entry.id);
      const feed = feedEntrys.concat(
        data.data.filter((entry: any) => !feedIds.includes(entry.id))
      );

      this.setState({
        feedEntrys: feed,
        feedLoaded: true,
        hasMore:
          data.data.length !== 0 && data.data.length >= this.state.batchSize,
      });
    }
  };

  requestNewQuery = () => {
    this.props.history.push({
      search: qs.stringify({
        suserId: this.state.selectedUserId,
        selemId: this.state.selectedElementId,
        stype: this.state.selectedType,
      }),
    });
    this.setState(
      { hasMore: true, feedEntrys: [], feedLoaded: false },
      this.requestFeed
    );
  };

  handleEventTypeSelect = (eventType: string) => {
    this.setState({ selectedType: eventType }, this.requestNewQuery);
  };

  handleElemSelect = (elemId: string) => {
    this.setState({ selectedElementId: elemId }, this.requestNewQuery);
  };

  handleUserSelect = (userId: string) => {
    this.setState({ selectedUserId: userId }, this.requestNewQuery);
  };

  handleError = (err: any) => {
    console.error(err);
  };
  render() {
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Bereichsfeed" />
            <div className="search-bar-wrapper">
              <SingleSelector
                searchTextPlaceholder="Event-Typ"
                arr={[
                  { name: "alle", value: null, color: "#ffd025" },
                  { name: "Buchungen", value: "booking" },
                  { name: "Stornierungen", value: "cancellation" },
                  { name: "Fehlgeschlagene Zahlungen", value: "paymentfailed" },
                  {
                    name: "Bereichsmitgliedschaften",
                    value: "branchmembership",
                  },
                ]}
                selectedElem={this.state.selectedEventTypeElem}
                onSelect={(eventElem: any) => {
                  this.setState({
                    selectedEventTypeElem: eventElem,
                  });
                  this.handleEventTypeSelect(eventElem.value);
                }}
              />
              <SingleSelector
                searchTextPlaceholder="Kurs/Mitgliedschaft"
                arr={this.state.elementOptions}
                selectedElem={this.state.selectedElement}
                onSelect={(eventElem: any) => {
                  this.setState({
                    selectedElement: eventElem,
                  });
                  this.handleElemSelect(eventElem.value);
                }}
              />
              <SingleSelector
                searchTextPlaceholder="Nutzer"
                arr={this.state.userOptions}
                selectedElem={this.state.selectedUserElem}
                onSelect={(eventElem: any) => {
                  this.setState({
                    selectedUserElem: eventElem,
                  });
                  this.handleUserSelect(eventElem.value);
                }}
              />
            </div>

            <InfiniteScroll
              dataLength={this.state.feedEntrys.length}
              next={this.requestFeed}
              hasMore={this.state.hasMore}
              loader={<></>}
              height={window.innerHeight - 300}
              scrollThreshold={"200px"}
              className="infinity-scroll-feed"
            >
              <table className="user-table no-margin">
                <thead className="feed-thead">
                  <tr>
                    <th>Zeitpunkt</th>
                    <th>Nachricht</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.feedEntrys.map((feed, index) => (
                    <tr key={index} onClick={() => {}}>
                      <td>
                        {dateTosimpleDateStr(
                          firebaseDateToDateObj(feed.createdAt)
                        )}
                      </td>
                      <td>
                        <FeedEntry feed={feed} />
                      </td>
                    </tr>
                  ))}
                  {!this.state.feedLoaded && (
                    <>
                      <tr>
                        <td>
                          <div className="f-skeleton f-skeleton-text f-skeleton-text-37"></div>
                        </td>
                        <td>
                          <div className="f-skeleton f-skeleton-text f-skeleton-text-60"></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="f-skeleton f-skeleton-text f-skeleton-text-40"></div>
                        </td>
                        <td>
                          <div className="f-skeleton f-skeleton-text f-skeleton-text-50"></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="f-skeleton f-skeleton-text f-skeleton-text-35"></div>
                        </td>
                        <td>
                          <div className="f-skeleton f-skeleton-text f-skeleton-text-60"></div>
                        </td>
                      </tr>
                    </>
                  )}
                  {this.state.feedEntrys.length === 0 &&
                    this.state.feedLoaded && (
                      <tr>
                        <td colSpan={2} className="center-text">
                          <div>
                            Mit diesem Filter wurden keine Einträge gefunden.
                          </div>
                        </td>
                      </tr>
                    )}
                  {this.state.hasMore && this.state.feedLoaded && (
                    <tr>
                      <td colSpan={2}>
                        <div onClick={this.requestFeed}>
                          Weitere Einträge laden
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </>
    );
  }
}
