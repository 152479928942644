import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Settings.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import SwitchSetting from "../../components/SwitchSetting";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";
import SubTitle from "../../components/SubTitle";
import TextArea from "../../components/TextArea";
import { storage } from "../../services/firebase";
import Upload from "../../components/Upload";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Settings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    requestNeeded: boolean;
    change: boolean;
    updateLoading: boolean;
    useMembershipNumber: boolean;
    askForMembershipNumber: boolean;
    impressum: string;
    agbProgress: number;
    removeAgbLoading: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      requestNeeded: false,
      change: false,
      updateLoading: false,
      useMembershipNumber: false,
      askForMembershipNumber: false,
      impressum: "",
      agbProgress: 0,
      removeAgbLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestClub();
  };

  requestClub = () => {
    fetchRetry("getClubFromId", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleClub)
      .catch(this.handleError);
    // this.context
    // .requestClub(this.state.clubId)
    // .then(this.handleClub)
    // .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      this.setState(
        {
          club: club,
          useMembershipNumber: club.settings?.useMembershipNumber,
          askForMembershipNumber: club.settings?.askForMembershipNumber,
          impressum: club.impressum,
        },
        this.checkChange
      );
    }
  };

  updateClub = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateClub",
      {
        clubId: this.state.clubId,
        useMembershipNumber: this.state.useMembershipNumber,
        askForMembershipNumber: this.state.askForMembershipNumber,
        impressum: this.state.impressum,
      },
      1,
      5
    )
      .then(this.handleSuccessClubUpdate)
      .catch(this.handleError);
  };

  handleSuccessClubUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestClub();
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleUseMembershipNumber = () => {
    this.setState(
      { useMembershipNumber: !this.state.useMembershipNumber },
      this.checkChange
    );
  };

  handleAskForMembershipNumber = () => {
    this.setState(
      { askForMembershipNumber: !this.state.askForMembershipNumber },
      this.checkChange
    );
  };

  handleImpressumChange = (val: string) => {
    this.setState({ impressum: val }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.useMembershipNumber !==
          this.state.club?.settings?.useMembershipNumber ||
        this.state.askForMembershipNumber !==
          this.state.club?.settings?.askForMembershipNumber ||
        this.state.impressum !== this.state.club?.impressum,
    });
  };

  handleAgbUpload = (e: any) => {
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      console.error(
        "the filetype is invalid, valid fileTypes are application/pdf"
      );
    } else {
      var storageRef = storage.ref(
        `files/agbs/clubs/${this.state.clubId}/agb.pdf`
      );
      // Upload file
      const task = storageRef.put(file);
      // Update progress bar
      task.on(
        "state_changed",
        (snapshot) => {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ agbProgress: percentage });
        },
        (error) => {
          console.error("Error: ");
          console.error(error);
        },
        () => {
          this.context.createInfo(
            "Du hast die AGB erfolgreich hochgeladen.",
            "success",
            4
          );
        }
      );
    }
  };

  handleDeleteAgb = () => {
    this.setState({ removeAgbLoading: true });
    fetchRetry("removeClubAgb", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleRemoveAgbSuccess)
      .catch(this.handleError);
  };

  handleRemoveAgbSuccess = ({ data }: any) => {
    this.setState({ removeAgbLoading: false });
    if (data.success) {
      this.context.requestClub(this.state.clubId);
      this.requestClub();
      this.context.createInfo(
        "Du hast die AGB erfolgreich gelöscht.",
        "success",
        4
      );
    }
  };

  render() {
    if (!this.state.clubLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Einstellungen" />
            <SwitchSetting
              value={this.state.useMembershipNumber}
              onChange={this.handleUseMembershipNumber}
              name="Mitgliedsnummer verwenden"
            />
            <SwitchSetting
              value={this.state.askForMembershipNumber}
              onChange={this.handleAskForMembershipNumber}
              name="Mitglieder nach Mitgliedsnummer fragen"
            />
            <SubTitle title="Impressum" />
            <TextArea
              value={this.state.impressum}
              onChange={this.handleImpressumChange}
              placeholder="Impressum"
            />
            <Upload
              handleDelete={this.handleDeleteAgb}
              handleUpload={this.handleAgbUpload}
              progress={this.state.agbProgress}
              removeLoading={this.state.removeAgbLoading}
              href={this.state.club.agb}
            />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateClub}
              title="Speichern"
            />
          </div>
        </div>
      </>
    );
  }
}
