import React from "react";
import TextSkeleton from "./TextSkeleton";

export default class RowSkeleton extends React.Component<
  {
    columns: number;
    noise: number;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  scramble = (x: number) => {
    if (x === 0) return 0;
    if (x === 1) return 3;
    if (x === 2) return 2;
    return 1;
  };

  render() {
    return (
      <tr>
        {[...Array(this.props.columns)].map((x, i) => (
          <td key={i}>
            <TextSkeleton
              width={40 + this.scramble((this.props.noise+i) % 4) * 5}
            />
          </td>
        ))}
      </tr>
    );
  }
}
