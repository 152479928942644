import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Settings.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import SwitchSetting from "../../components/SwitchSetting";
import Title from "../../components/Title";
import { MainContext } from "../../contexts/MainContext";
import Upload from "../../components/Upload";
import { storage } from "../../services/firebase";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Settings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    requestNeeded: boolean;
    branch: any;
    branchLoaded: boolean;
    change: boolean;
    updateLoading: boolean;
    showRequestWindow: boolean;
    bBirthDate: boolean;
    bAddress: boolean;
    bPhoneNumber: boolean;
    alreadyMemberText: string;
    requestMembershipText: string;
    requestMembershipBtnText: string;
    requestMembershipUrl: string;
    removeAgbLoading: boolean;
    agbProgress: number;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      requestNeeded: false,
      branch: {},
      branchLoaded: false,
      change: false,
      updateLoading: false,
      showRequestWindow: false,
      bBirthDate: false,
      bAddress: false,
      bPhoneNumber: false,
      alreadyMemberText: "",
      requestMembershipText: "",
      requestMembershipBtnText: "",
      requestMembershipUrl: "",
      removeAgbLoading: false,
      agbProgress: 0,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
          requestNeeded: branch.settings.requestNeeded,
          showRequestWindow: branch.settings.showRequestWindow,
          bPhoneNumber: branch.settings.bPhoneNumber,
          bAddress: branch.settings.bAddress,
          bBirthDate: branch.settings.bBirthDate,
          alreadyMemberText: branch?.text?.alreadyMemberText
            ? branch.text.alreadyMemberText
            : "",
          requestMembershipText: branch?.text?.requestMembershipText
            ? branch.text.requestMembershipText
            : "",
          requestMembershipBtnText: branch?.text?.requestMembershipBtnText
            ? branch.text.requestMembershipBtnText
            : "",
          requestMembershipUrl: branch?.text?.requestMembershipUrl
            ? branch.text.requestMembershipUrl
            : "",
        },
        this.checkChange
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleRequestNeededChange = () => {
    this.setState(
      { requestNeeded: !this.state.requestNeeded },
      this.checkChange
    );
  };

  handleShowRequestWindowChange = () => {
    this.setState(
      { showRequestWindow: !this.state.showRequestWindow },
      this.checkChange
    );
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.requestNeeded !== this.state.branch.settings.requestNeeded ||
        this.state.showRequestWindow !==
          this.state.branch.settings.showRequestWindow ||
        this.state.bBirthDate !== this.state.branch.settings.bBirthDate ||
        this.state.bAddress !== this.state.branch.settings.bAddress ||
        this.state.bPhoneNumber !== this.state.branch.settings.bPhoneNumber ||
        this.state.alreadyMemberText !==
          (this.state.branch?.text?.alreadyMemberText
            ? this.state.branch.text.alreadyMemberText
            : "") ||
        this.state.requestMembershipText !==
          (this.state.branch?.text?.requestMembershipText
            ? this.state.branch.text.requestMembershipText
            : "") ||
        this.state.requestMembershipBtnText !==
          (this.state.branch?.text?.requestMembershipBtnText
            ? this.state.branch.text.requestMembershipBtnText
            : "") ||
        this.state.requestMembershipUrl !==
          (this.state.branch?.text?.requestMembershipUrl
            ? this.state.branch.text.requestMembershipUrl
            : ""),
    });
  };

  updateBranch = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        settings: {
          requestNeeded: this.state.requestNeeded,
          showRequestWindow: this.state.showRequestWindow,
          bBirthDate: this.state.bBirthDate,
          bAddress: this.state.bAddress,
          bPhoneNumber: this.state.bPhoneNumber,
        },
        text: {
          alreadyMemberText: this.state.alreadyMemberText,
          requestMembershipText: this.state.requestMembershipText,
          requestMembershipBtnText: this.state.requestMembershipBtnText,
          requestMembershipUrl: this.state.requestMembershipUrl,
        },
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    this.requestBranch();
  };

  handleDeleteAgb = () => {
    this.setState({ removeAgbLoading: true });
    fetchRetry(
      "removeBranchAgb",
      { clubId: this.state.clubId, branchId: this.state.branchId },
      1,
      5
    )
      .then(this.handleRemoveAgbSuccess)
      .catch(this.handleError);
  };

  handleRemoveAgbSuccess = ({ data }: any) => {
    this.setState({ removeAgbLoading: false });
    if (data.success) {
      this.context.requestBranch(this.state.clubId, this.state.branchId);
      this.requestBranch();
      this.context.createInfo(
        "Du hast die Nutzungsbedinungen erfolgreich gelöscht.",
        "success",
        4
      );
    }
  };

  handleAgbUpload = (e: any) => {
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      console.error(
        "the filetype is invalid, valid fileTypes are application/pdf"
      );
    } else {
      var storageRef = storage.ref(
        `files/agbsbranch/clubs/${this.state.clubId}/branches/${this.state.branchId}/agb.pdf`
      );
      // Upload file
      const task = storageRef.put(file);
      // Update progress bar
      task.on(
        "state_changed",
        (snapshot) => {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState({ agbProgress: percentage });
        },
        (error) => {
          console.error("Error: ");
          console.error(error);
        },
        () => {
          this.context.createInfo(
            "Du hast die AGB erfolgreich hochgeladen.",
            "success",
            4
          );
        }
      );
    }
  };

  render() {
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Einstellungen" />
            {/* <SwitchSetting
              value={this.state.requestNeeded}
              onChange={this.handleRequestNeededChange}
              name="Mitglieder mussen verifiziert werden"
            /> */}
            <SwitchSetting
              value={this.state.showRequestWindow}
              onChange={this.handleShowRequestWindowChange}
              name="Beitrittsfenster anzeigen"
            />
            <div className="medium-space"></div>
            <Upload
              handleDelete={this.handleDeleteAgb}
              handleUpload={this.handleAgbUpload}
              progress={this.state.agbProgress}
              removeLoading={this.state.removeAgbLoading}
              href={this.state.branch.agb}
            />
            <div className="medium-space"></div>
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateBranch}
              title="Speichern"
            />
          </div>
        </div>
      </>
    );
  }
}
