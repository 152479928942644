import QRCode from "qrcode";

export async function generateQRCode(code: string): Promise<string> {
  return await QRCode.toDataURL(code)
    .then(function (url: any) {
      return url;
    })
    .catch(function () {
      return "";
    });
}
