import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import "./InviteUsers.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import Input from "../../components/Input";
import { fetchRetry } from "../../functions/request";
import MultiSelector from "../../components/MultiSelector";
import { makeid, objectToArray } from "../../functions/utils";
import debounce from "lodash.debounce";
import SubTitle from "../../components/SubTitle";
import TextArea from "../../components/TextArea";
import ErrorMessage from "../../components/ErrorMessage";
import SecButton from "../../components/SecButton";
import ClipLoader from "react-spinners/ClipLoader";
import InfoContainer from "../../components/InfoContainer";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SearchInput from "../../components/SearchInput";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class InviteUsers extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    branches: any;
    branchesLoaded: boolean;
    userSearchVal: string;
    users: Array<any>;
    filteredUsers: Array<any>;
    usersLoaded: boolean;
    selectedUserId: string;
    redirect: string | null;
    allSelected: boolean;
    mailMessage: string;
    mailSendErrMsg: string | null;
    sendMailLoading: boolean;
    deleteLoading: boolean;
    deleteErrMsg: string | null;
    showConfirmationModal: boolean;
    showInfoCannotDeleteAlreadyAccepted: boolean;
  }
> {
  private wrapperRef: any;
  static contextType = MainContext;

  constructor(props: ComposedProps) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      branches: {},
      branchesLoaded: false,
      userSearchVal: "",
      users: [],
      filteredUsers: [],
      usersLoaded: false,
      selectedUserId: "",
      redirect: null,
      allSelected: false,
      mailMessage: "",
      mailSendErrMsg: null,
      sendMailLoading: false,
      deleteLoading: false,
      deleteErrMsg: null,
      showConfirmationModal: false,
      showInfoCannotDeleteAlreadyAccepted: false,
    };
  }

  componentDidMount = () => {
    this.requestClub();
    this.requestBranches();
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.state.selectedUserId) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.handleSaveUser(this.state.selectedUserId);
        let users = this.state.users;
        // remove empty field if user is selected
        users = users.filter((user: any) => !user.empty);
        this.setState({ selectedUserId: "", users }, this.filterUsers);
      }
    }
  }

  requestClub = () => {
    fetchRetry("getClubFromId", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleClub)
      .catch(this.handleError);
  };

  requestBranches = () => {
    fetchRetry("getBranchesFromClub", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleBranches)
      .catch(this.handleError);
  };

  requestUserInvites = () => {
    fetchRetry("getOnboardingFromClub", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleUsers)
      .catch(this.handleError);
  };

  handleBranches = ({ data }: any) => {
    this.setState({ branchesLoaded: true });
    if (data.success) {
      this.setState({ branches: data.data }, this.requestUserInvites);
    }
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      this.setState({
        club: club,
      });
    }
  };

  handleUsers = ({ data }: any) => {
    this.setState({ usersLoaded: true });
    if (data.success) {
      const userData = data.data;
      const users = [];

      for (const user of userData) {
        // reformating branches
        const branchObj: Array<any> = [];
        let selectedRoles: Array<string> = [];
        for (const branch of user.branches) {
          branchObj[branch.branchId] = branch;
          selectedRoles = [...selectedRoles, ...branch.roles];
        }

        const branches: any = {};
        this.state.branches.forEach((branch: any) => {
          const currRoles = objectToArray(branch.roles).map(
            (branchRole: any) => {
              const roleId = branchRole.id;
              const role = { id: roleId, name: branchRole.name };
              return {
                ...role,
                ...{
                  selected: branchObj[branch.id]?.roles.includes(roleId),
                  color: branchRole?.type === "club" ? "#5c67ee" : "#ee5c5c",
                  type: branchRole?.type,
                },
              };
            }
          );
          branches[branch.id] = {
            roles: currRoles,
            isMember: branchObj[branch.id]?.isMember,
            role: branchObj[branch.id]?.role,
          };
        });

        users.push({
          membershipNumber: user.membershipNumber,
          fName: user.fName,
          lName: user.lName,
          email: user.email,
          role: user.role,
          status: user.status,
          acceptedBy: user.acceptedBy,
          accepted: false,
          empty: false,
          branches: branches,
          id: user.onboardingId,
          selected: this.state.allSelected,
          debounceFunc: debounce(() => {
            this.handleSaveUser(user.onboardingId);
          }, 1000),
          saved: true,
          selectedRoles,
        });
      }

      users.sort((a: any, b: any) => (a.lName < b.lName ? 1 : -1));
      users.sort((a: any, b: any) => (a.fName < b.fName ? 1 : -1));
      users.sort((a: any, b: any) => (a.accepted > b.accepted ? 1 : -1));
      this.setState(
        {
          users,
        },
        this.filterUsers
      );
    }
  };

  filterUsers = () => {
    let filterText = this.state.userSearchVal;
    filterText = filterText.toLocaleLowerCase();
    const filteredUsers = this.state.users.filter((user: any) => {
      let fName = user.fName ? user.fName : "";
      fName = fName.toLowerCase();
      let lName = user.lName ? user.lName : "";
      lName = lName.toLowerCase();
      let email = user.email ? user.email : "";
      email = email.toLowerCase();
      let role = user.role ? user.role : "";
      role = role.toLowerCase();
      if (
        `${fName} ${lName}`.includes(filterText) ||
        `${lName} ${fName}`.includes(filterText) ||
        (email && email.includes(filterText)) ||
        (role && role.includes(filterText)) ||
        (this.state.club?.settings?.useMembershipNumber &&
          user?.membershipNumber &&
          user?.membershipNumber.includes(filterText))
      ) {
        return true;
      }
      return false;
    });
    this.setState({ filteredUsers: filteredUsers });
  };

  handleChangeClubSearchVal = (val: any) => {
    this.setState({ userSearchVal: val }, this.filterUsers);
  };

  handleUserClick = (userData: any) => {
    if (this.state.selectedUserId !== userData.id) {
      this.handleSaveUser(this.state.selectedUserId);
    }
    let users = this.state.users;
    // remove empty field if user is selected
    users = users.filter(
      (user: any) => !user.empty || userData.id === this.state.selectedUserId
    );
    this.setState(
      {
        selectedUserId: userData.id,
        users,
      },
      this.filterUsers
    );
  };

  createUser = () => {
    // check if there is already an empty field
    const emptyField = this.state.users.find((user: any) => {
      return user.empty;
    });
    if (!emptyField) {
      const users = this.state.users;
      const branches: any = {};

      this.state.branches.forEach((branch: any) => {
        const currRoles = objectToArray(branch.roles).map((branchRole: any) => {
          const roleId = branchRole.id;
          const role = {
            id: roleId,
            name: branchRole.name,
            color: branchRole.type === "club" ? "#5c67ee" : "#ee5c5c",
          };
          return {
            ...role,
            ...{ selected: false },
          };
        });
        branches[branch.id] = {
          roles: currRoles,
          isMember: false,
          role: "Member",
        };
      });
      const randomId = makeid(32);
      users.push({
        membershipNumber: "",
        fName: "",
        lName: "",
        email: "",
        role: "Member",
        accepted: false,
        empty: true,
        branches,
        id: randomId,
        selected: this.state.allSelected,
        debounceFunc: debounce(() => {
          this.handleSaveUser(randomId);
        }, 1000),
        saved: true,
      });
      if (this.state.selectedUserId) {
        this.handleSaveUser(this.state.selectedUserId);
      }
      this.setState({ users, selectedUserId: randomId }, this.filterUsers);
    }
  };

  handleMembershipNumberChange = (membershipNumber: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      user.membershipNumber = membershipNumber;
      user.empty = false;
      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  handleEmailChange = (email: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      user.email = email;
      user.empty = false;
      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  handleRoleSelected = (e: any) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      const roleDict = ["Member", "Owner"];
      user.role = roleDict[e.target.options.selectedIndex];
      user.empty = false;
      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  branchRoleSelected = (branchId: string, e: any) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      const roleDict = ["Member", "ViewOnly", "Owner"];
      user.branches[branchId].role = roleDict[e.target.options.selectedIndex];
      user.empty = false;
      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  handlefNameChange = (fName: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      user.fName = fName;
      user.empty = false;
      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  handlelNameChange = (lName: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      user.lName = lName;
      user.empty = false;
      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  toggleSelectedUser = (userId: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === userId;
    });
    if (user) {
      user.selected = !user.selected;
      this.setState({ users }, this.filterUsers);
    }
  };

  toggleBranchMemberChange = (branchId: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      const branch = user.branches[branchId];
      // change branch member status
      branch.isMember = !branch.isMember;
      // unselect all roles
      if (!branch.isMember) {
        branch.roles.forEach((role: any) => {
          role.selected = false;
        });
      } else {
        branch.roles.forEach((role: any) => {
          if (role.type === "club" && user.selectedRoles.includes(role.id)) {
            role.selected = true;
          }
        });
      }
      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  toggleRole = (branchId: string, roleId: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === this.state.selectedUserId;
    });
    if (user) {
      const branch = user.branches[branchId];
      const selectedRole = branch.roles.filter(
        (role: any) => role.id === roleId
      )[0];
      selectedRole.selected = !selectedRole.selected;
      if (selectedRole.selected) {
        branch.isMember = true;
        branch.roles.forEach((role: any) => {
          if (role.type === "club" && user.selectedRoles.includes(role.id)) {
            role.selected = true;
          }
        });
      }
      // update selectedRoles
      if (!selectedRole.selected) {
        user.selectedRoles = user.selectedRoles.filter(
          (currRoleId: string) => currRoleId !== roleId
        );
      } else {
        if (!user.selectedRoles) user.selectedRoles = [];
        user.selectedRoles.push(roleId);
      }

      // update all club roles
      Object.keys(user.branches).forEach((currBranchId: any) => {
        // for every branch
        const currBranch = user.branches[currBranchId];
        // when clubRole or branch is selected
        if (selectedRole.type === "club") {
          currBranch.roles.forEach((role: any) => {
            // for every role
            if (currBranch.isMember) {
              role.selected = user.selectedRoles.includes(role.id);
            }
          });
        }
      });

      user.saved = false;
      this.setState({ users }, () => {
        this.filterUsers();
        user.debounceFunc();
      });
    }
  };

  toggleAllSelected = () => {
    const users = this.state.users;
    if (this.state.allSelected) {
      // unselect everything
      users.forEach((user: any) => {
        user.selected = false;
      });
    } else {
      // selecte everything
      users.forEach((user: any) => {
        user.selected = true;
      });
    }
    this.setState({ allSelected: !this.state.allSelected, users });
  };

  handleSaveUser = (onboardingId: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === onboardingId;
    });

    if (user && !user.saved) {
      const branches = Object.keys(user.branches).map((branchId: string) => {
        const selectedRolesIds = user.branches[branchId].roles
          .filter((role: any) => role.selected)
          .map((role: any) => role.id);
        return {
          branchId: branchId,
          roles: selectedRolesIds,
          role: user.branches[branchId].role
            ? user.branches[branchId].role
            : "Member",
          isMember: !!user.branches[branchId].isMember,
        };
      });
      user.loading = true;
      this.setState({ users });
      fetchRetry(
        "updateOnboarding",
        {
          clubId: this.state.clubId,
          onboardingId: onboardingId,
          data: {
            fName: user.fName,
            lName: user.lName,
            email: user.email,
            membershipNumber: user.membershipNumber,
            role: user.role,
            branches: branches,
          },
        },
        1,
        5
      )
        .then(({ data }: any) => {
          if (data.success) {
            user.saved = true;
            user.loading = false;
            this.setState({ users });
          }
        })
        .catch(this.handleError);
    }
  };

  deleteUser = (onboardingId: string) => {
    const users = this.state.users;
    const user = users.find((user: any) => {
      return user.id === onboardingId;
    });
    if (user) {
      user.loading = true;
      this.setState({ users });
      fetchRetry(
        "deleteOnboardings",
        {
          clubId: this.state.clubId,
          onboardingIdArr: [user.id],
        },
        1,
        5
      )
        .then(({ data }: any) => {
          if (data.success) {
            const users = this.state.users.filter((user: any) => {
              return user.id !== onboardingId;
            });
            this.setState({ users, selectedUserId: "" }, this.filterUsers);
          } else {
            user.loading = false;
            this.setState({ users }, this.filterUsers);
          }
        })
        .catch(this.handleError);
    }
  };

  handleMailMessageChange = (val: string) => {
    this.setState({ mailMessage: val });
  };

  sendMail = () => {
    const onboardingSelectedIds: Array<string> = this.state.users
      .filter((user: any) => user.selected)
      .map((user: any) => user.id);
    if (onboardingSelectedIds.length === 0) {
      this.setState({
        mailSendErrMsg: "Bitte wähle mindestens einen Benutzer aus.",
      });
    } else {
      this.setState({ sendMailLoading: true });
      fetchRetry(
        "sendOnboardingMail",
        {
          clubId: this.state.clubId,
          onboardingIdArr: onboardingSelectedIds,
          message: this.state.mailMessage,
        },
        1,
        5
      )
        .then(this.handleSendMailSuccess)
        .catch(this.handleError);
    }
  };

  handleSendMailSuccess = ({ data }: any) => {
    this.setState({ sendMailLoading: false });
    if (data.success) {
      this.setState({
        mailMessage: "",
        mailSendErrMsg: null,
      });
      this.context.createInfo("Mail wurde erfolgreich gesendet.", "success", 4);
    } else {
      this.setState({ mailSendErrMsg: data.errorMsgDe });
    }
  };

  handleDeleteOnboardings = () => {
    this.setState({ showConfirmationModal: false });
    const onboardingSelectedIds: Array<string> = this.state.users
      .filter((user: any) => user.selected)
      .map((user: any) => user.id);
    if (onboardingSelectedIds.length === 0) {
      this.setState({
        deleteErrMsg: "Bitte wähle mindestens eine Einladung aus.",
      });
    } else {
      this.setState({ deleteLoading: true });
      fetchRetry(
        "deleteOnboardings",
        {
          clubId: this.state.clubId,
          onboardingIdArr: onboardingSelectedIds,
        },
        1,
        5
      )
        .then(this.handleDeleteOnboardingsSuccess)
        .catch(this.handleError);
    }
  };

  handleDeleteOnboardingsSuccess = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      const users = this.state.users.filter(
        (user: any) => !data.deletedIdArr.includes(user.id)
      );
      this.setState(
        {
          showInfoCannotDeleteAlreadyAccepted: data.deleteAccepted,
          users,
        },
        this.filterUsers
      );

      this.context.createInfo(
        `${data.deletedIdArr.length} Einladungen wurden erfolgreich gelöscht.`,
        "success",
        4
      );
    } else {
      this.setState({ deleteErrMsg: data.errorMsgDe });
    }
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  showConfirmationModal = () => {
    const onboardingSelectedIds: Array<string> = this.state.users
      .filter((user: any) => user.selected)
      .map((user: any) => user.id);
    if (onboardingSelectedIds.length === 0) {
      this.setState({
        deleteErrMsg: "Bitte wähle mindestens eine Einladung aus.",
      });
    } else {
      this.setState({ showConfirmationModal: true });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (
      !this.state.usersLoaded ||
      !this.state.branchesLoaded ||
      !this.state.clubLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    const selectedCount = this.state.users
      .filter((user: any) => user.selected)
      .map((user: any) => user.id).length;
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`${
            selectedCount === 1
              ? "Willst du die Einladung wirklich löschen? "
              : `Willst du wirklich ${selectedCount} Einladungen löschen? `
          }Dieser Vorgang kann nicht rückgängig gemacht werden. Bereits gesendete Einladungen sind dann ungültig.`}
          handleConfirm={this.handleDeleteOnboardings}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <SearchInput
              name="course-search-input max-booking-length"
              placeholder="Nutzersuche"
              value={this.state.userSearchVal}
              onChange={this.handleChangeClubSearchVal}
              className="no-margin"
            />

            <table className="user-table" ref={this.wrapperRef}>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={this.state.allSelected}
                      onChange={this.toggleAllSelected}
                      className="margin-right"
                    />
                  </th>
                  {this.state.club?.settings?.useMembershipNumber && (
                    <th className="user-table-membershipNumber-title"></th>
                  )}
                  <th>Vorname</th>
                  <th>Nachname</th>
                  <th className="user-table-email">E-Mail</th>
                  <th className="user-table-rights">Rechte</th>
                  <th className="user-table-status">Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredUsers.map((userData, index) => (
                  <>
                    {this.state.selectedUserId === userData.id ? (
                      <>
                        <tr
                          key={index}
                          onClick={() => {
                            this.handleUserClick(userData);
                          }}
                          className={`invite-user-row${
                            this.state.selectedUserId === userData.id
                              ? " invite-user-row-selected"
                              : ""
                          }`}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={userData.selected}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.toggleSelectedUser(userData.id);
                              }}
                            />
                          </td>
                          {this.state.club?.settings?.useMembershipNumber && (
                            <td>
                              <Input
                                type="text"
                                value={userData.membershipNumber}
                                onChange={(val: string) => {
                                  this.handleMembershipNumberChange(val);
                                }}
                                placeholder="Mitgliedsnummer"
                                className="table-input-margin-top"
                                disabled={userData.status === "accepted"}
                              />
                            </td>
                          )}
                          <td>
                            <Input
                              type="text"
                              value={userData.fName}
                              onChange={this.handlefNameChange}
                              placeholder="Vorname"
                              className="table-input-margin-top"
                              disabled={userData.status === "accepted"}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              value={userData.lName}
                              onChange={this.handlelNameChange}
                              placeholder="Nachname"
                              className="table-input-margin-top"
                              disabled={userData.status === "accepted"}
                            />
                          </td>
                          <td className="user-table-email">
                            <Input
                              type="text"
                              value={userData.email}
                              onChange={this.handleEmailChange}
                              placeholder="E-Mail"
                              className="table-input-margin-top"
                              disabled={userData.status === "accepted"}
                            />
                          </td>
                          <td className="user-table-rights">
                            <select
                              placeholder="Rechte"
                              className="input-container table-input-margin-top"
                              value={userData.role}
                              onChange={this.handleRoleSelected}
                              disabled={userData.status === "accepted"}
                            >
                              <option value="Member">Mitglied</option>
                              <option value="Owner">Admin</option>
                            </select>
                          </td>
                          <td className="user-table-status">
                            <div
                              className={`user-table-invite-status user-table-invite-status-${
                                userData.status ? userData.status : "created"
                              }`}
                            ></div>
                          </td>
                        </tr>
                        <tr className="invite-user-row-lower">
                          <td colSpan={7}>
                            <InfoContainer
                              active={userData.status === "accepted"}
                            >
                              Diese Einladung kann nicht mehr bearbeitet werden,
                              da sie bereits angenommen wurde. Du kannst den
                              Nutzer stattdessen{" "}
                              <Link
                                to={`/club-settings/${this.state.clubId}/users/${userData.acceptedBy}`}
                                className="no-underline"
                              >
                                hier
                              </Link>{" "}
                              bearbeiten.
                            </InfoContainer>
                            <fieldset className="branch-table-fieldset">
                              <legend>Bereiche</legend>
                              <table className="inner-branch-table">
                                <thead>
                                  <tr>
                                    <th>Mitglied</th>
                                    <th>Sparte</th>
                                    <th>Rechte</th>
                                    <th>Rollen</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.branches.map((branch: any) => (
                                    <tr>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={
                                            userData.branches[branch.id]
                                              .isMember
                                          }
                                          onChange={() => {
                                            this.toggleBranchMemberChange(
                                              branch.id
                                            );
                                          }}
                                          disabled={
                                            userData.status === "accepted"
                                          }
                                        />
                                      </td>
                                      <td>{branch.name}</td>
                                      <td className="table-onboarding-selector">
                                        <select
                                          placeholder="Rechte"
                                          className="input-container table-input-margin-top"
                                          value={
                                            userData.branches[branch.id].role
                                          }
                                          onChange={(e: any) => {
                                            this.branchRoleSelected(
                                              branch.id,
                                              e
                                            );
                                          }}
                                          disabled={
                                            userData.status === "accepted"
                                          }
                                        >
                                          <option value="Member">
                                            Mitglied
                                          </option>
                                          <option value="ViewOnly">
                                            Mitarbeiter
                                          </option>
                                          <option value="Owner">Admin</option>
                                        </select>
                                      </td>
                                      <td className="table-main-content">
                                        <MultiSelector
                                          searchTextPlaceholder="Rollen"
                                          arr={
                                            userData.branches[branch.id].roles
                                          }
                                          onSelect={(roleId: string) => {
                                            this.toggleRole(branch.id, roleId);
                                          }}
                                          onUnselect={(roleId: string) => {
                                            this.toggleRole(branch.id, roleId);
                                          }}
                                          viewOnly={
                                            userData.status === "accepted"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </fieldset>
                            {userData.status !== "accepted" && (
                              <div className="flex center">
                                {userData.loading ? (
                                  <div className="status-container">
                                    <ClipLoader
                                      color={"white"}
                                      size={20}
                                      loading={true}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {userData.saved ? (
                                      <div className="status-container saved-container">
                                        Gespeichert
                                      </div>
                                    ) : (
                                      <div
                                        className="status-container edit-container"
                                        onClick={() => {
                                          this.handleSaveUser(userData.id);
                                        }}
                                      ></div>
                                    )}
                                  </>
                                )}
                                <div
                                  className="trash-icon-container"
                                  onClick={() => {
                                    this.deleteUser(userData.id);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr
                        key={index + 0.5}
                        onClick={() => {
                          this.handleUserClick(userData);
                        }}
                        className={`invite-user-row${
                          this.state.selectedUserId === userData.id
                            ? " invite-user-row-selected"
                            : ""
                        }`}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={userData.selected}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.toggleSelectedUser(userData.id);
                            }}
                          />
                        </td>
                        {this.state.club?.settings?.useMembershipNumber && (
                          <td>{userData.membershipNumber}</td>
                        )}
                        <td>{userData.fName}</td>
                        <td>{userData.lName}</td>
                        <td className="user-table-email">{userData.email}</td>
                        <td className="user-table-rights">
                          {userData.role === "Owner" ? "Admin" : "Mitglied"}
                        </td>
                        <td className="user-table-status">
                          <div
                            className={`user-table-invite-status user-table-invite-status-${
                              userData.status ? userData.status : "created"
                            }`}
                          ></div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
                <tr>
                  <td
                    colSpan={7}
                    className="pointer center bold"
                    onClick={this.createUser}
                  >
                    Nutzer hinzufügen
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="medium-space"></div>
            <SubTitle title="Einladungsmail an Teilnehmer senden" />
            <TextArea
              value={this.state.mailMessage}
              onChange={this.handleMailMessageChange}
              placeholder="Mail Nachricht"
            />
            <ErrorMessage message={this.state.mailSendErrMsg} />
            <SecButton
              change={!!this.state.mailMessage}
              color="green"
              loading={this.state.sendMailLoading}
              onClick={this.sendMail}
              title="Mail senden"
            />
            <InfoContainer
              active={this.state.showInfoCannotDeleteAlreadyAccepted}
            >
              Bereits angenommene Einladungen können nicht gelöscht werden.
            </InfoContainer>
            <ErrorMessage message={this.state.deleteErrMsg} />
            <SecButton
              change={true}
              color="red"
              loading={this.state.deleteLoading}
              onClick={this.showConfirmationModal}
              title="Einladungen löschen"
            />
          </div>
        </div>
      </>
    );
  }
}
