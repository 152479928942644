import React from "react";
import "./TableBookingComponent.css";

export default class TableBookingComponent extends React.Component<
  {
    title: string;
    length: number;
    totalLength: number;
    bookable: boolean;
    disabled: boolean;
    booked: boolean;
    color: string;
    onClick: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className={`booking-main-container ${
          this.props.bookable ? "booking-bookable" : ""
        } ${this.props.booked ? "booking-booked" : ""} ${
          this.props.disabled ? "booking-disabled" : ""
        }`}
        style={{
          height: `${(this.props.length / this.props.totalLength) * 100}%`,
          borderLeftColor: this.props.color,
        }}
        onClick={this.props.onClick}
      >
        <div
        className="booking-main-inner-container"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: this.props.color
              ? `${this.props.color}33`
              : "#00000000",
          }}
        >
          <h1 className="">{this.props.title}</h1>
        </div>
      </div>
    );
  }
}
