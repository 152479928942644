import React from "react";
import { Redirect } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { MainContext } from "../contexts/MainContext";

export default class Home extends React.Component<
  {},
  {
    loaded: boolean;
    redirect: null | string;
    clubNotExists: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      redirect: null,
      loaded: false,
      clubNotExists: false,
    };
  }

  getSubdomain = () => {
    const hostname = window.location.hostname;
    const hostNameArr = hostname.split(".");
    if (hostNameArr.length >= 2) {
      return hostNameArr[0];
    } else {
      return null;
    }
  };

  componentDidMount() {
    const subdomain = this.getSubdomain();
    if (subdomain) {
      this.context
        .requestClubFromNameIdentifier(subdomain)
        .then((data: any) => {
          this.setState({ loaded: true });
          if (data.success) {
            const clubId = data.data.id;
            this.setState({ redirect: `/club/${clubId}` });
          } else {
            if (data.code === "club-not-exist") {
              this.setState({ clubNotExists: true });
            }
          }
        })
        .catch(this.handleError);
    } else {
      this.setState({ loaded: true, clubNotExists: true });
    }
  }

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.loaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    if (this.state.clubNotExists) {
      return (
        <div className="full-center">
          <span className="not-exist-text">
            Der Verein existiert nicht. Bitte überprüfe die URL auf Tippfehler.
            Oder beantrage ein Buchungssystem "
            <a href="https://www.getbookable.de/demo">hier</a>".
          </span>
        </div>
      );
    }
    return <></>;
  }
}
