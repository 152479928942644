import React from "react";
import "./ColorPicker.css";

export default class ColerPicker extends React.Component<
  { value: string; onChange: any; name?: string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  handleChange = (val: any) => {
    this.props.onChange(val.target.value);
  };

  render() {
    return (
      <>
        <div
          className="color-input"
          style={{ backgroundColor: this.props.value }}
        >
          <input
            name={this.props.name ? this.props.name : "color picker"}
            type="color"
            value={this.props.value}
            onChange={this.handleChange}
          />
        </div>
      </>
    );
  }
}
