import React from "react";
import "./Skeleton.css";

export default class TextSkeleton extends React.Component<
  {
    width: number;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className="t-skeleton t-skeleton-text t-skeleton-text-60"
        style={{ width: `${this.props.width}%` }}
      ></div>
    );
  }
}
