import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Booking.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import SwitchSetting from "../../components/SwitchSetting";
import SubTitle from "../../components/SubTitle";
import Title from "../../components/Title";
import ErrorMessage from "../../components/ErrorMessage";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Course extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branch: any;
    branchLoaded: boolean;
    change: boolean;
    updateLoading: boolean;
    rBirthDate: boolean;
    rAddress: boolean;
    rPhoneNumber: boolean;
    gBirthDate: boolean;
    gAddress: boolean;
    gPhoneNumber: boolean;
    errorMsg: null | string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branch: {},
      branchLoaded: false,
      change: false,
      updateLoading: false,
      rBirthDate: false,
      rAddress: false,
      rPhoneNumber: false,
      gBirthDate: false,
      gAddress: false,
      gPhoneNumber: false,
      errorMsg: null,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState(
        {
          branch: branch,
          rBirthDate: branch.settings.rBirthDate,
          rAddress: branch.settings.rAddress,
          rPhoneNumber: branch.settings.rPhoneNumber,
          gBirthDate: branch.settings.gBirthDate,
          gAddress: branch.settings.gAddress,
          gPhoneNumber: branch.settings.gPhoneNumber,
        },
        this.checkChange
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  updateBranch = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        settings: {
          rBirthDate: this.state.rBirthDate,
          rAddress: this.state.rAddress,
          rPhoneNumber: this.state.rPhoneNumber,
          gBirthDate: this.state.gBirthDate,
          gAddress: this.state.gAddress,
          gPhoneNumber: this.state.gPhoneNumber,
        },
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = ({ data }: any) => {
    this.setState({ updateLoading: false });
    if (data.success) {
      this.requestBranch();
      this.setState({ errorMsg: null });
    } else {
      this.setState({ errorMsg: data.errorMsgDe });
    }
  };

  handleGBirthDateChange = () => {
    this.setState({ gBirthDate: !this.state.gBirthDate }, this.checkChange);
  };

  handleGAddressChange = () => {
    this.setState({ gAddress: !this.state.gAddress }, this.checkChange);
  };

  handleGPhoneNumberChange = () => {
    this.setState({ gPhoneNumber: !this.state.gPhoneNumber }, this.checkChange);
  };

  handleRBirthDateChange = () => {
    this.setState({ rBirthDate: !this.state.rBirthDate }, this.checkChange);
  };

  handleRAddressChange = () => {
    this.setState({ rAddress: !this.state.rAddress }, this.checkChange);
  };

  handleRPhoneNumberChange = () => {
    this.setState({ rPhoneNumber: !this.state.rPhoneNumber }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.gBirthDate !== this.state.branch.settings.gBirthDate ||
        this.state.gAddress !== this.state.branch.settings.gAddress ||
        this.state.gPhoneNumber !== this.state.branch.settings.gPhoneNumber ||
        this.state.rBirthDate !== this.state.branch.settings.rBirthDate ||
        this.state.rAddress !== this.state.branch.settings.rAddress ||
        this.state.rPhoneNumber !== this.state.branch.settings.rPhoneNumber,
    });
  };

  render() {
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Kurssystem" />
            <div className="branch-subtitle-container">
              <SubTitle title="Benötigte Daten - Registriert" />
              <SwitchSetting
                value={this.state.rBirthDate}
                onChange={this.handleRBirthDateChange}
                name="Geburtsdatum"
              />
              <SwitchSetting
                value={this.state.rAddress}
                onChange={this.handleRAddressChange}
                name="Adresse"
              />
              <SwitchSetting
                value={this.state.rPhoneNumber}
                onChange={this.handleRPhoneNumberChange}
                name="Telefonnummer"
              />
              <div className="medium-space"></div>
              <SubTitle title="Benötigte Daten - Gast" />
              <SwitchSetting
                value={this.state.gBirthDate}
                onChange={this.handleGBirthDateChange}
                name="Geburtsdatum"
              />
              <SwitchSetting
                value={this.state.gAddress}
                onChange={this.handleGAddressChange}
                name="Adresse"
              />
              <SwitchSetting
                value={this.state.gPhoneNumber}
                onChange={this.handleGPhoneNumberChange}
                name="Telefonnummer"
              />
            </div>
            <ErrorMessage message={this.state.errorMsg} />
            <SecButton
              change={this.state.change}
              color="green"
              loading={this.state.updateLoading}
              onClick={this.updateBranch}
              title="Speichern"
            />
          </div>
        </div>
      </>
    );
  }
}
