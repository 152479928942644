import React from "react";
import "./Switch.css";
import SecButton from "./SecButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

export default class Upload extends React.Component<
  {
    handleUpload: any;
    progress: number;
    removeLoading: boolean;
    handleDelete: any;
    href: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="upload-main-container">
        <div className="image-container">
          <label className="image-upper-container">
            <input
              type="file"
              id="uploadBanner"
              className="fileInput"
              onChange={this.props.handleUpload}
              accept="application/pdf"
            />
            <span>AGB auswählen.</span>
            <progress
              value={this.props.progress}
              max="100"
              id="uploaderBanner"
              className="progress-bar"
            />
          </label>
          <div className="image-lower-container">
            <SecButton
              className="delete-button"
              loading={this.props.removeLoading}
              onClick={this.props.handleDelete}
              title="AGB löschen"
            />
          </div>
        </div>
        <div className="upload-logo-container">
          {!this.props.href ? (
            <div className="background-container agb-container">
              <FontAwesomeIcon
                icon={faFileUpload}
                size="4x"
                className="image-icon"
              />
              Keine AGB
            </div>
          ) : (
            <a
              href={this.props.href}
              target="_blank"
              rel="noreferrer"
              className="no-underline background-container agb-container agb-selected"
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                size="4x"
                className="image-icon"
              />
              AGB hochgeladen
            </a>
          )}
        </div>
      </div>
    );
  }
}
