import qs from "qs";
import { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { auth } from "../services/firebase";
import { fetchRetry } from "../functions/request";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { objectToArray } from "../functions/utils";
import { PermittedAccount } from "../Types";
import Status from "../components/Status/Status";

export const MainContext = createContext({});

export const MainProvider = ({ children }: any) => {
  const history = useHistory();

  const [user, setUser]: any = useState({});
  const [userLoaded, setUserLoaded] = useState(false);
  const [userDataUpdated, setUserDataUpdated] = useState(true);
  const [userData, setUserData]: any = useState({});
  const [selectedUserId, setSelectedUserId]: any = useState(null);
  const [selectedUserData, setSelectedUserData]: any = useState({});
  const [isAdmin, setIsAdmin]: any = useState(false);
  const [infoArr, setInfoArr]: any = useState([]);
  const [clubId, setClubId]: any = useState(null);
  const [clubLoaded, setClubLoaded] = useState(false);
  const [club, setClub]: any = useState({});
  const [branchId, setBranchId]: any = useState(null);
  const [branchLoaded, setBranchLoaded]: any = useState(false);
  const [branch, setBranch]: any = useState({});
  const [path, setPath]: any = useState([]);
  const [customPath, setCustomPath]: any = useState({});
  const [showScrollToTop, setShowScrollToTop]: any = useState(false);
  const [currScrollContainer, setCurrScrollContainer]: any = useState(null);
  const [permittedAccountsTable, setPermittedAccountsTable]: any = useState([]);

  const [notificationArr, setNotificationArr]: any = useState([]);
  const [notificationsLoaded, setNotificationsLoaded]: any = useState(false);

  // notification Context
  useEffect(() => {
    requestUnreadNotifications();
    const interval = setInterval(() => {
      requestUnreadNotifications();
    }, 600000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function requestUnreadNotifications() {
    if (selectedUserId) {
      fetchRetry(
        "getUnseenNotificationsF",
        {
          limit: 10,
          targetUserId: selectedUserId,
        },
        1,
        5
      )
        .then(handleUnreadNotifications)
        .catch(handleError);
    }
  }

  function handleUnreadNotifications({ data }: any) {
    setNotificationsLoaded(true);
    if (data.success) {
      const notifications = data.data;
      const notificationArr = [];
      for (const notificationId in notifications) {
        const notification = notifications[notificationId];
        notificationArr.push(notification);
      }
      setNotificationArr(notificationArr);
    }
  }

  function clearUnreadNotifications() {
    setNotificationArr([]);
  }

  // info Context
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authChange);
    window.ononline = function () {
      deleteNoInternetInfo();
    };

    window.onoffline = function () {
      createNoInternetInfo();
    };

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function deleteInfo(id: string) {
    const newInfoArr = [...infoArr].filter(
      (currInfo: any) => currInfo.id !== id
    );
    setInfoArr(newInfoArr);
  }

  function createNoInternetInfo() {
    const newInfoArr: any[] = [...infoArr];
    newInfoArr.push({
      text: "Keine Internetverbindung",
      type: "error",
      time: -1,
      id: "no-internet-connection",
    });
    setInfoArr(newInfoArr);
  }

  function deleteNoInternetInfo() {
    const newInfoArr = [...infoArr].filter(
      (currInfo) => currInfo.id !== "no-internet-connection"
    );
    setInfoArr(newInfoArr);
  }

  function createInfo(text: string, type: string, time: number) {
    const newInfoArr = [...infoArr];
    newInfoArr.push({
      text,
      type,
      time,
      id: Math.random().toString(36).substring(2, 15),
    });
    setInfoArr(newInfoArr);
  }

  // auth context
  function authChange(user: firebase.User | null) {
    setUser(user);
    if (user) {
      setSelectedUserId(user.uid);
      user.getIdTokenResult().then((idTokenResult) => {
        setIsAdmin(!!idTokenResult.claims.admin);
      });
      requestUnreadNotifications();
    } else {
      setIsAdmin(false);
      setSelectedUserId(null);
      setClub({});
      setClubLoaded(false);
      requestBranch(clubId, branchId);
    }
    setNotificationArr([]);
  }

  useEffect(() => {
    if (user) {
      requestOwnUserData();
    } else {
      requestClub(clubId);
    }
  }, [user]);

  async function requestOwnUserData() {
    setUserLoaded(false);
    if (user?.uid) {
      await fetchRetry("getUserFromId", { targetUserId: user.uid }, 1, 5)
        .then((data) => {
          const selectedUserId = localStorage.getItem("selectedUserId");
          const permittedAccountIds = Object.keys(
            data?.data?.data?.permittedAccounts || {}
          );
          if (selectedUserId && permittedAccountIds.includes(selectedUserId)) {
            setSelectedUserId(selectedUserId);
          }
          handleUserData(data);
          return data;
        })
        .catch(handleError);
    }
  }

  function handleUserData({ data }: any) {
    if (data.success) {
      setUserData(data.data);
      const currUserData = data.data;
      const tableData = objectToArray(currUserData.permittedAccounts).map(
        (account: PermittedAccount) => {
          return {
            cells: [
              { value: account.fName, sort: account.fName },
              { value: account.lName, sort: account.lName },
              { value: account.email, sort: account.email },
              {
                value: <Status type={account.status} />,
                sort: account.status,
              },
            ],
            onClick: ``,
          };
        }
      );
      setPermittedAccountsTable(tableData);
      setUserLoaded(true);
    }
  }

  function setSelUserId(id: string) {
    setSelectedUserId(id);
    localStorage.setItem("selectedUserId", id);
    history.push(`/club/${clubId}`);
  }

  useEffect(() => {
    if (selectedUserId) {
      requestUnreadNotifications();
      requestUserData();
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (clubId && selectedUserId) {
      setClubLoaded(false);
      requestClub(clubId);
    }
  }, [clubId, selectedUserId]);

  useEffect(() => {
    if (clubId && branchId && (!user || selectedUserId)) {
      setBranchLoaded(false);
      requestBranch(clubId, branchId);
    }
  }, [clubId, branchId, selectedUserId]);

  async function requestUserData() {
    await fetchRetry("getUserFromId", { targetUserId: selectedUserId }, 1, 5)
      .then((data) => {
        handleSelectedUserData(data);
        return data;
      })
      .catch(handleError);
  }

  function handleSelectedUserData({ data }: any) {
    setSelectedUserData(data.data);
  }

  useEffect(() => {
    if (userLoaded && clubLoaded && userDataUpdated) {
      if (user && userData && !userData?.emailVerified) {
        const propsStr = qs.stringify({
          r: window.location.href,
        });
        if (clubId) {
          history.push(
            `/confirmEmail/${clubId}${propsStr ? `?${propsStr}` : ""}`
          );
        } else {
          history.push(`/confirmEmail${propsStr ? `?${propsStr}` : ""}`);
        }
      }
    }
  }, [
    userLoaded,
    clubLoaded,
    userDataUpdated,
    clubId,
    history,
    user,
    userData,
  ]);

  function signup(email: string, password: string) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email: string, password: string) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function forgotPassword(email: string) {
    return auth.sendPasswordResetEmail(email);
  }

  function signInWithGoogle() {
    const base_provider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(base_provider);
    // .signInWithRedirect(base_provider)
  }

  function logout() {
    setUser(null);
    setUserData(null);
    setSelectedUserId(null);
    localStorage.removeItem("selectedUserId");
    return auth.signOut();
  }

  async function requestClub(reqClubId: string) {
    setClubId(reqClubId);
    if (reqClubId) {
      return await fetchRetry(
        "getClubFromId",
        {
          clubId: reqClubId,
          targetUserId: selectedUserId,
        },
        1,
        5
      )
        .then(({ data }) => {
          handleClub(data);
          return data;
        })
        .catch(handleError);
    }
  }

  async function requestClubFromNameIdentifier(nameIdentifier: string) {
    return await fetchRetry(
      "getClubFromNameIdentifierNew",
      {
        nameIdentifier: nameIdentifier,
        targetUserId: selectedUserId,
      },
      1,
      5
    )
      .then(({ data }) => {
        handleClub(data);
        return data;
      })
      .catch(handleError);
  }

  function getFaviconEl(id: string) {
    return document.getElementById(id);
  }

  function setFaviconElement(id: string, href: string) {
    const faviconEl: any = getFaviconEl(id);
    if (faviconEl) {
      faviconEl.href = href;
    }
  }

  function handleClub(data: any) {
    if (data.success) {
      const currClub = data.data;
      const clubId = currClub.id;
      setClub(currClub);
      setClubId(clubId);
      if (getPathDepth() === 1) {
        setPath([
          {
            name: currClub.name,
            link: `/club/${clubId}`,
          },
        ]);
      }
      document.title = currClub.name;
      setFaviconElement("192x192", currClub.logo_192x192);
      setFaviconElement("32x32", currClub.logo_32x32);
      setFaviconElement("96x96", currClub.logo_96x96);
      setFaviconElement("16x16", currClub.logo_16x16);
      setFaviconElement("57x57", currClub.logo_57x57);
      setFaviconElement("60x60", currClub.logo_60x60);
      setFaviconElement("72x72", currClub.logo_72x72);
      setFaviconElement("76x76", currClub.logo_76x76);
      setFaviconElement("114x114", currClub.logo_114x114);
      setFaviconElement("120x120", currClub.logo_120x120);
      setFaviconElement("144x144", currClub.logo_144x144);
      setFaviconElement("152x152", currClub.logo_152x152);
      setFaviconElement("180x180", currClub.logo_180x180);
    }
    setClubLoaded(true);
  }

  function getPathDepth() {
    const urlArr = history.location.pathname.split("/");
    if (urlArr[1] === "club" || urlArr[1] === "club-settings") {
      if (urlArr[3] === "branch" || urlArr[3] === "branch-settings") {
        if (
          urlArr[5] === "participants" ||
          urlArr[5] === "subscription-participants"
        ) {
          return 3;
        }
        return 2;
      }
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    return history.listen((location) => {
      setShowScrollToTop(false);
      const urlArr = location.pathname.split("/");
      if (urlArr[1] === "club" || urlArr[1] === "club-settings") {
        const newClubId = urlArr[2];
        if (newClubId === "") {
          setClubId("");
          setClubLoaded(false);
          setClub({});
        } else if (newClubId !== clubId) {
          setClubId(newClubId);
          setClubLoaded(false);
          setClub({});
          requestClub(newClubId);
        }
      }
      if (urlArr[1] === "club") {
        const newClubId = urlArr[2];
        if (urlArr[3] === "branch" || urlArr[3] === "branch-settings") {
          const newBranchId = urlArr[4];
          if (newBranchId === "") {
            setBranchId("");
            setBranchLoaded(false);
            setBranch({});
          } else if (newBranchId !== branchId) {
            setBranchId(newBranchId);
            setBranchLoaded(false);
            setBranch({});
            requestBranch(newClubId, newBranchId);
          } else if (branchLoaded) {
            if (getPathDepth() === 2) {
              setPath([
                {
                  name: branch.club?.name,
                  link: `/club/${clubId}`,
                },
                {
                  name: branch.name,
                  link: `/club/${clubId}/branch/${branchId}/course`,
                },
              ]);
              setCustomPath({});
            } else {
              setPath([
                {
                  name: branch.club?.name,
                  link: `/club/${clubId}`,
                },
                {
                  name: branch.name,
                  link: `/club/${clubId}/branch/${branchId}/course`,
                },
                {
                  name: customPath?.name || "",
                  link: customPath?.link || "",
                },
              ]);
            }
          }
        }
      }
      const depth = getPathDepth();
      if (path.length > depth) {
        setPath(path.slice(0, depth));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, clubId, branchId, path]);

  useEffect(() => {
    if (customPath.name) {
      setPath([
        {
          name: branch.club?.name,
          link: `/club/${clubId}`,
        },
        {
          name: branch.name,
          link: `/club/${clubId}/branch/${branchId}/course`,
        },
        {
          name: customPath.name,
          link: customPath.link,
        },
      ]);
    }
  }, [customPath]);

  async function requestBranch(reqClubId: string, reqBranchId: string) {
    setBranchId(reqBranchId);
    if (reqClubId && reqBranchId && (!user?.uid || selectedUserId)) {
      return await fetchRetry(
        "getBranchFromIdF",
        {
          clubId: reqClubId,
          branchId: reqBranchId,
          targetUserId: selectedUserId,
        },
        1,
        5
      )
        .then((data) => {
          handleBranch(reqClubId, reqBranchId, data);
          return data;
        })
        .catch(handleError);
    }
  }

  function handleBranch(reqClubId: string, reqBranchId: string, res: any) {
    setBranchLoaded(true);
    const data = res.data;
    if (data.success) {
      const branch = data.data;
      setBranch(branch);
      setBranchId(reqBranchId);

      if (getPathDepth() === 2) {
        setPath([
          {
            name: branch.club?.name,
            link: `/club/${reqClubId}`,
          },
          {
            name: branch.name,
            link: `/club/${reqClubId}/branch/${reqBranchId}/course`,
          },
        ]);
      }
    }
  }

  function scrollToTop() {
    if (currScrollContainer) {
      currScrollContainer.scrollTo(0, 0);
    }
  }

  function onScroll(e: any) {
    setCurrScrollContainer(e.target);
    if (e.target.scrollTop > 20) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  }

  const value = {
    onScroll,
    user,
    userData,
    selectedUserData,
    selectedUserId,
    signup,
    login,
    signInWithGoogle,
    logout,
    forgotPassword,
    requestOwnUserData,
    requestUserData,
    setSelUserId,
    setSelectedUserId,
    isAdmin,
    userLoaded,
    createInfo,
    clubId,
    club,
    clubLoaded,
    requestClub,
    requestClubFromNameIdentifier,
    branchId,
    branch,
    branchLoaded,
    requestBranch,
    path,
    setPath,
    setCustomPath,
    notificationArr,
    notificationsLoaded,
    requestUnreadNotifications,
    clearUnreadNotifications,
    setUserDataUpdated,
    permittedAccountsTable,
  };

  function handleError(err: any) {
    console.error(err);
  }

  return (
    <MainContext.Provider value={value}>
      {showScrollToTop && (
        <div id="scrollToTop" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </div>
      )}
      <div className="main-info-container">
        {infoArr.map((info: any, i: number) => (
          <div className="main-info-element" key={i}>
            <div className="main-info-inner-element">
              <div className="main-info-icon-container">
                {info.type === "error" && (
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    size="6x"
                    className="main-info-template-icon main-info-error-icon"
                  />
                )}
                {info.type === "info" && (
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="6x"
                    className="main-info-template-icon main-info-info-icon"
                  />
                )}
                {info.type === "success" && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="6x"
                    className="main-info-template-icon main-info-success-icon"
                  />
                )}
              </div>
              <div className="main-info-text-container">{info.text}</div>
              <div
                className="main-info-close-icon"
                onClick={() => {
                  deleteInfo(info.id);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
            {info.time !== -1 && (
              <div
                className="main-info-progress-container"
                onAnimationEnd={() => {
                  deleteInfo(info.id);
                }}
                style={{ animationDuration: `${info.time}s` }}
              ></div>
            )}
          </div>
        ))}
      </div>
      {children}
    </MainContext.Provider>
  );
};
