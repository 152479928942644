import React from "react";
import "./Status.css";
import { statusMsg } from "../../services/constants";

export default class Status extends React.Component<
  {
    type: "permitted" | "requested";
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="status-wrapper"
        style={{ backgroundColor: statusMsg[this.props.type].color }}
      >
        {statusMsg[this.props.type].textDe}
      </div>
    );
  }
}
