import React from "react";
import "./PaymentMethod.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import PaymentMethodsModal from "./modals/PaymentMethodsModal";

export default class PaymentMethod extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBookingId: string;
    selectedMandateId: string;
    method: string;
    editable?: boolean;
    refresh: any;
    type: "course" | "subscription";
  },
  { showModal: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  handleRefresh = () => {
    this.props.refresh();
  };

  render() {
    return (
      <>
        {this.props.editable && (
          <PaymentMethodsModal
            show={this.state.showModal}
            clubId={this.props.clubId}
            branchId={this.props.branchId}
            elemId={this.props.courseId}
            elemBookingId={this.props.courseBookingId}
            handleClose={() => {
              this.setState({ showModal: false });
            }}
            selectedMandateId={this.props.selectedMandateId}
            refresh={this.props.refresh}
            type={this.props.type}
          />
        )}

        {this.props.method ? (
          <div className="payment-method-wrapper">
            {!(this.props.method === "Kostenlos" ||
              this.props.method === "manuell") && (
              <img
                src={`images/icons/paymentMethods/${this.props.method}.svg`}
                alt="payment-method-icon"
                className="payment-method-img"
              />
            )}
            {this.props.method}
            {this.props.editable && (
              <>
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={() => {
                    this.setState({ showModal: true });
                  }}
                  className="edit-payment-method-icon"
                />
              </>
            )}
          </div>
        ) : (
          "-"
        )}
      </>
    );
  }
}
