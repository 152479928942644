import React from "react";
import "./CourseElement.css";
import "./CourseElementSkeleton.css";
import { MainContext } from "../contexts/MainContext";

export default class CourseElementSkeleton extends React.Component<{}, {}> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="course-container">
        <div>
          <div className="course-img f-skeleton f-skeleton-img"></div>
        </div>
        <div className="course-info-container">
          <div className="course-info-inner-container">
            <div
              className="f-skeleton f-skeleton-heading"
              style={{ width: "50%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text f-skeleton-text"
              style={{ width: "100%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text f-skeleton-text show-mobile"
              style={{ width: "100%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text f-skeleton-text show-mobile"
              style={{ width: "100%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text"
              style={{ width: "90%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text"
              style={{ width: "0%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text"
              style={{ width: "10%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text"
              style={{ width: "14%" }}
            ></div>
            <div
              className="f-skeleton f-skeleton-text"
              style={{ width: "13%" }}
            ></div>
          </div>
          <div className="f-skeleton f-skeleton-btn"></div>
        </div>
      </div>
    );
  }
}
