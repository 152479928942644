import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./Objects.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Input from "../../components/Input";
import SecButton from "../../components/SecButton";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props & { refreshNavbar: any } & RouteComponentProps<{
    clubId: string;
    branchId: string;
    objectId: string;
  }>;

export default class Objects extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    objectId: string;
    branch: any;
    branchLoaded: boolean;
    objectName: string;
    change: boolean;
    updateLoading: boolean;
    redirect: null | string;
    deleteLoading: boolean;
    showConfirmationModal: boolean;
    objectArr: Array<any>;
    bookingInterval: number;
    startTime: string;
    endTime: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branch: {},
      branchLoaded: false,
      branchId: this.props.match.params.branchId,
      objectId: this.props.match.params.objectId,
      objectName: "",
      change: false,
      updateLoading: false,
      redirect: null,
      deleteLoading: false,
      showConfirmationModal: false,
      objectArr: [],
      bookingInterval: 10,
      startTime: "00:00",
      endTime: "00:00",
    };
  }

  componentDidMount = () => {
    this.requestBranch();
  };

  componentDidUpdate = () => {
    if (this.state.objectId !== this.props.match.params.objectId) {
      this.setState(
        { objectId: this.props.match.params.objectId },
        this.updateObjectData
      );
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  updateObject = () => {
    this.setState({ updateLoading: true });
    fetchRetry(
      "updateObject",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        objectId: this.state.objectId,
        name: this.state.objectName,
        settings: {
          bookingInterval: this.state.bookingInterval,
          startTime: this.state.startTime,
          stopTime: this.state.endTime,
        },
      },
      1,
      5
    )
      .then(this.handleSuccessUpdate)
      .catch(this.handleError);
  };

  handleSuccessUpdate = () => {
    this.setState({ updateLoading: false });
    this.props.refreshNavbar();
    this.requestBranch();
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      const objectArr: any = [];
      for (const objectId in branch.objects) {
        const object = branch.objects[objectId];
        object.id = objectId;
        objectArr.push(object);
      }
      objectArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      this.setState(
        {
          branch: branch,
          objectArr,
        },
        this.updateObjectData
      );
    }
  };

  updateObjectData = () => {
    if (this.state.objectId in this.state.branch.objects) {
      const object = this.state.branch.objects[this.state.objectId];
      this.setState(
        {
          objectName: object.name,
          bookingInterval: object.settings.bookingInterval,
          startTime: object.settings.startTime,
          endTime: object.settings.stopTime,
        },
        this.checkChange
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleObjectNameChange = (val: any) => {
    this.setState({ objectName: val }, this.checkChange);
  };

  handleBookingIntervalChange = (val: any) => {
    this.setState({ bookingInterval: val }, this.checkChange);
  };
  handleStartTimeChange = (val: any) => {
    this.setState({ startTime: val.target.value }, this.checkChange);
  };

  handleEndTimeChange = (val: any) => {
    this.setState({ endTime: val.target.value }, this.checkChange);
  };

  checkChange = () => {
    this.setState({
      change:
        this.state.objectName !==
          this.state.branch.objects[this.state.objectId].name ||
        this.state.bookingInterval !==
          this.state.branch.objects[this.state.objectId].settings
            .bookingInterval ||
        this.state.startTime !==
          this.state.branch.objects[this.state.objectId].settings.startTime ||
        this.state.endTime !==
          this.state.branch.objects[this.state.objectId].settings.stopTime,
    });
  };

  handleDeleteObject = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleObjectDeleteConfirmed = () => {
    this.setState({ deleteLoading: true, showConfirmationModal: false });
    fetchRetry(
      "deleteObject",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        objectId: this.state.objectId,
      },
      1,
      5
    )
      .then(this.handleSuccessObjectDeleted)
      .catch(this.handleError);
  };

  handleSuccessObjectDeleted = ({ data }: any) => {
    this.setState({ deleteLoading: false });
    if (data.success) {
      this.setState(
        {
          redirect: `/club/${this.state.clubId}/branch-settings/${this.state.branchId}/objects`,
        },
        () => {
          this.updateObjectData();
          this.props.refreshNavbar();
        }
      );
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          handleClose={this.hideConfirmationModal}
          title="Bist du sicher?"
          msg={`Willst du das Objekt "${
            this.state.branch.objects[this.state.objectId]?.name
              ? this.state.branch.objects[this.state.objectId].name
              : "-"
          }" wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.`}
          handleConfirm={this.handleObjectDeleteConfirmed}
        />
        <div className="subscreen-branch-inner-container">
          <div className="subscreen-main-container">
            {Object.keys(this.state.branch.objects).length > 0 &&
              this.state.objectId && (
                <>
                  <div className="subscreen-box-container">
                    <Title title="Einstellungen" />
                    <SubTitle title="Name" />
                    <Input
                      name="club-search-input"
                      value={this.state.objectName}
                      placeholder="Bereich name"
                      onChange={this.handleObjectNameChange}
                    />

                    {this.state.branch.settings.useBooking && (
                      <>
                        <Title title="Buchungseinstellungen" />
                        <SubTitle title="Minimales Buchungsintervall in Minuten" />
                        <Input
                          name="bookingInterval"
                          type={"number"}
                          min={10}
                          value={this.state.bookingInterval}
                          placeholder="Buchungsintervall"
                          onChange={this.handleBookingIntervalChange}
                        />
                        <div className="branch-subtitle-container">
                          <h3>Startzeit</h3>
                        </div>
                        <input
                          className="club-search-text-input"
                          name="startTime"
                          type="time"
                          value={this.state.startTime}
                          placeholder="Buchungsintervall"
                          onChange={this.handleStartTimeChange}
                        />
                        <div className="branch-subtitle-container">
                          <h3>Endzeit</h3>
                        </div>
                        <input
                          className="club-search-text-input"
                          name="startTime"
                          type="time"
                          value={this.state.endTime}
                          placeholder="Buchungsintervall"
                          onChange={this.handleEndTimeChange}
                        />
                      </>
                    )}
                    <SecButton
                      change={this.state.change}
                      color="green"
                      loading={this.state.updateLoading}
                      onClick={this.updateObject}
                      title="Speichern"
                    />
                    <SecButton
                      change={true}
                      color="red"
                      loading={this.state.deleteLoading}
                      onClick={this.handleDeleteObject}
                      title="Objekt löschen"
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      </>
    );
  }
}
