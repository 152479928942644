import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import "./Main.css";
import HashLoader from "react-spinners/HashLoader";
import { MainContext } from "../../contexts/MainContext";
import ReqMemAppMessage from "../../components/ReqMemAppMessage";
import EnterMembershipModal from "../../components/modals/EnterMembershipModal";
import qs from "qs";
import InfoContainer from "../../components/InfoContainer";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Main extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    braches: Array<any>;
    selectedBranch: any;
    showJoinModal: boolean;
    joinModalLoading: boolean;
    joinModalMsg: null | string;
    joinModalType: "error" | "success";
    showRequestedModal: boolean;
    requestedModalLoading: boolean;
    requestedModalMsg: null | string;
    requestedModalType: "error" | "success";
    redirect: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      braches: [],
      selectedBranch: {},
      showJoinModal:
        qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).joinBranch === "true",
      joinModalLoading: false,
      joinModalMsg: null,
      joinModalType: "error",
      showRequestedModal: false,
      requestedModalLoading: false,
      requestedModalMsg: null,
      requestedModalType: "error",
      redirect:
        (qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).redirect || "") + "",
    };
  }

  componentDidMount = () => {
    this.handleClub();
  };

  handleClub = () => {
    const club = this.context.club;
    const branches = club.branches;
    const branchesArr: Array<any> = [];

    for (const branchId in branches) {
      const branch = branches[branchId];
      branch.id = branchId;
      branchesArr.push(branch);
    }
    branchesArr.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

    this.setState({
      club: club,
      braches: branchesArr,
    });
  };

  handleBranchJoinModalClose = () => {
    this.setState({ showJoinModal: false });
  };

  handleShowJoinModal = () => {
    this.setState({ showJoinModal: true });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    const club = this.context.club;
    const branches = club.branches;
    const branchesMember: Array<any> = [];
    for (const branchId in branches) {
      const branch = branches[branchId];
      branch.id = branchId;
      branchesMember.push(branch);
    }
    branchesMember.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

    const userRoles: string[] = club?.user?.roles || [];
    const clubRoleIds: string[] = Object.keys(club?.roles || {}) || [];
    const doesUserHasClubRole: boolean = clubRoleIds.some((role) =>
      userRoles.includes(role)
    );

    return (
      <>
        <EnterMembershipModal
          clubId={this.state.clubId}
          show={this.state.showJoinModal}
          clubName={this.context.club?.name}
          membershipName={this.context.club?.text?.membershipNumberText}
          handleClose={this.handleBranchJoinModalClose}
          useMembershipnumber={this.context.club?.settings?.useMembershipNumber}
          askForMembershipnumber={
            this.context.club?.settings?.askForMembershipNumber
          }
          useBirthDate={this.context.club?.settings?.bBirthDate}
          useAddress={this.context.club?.settings?.bAddress}
          usePhoneNumber={this.context.club?.settings?.bPhoneNumber}
          alreadyMemberBtnText={this.context.club?.text?.alreadyMemberBtnText}
          redirect={this.state.redirect}
        />
        <div className="big-banner-container">
          <>
            {this.context.club?.bannerHighRes && (
              <img
                src={this.context.club.bannerHighRes}
                alt="Vereinsbanner"
                className="club-banner-big"
              />
            )}
            {this.context.club?.settings?.showLogoInBanner &&
              this.context.club?.logoHighRes && (
                <img
                  src={this.context.club.logoHighRes}
                  alt="Vereinslogo"
                  className="club-logo-big"
                />
              )}
          </>
        </div>
        <div className="inner-container-club">
          {!this.context.clubLoaded ? (
            <>
              <div className="loading-container">
                <HashLoader color={"#c31924"} size={100} loading={true} />
              </div>
            </>
          ) : (
            <>
              <div className="club-name-container">
                <h1>{this.context.club?.name}</h1>
              </div>
              {this.context.user && (
                <InfoContainer>
                  Wenn du die Kurse und Mitgliedschaften deiner
                  Familienmitglieder über einen Account verwalten möchtest,
                  kannst du diese nun in deinem{" "}
                  <Link to={`${this.state.clubId}/profile`}>Profil</Link>{" "}
                  hinzufügen.
                </InfoContainer>
              )}
              <ReqMemAppMessage
                show={
                  this.context.club?.settings?.showRequestClubWindow &&
                  !doesUserHasClubRole
                }
                clubId={this.state.clubId}
                alreadyMemberBtnText={
                  this.context.club?.text?.alreadyMemberBtnText
                }
                requestMembershipBtnText={
                  this.context.club?.text?.requestMembershipBtnText
                }
                requestMembershipText={
                  this.context.club?.text?.requestMembershipText
                }
                requestMembershipUrl={
                  this.context.club?.text?.requestMembershipUrl
                }
                alreadyMemberText={this.context.club?.text?.alreadyMemberText}
                handleShowJoinModal={this.handleShowJoinModal}
                handleShowRequestedModal={() => {}}
                hasRequested={false}
              />
              <div className="branches-container">
                <span className="branch-title">Bereiche</span>
                <div className="branch-grid">
                  {branchesMember.map((branch: any) => (
                    <Link
                      to={`/club/${this.context.clubId}/branch/${branch.id}/course`}
                      className="branch-container no-underline"
                      key={branch.id}
                    >
                      <span>{branch.name}</span>
                    </Link>
                  ))}
                </div>
                {branchesMember.length === 0 && this.context.clubLoaded && (
                  <div className="center no-branches-available">
                    Es stehen keine Bereiche zur Verfügung.
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
