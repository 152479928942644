import React from "react";
import "./Input.css";

export default class MarkAsResolvedBtn extends React.Component<
  {
    onClick: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          this.props.onClick();
        }}
        className="mark-as-resolved-btn"
      >
        Als gelöst markieren
      </div>
    );
  }
}
