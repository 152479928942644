import React from "react";
import {
  formatDateUnit,
  getMonthNameFromDate,
  priceToStr,
} from "../functions/utils";
import { MainContext } from "../contexts/MainContext";
import "./CourseTerms.css";

export default class CourseTerms extends React.Component<
  {
    termsAccepted: boolean;
    toggleTermsAccepted: any;
    initialPrice: number;
    price: number;
    billingPeriod: number;
    admissionFee: number;
    startDate: any;
    noticePeriod: number;
    noticePeriodDateUnit: string;
    cancellationDays: number;
    cancellationDate: any;
    showAcceptTermsMessage: boolean;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`accept-container ${
            this.props.showAcceptTermsMessage ? "accept-container-glowing" : ""
          }`}
        >
          <div>
            <input
              type="checkbox"
              className="accept-container-checkbox"
              checked={this.props.termsAccepted}
              onClick={this.props.toggleTermsAccepted}
            />
          </div>
          <div>
            {this.props.initialPrice === this.props.price ? (
              <>
                Deine Mitgliedschaft beginnt im{" "}
                {getMonthNameFromDate(this.props.startDate)}. Es werden{" "}
                {priceToStr(this.props.price)}€{" "}
                {this.props.billingPeriod === 1
                  ? "pro Monat"
                  : `alle ${this.props.billingPeriod} Monate`}{" "}
                abgerechnet. Preisänderungen bei Änderung deiner
                Rolle/Mitgliedschaft vorbehalten.
              </>
            ) : (
              <>
                Deine Mitgliedschaft beginnt im{" "}
                {getMonthNameFromDate(this.props.startDate)}{" "}
                {this.props.startDate.getFullYear()}, wir berechnen für{" "}
                {getMonthNameFromDate(this.props.startDate)}{" "}
                {priceToStr(this.props.initialPrice)}€. Fortlaufend werden{" "}
                {priceToStr(this.props.price)}€{" "}
                {this.props.billingPeriod === 1
                  ? "pro Monat"
                  : `alle ${this.props.billingPeriod} Monate`}{" "}
                abgerechnet. Preisänderungen bei Änderung deiner
                Rolle/Mitgliedschaft vorbehalten.
              </>
            )}{" "}
            Die Kündigungsfrist beträgt {this.props.noticePeriod}{" "}
            {formatDateUnit(
              this.props.noticePeriodDateUnit,
              this.props.noticePeriod
            )}
            .{" "}
            {this.context.club?.agb && (
              <>
                Es gelten diese{" "}
                <a
                  href={this.context.club?.agb}
                  target="_blank"
                  rel="noreferrer"
                >
                  AGB
                </a>
                .
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
