import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./Main.css";
import HashLoader from "react-spinners/HashLoader";
import { MainContext } from "../../contexts/MainContext";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    onboardingId: string;
  }>;

export default class OnBoarding extends React.Component<
  ComposedProps,
  {
    clubId: string;
    onboardingId: string;
    club: any;
    clubLoaded: boolean;
    redirect: string | null;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      onboardingId: this.props.match.params.onboardingId,
      club: {},
      clubLoaded: false,
      redirect: null,
    };
  }

  componentDidMount = () => {
    if (this.context.user) {
      // user is authenticated
    } else {
      // user is not authenticated
      this.setState({
        redirect: `/sign-up/${this.state.clubId}/${this.state.onboardingId}`,
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        {!this.context.clubLoaded && (
          <>
            <div className="loading-container">
              <HashLoader color={"#c31924"} size={100} loading={true} />
            </div>
          </>
        )}
      </>
    );
  }
}
