import React from "react";
import { RouteComponentProps, Redirect, Link } from "react-router-dom";
import "./SingleCourse.css";
import "../../components/CourseElement.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  addDayToDate,
  calcCourseSubscription,
  dateDurationToFullStr,
  dateToFullStr,
  firebaseDateToDateObj,
  getAgeStr,
  getRightSeperator,
  makeid,
  minTimeToStr,
  priceToStr,
  reformatDate,
  strTimeToMin,
  strToDate,
} from "../../functions/utils";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import SubTitle from "../../components/SubTitle";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import { weekDays } from "../../services/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestMode from "../../components/TestMode";
import Title from "../../components/Title";
import CourseTerms from "../../components/CourseTerms";
import {
  faChevronDown,
  faChevronUp,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import InfoContainer from "../../components/InfoContainer";
import BookButton from "../../components/BookButton";
import qs from "qs";
import ViewForm from "../../components/form/view/ViewForm";
import { FormInput } from "../../Types";
import BranchCourseTerms from "../../components/BranchCourseTerms";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
  }>;

export default class SingleCourse extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    redirectTo: string;
    branchLoaded: boolean;
    courseLoaded: boolean;
    bookCourseLoading: boolean;
    branch: any;
    course: any;
    redirect: string | null;
    email: string;
    fName: string;
    lName: string;
    lNameFamily: string;
    fNameFamily: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    birthDateFamily: string;
    change: boolean;
    errorMsg: null | string;
    termsAccepted: boolean;
    branchTermsAccepted: boolean;
    showAcceptTermsMessage: boolean;
    bookForOther: boolean;
    showMore: boolean;
    sessionId: string;
    formInput: FormInput[];
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      redirectTo: window.location.href.split("/")[5],
      branchLoaded: false,
      courseLoaded: false,
      bookCourseLoading: false,
      branch: {},
      course: {},
      redirect: null,
      email: "",
      fName: "",
      lName: "",
      fNameFamily: "",
      lNameFamily: "",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      birthDateFamily: "",
      change: false,
      errorMsg: null,
      termsAccepted: false,
      branchTermsAccepted: false,
      showAcceptTermsMessage: false,
      bookForOther: false,
      showMore: false,
      sessionId: makeid(32),
      formInput: [],
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestCourse();
  };

  componentWillUnmount() {
    document.getElementById("paypal-fraud-1")?.remove();
    document.getElementById("paypal-fraud-2")?.remove();
  }

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    if (data.success) {
      const branch = data.data;
      this.setState({
        branchLoaded: true,
        branch,
      });
      const paypalWebsiteId = this.state.branch?.paypalWebsiteId;
      if (paypalWebsiteId) {
        const script = document.createElement("script");
        script.innerHTML = `{
        "f": "${this.state.sessionId}",
        "s": "${paypalWebsiteId}"
     }`;
        script.type = "application/json";
        script.setAttribute(
          "fncls",
          "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99"
        );
        script.setAttribute("id", "paypal-fraud-1");
        const paypalFraudScript = document.createElement("script");

        paypalFraudScript.src = "https://c.paypal.com/da/r/fb.js";
        paypalFraudScript.type = "text/javascript";
        paypalFraudScript.setAttribute("id", "paypal-fraud-2");

        document.body.appendChild(script);
        document.body.appendChild(paypalFraudScript);
      }
    }
  };

  requestCourse = () => {
    fetchRetry(
      "getCourseFromIdFNew",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
        targetUserId: this.context.selectedUserId,
        includeForm: true,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCourseSuccess = ({ data }: any) => {
    if (data.success) {
      const courseData = data.data[0];
      const formData = courseData?.formData || {};
      if (formData) {
        const options = formData?.options || [];

        const formInput: FormInput[] = options.map((form: any) => {
          return {
            id: form.id,
            isActive: false,
            value: "",
          };
        });
        this.setState({ formInput });
      }
      this.setState({ courseLoaded: true, course: data.data[0] });
    }
  };

  bookCourse = () => {
    if (
      this.state.termsAccepted &&
      (!this.state.branch.agb || this.state.branchTermsAccepted)
    ) {
      const redirectUrl = `${window.location.origin}/club/${this.state.clubId}/branch/${this.state.branchId}/courseBooking/`;
      if (!this.state.bookCourseLoading) {
        if (!this.context.user) {
          this.setState({ bookCourseLoading: true });
          fetchRetry(
            "bookCourseFNew",
            {
              targetUserId: this.context.selectedUserId,
              clubId: this.state.clubId,
              branchId: this.state.branchId,
              courseId: this.state.courseId,
              price: this.state.course.price,
              redirectUrl: redirectUrl,
              email: this.state.email,
              fName: this.state.fName,
              lName: this.state.lName,
              birthDate: reformatDate(this.state.birthDate),
              address: {
                street: this.state.street,
                houseNumber: this.state.houseNumber,
                zipcode: this.state.zipcode,
                city: this.state.city,
              },
              phoneNumber: this.state.phoneNumber,
              cancellationDays: this.state.course.cancellationDays,
              sessionId: this.state.sessionId,
              formInput: this.state.formInput,
            },
            1,
            5
          )
            .then(this.handleBookCourseSuccess)
            .catch(this.handleError);
        } else {
          if (this.context.userLoaded) {
            this.setState({ bookCourseLoading: true });
            fetchRetry(
              "bookCourseFNew",
              {
                targetUserId: this.context.selectedUserId,
                clubId: this.state.clubId,
                branchId: this.state.branchId,
                courseId: this.state.courseId,
                price: this.state.course.price,
                redirectUrl: redirectUrl,
                cancellationDays: this.state.course.cancellationDays,
                bookForFamily: this.state.bookForOther,
                fNameFamily: this.state.fNameFamily,
                lNameFamily: this.state.lNameFamily,
                birthDateFamily: reformatDate(this.state.birthDateFamily),
                sessionId: this.state.sessionId,
                formInput: this.state.formInput,
              },
              1,
              5
            )
              .then(this.handleBookCourseSuccess)
              .catch(this.handleError);
          }
        }
      }
    } else {
      this.setState({ showAcceptTermsMessage: true });
    }
  };

  handleBookCourseSuccess = ({ data }: any) => {
    this.setState({ bookCourseLoading: false });
    if (data.success) {
      this.setState({ errorMsg: null });
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      } else {
        window.location.href = `${window.location.origin}/club/${this.state.clubId}/branch/${this.state.branchId}/courseBooking/${data.courseBookingId}/${data.clientKey}`;
        this.requestCourse();
      }
    } else {
      this.setState({ errorMsg: data.errorMsgDe });
      if (data.code === "user-data-needed") {
        if (this.state.redirectTo === "branchCourse") {
          const singleCourseUrls = `/club/${this.state.clubId}/branchCourse/${this.state.branchId}/${this.state.courseId}`;
          this.setState({
            redirect: `/club/${this.state.clubId}/profile?redirect=${singleCourseUrls}&complete=true`,
          });
        } else {
          const singleCourseUrls = `/club/${this.state.clubId}/branch/${this.state.branchId}/course/${this.state.courseId}`;
          this.setState({
            redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/profile?redirect=${singleCourseUrls}&complete=true`,
          });
        }
      }
    }
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val });
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val });
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val });
  };

  handleFNameFamilyChange = (val: any) => {
    this.setState({ fNameFamily: val });
  };

  handleLNameFamilyChange = (val: any) => {
    this.setState({ lNameFamily: val });
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val });
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val });
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val });
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val });
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val });
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value });
  };

  handleBirthDateFamilyChange = (val: any) => {
    this.setState({ birthDateFamily: val.target.value });
  };

  toggleTermsAccepted = () => {
    if (!this.state.termsAccepted) {
    }
    this.setState({
      termsAccepted: !this.state.termsAccepted,
    });
  };

  toggleBranchTermsAccepted = () => {
    if (!this.state.branchTermsAccepted) {
    }
    this.setState({
      branchTermsAccepted: !this.state.branchTermsAccepted,
    });
  };

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    if (
      !this.state.branchLoaded ||
      !this.state.courseLoaded ||
      (this.context.user && !this.context.userLoaded)
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const testMode = this.state.branch?.billing?.mode !== "live";

    const cancellationDate = !this.state.course?.cancellationDays
      ? null
      : addDayToDate(
          firebaseDateToDateObj(this.state.course?.startTime),
          -this.state.course?.cancellationDays
        );

    const [startDate, initialPrice] = this.state.course
      ? calcCourseSubscription(
          this.state.course.price,
          firebaseDateToDateObj(this.state.course.startTime)
        )
      : [null, null];

    const bookableFromDate = this.state.course
      ? firebaseDateToDateObj(this.state.course.bookableDate)
      : null;
    const bookableFrom = bookableFromDate
      ? bookableFromDate > new Date()
        ? bookableFromDate
        : null
      : null;

    const branchRoleObj = this.state.branch?.roles
      ? this.state.branch.roles
      : {};
    branchRoleObj["default"] = {
      name: "Regulärer Preis",
      priority: -2,
    };
    branchRoleObj["public"] = {
      name: "Registrierter Benutzer Preis",
      priority: -1,
    };

    const alternativePriceArr: Array<any> = (
      this.state.course?.priceArr ? this.state.course.priceArr : []
    )
      .filter(
        (currPriceElem: any) =>
          (currPriceElem.showAlways ||
            currPriceElem.id === this.state.course.priceRole) &&
          branchRoleObj[currPriceElem.id]?.name
      )
      .map((currPriceElem: any) => {
        currPriceElem.roleName = branchRoleObj[currPriceElem.id]?.name;
        currPriceElem.priority = branchRoleObj[currPriceElem.id]?.priority;
        currPriceElem.used = currPriceElem.id === this.state.course.priceRole;
        return currPriceElem;
      });

    const allowedRoleNameArr: Array<any> = this.state.course?.allowedRoles
      .filter((currRole: any) => branchRoleObj[currRole]?.name)
      .map((currRole: any) => {
        return {
          name: branchRoleObj[currRole]?.name,
          useMemApp:
            this.state.branch?.clubSettings?.memAppRoleArr?.includes(currRole),
          subscriptions: branchRoleObj[currRole]?.subscriptions || [],
        };
      });

    alternativePriceArr.sort((a: any, b: any) =>
      a.priority < b.priority ? 1 : -1
    );

    const freePlaces = testMode
      ? this.state.course?.freePlacesTest
      : this.state.course?.freePlaces > 0
      ? this.state.course?.freePlaces
      : 0;
    const hasFreePlaces = freePlaces > 0;

    if (!this.state.course) {
      return (
        <>
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              <BackElement
                text="zurück zur Kursauswahl"
                to={
                  this.state.redirectTo === "branchCourse"
                    ? `/club/${this.state.clubId}/course#${this.state.courseId}`
                    : `/club/${this.state.clubId}/branch/${this.state.branchId}/course#${this.state.courseId}`
                }
              />
              <div className="full-center">
                <span className="not-exist-text">
                  Dieses Angebot existiert nicht oder du darfst es nicht sehen.
                </span>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <>
          <div className="subscreen-main-container">
            <div className="subscreen-box-container">
              <BackElement
                text="zurück zur Kursauswahl"
                to={
                  this.state.redirectTo === "branchCourse"
                    ? `/club/${this.state.clubId}/course#${this.state.courseId}`
                    : `/club/${this.state.clubId}/branch/${this.state.branchId}/course#${this.state.courseId}`
                }
              />
              <Title title="Angebot buchen" />
              <TestMode active={this.state.branch?.billing?.mode === "test"} />
              <div className="courseBooking-course-info-container">
                <div>
                  {!this.state.course?.imgLowRes ? (
                    <div className="single-course-img">
                      <FontAwesomeIcon
                        icon={faImage}
                        size="6x"
                        className="image-icon"
                      />
                    </div>
                  ) : (
                    <img
                      className="single-course-img"
                      src={this.state.course?.imgLowRes}
                      alt="Kursbild"
                    />
                  )}
                </div>
                <div>
                  {this.state.course?.courseType === "single-course" && (
                    <>
                      <h2>{this.state.course?.title}</h2>
                      <span>{this.state.course?.description}</span>
                      <div className="course-text-container">
                        Wann:{" "}
                        <span className="normal-weight">
                          {dateDurationToFullStr(
                            firebaseDateToDateObj(this.state.course?.startTime),
                            firebaseDateToDateObj(this.state.course?.endTime)
                          )}
                        </span>
                      </div>
                      {this.state.course?.location?.name && (
                        <div className="course-text-container">
                          Wo:{" "}
                          <span className="normal-weight">
                            {this.state.course?.location?.name}
                          </span>
                        </div>
                      )}
                      <div className="course-text-container">
                        Freie Plätze:{" "}
                        <span className="normal-weight">{freePlaces}</span>
                      </div>
                      <div className="course-text-container">
                        Preis:{" "}
                        {alternativePriceArr.length <= 1 ? (
                          <span className="normal-weight">
                            {priceToStr(this.state.course?.price)}€
                          </span>
                        ) : (
                          <div className="course-text-container">
                            <table className="alternative-price-table">
                              <tbody>
                                {alternativePriceArr.map(
                                  (price: any, index: number) => (
                                    <tr
                                      key={index}
                                      className={`${
                                        price.used
                                          ? "alternative-price-used"
                                          : ""
                                      }`}
                                    >
                                      <td>
                                        {price.roleName}
                                        {price.used ? " (Dein Preis)" : ""}:
                                      </td>
                                      <td>{priceToStr(price.price)}€</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {this.state.course?.courseType === "block-course" && (
                    <>
                      <h2>{this.state.course?.title}</h2>
                      <span>{this.state.course?.description}</span>
                      <div className="course-text-container">
                        Wann:{" "}
                        <span className="normal-weight">
                          {this.state.course?.courses.map(
                            (course: any, index: number) => (
                              <div key={index}>
                                -{" "}
                                {dateDurationToFullStr(
                                  firebaseDateToDateObj(course.startTime),
                                  firebaseDateToDateObj(course.endTime)
                                )}{" "}
                                {course?.location?.name && (
                                  <> ({course.location.name})</>
                                )}
                              </div>
                            )
                          )}
                        </span>
                      </div>

                      <div className="course-text-container">
                        Freie Plätze:{" "}
                        <span className="normal-weight">{freePlaces}</span>
                      </div>
                      {(this.state.course?.minAge ||
                        this.state.course?.maxAge) && (
                        <div className="course-text-container">
                          Alter:{" "}
                          <span className="normal-weight">
                            {getAgeStr(
                              this.state.course.minAge,
                              this.state.course.maxAge
                            )}
                          </span>
                        </div>
                      )}
                      <div className="course-text-container">
                        Preis:{" "}
                        {alternativePriceArr.length <= 1 ? (
                          <span className="normal-weight">
                            {priceToStr(this.state.course?.price)}€
                          </span>
                        ) : (
                          <div className="course-text-container">
                            <table className="alternative-price-table">
                              <tbody>
                                {alternativePriceArr.map(
                                  (price: any, index: number) => (
                                    <tr
                                      key={index}
                                      className={`${
                                        price.used
                                          ? "alternative-price-used"
                                          : ""
                                      }`}
                                    >
                                      <td>
                                        {price.roleName}
                                        {price.used ? " (Dein Preis)" : ""}:
                                      </td>
                                      <td>{priceToStr(price.price)}€</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {this.state.course?.courseType === "infinity-course" && (
                    <>
                      <h2>{this.state.course?.title}</h2>
                      <span>{this.state.course?.description}</span>
                      <div className="course-text-container">
                        Wann:{" "}
                        <span className="normal-weight">
                          jeden {weekDays[this.state.course?.weekDay].name} von{" "}
                          {this.state.course?.time} Uhr -{" "}
                          {minTimeToStr(
                            strTimeToMin(this.state.course?.time) +
                              this.state.course.duration
                          )}{" "}
                          Uhr
                        </span>
                      </div>
                      {this.state.course?.negativeDates &&
                        this.state.course?.negativeDates.filter(
                          (dateStr: string) => {
                            const date = strToDate(dateStr);
                            return date > new Date();
                          }
                        ).length > 0 && (
                          <div className="course-text-container">
                            Ausfallende Termine:{" "}
                            <span className="normal-weight">
                              {this.state.course?.negativeDates
                                .filter((dateStr: string) => {
                                  const date = strToDate(dateStr);
                                  const dateAdded = addDayToDate(
                                    new Date(),
                                    -1
                                  );
                                  return date > (dateAdded ? dateAdded : 0);
                                })
                                .sort((a: any, b: any) =>
                                  strToDate(a).getTime() >
                                  strToDate(b).getTime()
                                    ? 1
                                    : -1
                                )
                                .slice(
                                  0,
                                  this.state.showMore
                                    ? this.state.course?.negativeDates.length
                                    : 3
                                )
                                .map((date: string, index: number) => (
                                  <div key={index}>- {date} </div>
                                ))}
                            </span>
                            {this.state.course?.negativeDates.length > 3 && (
                              <div
                                className="more-info-container"
                                onClick={this.toggleShowMore}
                              >
                                <div className="more-info-inner-container">
                                  {!this.state.showMore ? (
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      size="sm"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faChevronUp}
                                      size="sm"
                                    />
                                  )}
                                </div>
                                {this.state.showMore
                                  ? "weniger anzeigen"
                                  : "mehr anzeigen"}
                              </div>
                            )}
                          </div>
                        )}
                      {this.state.course?.location?.name && (
                        <div className="course-text-container">
                          Wo:{" "}
                          <span className="normal-weight">
                            {this.state.course?.location?.name}
                          </span>
                        </div>
                      )}
                      <div className="course-text-container">
                        Freie Plätze:{" "}
                        <span className="normal-weight">{freePlaces}</span>
                      </div>
                      {(this.state.course?.minAge ||
                        this.state.course?.maxAge) && (
                        <div className="course-text-container">
                          Alter:{" "}
                          <span className="normal-weight">
                            {getAgeStr(
                              this.state.course.minAge,
                              this.state.course.maxAge
                            )}
                          </span>
                        </div>
                      )}
                      <div className="course-text-container">
                        Preis:{" "}
                        {alternativePriceArr.length <= 1 ? (
                          <span className="normal-weight">
                            {priceToStr(this.state.course?.price)}€
                          </span>
                        ) : (
                          <div className="course-text-container">
                            <table className="alternative-price-table">
                              <tbody>
                                {alternativePriceArr.map(
                                  (price: any, index: number) => (
                                    <tr
                                      key={index}
                                      className={`${
                                        price.used
                                          ? "alternative-price-used"
                                          : ""
                                      }`}
                                    >
                                      <td>
                                        {price.roleName}
                                        {price.used ? " (Dein Preis)" : ""}:
                                      </td>
                                      <td>{priceToStr(price.price)}€</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {this.state.course.userAllowedToBook ? (
                    <>
                      {this.context.user && (
                        <>
                          {this.state.course?.courseType ===
                            "infinity-course" && (
                            <>
                              <div className="medium-space"></div>
                              <InfoContainer>
                                Abrechnung per Lastschrift über Trustly,
                                Kreditkarte oder PayPal.
                              </InfoContainer>
                            </>
                          )}
                          <ViewForm
                            form={this.state.course.formData}
                            formInput={this.state.formInput}
                            onChange={(v: FormInput[]) =>
                              this.setState({ formInput: v })
                            }
                          />
                          <CourseTerms
                            single={
                              this.state.course?.courseType ===
                                "single-course" ||
                              this.state.course?.courseType === "block-course"
                            }
                            termsAccepted={this.state.termsAccepted}
                            toggleTermsAccepted={this.toggleTermsAccepted}
                            initialPrice={initialPrice}
                            price={this.state.course?.price}
                            startDate={startDate}
                            noticePeriod={this.state.course?.noticePeriod}
                            noticePeriodDateUnit={
                              this.state.course?.noticePeriodDateUnit
                            }
                            cancellationDays={
                              this.state.course?.cancellationDays
                            }
                            cancellationDate={cancellationDate}
                            showAcceptTermsMessage={
                              this.state.showAcceptTermsMessage
                            }
                          />
                          <BranchCourseTerms
                            agb={this.state.branch.agb}
                            showAcceptTermsMessage={
                              this.state.showAcceptTermsMessage
                            }
                            termsAccepted={this.state.branchTermsAccepted}
                            toggleTermsAccepted={this.toggleBranchTermsAccepted}
                          />
                          <ErrorMessage message={this.state.errorMsg} />
                          {!this.state.bookForOther && (
                            <BookButton
                              loading={this.state.bookCourseLoading}
                              onClick={this.bookCourse}
                              title={
                                this.state.course?.courseType ===
                                  "single-course" ||
                                this.state.course?.courseType === "block-course"
                                  ? bookableFrom
                                    ? `Buchbar ab ${dateToFullStr(
                                        bookableFrom
                                      )}`
                                    : hasFreePlaces
                                    ? "Angebot buchen"
                                    : "Ausgebucht"
                                  : bookableFrom
                                  ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                                  : "Abonnement starten"
                              }
                              className={`course-book-btn ${
                                bookableFrom || !hasFreePlaces
                                  ? " course-book-btn-gray"
                                  : ""
                              }`}
                            />
                          )}
                          <div
                            className={`collapse-outer-container${
                              this.state.bookForOther ? " startAnimation" : ""
                            }`}
                          >
                            <div className="collapse-container">
                              <div className="flex gap">
                                <Input
                                  value={this.state.fNameFamily}
                                  onChange={this.handleFNameFamilyChange}
                                  placeholder="Vorname"
                                  className="no-margin"
                                />
                                <Input
                                  value={this.state.lNameFamily}
                                  onChange={this.handleLNameFamilyChange}
                                  placeholder="Nachname"
                                  className="no-margin"
                                />
                              </div>
                            </div>
                            {(this.state.branch?.settings?.gBirthDate ||
                              this.state.course.minAge !== null ||
                              this.state.course.maxAge !== null) && (
                              <div className="collapse-container">
                                <div className="flex gap birthDate-container">
                                  <SubTitle
                                    title="Geburtsdatum"
                                    className="no-margin"
                                  />
                                  <input
                                    type="date"
                                    className="input-container no-margin"
                                    value={this.state.birthDateFamily}
                                    onChange={this.handleBirthDateFamilyChange}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="collapse-container">
                              <ErrorMessage message={this.state.errorMsg} />
                              <BookButton
                                loading={this.state.bookCourseLoading}
                                onClick={this.bookCourse}
                                title={
                                  this.state.course?.courseType ===
                                    "single-course" ||
                                  this.state.course?.courseType ===
                                    "block-course"
                                    ? bookableFrom
                                      ? `Buchbar ab ${dateToFullStr(
                                          bookableFrom
                                        )}`
                                      : "Angebot für Familienmitglied buchen"
                                    : bookableFrom
                                    ? `Buchbar ab ${dateToFullStr(
                                        bookableFrom
                                      )}`
                                    : "Abonnement starten"
                                }
                                className={`course-book-btn max-width${
                                  bookableFrom ? " course-book-btn-gray" : ""
                                }`}
                              />
                            </div>
                          </div>
                          {this.state.course.allowBookForFamily && (
                            <div
                              className="book-for-other-container"
                              onClick={() => {
                                this.setState({
                                  bookForOther: !this.state.bookForOther,
                                });
                              }}
                            >
                              {this.state.bookForOther
                                ? "Für mich buchen"
                                : "Für ein Familienmitglied buchen"}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <br />
                      Dieses Angebot ist nur für diese Gruppen buchbar:
                      <div className="course-text-container">
                        <table className="alternative-price-table">
                          <tbody>
                            {allowedRoleNameArr.map(
                              (role: any, index: number) => (
                                <tr
                                  key={index}
                                  className="alternative-price-used"
                                >
                                  <td>
                                    - {role.name}
                                    {role.useMemApp ? (
                                      <a
                                        href={`${
                                          this.state.branch?.clubSettings
                                            ?.requestMembershipUrl
                                        }${getRightSeperator(
                                          this.state.branch?.clubSettings
                                            ?.requestMembershipUrl
                                        )}${qs.stringify({
                                          redirect: window.location.href,
                                        })}`}
                                        className="req-memApp-link"
                                      >
                                        Jetzt Mitglied werden
                                      </a>
                                    ) : (
                                      role.subscriptions.length > 0 && (
                                        <Link
                                          to={`/club/${
                                            this.state.clubId
                                          }/branch/${
                                            this.state.branchId
                                          }/course?showSub=true&neededRoles=${role.subscriptions.join(
                                            ","
                                          )}&redirect=${window.location.href}`}
                                          className="req-memApp-link"
                                        >
                                          Jetzt Mitglied werden
                                        </Link>
                                      )
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!this.context.user && (
                <>
                  {this.state.course.userAllowedToBook && (
                    <>
                      {this.state.course?.courseType === "infinity-course" &&
                      !this.context.user ? (
                        <InfoContainer>
                          Damit du deine Abonnements verwalten kannst, benötigst
                          du einen Account.{" "}
                          <Link
                            to={`/sign-up/${this.state.clubId}?redirect=${window.location.href}`}
                          >
                            Jetzt registrieren
                          </Link>
                        </InfoContainer>
                      ) : (
                        <div>
                          <div className="medium-space"></div>
                          <SubTitle title="Name" />
                          <div className="course-time-input-container">
                            <Input
                              value={this.state.fName}
                              onChange={this.handleFNameChange}
                              placeholder="Vorname"
                            />
                            <Input
                              value={this.state.lName}
                              onChange={this.handleLNameChange}
                              placeholder="Nachname"
                            />
                          </div>
                          <SubTitle title="E-Mail" />
                          <Input
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            placeholder="E-Mail"
                          />
                          {this.state.branch?.settings?.gAddress && (
                            <>
                              <SubTitle title="Adresse" />
                              <div className="course-time-input-container">
                                <Input
                                  value={this.state.street}
                                  onChange={this.handleStreetChange}
                                  placeholder="Straße"
                                  className="street-input"
                                />
                                <Input
                                  value={this.state.houseNumber}
                                  onChange={this.handleHouseNumberChange}
                                  placeholder="Nr."
                                  className="house-number-input"
                                />
                              </div>
                              <div className="profile-location-container">
                                <Input
                                  value={this.state.zipcode}
                                  onChange={this.handleZipcodeChange}
                                  placeholder="PLZ"
                                  className="zipcode-input"
                                />
                                <Input
                                  value={this.state.city}
                                  onChange={this.handleCityChange}
                                  placeholder="Stadt"
                                  className="city-input"
                                />
                              </div>
                            </>
                          )}
                          {this.state.branch?.settings?.gPhoneNumber && (
                            <>
                              <SubTitle title="Telefonnummer" />
                              <Input
                                value={this.state.phoneNumber}
                                onChange={this.handlePhoneNumberChange}
                                placeholder="Telefonnummer"
                              />
                            </>
                          )}
                          {(this.state.branch?.settings?.gBirthDate ||
                            this.state.course.minAge !== null ||
                            this.state.course.maxAge !== null) && (
                            <>
                              <SubTitle title="Geburtsdatum" />
                              <input
                                type="date"
                                className="input-container"
                                value={this.state.birthDate}
                                onChange={this.handleBirthDateChange}
                              />
                            </>
                          )}

                          <div className="medium-space"></div>
                          <ViewForm
                            form={this.state.course.formData}
                            formInput={this.state.formInput}
                            onChange={(v: FormInput[]) =>
                              this.setState({ formInput: v })
                            }
                          />
                          <CourseTerms
                            single={
                              this.state.course?.courseType ===
                                "single-course" ||
                              this.state.course?.courseType === "block-course"
                            }
                            termsAccepted={this.state.termsAccepted}
                            toggleTermsAccepted={this.toggleTermsAccepted}
                            initialPrice={initialPrice}
                            price={this.state.course?.price}
                            startDate={startDate}
                            noticePeriod={this.state.course?.noticePeriod}
                            noticePeriodDateUnit={
                              this.state.course?.noticePeriodDateUnit
                            }
                            cancellationDays={
                              this.state.course?.cancellationDays
                            }
                            cancellationDate={cancellationDate}
                            showAcceptTermsMessage={
                              this.state.showAcceptTermsMessage
                            }
                          />
                          <BranchCourseTerms
                            agb={this.state.branch.agb}
                            showAcceptTermsMessage={
                              this.state.showAcceptTermsMessage
                            }
                            termsAccepted={this.state.branchTermsAccepted}
                            toggleTermsAccepted={this.toggleBranchTermsAccepted}
                          />

                          <ErrorMessage message={this.state.errorMsg} />
                          <BookButton
                            loading={this.state.bookCourseLoading}
                            onClick={this.bookCourse}
                            title={
                              this.state.course?.courseType ===
                                "single-course" ||
                              this.state.course?.courseType === "block-course"
                                ? bookableFrom
                                  ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                                  : "Angebot als Gast buchen"
                                : bookableFrom
                                ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                                : "Abonnement starten"
                            }
                            className={`course-book-btn${
                              bookableFrom ? " course-book-btn-gray" : ""
                            }`}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </>
    );
  }
}
