import React from "react";
import { RouteComponentProps, Route } from "react-router-dom";
import "./Club.css";
import TopMenuBar from "../components/TopMenuBar";
import ClubNavbar from "../components/navbars/ClubNavbar";
import Footer from "../components/Footer";
import { MainContext } from "../contexts/MainContext";
import Main from "./ClubSubScreen/Main";
import MyCourses from "./MyCourses";
import Course from "./ClubSubScreen/Course";
import SingleCourse from "./BranchSubScreens/SingleCourse";
import Profile from "./Profile";
import OnBoarding from "./ClubSubScreen/Onboarding";
import MembershipApplication from "./ClubSubScreen/MembershipApplication";
import AccountTransfer from "./AccountTransfer";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    site: string;
  }>;

export default class Club extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    showMobileNav: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      showMobileNav: false,
    };
  }

  componentDidMount = () => {
    this.context.requestClub(this.state.clubId);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleShowMobileNav = () => {
    this.setState({ showMobileNav: true });
  };

  handleHideMobileNav = () => {
    this.setState({ showMobileNav: false });
  };

  render() {
    return (
      <div className="br-main-container">
        <ClubNavbar
          selectedScreen={
            this.props.match.params.site ? this.props.match.params.site : "club"
          }
          showMobile={this.state.showMobileNav}
          hideMobileNav={this.handleHideMobileNav}
          clubId={this.state.clubId}
          isAdmin={
            this.context.club?.user?.role === "Owner" || this.context.isAdmin
          }
        />
        <TopMenuBar handleShowMobileNav={this.handleShowMobileNav} />
        <div
          className="br-inner-container"
          id="scroll-container"
          onScroll={this.context.onScroll}
        >
          <div className="br-padding-container">
            <Route exact path="/club/:clubId/mycourses" component={MyCourses} />
            <Route exact path="/club/:clubId/course" component={Course} />
            <Route
              exact
              path="/club/:clubId/branchCourse/:branchId/:courseId"
              component={SingleCourse}
            />
            <Route exact path="/club/:clubId/profile" component={Profile} />
            <Route
              exact
              path="/club/:clubId/onboarding/:onboardingId"
              component={OnBoarding}
            />
            <Route exact path="/club/:clubId" component={Main} />
            {(!this.context.user || this.context.selectedUserId) && (
              <Route
                exact
                path="/club/:clubId/Aufnahmeantrag"
                component={MembershipApplication}
              />
            )}
            <Route
              exact
              path="/club/:clubId/accountTransfer/:transferId/:clientKey"
              component={AccountTransfer}
            />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
