import "./MainBtn.css";
import React from "react";

export default class MainBtn extends React.Component<
  {
    onClick: any;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <button className="main-btn" onClick={this.props.onClick}>
        {this.props.children}
      </button>
    );
  }
}
