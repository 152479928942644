import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import "./Users.css";
import "../../App.css";
import { fetchRetry } from "../../functions/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import SearchInput from "../../components/SearchInput";
import Table from "../../components/Table/Table";
import { RowData } from "../../Types";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Users extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    userSearchVal: string;
    users: Array<any>;
    filteredUsers: Array<any>;
    usersLoaded: boolean;
    selectedUser: any;
    redirect: string | null;
    userTableArr: Array<RowData>;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      userSearchVal: "",
      users: [],
      filteredUsers: [],
      usersLoaded: false,
      selectedUser: {},
      redirect: null,
      userTableArr: [],
    };
  }

  componentDidMount = () => {
    this.requestUsers();
  };

  requestUsers = () => {
    fetchRetry("getUsersFromClub", { clubId: this.state.clubId }, 1, 5)
      .then(this.handleUsers)
      .catch(this.handleError);
  };

  handleUsers = ({ data }: any) => {
    this.setState({ usersLoaded: true });
    if (data.success) {
      const userData = data.data;
      const userArr = [];
      for (const userId in userData) {
        const user = userData[userId];
        user.id = userId;
        userArr.push(user);
      }
      userArr.sort((a: any, b: any) => (a.lName < b.lName ? 1 : -1));
      userArr.sort((a: any, b: any) => (a.fName < b.fName ? 1 : -1));
      userArr.sort((a: any, b: any) => (a.accepted > b.accepted ? 1 : -1));

      const userTableArr = userArr.map((user: any) => {
        return {
          cells: [
            {
              value: user.membershipNumber,
              sort: user.membershipNumber,
              search: user.membershipNumber,
            },
            {
              value: user.fName,
              sort: user.fName,
              search: `${user.fName} ${user.lName}`,
            },
            {
              value: user.lName,
              sort: user.lName,
              search: user.lName,
            },
            {
              value: user.email,
              sort: user.email,
              search: user.email,
            },
            {
              value: user.role === "Owner" ? "Admin" : "Mitglied",
              sort: user.role === "Owner" ? "Admin" : "Mitglied",
              search: user.role === "Owner" ? "Admin" : "Mitglied",
            },
          ],
          onClick: `/club-settings/${this.state.clubId}/users/${user.id}`,
        };
      });
      this.setState(
        {
          users: userArr,
          userTableArr,
        },
        this.filterUsers
      );
    }
  };

  filterUsers = () => {
    let filterText = this.state.userSearchVal;
    filterText = filterText.toLocaleLowerCase();
    const filteredUsers = this.state.users.filter((user: any) => {
      let fName = user.fName ? user.fName : "";
      fName = fName.toLowerCase();
      let lName = user.lName ? user.lName : "";
      lName = lName.toLowerCase();
      let email = user.email ? user.email : "";
      email = email.toLowerCase();
      let role = user.role ? user.role : "";
      role = role.toLowerCase();
      if (
        `${fName} ${lName}`.includes(filterText) ||
        `${lName} ${fName}`.includes(filterText) ||
        (email && email.includes(filterText)) ||
        (role && role.includes(filterText)) ||
        (user?.membershipNumber && user?.membershipNumber.includes(filterText))
      ) {
        return true;
      }
      return false;
    });
    this.setState({ filteredUsers: filteredUsers });
  };

  handleChangeClubSearchVal = (val: any) => {
    this.setState({ userSearchVal: val }, this.filterUsers);
  };

  handleUserClick = (userData: any) => {
    this.props.history.push({
      pathname: `/club-settings/${this.state.clubId}/users/${userData.id}`,
    });
  };

  handleRedirect = (redirect: string) => {
    this.props.history.push({
      pathname: redirect,
    });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <SearchInput
              name="course-search-input"
              placeholder="Nutzersuche"
              value={this.state.userSearchVal}
              onChange={this.handleChangeClubSearchVal}
            />
            <div className="inline-block">
              <Link
                className="create-btn"
                to={`/club-settings/${this.state.clubId}/inviteUsers`}
              >
                <div className="create-icon-container">
                  <FontAwesomeIcon icon={faPlusCircle} size="1x" />
                </div>
                Mitglied einladen
              </Link>
            </div>
            <Table
              header={[
                { title: "Mitgliedsnummer", displayWidth: 450 },
                { title: "Vorname" },
                { title: "Nachname" },
                { title: "E-Mail", displayWidth: 950 },
                { title: "Rechte", displayWidth: 600 },
              ]}
              data={this.state.userTableArr}
              loaded={this.state.usersLoaded}
              skeletonRows={10}
              onClick={this.handleRedirect}
              defaultSortDirection="asc"
              defaultSortIndex={2}
              searchValue={this.state.userSearchVal}
              counter
            />
          </div>
        </div>
      </>
    );
  }
}
