import React from "react";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import "./Login.css";
import "./ConfirmEmailEndpoint.css";
import { MainContext } from "../contexts/MainContext";
import { fetchRetry } from "../functions/request";
import HashLoader from "react-spinners/HashLoader";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    verificationId: string;
  }>;

export default class ConfirmEmailEndpoint extends React.Component<
  ComposedProps,
  {
    verificationId: string;
    verificationLoading: boolean;
    redirect: null | string;
    errorMsg: string;
  }
> {
  static contextType = MainContext;

  constructor(props: any) {
    super(props);
    this.state = {
      verificationId: this.props.match.params.verificationId,
      verificationLoading: false,
      redirect: null,
      errorMsg: "",
    };
  }

  componentDidMount() {
    this.requestEmailVerification();
  }

  requestEmailVerification = () => {
    this.setState({ verificationLoading: true });
    fetchRetry(
      "validateEmailConfirmationMail",
      {
        verificationId: this.state.verificationId,
      },
      1,
      5
    )
      .then(this.handleSuccessEmailVerification)
      .catch(this.handleError);
  };

  handleSuccessEmailVerification = ({ data }: any) => {
    this.setState({ verificationLoading: false });
    if (data.success) {
      this.context.requestClub(this.context.clubId);
      this.setState({ redirect: "/" });
    } else {
      if (data.code === "no-match") {
        this.setState({
          errorMsg:
            "Der Bestätigungslink ist nicht gültig. Bitte überprüfe deinen Posteingang auf eine neue Einladung.",
        });
      }
    }
  };

  handleError = (error: any) => {
    console.error(error);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.state.verificationLoading) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="full-center">
          <span className="not-exist-text">
            {this.state.errorMsg} <Link to="/">weiter</Link>
          </span>
        </div>
      </>
    );
  }
}
