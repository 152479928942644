import React from "react";
import {
  dateToFullStr,
  formatDateUnit,
  getMonthNameFromDate,
  priceToStr,
} from "../functions/utils";
import { MainContext } from "../contexts/MainContext";
import "./CourseTerms.css";

export default class CourseTerms extends React.Component<
  {
    single: boolean;
    termsAccepted: boolean;
    toggleTermsAccepted: any;
    initialPrice: number;
    price: number;
    startDate: any;
    noticePeriod: number;
    noticePeriodDateUnit: string;
    cancellationDays: number;
    cancellationDate: any;
    showAcceptTermsMessage: boolean;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.single ? (
          <div
            className={`accept-container ${
              this.props.showAcceptTermsMessage && !this.props.termsAccepted
                ? "accept-container-glowing"
                : ""
            }`}
          >
            <div>
              <input
                type="checkbox"
                className="accept-container-checkbox"
                checked={this.props.termsAccepted}
                onClick={this.props.toggleTermsAccepted}
              />
            </div>
            <div>
              {/* {this.props.price !== 0
                ? "Dieses Angebot kostenpflichtig buchen. "
                : ""} */}
              Dieses Angebot kostet {priceToStr(this.props.price)}€
              {this.props.cancellationDays !== null ? (
                <>
                  {" "}
                  und die Stornierungsfrist dieses Kurses ist{" "}
                  {dateToFullStr(this.props.cancellationDate)}.
                </>
              ) : (
                <> und kann nicht storniert werden.</>
              )}{" "}
              {this.context.club?.agb && (
                <>
                  Es gelten diese{" "}
                  <a
                    href={this.context.club?.agb}
                    target="_blank"
                    rel="noreferrer"
                  >
                    AGB
                  </a>
                  .
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`accept-container ${
              this.props.showAcceptTermsMessage && !this.props.termsAccepted
                ? "accept-container-glowing"
                : ""
            }`}
          >
            <div>
              <input
                type="checkbox"
                className="accept-container-checkbox"
                checked={this.props.termsAccepted}
                onClick={this.props.toggleTermsAccepted}
              />
            </div>
            <div>
              {this.props.initialPrice === this.props.price ? (
                <>
                  Der Kursstart deines Kurses ist{" "}
                  {getMonthNameFromDate(this.props.startDate)}. Es werden{" "}
                  {priceToStr(this.props.price)}€ pro Monat abgerechnet.
                  Preisänderungen bei Änderung deiner Rolle/Mitgliedschaft
                  vorbehalten.
                </>
              ) : (
                <>
                  Der Kursstart deines Kurses ist{" "}
                  {getMonthNameFromDate(this.props.startDate)}{" "}
                  {this.props.startDate.getFullYear()}, wir berechnen für{" "}
                  {getMonthNameFromDate(this.props.startDate)}{" "}
                  {priceToStr(this.props.initialPrice)}€. Fortlaufend werden{" "}
                  {priceToStr(this.props.price)}€ pro Monat abgerechnet.
                  Preisänderungen bei Änderung deiner Rolle/Mitgliedschaft
                  vorbehalten.
                </>
              )}{" "}
              Die Kündigungsfrist beträgt {this.props.noticePeriod}{" "}
              {formatDateUnit(
                this.props.noticePeriodDateUnit,
                this.props.noticePeriod
              )}
              .{" "}
              {this.context.club?.agb && (
                <>
                  Es gelten diese{" "}
                  <a
                    href={this.context.club?.agb}
                    target="_blank"
                    rel="noreferrer"
                  >
                    AGB
                  </a>
                  .
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
