import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./ConfirmationDeleteCoursesInRowModal.css";
import "./ConfirmationModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { dateToStr, firebaseDateToDateObj } from "../../functions/utils";
import InfoContainer from "../InfoContainer";
import { Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";

export default class ConfirmationDeleteCoursesInRowModal extends React.Component<
  {
    show: boolean;
    title: string;
    msg: string;
    handleClose: any;
    handleConfirm: any;
    loading: boolean;
    courseArr: Array<any>;
    clubId: string;
    branchId: string;
  },
  { redirect: null | string }
> {
  constructor(props: any) {
    super(props);
    this.state = { redirect: null };
  }

  handleRedirect = (courseId: string) => {
    this.setState({
      redirect: `/club/${this.props.clubId}/branch/${this.props.branchId}/participants/${courseId}`,
    });
  };

  render() {
    const countActiveBookings = this.props.courseArr.filter(
      (currCourse: any) => currCourse.activeBookings
    ).length;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="confirm"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="confirm-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">{this.props.title}</span>
            </div>
            <span>{this.props.msg}</span>
            {this.props.loading ? (
              <div className="modal-loading-container">
                <ClipLoader color={"#6D6D6D"} size={50} loading={true} />
              </div>
            ) : (
              <>
                <div className="course-delete-table">
                  <table className="user-table no-margin">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Datum</th>
                        <th>Titel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.courseArr.map(
                        (currCourse: any, index: number) => (
                          <tr
                            onClick={() => {
                              this.handleRedirect(currCourse.courseId);
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>
                              {currCourse.activeBookings
                                ? "Ohne Buchungen"
                                : "Mit aktiven Buchungen"}
                            </td>
                            <td>
                              {dateToStr(
                                firebaseDateToDateObj(
                                  currCourse?.courseData?.startTime
                                )
                              )}
                            </td>
                            <td>{currCourse?.courseData?.title}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="medium-space"></div>
                {countActiveBookings > 0 && (
                  <InfoContainer>
                    {countActiveBookings > 1
                      ? 'Einige Kurse aus dieser Reihe haben noch aktive Buchungen. Diese können in den Bereichseinstellungen unter "Zahlungskonflikte" storniert werden.'
                      : 'Ein Kurs aus dieser Reihe hat noch aktive Buchungen. Diese können in den Bereichseinstellungen unter "Zahlungskonflikte" storniert werden.'}
                  </InfoContainer>
                )}
              </>
            )}

            <div className="modal-btn-container">
              <button
                className={"modal-btn"}
                onClick={() => {
                  this.props.handleConfirm();
                }}
              >
                Ja
              </button>
              <button
                className={"modal-btn modal-btn-red"}
                onClick={() => {
                  this.props.handleClose();
                }}
              >
                Nein
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
