import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import "./ClubSettings.css";
import ClubSettingsNavbar from "../components/navbars/ClubSettingsNavbar";
import TopMenuBar from "../components/TopMenuBar";
import Appearance from "./ClubSettingSubScreens/Appearance";
import Users from "./ClubSettingSubScreens/Users";
import Branches from "./ClubSettingSubScreens/Branches";
import TextInputModal from "../components/modals/TextInputModal";
import Footer from "../components/Footer";
import { fetchRetry } from "../functions/request";
import Settings from "./ClubSettingSubScreens/Settings";
import SingleUser from "./ClubSettingSubScreens/SingleUser";
import { MainContext } from "../contexts/MainContext";
import InviteUsers from "./ClubSettingSubScreens/InviteUsers";
import Locations from "./ClubSettingSubScreens/Locations";
import { objectToArray } from "../functions/utils";
import Roles from "./ClubSettingSubScreens/Roles";
import MembershipApplication from "./ClubSettingSubScreens/MembershipApplication";
import MembershipApplicationSettings from "./ClubSettingSubScreens/MembershipApplicationSettings";
import SingleMembershipApplication from "./ClubSettingSubScreens/SingleMembershipApplication";
import Family from "./ClubSettingSubScreens/Family";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    site: string;
    branchId: string;
  }>;

export default class ClubSettings extends React.Component<
  ComposedProps,
  {
    clubId: string;
    club: any;
    clubLoaded: boolean;
    branchArr: Array<any>;
    showMobileNav: boolean;
    showCreateBranchModal: boolean;
    createBranchLoading: boolean;
    showCreateLocationModal: boolean;
    showCreateRoleModal: boolean;
    createLocationLoading: boolean;
    createRoleLoading: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      club: {},
      clubLoaded: false,
      branchArr: [],
      showMobileNav: false,
      showCreateBranchModal: false,
      createBranchLoading: false,
      showCreateLocationModal: false,
      showCreateRoleModal: false,
      createLocationLoading: false,
      createRoleLoading: false,
    };
  }

  componentDidMount = () => {
    this.requestClub();
  };

  requestClub = () => {
    this.context
      .requestClub(this.state.clubId)
      .then(this.handleClub)
      .catch(this.handleError);
  };

  handleClub = ({ data }: any) => {
    this.setState({ clubLoaded: true });
    if (data.success) {
      const club = data.data;
      const branchArr: any = [];
      for (const branchId in club.branches) {
        const branch = club.branches[branchId];
        branch.id = branchId;
        branchArr.push(branch);
      }
      branchArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );

      this.setState({
        club,
        branchArr,
      });
    }
  };

  handleCreateBranch = (name: string) => {
    this.setState({ createBranchLoading: true });
    fetchRetry(
      "createBranch",
      {
        clubId: this.state.clubId,
        name: name,
        type: "sport",
      },
      1,
      5
    )
      .then(this.handleSuccessBranchCreated)
      .catch(this.handleError);
  };

  handleSuccessBranchCreated = ({ data }: any) => {
    this.setState({ createBranchLoading: false });
    if (data.success) {
      this.setState(
        {
          showCreateBranchModal: false,
        },
        this.requestClub
      );
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleShowMobileNav = () => {
    this.setState({ showMobileNav: true });
  };

  handleHideMobileNav = () => {
    this.setState({ showMobileNav: false });
  };

  handleShowCreateBranchModal = () => {
    this.setState({ showCreateBranchModal: true });
  };

  handleShowCreateLocationModal = () => {
    this.setState({ showCreateLocationModal: true });
  };

  handleShowCreateRoleModal = () => {
    this.setState({ showCreateRoleModal: true });
  };

  handleHideCreateBranchModal = () => {
    this.setState({ showCreateBranchModal: false });
  };

  handleCreateLocation = (name: string) => {
    this.setState({ createLocationLoading: true });
    fetchRetry(
      "createClubLocation",
      {
        clubId: this.state.clubId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessLocationCreated)
      .catch(this.handleError);
  };

  handleSuccessLocationCreated = ({ data }: any) => {
    this.setState({ createLocationLoading: false });
    if (data.success) {
      this.setState(
        {
          showCreateLocationModal: false,
        },
        this.requestClub
      );
    }
  };

  handleCreateRole = (name: string) => {
    this.setState({ createRoleLoading: true });
    fetchRetry(
      "createClubRoleNew",
      {
        clubId: this.state.clubId,
        name: name,
      },
      1,
      5
    )
      .then(this.handleSuccessRoleCreated)
      .catch(this.handleError);
  };

  handleSuccessRoleCreated = ({ data }: any) => {
    this.setState({ createRoleLoading: false });
    if (data.success) {
      this.setState(
        {
          showCreateRoleModal: false,
        },
        this.requestClub
      );
    }
  };

  handleHideCreateLocationModal = () => {
    this.setState({ showCreateLocationModal: false });
  };

  handleHideCreateRoleModal = () => {
    this.setState({ showCreateRoleModal: false });
  };

  render() {
    const branchArr: Array<any> = [];
    for (const branchId in this.context.club.branches) {
      const branch = this.context.club.branches[branchId];
      branch.id = branchId;
      branchArr.push(branch);
    }
    branchArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    const locationArr: Array<any> = objectToArray(this.context.club.locations);
    const roleArr: Array<any> = objectToArray(this.context.club.roles);

    return (
      <>
        <div className="br-main-container">
          <TextInputModal
            show={this.state.showCreateBranchModal}
            handleClose={this.handleHideCreateBranchModal}
            handleConfirm={this.handleCreateBranch}
            loading={this.state.createBranchLoading}
            btnText="Bereich erstellen"
            textBoxPlaceholder="Bereichname"
            title="Erstellen"
          />
          <TextInputModal
            show={this.state.showCreateLocationModal}
            handleClose={this.handleHideCreateLocationModal}
            handleConfirm={this.handleCreateLocation}
            loading={this.state.createLocationLoading}
            btnText="Örtlichkeit erstellen"
            textBoxPlaceholder="Örtlichkeit"
            title="Erstellen"
          />
          <TextInputModal
            show={this.state.showCreateRoleModal}
            handleClose={this.handleHideCreateRoleModal}
            handleConfirm={this.handleCreateRole}
            loading={this.state.createRoleLoading}
            btnText="Rolle erstellen"
            textBoxPlaceholder="Rolle"
            title="Erstellen"
          />
          <ClubSettingsNavbar
            selectedScreen={this.props.match.params.site}
            clubId={this.state.clubId}
            branchArr={branchArr}
            locationArr={locationArr}
            roleArr={roleArr}
            showMobile={this.state.showMobileNav}
            selectedBranch={this.props.match.params.branchId}
            onCreateBranch={this.handleShowCreateBranchModal}
            hideMobileNav={this.handleHideMobileNav}
            selectedObject={this.props.match.params.branchId}
            onCreateLocation={this.handleShowCreateLocationModal}
            onCreateRole={this.handleShowCreateRoleModal}
            useMembershipApplication={
              this.context.club?.settings?.useMembershipApplication
            }
          />
          <TopMenuBar handleShowMobileNav={this.handleShowMobileNav} />
          <div className="br-inner-container" onScroll={this.context.onScroll}>
            <div className="br-padding-container">
              <Route
                exact
                path="/club-settings/:clubId/appearance"
                component={Appearance}
              />
              <Route
                exact
                path="/club-settings/:clubId/family/:familyId"
                component={Family}
              />
              <Route
                exact
                path="/club-settings/:clubId/users"
                component={Users}
              />
              <Route
                exact
                path="/club-settings/:clubId/inviteUsers"
                component={InviteUsers}
              />
              <Route
                exact
                path="/club-settings/:clubId/users/:userId"
                component={SingleUser}
              />
              <Route
                exact
                path="/club-settings/:clubId/settings"
                component={Settings}
              />
              <Route
                exact
                path="/club-settings/:clubId/membership-applications/:applicationId"
                component={SingleMembershipApplication}
              />
              <Route
                exact
                path="/club-settings/:clubId/membership-applications"
                component={MembershipApplication}
              />
              <Route
                exact
                path="/club-settings/:clubId/membership-application-settings"
                component={MembershipApplicationSettings}
              />
              <Route
                exact
                path="/club-settings/:clubId/roles/:roleId"
                render={(props) => (
                  <Roles {...props} refreshNavbar={this.requestClub} />
                )}
              />
              <Route
                exact
                path="/club-settings/:clubId/locations/:locationId"
                render={(props) => (
                  <Locations {...props} refreshNavbar={this.requestClub} />
                )}
              />

              <Route
                path="/club-settings/:clubId/branches/:branchId"
                render={(props) => (
                  <Branches {...props} refreshNavbar={this.requestClub} />
                )}
              />
              {/* <Route
                exact
                path="/club-settings/:clubId/branches/"
                render={(props) => (
                  <Branches {...props} refreshNavbar={this.requestClub} />
                )}
              /> */}
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
