import React from "react";
import Switch from "./Switch";
import "./SwitchSetting.css";

export default class SwitchSetting extends React.Component<
  { value: boolean; onChange: any; name: string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="switch-setting">
        <div className="switch-container">
          <Switch value={this.props.value} onChange={this.props.onChange} />
        </div>
        <span>{this.props.name}</span>
      </div>
    );
  }
}
