import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./Course.css";
import "./ManageCourses.css";
import "../../App.css";
import SingleCourseElementManage from "../../components/SingleCourseElementManage";
import InfinityCourseElementManage from "../../components/InfinityCourseElementManage";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  objectToArray,
  priceToStr,
} from "../../functions/utils";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestMode from "../../components/TestMode";
import Title from "../../components/Title";
import BlockCourseElementManage from "../../components/BlockCourseElementManage";
import SearchInput from "../../components/SearchInput";
import TextCheckbox from "../../components/TextCheckbox";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Tag from "../../components/Tag";
import SubscriptionElementManage from "../../components/SubscriptionElementManage";
import InfiniteScroll from "react-infinite-scroll-component";
import CourseElementSkeleton from "../../components/CourseElementSkeleton";
import debounce from "lodash.debounce";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class ManageCourses extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branchLoaded: boolean;
    elementsLoaded: boolean;
    branch: any;
    elements: Array<any>;
    courseSearch: string;
    showDeleted: boolean;
    showPast: boolean;
    selectedTag: string;
    page: number;
    hasMore: boolean;
    batchSize: number;
    initialLoad: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branchLoaded: false,
      elementsLoaded: false,
      branch: {},
      elements: [],
      courseSearch: "",
      showDeleted: false,
      showPast: false,
      selectedTag: "",
      page: 0,
      hasMore: true,
      batchSize: 10,
      initialLoad: false,
    };
  }

  componentDidMount = async () => {
    await Promise.all([this.requestBranch(), this.requestElements()]);
  };

  requestElements = async () => {
    await fetchRetry(
      "getElementsOfBranchAlgoliaF",
      {
        targetUserId: this.context.selectedUserId,
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        query: this.state.courseSearch,
        page: this.state.page,
        hitsPerPage: this.state.batchSize,
        showDeleted: this.state.showDeleted,
        showPast: this.state.showPast,
        tag:
          this.state.selectedTag !== "subscriptions"
            ? this.state.selectedTag
            : "",
        onlySubscriptions: this.state.selectedTag === "subscriptions",
      },
      1,
      5
    )
      .then(this.handleElementsSuccess)
      .catch(this.handleError);
  };

  handleElementsSuccess = ({ data }: any) => {
    this.setState({ elementsLoaded: true });
    if (data.success) {
      const elements = this.state.elements.concat(data.data);
      this.setState(
        {
          elements,
          hasMore: data.data.length >= this.state.batchSize,
        },
        () => {
          if (!this.state.initialLoad) {
            const highlighedCourseId = window.location.href.slice(
              window.location.href.indexOf("#") + 1
            );
            var element = document.getElementById(highlighedCourseId);
            element?.scrollIntoView();
            this.setState({ initialLoad: true });
          }
        }
      );
    }
  };

  getNextPage = () => {
    this.setState(
      { page: this.state.page + 1, elementsLoaded: false },
      this.requestElements
    );
  };

  requestBranch = async () => {
    await fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch: branch,
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleChangeCourseSearch = (val: string) => {
    this.setState({ courseSearch: val }, this.debounceRequest);
  };

  debounceRequest = debounce(() => {
    this.newRequest();
  }, 500);

  newRequest = () => {
    this.setState(
      { elements: [], page: 0, elementsLoaded: false, hasMore: true },
      this.requestElements
    );
  };

  toggleShowDeleted = () => {
    this.setState({ showDeleted: !this.state.showDeleted }, this.newRequest);
  };

  toggleShowPast = () => {
    this.setState({ showPast: !this.state.showPast }, this.newRequest);
  };

  handleTagClicked = (tagId: string) => {
    if (tagId === this.state.selectedTag) {
      this.setState({ selectedTag: "" }, this.newRequest);
    } else {
      this.setState({ selectedTag: tagId }, this.newRequest);
    }
  };

  render() {
    const tags = this.state.branch?.tags ? this.state.branch.tags : {};
    const tagArr = objectToArray(tags);
    tagArr.sort((a: any, b: any) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );

    const testMode = this.state.branch?.billing?.mode === "test";
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Kurse" />
            <TestMode active={testMode} />
            {(this.state.branch?.user?.role === "Owner" ||
              this.context.isAdmin) && (
              <>
                <div className="inline-block">
                  <Link
                    className="create-btn"
                    to={`/club/${this.state.clubId}/branch/${this.state.branchId}/createCourse`}
                  >
                    <div className="create-icon-container">
                      <FontAwesomeIcon icon={faPlusCircle} size="1x" />
                    </div>
                    Kurs erstellen
                  </Link>
                </div>
                {this.state.branch?.settings?.useSubscriptions && (
                  <div className="inline-block">
                    <Link
                      className="create-btn"
                      to={`/club/${this.state.clubId}/branch/${this.state.branchId}/createSubscription`}
                    >
                      <div className="create-icon-container">
                        <FontAwesomeIcon icon={faPlusCircle} size="1x" />
                      </div>
                      Mitgliedschaft erstellen
                    </Link>
                  </div>
                )}
              </>
            )}
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            <div className="tag-rating-container">
              {this.state.branch?.settings?.useSubscriptions && (
                <Tag
                  item={"Mitgliedschaften"}
                  selected={this.state.selectedTag === "subscriptions"}
                  color={"#c31924"}
                  onClick={() => {
                    this.handleTagClicked("subscriptions");
                  }}
                />
              )}
              {tagArr.map((currTag: any) => (
                <>
                  <Tag
                    item={currTag.name}
                    selected={currTag.id === this.state.selectedTag}
                    color={"#5DADE2"}
                    onClick={() => {
                      this.handleTagClicked(currTag.id);
                    }}
                  />
                </>
              ))}
            </div>
            <div>
              <TextCheckbox
                text="gelöschte Kurse anzeigen"
                val={this.state.showDeleted}
                onChange={this.toggleShowDeleted}
              />
              <TextCheckbox
                text="vergangene Kurse anzeigen"
                val={this.state.showPast}
                onChange={this.toggleShowPast}
              />
            </div>

            <>
              <InfiniteScroll
                dataLength={this.state.elements.length}
                next={this.getNextPage}
                hasMore={this.state.hasMore}
                loader={<></>}
                scrollableTarget="scroll-container"
              >
                {this.state.elements.map((element: any) => (
                  <>
                    {element.courseType === "single-course" && (
                      <SingleCourseElementManage
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={element.id}
                        title={element.title}
                        description={element.description}
                        startTime={element.startTime}
                        endTime={element.endTime}
                        where={element.location.name}
                        freePlaces={
                          testMode ? element.freePlacesTest : element.freePlaces
                        }
                        price={priceToStr(element.price)}
                        imgUrl={element.imgLowRes}
                        editable={
                          this.state.branch?.user?.role === "Owner" ||
                          this.context.isAdmin
                        }
                        viewable={this.state.branch?.user?.role === "ViewOnly"}
                        deleted={element.deleted}
                        key={element.id}
                      />
                    )}
                    {element.courseType === "block-course" && (
                      <BlockCourseElementManage
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={element.id}
                        title={element.title}
                        description={element.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(element.startTime)
                        )}
                        courses={element.courses}
                        freePlaces={
                          testMode ? element.freePlacesTest : element.freePlaces
                        }
                        price={priceToStr(element.price)}
                        imgUrl={element.imgLowRes}
                        editable={
                          this.state.branch?.user?.role === "Owner" ||
                          this.context.isAdmin
                        }
                        viewable={this.state.branch?.user?.role === "ViewOnly"}
                        deleted={element.deleted}
                        key={element.id}
                      />
                    )}
                    {element.courseType === "infinity-course" && (
                      <InfinityCourseElementManage
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={element.id}
                        title={element.title}
                        description={element.description}
                        where={element.location?.name}
                        time={element.time}
                        duration={element.duration}
                        weekDay={element.weekDay}
                        freePlaces={
                          testMode ? element.freePlacesTest : element.freePlaces
                        }
                        price={priceToStr(element.price)}
                        imgUrl={element.imgLowRes}
                        editable={
                          this.state.branch?.user?.role === "Owner" ||
                          this.context.isAdmin
                        }
                        viewable={this.state.branch?.user?.role === "ViewOnly"}
                        deleted={element.deleted}
                        key={element.id}
                      />
                    )}
                    {element.courseType === "subscription" && (
                      <SubscriptionElementManage
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        subscriptionId={element.id}
                        title={element.title}
                        description={element.description}
                        price={priceToStr(element.price)}
                        imgUrl={element.imgLowRes}
                        editable={
                          this.state.branch?.user?.role === "Owner" ||
                          this.context.isAdmin
                        }
                        viewable={this.state.branch?.user?.role === "ViewOnly"}
                        deleted={element.deleted}
                        key={element.id}
                      />
                    )}
                  </>
                ))}
              </InfiniteScroll>
              {!this.state.elementsLoaded && (
                <>
                  <CourseElementSkeleton />
                  <CourseElementSkeleton />
                  <CourseElementSkeleton />
                  <CourseElementSkeleton />
                </>
              )}
              {this.state.elementsLoaded && this.state.hasMore && (
                <div className="load-more-container" onClick={this.getNextPage}>
                  Mehr laden
                </div>
              )}
            </>
          </div>
        </div>
      </>
    );
  }
}
