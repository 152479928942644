import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { monthToDate } from "../functions/utils";
import "./MonthSelector.css";

export default class MonthSelector extends React.Component<
  {
    minMonthNum: number;
    maxMonthNum: number;
    value: string | number;
    onSelect: any;
  },
  {
    options: Array<any>;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { options: [] };
  }

  componentDidMount = () => {
    const options = this.state.options;
    for (let i = this.props.maxMonthNum; i >= this.props.minMonthNum; i--) {
      options.push({ id: i, name: monthToDate(i) });
    }
    this.setState({ options });
  };

  onSelect = (e: any) => {
    this.props.onSelect(this.state.options[e.target.options.selectedIndex].id);
  };

  render() {
    return (
      <div>
        <FontAwesomeIcon icon={faCalendarAlt} />
        <select
          className="month-selector"
          value={this.props.value}
          onChange={this.onSelect}
        >
          {this.state.options.map((option: any) => (
            <option value={option.id} key={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
