import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Booking.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  addADay,
  dateToStr,
  getDayOfWeek,
  minTimeToStr,
  startOfDay,
  strTimeToMin,
} from "../../functions/utils";
import TableBookingComponent from "../../components/TableBookingComponent";
import BookModal from "../../components/modals/BookModal";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchRetry } from "../../functions/request";
import Title from "../../components/Title";
import { faArrowCircleLeft, faArrowCircleRight, faCalendar, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
  }>;

export default class Booking extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    branchLoaded: boolean;
    branch: any;
    objectArr: Array<any>;
    minStartTimeInMin: number;
    maxEndTimeInMin: number;
    selectedDay: Date;
    bookings: any;
    bookingsLoaded: boolean;
    tableObjects: any;
    showObjectsNum: number;
    showBookModal: boolean;
    selectedTimeInMin: number;
    selectedObject: any;
    objectOffset: number;
    timePerc: number;
  }
> {
  private interval: any;

  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      branchLoaded: false,
      branch: {},
      objectArr: [],
      minStartTimeInMin: 0,
      maxEndTimeInMin: 1440,
      selectedDay: new Date(),
      bookings: {},
      bookingsLoaded: false,
      tableObjects: {},
      showObjectsNum: 7,
      showBookModal: false,
      selectedTimeInMin: 0,
      selectedObject: {
        id: "",
        settings: {
          bookingInterval: 30,
          startTime: "00:00",
          stopTime: "00:00",
        },
      },
      objectOffset: 0,
      timePerc: 0,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestBookings();
    this.handleResize();
    this.interval = setInterval(() => this.updateTimeContainer(), 60000);
    window.addEventListener(
      "resize",
      _.debounce((q: any) => this.handleResize(), 50)
    );
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  handleResize = () => {
    const width = window.innerWidth;
    let showObjects = Math.floor((width - (width > 840 ? 224 : 0)) / 300);
    const objectCount = this.state.branch.objects
      ? Object.keys(this.state.branch.objects).length
      : -1;
    if (objectCount < showObjects && objectCount !== -1) {
      showObjects = objectCount;
    }
    if (showObjects < 1) {
      showObjects = 1;
    }
    if (showObjects !== this.state.showObjectsNum) {
      this.setState({ showObjectsNum: showObjects });
    }
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  requestBookings = () => {
    this.setState({ bookingsLoaded: false });

    const startDayStr = dateToStr(addADay(this.state.selectedDay, -1));
    const endDayStr = dateToStr(addADay(this.state.selectedDay, 1));
    fetchRetry(
      "getBookingsFromBranch",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        startDate: startDayStr,
        endDate: endDayStr,
      },
      1,
      5
    )
      .then(this.handleBookings)
      .catch(this.handleError);
  };

  handleBookings = ({ data }: any) => {
    const bookings = this.state.bookings;
    this.setState({ bookingsLoaded: true });
    if (data.success) {
      const bookingData = data.data;
      for (const currDateStr of Object.keys(bookingData)) {
        const dayBookings: Array<any> = bookingData[currDateStr];
        bookings[currDateStr] = { objects: {} };
        for (const currBooking of dayBookings) {
          currBooking.startTimeInMin = strTimeToMin(
            currBooking.startTimeString
          );
          currBooking.endTimeInMin = strTimeToMin(currBooking.endTimeString);
          if (!bookings[currDateStr].objects[currBooking.object.id]) {
            bookings[currDateStr].objects[currBooking.object.id] = [];
          }
          bookings[currDateStr].objects[currBooking.object.id].push(
            currBooking
          );
        }
      }
    }
    this.setState({ bookings }, this.formatTableData);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      const objectArr: any = [];
      for (const objectId in branch.objects) {
        const object = branch.objects[objectId];
        object.id = objectId;
        objectArr.push(object);
      }
      objectArr.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      this.setState(
        {
          branch: branch,
          objectArr,
        },
        () => {
          this.getBookingTableData();
          this.formatTableData();
          this.handleResize();
        }
      );
    }
  };

  getBookingTableData = () => {
    let minStartTimeInMin = 1400;
    let maxEndTimeInMin = 0;
    const objects = this.state.objectArr;
    for (const object of objects) {
      const currStartTimeInMin = strTimeToMin(object.settings.startTime);
      const currEndTimeInMin = strTimeToMin(object.settings.stopTime);
      if (currStartTimeInMin < minStartTimeInMin) {
        minStartTimeInMin = currStartTimeInMin;
      }
      if (currEndTimeInMin > maxEndTimeInMin) {
        maxEndTimeInMin = currEndTimeInMin;
      }
    }
    if (minStartTimeInMin < maxEndTimeInMin) {
      this.setState({ minStartTimeInMin, maxEndTimeInMin }, () => {
        this.formatTableData();
        this.updateTimeContainer();
      });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  getObjectId = (index: any) => {
    return (
      "" +
      this.state.branch.objects[Object.keys(this.state.branch.objects)[index]]
        .id
    );
  };

  addSlots = (
    currTableObjects: any,
    objectId: string,
    currEndTimeInMin: number,
    currTime: number,
    bookingInterval: number
  ) => {
    const length = currEndTimeInMin - currTime;
    const slotCount = Math.floor(length / bookingInterval);
    for (var i = 0; i < slotCount; i++) {
      currTableObjects[objectId].push({
        length: bookingInterval,
        startTimeInMin: currTime + i * bookingInterval,
        disabled: false,
        bookable: true,
        booked: false,
      });
    }
    if (slotCount * bookingInterval < length) {
      currTableObjects[objectId].push({
        length: length - slotCount * bookingInterval,
        startTimeInMin: currTime + slotCount * bookingInterval,
        disabled: false,
        bookable: true,
        booked: false,
      });
    }
    return currTableObjects;
  };

  formatTableData = () => {
    if (this.state.branchLoaded) {
      const selectedDateStr = dateToStr(this.state.selectedDay);
      let currTableObjects: any = {};
      for (const objectId in this.state.branch.objects) {
        let currTime = 0;
        const currObject = this.state.branch.objects[objectId];
        const currStartTimeInMin = strTimeToMin(currObject.settings.startTime);
        const currEndTimeInMin = strTimeToMin(currObject.settings.stopTime);
        currTableObjects[objectId] = [];
        // check if the startTime is higher than the global startTime
        if (this.state.minStartTimeInMin < currStartTimeInMin) {
          // add a disable container
          currTableObjects[objectId].push({
            length: currStartTimeInMin - this.state.minStartTimeInMin,
            startTimeInMin: this.state.minStartTimeInMin,
            disabled: true,
            bookable: false,
            booked: false,
          });
        }
        currTime = currStartTimeInMin;
        const currBookings =
          this.state.bookings[selectedDateStr]?.objects[objectId];
        if (currBookings) {
          currBookings.sort((a: any, b: any) =>
            a.startTimeInMin > b.startTimeInMin ? 1 : -1
          );
          for (let booking of currBookings) {
            if (currTime < booking.startTimeInMin) {
              currTableObjects = this.addSlots(
                currTableObjects,
                objectId,
                booking.startTimeInMin,
                currTime,
                currObject.settings.bookingInterval
              );
              currTime = booking.startTimeInMin;
            }
            currTableObjects[objectId].push({
              length: booking.endTimeInMin - currTime,
              startTimeInMin: currTime,
              disabled: false,
              bookable: false,
              booked: true,
              title: booking.title,
              color: booking.bookingType.color,
            });
            currTime = booking.endTimeInMin;
          }
        }
        if (currTime < currEndTimeInMin) {
          currTableObjects = this.addSlots(
            currTableObjects,
            objectId,
            currEndTimeInMin,
            currTime,
            currObject.settings.bookingInterval
          );
          currTime = currEndTimeInMin;
        }
        if (currTime < this.state.maxEndTimeInMin) {
          currTableObjects[objectId].push({
            length: this.state.maxEndTimeInMin - currTime,
            startTimeInMin: currTime,
            disabled: true,
            bookable: false,
            booked: false,
          });
          currTime = this.state.maxEndTimeInMin;
        }
      }
      this.setState({ tableObjects: currTableObjects });
    }
  };

  handleSlotClicked = (
    timeInMin: number,
    objectId: string,
    bookable: boolean,
    booked: boolean,
    disabled: boolean
  ) => {
    if (!disabled) {
      if (booked) {
        // show booking info
      } else {
        if (bookable) {
          // show reservation popup
          const object = this.state.branch.objects[objectId];
          object.id = objectId;
          this.setState({
            showBookModal: true,
            selectedObject: object,
            selectedTimeInMin: timeInMin,
          });
        }
      }
    }
  };

  handleCloseBookModal = () => {
    this.setState({ showBookModal: false });
  };

  nextDay = () => {
    const currDay = this.state.selectedDay;
    currDay.setDate(currDay.getDate() + 1);
    this.setState({ selectedDay: currDay }, () => {
      this.requestBookings();
      this.formatTableData();
      this.updateTimeContainer();
    });
  };

  previousDay = () => {
    const currDay = this.state.selectedDay;
    currDay.setDate(currDay.getDate() - 1);
    this.setState({ selectedDay: currDay }, () => {
      this.requestBookings();
      this.formatTableData();
      this.updateTimeContainer();
    });
  };

  nextObjects = () => {
    let offset = this.state.objectOffset + this.state.showObjectsNum;
    if (offset + this.state.showObjectsNum > this.state.objectArr.length) {
      offset = this.state.objectArr.length - this.state.showObjectsNum;
    }
    this.setState({
      objectOffset: offset,
    });
  };

  previousObjects = () => {
    let offset = this.state.objectOffset - this.state.showObjectsNum;
    if (offset < 0) {
      offset = 0;
    }
    this.setState({
      objectOffset: offset,
    });
  };

  updateTimeContainer = () => {
    const minStartTime = this.state.minStartTimeInMin;
    const maxEndTime = this.state.maxEndTimeInMin;
    const timeDiff = maxEndTime - minStartTime;
    const now = new Date();
    let perc = 0;
    if (
      startOfDay(now).getTime() === startOfDay(this.state.selectedDay).getTime()
    ) {
      const nowTimeInMin = (now.getTime() - startOfDay(now).getTime()) / 60000;
      const relStartTime = nowTimeInMin - minStartTime;
      perc = (relStartTime / timeDiff) * 100;
    } else if (now.getTime() > startOfDay(this.state.selectedDay).getTime()) {
      perc = 100;
    } else if (
      now.getTime() < addADay(startOfDay(this.state.selectedDay), 1).getTime()
    ) {
      perc = 0;
    }
    this.setState({ timePerc: perc });
  };

  render() {
    if (!this.state.branchLoaded) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    const timeCount = Math.ceil(
      (this.state.maxEndTimeInMin - this.state.minStartTimeInMin) / 60
    );
    return (
      <>
        <BookModal
          show={this.state.showBookModal}
          handleClose={this.handleCloseBookModal}
          clubId={this.state.clubId}
          branchId={this.state.branchId}
          selectedObject={this.state.selectedObject}
          selectedTimeInMin={this.state.selectedTimeInMin}
          date={this.state.selectedDay}
          refreshBookings={this.requestBookings}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Buchungssystem" />
            <div className="booking-table">
              <div className="booking-table-head">
                <div className="booking-date-container">
                  <div
                    className="booking-date-icon-container"
                    onClick={this.previousDay}
                  >
                    <FontAwesomeIcon icon={faArrowCircleLeft} />
                  </div>
                  <div className="booking-date-inner-container">
                    <div className="booking-date-text-container">
                      {`${getDayOfWeek(this.state.selectedDay)}, ${dateToStr(
                        this.state.selectedDay
                      )}`}
                    </div>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  <div
                    className="booking-date-icon-container"
                    onClick={this.nextDay}
                  >
                    <FontAwesomeIcon icon={faArrowCircleRight} />
                  </div>
                </div>
                <div className="table-title-main-container">
                  <div className="table-time-container">
                    <div className="table-title-container table-title-time-container">
                      Zeit
                    </div>
                  </div>
                  {[...Array(this.state.showObjectsNum)].map((x, i) => (
                    <div className="table-object-container" key={i}>
                      <div className="table-title-container">
                        <div
                          className="arrow-container"
                          onClick={this.previousObjects}
                        >
                          {i === 0 && this.state.objectOffset > 0 && (
                            <FontAwesomeIcon icon={faChevronLeft} />
                          )}
                        </div>
                        {this.state.branch.objects[
                          this.state.objectArr[i + this.state.objectOffset]?.id
                        ]?.name
                          ? this.state.branch.objects[
                              this.state.objectArr[i + this.state.objectOffset]
                                .id
                            ].name
                          : "-"}
                        <div
                          className="arrow-container"
                          onClick={this.nextObjects}
                        >
                          {i === this.state.showObjectsNum - 1 &&
                            !(
                              i + this.state.objectOffset ===
                              this.state.objectArr.length - 1
                            ) && (
                              <FontAwesomeIcon
                                icon={faChevronRight}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="table-time-container table-secondTimeContainer">
                    <div className="table-title-container table-title-time-container table-secondTimeContainer">
                      Zeit
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-column-container">
                <div
                  className="table-absolute-container"
                  style={{ height: `${this.state.timePerc}%` }}
                ></div>
                <div className="table-time-container">
                  {[...Array(timeCount)].map((x, i) => (
                    <div className="table-time-inner-container" key={i}>
                      {minTimeToStr(this.state.minStartTimeInMin + 60 * i)}
                    </div>
                  ))}
                </div>
                {[...Array(this.state.showObjectsNum)].map((x, i) => (
                  <div
                    className="table-object-container table-object-main-container"
                    key={i}
                  >
                    {this.state.tableObjects[
                      this.state.objectArr[i + this.state.objectOffset]?.id
                    ] &&
                      this.state.tableObjects[
                        this.state.objectArr[i + this.state.objectOffset].id
                      ].map((object: any, index: number) => (
                        <TableBookingComponent
                          title={object.title}
                          length={object.length}
                          totalLength={
                            this.state.maxEndTimeInMin -
                            this.state.minStartTimeInMin
                          }
                          bookable={object.bookable}
                          disabled={object.disabled}
                          booked={object.booked}
                          color={object.color}
                          onClick={() => {
                            this.handleSlotClicked(
                              object.startTimeInMin,
                              this.state.objectArr[i]?.id,
                              object.bookable,
                              object.booked,
                              object.disabled
                            );
                          }}
                          key={index}
                        />
                      ))}
                  </div>
                ))}
                <div className="table-time-container table-time-container-last table-secondTimeContainer">
                  {[...Array(timeCount)].map((x, i) => (
                    <div className="table-time-inner-container" key={i}>
                      {minTimeToStr(this.state.minStartTimeInMin + 60 * i)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
