import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import "./Impressum.css";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Impressum extends React.Component<
  ComposedProps,
  { clubId: string }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
    };
  }

  componentDidMount = () => {
    this.context.requestClub(this.state.clubId);
  };

  render() {
    return (
      <>
        <div className="main-info-screen">
          <h1>Impressum Plattformbetreiber</h1>
          <h2>Angaben gemäß § 5 TMG</h2>
          Weimer und Teller – Web Applikationen GbR
          <br />
          Lenzhalde 1<br />
          71332 Waiblingen
          <br />
          Vertreten durch die Gesellschafter:
          <br />
          Moritz Weimer
          <br />
          Tim Teller
          <br />
          <h2>Kontakt</h2>
          Telefon: 0178 5302489 E-Mail: contact@network-sports.de
          {this.context.clubId && this.context.club.impressum && (
            <>
              <br />
              <br />
              <h1>Impressum Verein</h1>
              <span className="pre-line">{this.context.club.impressum}</span>
            </>
          )}
        </div>
      </>
    );
  }
}
