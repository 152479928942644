import React from "react";
import "./VertNavbar.css";
import "./BranchSettingsNavbar.css";
import { Link } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import BranchSettingsNavbarElement from "./elements/BranchSettingsNavbarElement";
import BranchSelectorNavbarElement from "./elements/BranchSelectorNavbarElement";
import LogoNavbarElement from "./elements/LogoNavbarElement";
import UserNavbarElement from "./elements/UserNavbarElement";
import {
  faAlignLeft,
  faBoxOpen,
  faCalendarAlt,
  faCalendarPlus,
  faCog,
  faCreditCard,
  faCube,
  faEuroSign,
  faListUl,
  faMoneyCheck,
  faTags,
  faUsers,
  faUserTag,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";

export default class BranchSettingsNavbar extends React.Component<
  {
    selectedScreen: string;
    clubId: string;
    branchId: string;
    objectArr: Array<any>;
    roleArr: Array<any>;
    locationArr: Array<any>;
    tagArr: Array<any>;
    bookingTypeArr: Array<any>;
    pricingProfileArr: Array<any>;
    formArr: Array<any>;
    showMobile?: boolean;
    hideMobileNav: any;
    selectedObject: string;
    selectedTag: string;
    onCreateBranch: any;
    onCreateRole: any;
    onCreateBookingType: any;
    onCreatePricingProfile: any;
    onCreateLocation: any;
    onCreateForm: any;
    onCreateTag: any;
    useBooking: boolean;
    useCourse: boolean;
    useBilling: boolean;
    useForm: boolean;
    paymentConflicts: number;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={`nav-background ${
            this.props.showMobile && " show-nav-background"
          }`}
          onClick={this.props.hideMobileNav}
        ></div>
        <nav
          className={`vert-navbar br-navbar ${
            this.props.showMobile && " vert-navbar-mobile"
          }`}
        >
          <LogoNavbarElement />
          <div className="vert-navbar-scrollable">
            <UserNavbarElement
              to={`/club/${this.props.clubId}/branch/${this.props.branchId}/profile`}
            />
            <BranchSettingsNavbarElement
              title="Benutzer"
              icon={faUsers}
              selected={this.props.selectedScreen === "users"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/users`}
            />
            <BranchSettingsNavbarElement
              title="Rollen"
              icon={faUserTag}
              selected={this.props.selectedScreen === "roles"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${
                this.props.branchId
              }/roles${
                this.props.roleArr.length > 0
                  ? `/${this.props.roleArr[0].id}`
                  : ""
              }`}
            />
            <div
              className={`navbar-objects ${
                this.props.selectedScreen === "roles" &&
                "navbar-objects-selected"
              }`}
            >
              {this.props.roleArr.map((role: any) => (
                <BranchSelectorNavbarElement
                  title={role.name}
                  selected={this.props.selectedObject === role.id}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/roles/${role.id}`}
                  key={role.id}
                />
              ))}
              <div
                className={"navbar-element navbar-create-element"}
                onClick={this.props.onCreateRole}
              >
                <span>+</span>
              </div>
            </div>
            {this.props.useCourse && (
              <>
                <BranchSettingsNavbarElement
                  title="Kurs"
                  icon={faCalendarPlus}
                  selected={this.props.selectedScreen === "course"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/course`}
                />
                <BranchSettingsNavbarElement
                  title="Zahlungskonflikte"
                  icon={faMoneyCheck}
                  selected={this.props.selectedScreen === "payment-conflicts"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/payment-conflicts`}
                  notifications={this.props.paymentConflicts}
                />
                <BranchSettingsNavbarElement
                  title="Örtlichkeiten"
                  icon={faWarehouse}
                  selected={this.props.selectedScreen === "locations"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/locations${
                    this.props.locationArr.length > 0
                      ? `/${this.props.locationArr[0].id}`
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "locations" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.locationArr.map((location: any) => (
                    <BranchSelectorNavbarElement
                      title={location.name}
                      selected={this.props.selectedObject === location.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/locations/${location.id}`}
                      key={location.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateLocation}
                  >
                    <span>+</span>
                  </div>
                </div>
                <BranchSettingsNavbarElement
                  title="Tags"
                  icon={faTags}
                  selected={this.props.selectedScreen === "tags"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/tags${
                    this.props.tagArr.length > 0
                      ? `/${this.props.tagArr[0].id}`
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "tags" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.tagArr.map((tag: any) => (
                    <BranchSelectorNavbarElement
                      title={tag.name}
                      selected={this.props.selectedTag === tag.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/tags/${tag.id}`}
                      key={tag.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateTag}
                  >
                    <span>+</span>
                  </div>
                </div>
              </>
            )}

            {this.props.useForm && (
              <>
                <BranchSettingsNavbarElement
                  title="Formulare"
                  icon={faWpforms}
                  selected={this.props.selectedScreen === "forms"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/forms${
                    this.props.formArr.length > 0
                      ? `/${this.props.formArr[0].id}`
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "forms" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.formArr.map((form: any) => (
                    <BranchSelectorNavbarElement
                      title={form.name}
                      selected={this.props.selectedObject === form.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/forms/${form.id}`}
                      key={form.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateForm}
                  >
                    <span>+</span>
                  </div>
                </div>
              </>
            )}

            {this.props.useBooking && (
              <>
                <BranchSettingsNavbarElement
                  title="Buchungsarten"
                  icon={faListUl}
                  selected={this.props.selectedScreen === "booking-type"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/booking-type${
                    this.props.bookingTypeArr.length > 0
                      ? `/${this.props.bookingTypeArr[0].id}`
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "booking-type" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.bookingTypeArr.map((role: any) => (
                    <BranchSelectorNavbarElement
                      title={role.name}
                      selected={this.props.selectedObject === role.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/booking-type/${role.id}`}
                      key={role.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateBookingType}
                  >
                    <span>+</span>
                  </div>
                </div>
                <BranchSettingsNavbarElement
                  title="Buchungen"
                  icon={faCalendarAlt}
                  selected={this.props.selectedScreen === "booking"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/booking`}
                />
                <BranchSettingsNavbarElement
                  title="Objekte"
                  icon={faCube}
                  selected={this.props.selectedScreen === "objects"}
                  onClick={() => {}}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${
                    this.props.branchId
                  }/objects/${
                    this.props.objectArr[0]?.id
                      ? this.props.objectArr[0]?.id
                      : ""
                  }`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "objects" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.objectArr.map((object: any) => (
                    <BranchSelectorNavbarElement
                      title={object.name}
                      selected={this.props.selectedObject === object.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/objects/${object.id}`}
                      key={object.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreateBranch}
                  >
                    <span>+</span>
                  </div>
                </div>
                <BranchSettingsNavbarElement
                  title="Preisprofile"
                  icon={faEuroSign}
                  selected={this.props.selectedScreen === "pricing-profiles"}
                  onClick={this.props.hideMobileNav}
                  linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/pricing-profiles`}
                />
                <div
                  className={`navbar-objects ${
                    this.props.selectedScreen === "pricing-profiles" &&
                    "navbar-objects-selected"
                  }`}
                >
                  {this.props.pricingProfileArr.map((object: any) => (
                    <BranchSelectorNavbarElement
                      title={object.name}
                      selected={this.props.selectedObject === object.id}
                      onClick={this.props.hideMobileNav}
                      linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/pricing-profiles/${object.id}`}
                      key={object.id}
                    />
                  ))}
                  <div
                    className={"navbar-element navbar-create-element"}
                    onClick={this.props.onCreatePricingProfile}
                  >
                    <span>+</span>
                  </div>
                </div>
              </>
            )}
            {this.props.useBilling && (
              <BranchSettingsNavbarElement
                title="Abrechnungen"
                icon={faCreditCard}
                selected={this.props.selectedScreen === "billing"}
                onClick={this.props.hideMobileNav}
                linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/billing`}
              />
            )}

            <BranchSettingsNavbarElement
              title="Feed"
              icon={faAlignLeft}
              selected={this.props.selectedScreen === "feed"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/feed`}
            />
            {this.context.isAdmin && (
              <BranchSettingsNavbarElement
                title="Module"
                icon={faBoxOpen}
                selected={this.props.selectedScreen === "modules"}
                onClick={this.props.hideMobileNav}
                linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/modules`}
              />
            )}
            <BranchSettingsNavbarElement
              title="Einstellungen"
              icon={faCog}
              selected={this.props.selectedScreen === "settings"}
              onClick={this.props.hideMobileNav}
              linkTo={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/settings`}
            />
          </div>
          <Link
            to={`/club/${this.props.clubId}/branch/${this.props.branchId}/course`}
            className={"navbar-element navbar-back-element"}
            onClick={this.props.hideMobileNav}
          >
            zum System
          </Link>
        </nav>
      </>
    );
  }
}
