import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { faAlignLeft, faEdit, faImage, faUsers } from "@fortawesome/free-solid-svg-icons";

export default class SubscriptionElementManage extends React.Component<
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    title: string;
    description: string;
    price: string;
    imgUrl: string;
    editable: boolean;
    viewable: boolean;
    deleted: boolean;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div
          className={`course-container ${
            this.props.deleted ? " grayscale" : ""
          }`}
          id={this.props.subscriptionId}
        >
          {(this.props.editable || this.props.viewable) && (
            <div className="course-edit-icons">
              <Link
                to={`/club/${this.props.clubId}/branch/${this.props.branchId}/subscription-participants/${this.props.subscriptionId}`}
                className="black"
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  size="lg"
                  onClick={() => {}}
                  className="course-settings-icon"
                />
              </Link>
              {this.props.editable && (
                <>
                  <Link
                    to={`/club/${this.props.clubId}/branch-settings/${this.props.branchId}/feed?selemId=${this.props.subscriptionId}`}
                    className="black"
                  >
                    <FontAwesomeIcon
                      icon={faAlignLeft}
                      size="lg"
                      onClick={() => {}}
                      className="course-settings-icon"
                    />
                  </Link>
                  <Link
                    to={`/club/${this.props.clubId}/branch/${this.props.branchId}/edit-subscription/${this.props.subscriptionId}`}
                    className="black"
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      size="lg"
                      onClick={() => {}}
                      className="course-settings-icon"
                    />
                  </Link>
                </>
              )}
            </div>
          )}
          <div
            className={`inner-course-container ${
              this.props.editable ? "inner-course-container-editable" : ""
            }`}
          >
            <div>
              {!this.props.imgUrl ? (
                <div className="course-img">
                  <FontAwesomeIcon
                    icon={faImage}
                    size="6x"
                    className="image-icon"
                  />
                </div>
              ) : (
                <img
                  className="course-img"
                  src={this.props.imgUrl}
                  alt="Mitgliedschaftsbild"
                />
              )}
            </div>
            <div className="course-info-container">
              <div className="course-info-inner-container">
                <div className="course-title-container">{this.props.title}</div>
                <div>{this.props.description}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
