import React from "react";
import "./ErrorMessage.css";

export default class ErrorMessage extends React.Component<
  { message: undefined | null | string },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.message) {
      return (
        <div className="small-space">
          <h1 className="small-Text-Red">{this.props.message}</h1>
        </div>
      );
    } else {
      return null;
    }
  }
}
