import React from "react";
import "./CourseElement.css";
import { MainContext } from "../contexts/MainContext";
import {
  dateDurationToFullStr,
  firebaseDateToDateObj,
} from "../functions/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faChevronDown,
  faChevronUp,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { formatLineBreaks } from "../functions/formatHTML";

export default class BlockCourseElementOwn extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    courseBooking: any;
    title: string;
    description: string;
    when: string;
    bookingCreated: string;
    courses: Array<any>;
    freePlaces: number;
    price: string;
    imgUrl: string;
    inPast: boolean;
    notice: string;
    status: string;
    disabled: boolean;
  },
  { showMore: boolean }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    const courseBookingId = this.props.courseBooking.courseBookingId;

    return (
      <>
        <div
          className={`course-container ${
            this.props.disabled ? " grayscale" : ""
          }`}
          id={this.props.courseId}
        >
          <div>
            {!this.props.imgUrl ? (
              <div className="course-img">
                <FontAwesomeIcon
                  icon={faImage}
                  size="6x"
                  className="image-icon"
                />
              </div>
            ) : (
              <img
                className="course-img"
                src={this.props.imgUrl}
                alt="Kursbild"
              />
            )}
          </div>
          <div className="course-info-container">
            <div className="course-info-inner-container">
              <div className="course-title-container">{this.props.title}</div>
              <div>{this.props.description}</div>
              <div className="course-text-container">
                Status:{" "}
                <span className="normal-weight">{this.props.status}</span>
              </div>
              <div className="course-text-container">
                Termine:{" "}
                <span className="normal-weight">
                  {this.props.courses.length}
                </span>
              </div>
              <div className="course-text-container">
                Wann:{" "}
                <span className="normal-weight">
                  {this.props.courses
                    .slice(
                      0,
                      this.state.showMore ? this.props.courses.length : 3
                    )
                    .map((course: any) => (
                      <div>
                        -{" "}
                        {dateDurationToFullStr(
                          firebaseDateToDateObj(course.startTime),
                          firebaseDateToDateObj(course.endTime)
                        )}
                      </div>
                    ))}
                </span>
                {this.props.courses.length > 3 && (
                  <div
                    className="more-info-container"
                    onClick={this.toggleShowMore}
                  >
                    <div className="more-info-inner-container">
                      {!this.state.showMore ? (
                        <FontAwesomeIcon icon={faChevronDown} size="sm" />
                      ) : (
                        <FontAwesomeIcon icon={faChevronUp} size="sm" />
                      )}
                    </div>
                    {this.state.showMore ? "weniger anzeigen" : "mehr anzeigen"}
                  </div>
                )}
              </div>
              <div className="course-text-container">
                Gebucht:{" "}
                <span className="normal-weight">
                  {this.props.bookingCreated}
                </span>
              </div>
              {/* <div className="course-text-container">
                Preis:{" "}
                <span className="normal-weight">{this.props.price}€</span>
              </div> */}
              {this.props.notice && (
                <div className="course-text-container">
                  Notiz:{" "}
                  <span className="normal-weight">
                    {formatLineBreaks(this.props.notice)}
                  </span>
                </div>
              )}
            </div>
            <div>
              <Link
                to={`/club/${this.props.clubId}/branch/${this.props.branchId}/mycourses/${this.props.courseId}/courseBooking/${courseBookingId}`}
                className="course-booking-detail-btn no-underline"
              >
                Details anzeigen
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
