import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./BookButton.css";

export default class BookButton extends React.Component<
  {
    onClick: any;
    title: string;
    loading?: boolean;
    className?: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <button
          className={`${
            this.props.className ? this.props.className : "book-btn"
          } ${this.props.loading ? " book-btn-loading":""} `}
          onClick={this.props.onClick}
        >
          <div className="book-btn-inner-container">
            <div>
              {this.props.loading && (
                <div className="book-btn-loading-container">
                  <ClipLoader color={"lightgray"} size={20} loading={true} />
                </div>
              )}
            </div>
            <div>{this.props.title}</div>
            <div></div>
          </div>
        </button>
      </>
    );
  }
}
