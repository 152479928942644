import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./SubscriptionBooking.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import BackElement from "../../components/BackElement";
import Title from "../../components/Title";
import TestMode from "../../components/TestMode";
import PaymentStatus from "../../components/PaymentStatus";
import {
  dateToSmallStr,
  firebaseDateToDateObj,
  formatDateUnit,
  priceToStr,
} from "../../functions/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecButton from "../../components/SecButton";
import ErrorMessage from "../../components/ErrorMessage";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { firestore } from "../../services/firebase";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { getPaymentTable } from "../../functions/getPaymentTable";
import { getBookingStatus } from "../../functions/getBookingStatus";
import PaymentMethod from "../../components/PaymentMethod";
import { formatLineBreaks } from "../../functions/formatHTML";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    subscriptionId: string;
    subscriptionBookingId: string;
    clientKey: string | undefined;
  }>;

export default class SubscriptionBooking extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    subscriptionId: string;
    subscriptionBookingId: string;
    subscriptionBookingLoaded: boolean;
    subscriptionBookingData: any;
    subscription: any;
    subscriptionLoaded: boolean;
    cancellationLoading: boolean;
    errorMsgCancellation: null | string;
    showConfirmCancelSubscription: boolean;
    listenerActive: boolean;
    paymentArr: Array<any>;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      subscriptionId: this.props.match.params.subscriptionId,
      subscriptionBookingId: this.props.match.params.subscriptionBookingId,
      subscriptionBookingLoaded: false,
      subscriptionBookingData: {},
      subscription: {},
      subscriptionLoaded: false,
      cancellationLoading: false,
      errorMsgCancellation: null,
      showConfirmCancelSubscription: false,
      listenerActive: false,
      paymentArr: [],
    };
  }

  componentDidMount = () => {
    this.requestSubscription();
    if (!this.state.listenerActive) {
      if (this.context.user) {
        firestore
          .collection("subscriptions")
          .doc(this.state.subscriptionId)
          .collection("subscriptionBookings")
          .doc(this.state.subscriptionBookingId)
          .onSnapshot(() => {
            this.requestSubscriptionsBooking();
          });
      } else {
        this.requestSubscriptionsBooking();
      }
      this.setState({ listenerActive: true });
    }
  };

  requestSubscription = () => {
    fetchRetry(
      "getSubscriptionFromIdF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleSubscriptionSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleSubscriptionSuccess = ({ data }: any) => {
    this.setState({ subscriptionLoaded: true });
    if (data.success) {
      this.setState({ subscription: data.data[0] });
    }
  };

  requestSubscriptionsBooking = () => {
    this.setState({ subscriptionBookingLoaded: false });
    fetchRetry(
      "getSubscriptionBookingF",
      {
        subscriptionBookingId: this.state.subscriptionBookingId,
      },
      1,
      5
    )
      .then(this.handleSubscriptionsBookingSuccess)
      .catch(this.handleError);
  };

  handleSubscriptionsBookingSuccess = async ({ data }: any) => {
    this.setState({ subscriptionBookingLoaded: true });
    if (data.success) {
      const subscriptionBookingData = data.data;
      const paymentArr: Array<any> = getPaymentTable(subscriptionBookingData);
      const targetUserId: string = subscriptionBookingData.userId;
      this.context.setSelectedUserId(targetUserId);
      this.setState({
        subscriptionBookingData: subscriptionBookingData,
        paymentArr,
      });
    }
  };

  handleCancellateSubscription = () => {
    this.setState({ showConfirmCancelSubscription: true });
  };

  hideConfirmationCancelSubscription = () => {
    this.setState({ showConfirmCancelSubscription: false });
  };

  handleCancellateSubscriptionConfirmed = () => {
    this.setState({
      cancellationLoading: true,
      showConfirmCancelSubscription: false,
    });
    fetchRetry(
      "cancelSubscriptionF",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        subscriptionId: this.state.subscriptionId,
        subscriptionBookingId: this.state.subscriptionBookingId,
        targetUserId: this.context.selectedUserId,
      },
      1,
      5
    )
      .then(this.handleSubscriptionsCanceledSuccess)
      .catch(this.handleError);
  };

  handleSubscriptionsCanceledSuccess = ({ data }: any) => {
    this.setState({ cancellationLoading: false });
    if (data.success) {
      this.setState({ errorMsgCancellation: null });
      this.requestSubscriptionsBooking();
    } else {
      this.setState({ errorMsgCancellation: data.errorMsgDe });
    }
  };

  render() {
    if (
      !this.state.subscriptionBookingLoaded ||
      !this.state.subscriptionLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    return (
      <>
        <ConfirmationModal
          show={this.state.showConfirmCancelSubscription}
          title="Mitgliedschaft kündigen"
          msg="Bist du sicher, dass du diese Mitgliedschaft kündigen willst? "
          handleClose={this.hideConfirmationCancelSubscription}
          handleConfirm={this.handleCancellateSubscriptionConfirmed}
        />
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            {this.context.user ? (
              <BackElement
                text="zurück zu meinen Mitgliedschaften"
                to={`/club/${this.state.clubId}/branch/${this.state.branchId}/mysubscriptions#${this.state.subscriptionBookingId}`}
              />
            ) : (
              <BackElement
                text="zurück zur Mitgliedschaftsauswahl"
                to={`/club/${this.state.clubId}/branch/${this.state.branchId}/courses?showSub=true`}
              />
            )}
            <TestMode active={this.state.subscriptionBookingData.testmode} />
            <Title title="Mitgliedschaft" />
            <div className="courseBooking-course-info-container">
              <div>
                {!this.state.subscription.imgLowRes ? (
                  <div className="single-course-img">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="6x"
                      className="image-icon"
                    />
                  </div>
                ) : (
                  <img
                    className="single-course-img"
                    src={this.state.subscription.imgLowRes}
                    alt="Mitgliedschaftsbild"
                  />
                )}
              </div>
              <div>
                <h2>{this.state.subscription.title}</h2>
                <span>{this.state.subscription.description}</span>
                {this.state.subscription.bookingMailMessage && (
                  <div className="course-text-container">
                    Notiz:{" "}
                    <span className="normal-weight">
                      {formatLineBreaks(
                        this.state.subscription.bookingMailMessage
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Title title="Buchungsinformationen" />
            <div>
              <table className="course-booking-information-table course-booking-information">
                <tbody>
                  <tr className="no-border">
                    <td>Status</td>
                    <td>
                      {getBookingStatus(this.state.subscriptionBookingData)}
                    </td>
                  </tr>
                  <tr>
                    <td>Methode</td>
                    <td>
                      <PaymentMethod
                        clubId={this.state.clubId}
                        branchId={this.state.branchId}
                        courseId={this.state.subscriptionId}
                        courseBookingId={this.state.subscriptionBookingId}
                        method={this.state.subscriptionBookingData.method}
                        refresh={this.requestSubscriptionsBooking}
                        selectedMandateId={
                          this.state.subscriptionBookingData?.mandateId
                        }
                        type="subscription"
                        editable={this.state.subscriptionBookingData.active}
                      />
                    </td>
                  </tr>
                  {this.state.subscriptionBookingData.admissionFee > 0 && (
                    <tr>
                      <td>Aufnahmegebühr</td>
                      <td>
                        {priceToStr(
                          this.state.subscriptionBookingData.admissionFee
                        )}
                        €
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      {this.state.subscriptionBookingData.billingPeriod === 1
                        ? "pro Monat"
                        : `alle ${this.state.subscriptionBookingData.billingPeriod} Monate`}
                    </td>
                    <td>
                      {priceToStr(
                        this.state.subscriptionBookingData.montlyPrice
                      )}
                      €
                    </td>
                  </tr>
                  <tr>
                    <td>Buchungszeitpunkt</td>
                    <td>
                      {dateToSmallStr(
                        firebaseDateToDateObj(
                          this.state.subscriptionBookingData.created_at
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Kündigungsfrist</td>
                    <td>
                      {this.state.subscriptionBookingData.noticePeriod}{" "}
                      {formatDateUnit(
                        this.state.subscriptionBookingData.noticePeriodDateUnit,
                        this.state.subscriptionBookingData.noticePeriod
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.subscriptionBookingData.price !== 0 && (
                <table className="course-booking-information-table">
                  <thead>
                    <tr>
                      <th>Monat</th>
                      <th>Zahlungsstatus</th>
                      <th>Preis</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.paymentArr.map((currPayment: any) => (
                      <tr>
                        <td>{currPayment.monthStr}</td>
                        <td>
                          <div className="flex">
                            <PaymentStatus type={currPayment.paymentStatus} />
                            {currPayment.refunded && (
                              <div className="margin-left">
                                <PaymentStatus
                                  type={currPayment.refundStatus}
                                />
                              </div>
                            )}
                            {currPayment.hasChargebacks && (
                              <div className="margin-left">
                                <PaymentStatus type={"hasChargebacks"} />
                              </div>
                            )}
                          </div>
                        </td>
                        <td>{currPayment.priceStr}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!this.state.subscriptionBookingData.subscriptionCancelled && (
                <div className="cancellationContainer">
                  <ErrorMessage message={this.state.errorMsgCancellation} />
                  <SecButton
                    change={!this.state.subscriptionBookingData.refunded}
                    color="red"
                    loading={this.state.cancellationLoading}
                    onClick={this.handleCancellateSubscription}
                    title="Kündigen"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
