import React from "react";
import Modal from "react-modal";
import "./Modal.css";
import "./BranchModal.css";
import "./CreateFamilyMember.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Input";
import SecButton from "../SecButton";
import ErrorMessage from "../ErrorMessage";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SubTitle from "../SubTitle";
import { fetchRetry } from "../../functions/request";
import { reformatDate } from "../../functions/utils";
import { MainContext } from "../../contexts/MainContext";

export default class CreateFamilyMember extends React.Component<
  {
    show: boolean;
    handleClose: any;
    defaultLName?: string;
    defaultStreet?: string;
    defaultHouseNumber?: string;
    defaultZipcode?: string;
    defaultCity?: string;
    defaultPhoneNumber?: string;
  },
  {
    loading: boolean;
    errorMsg?: null | string;
    fName: string;
    lName: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    phoneNumber: string;
    birthDate: string;
  }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      fName: "",
      lName: this.props.defaultLName || "",
      street: this.props.defaultStreet || "",
      houseNumber: this.props.defaultHouseNumber || "",
      zipcode: this.props.defaultZipcode || "",
      city: this.props.defaultCity || "",
      phoneNumber: this.props.defaultPhoneNumber || "",
      birthDate: "",
    };
  }

  handleFNameChange = (val: string) => {
    this.setState({ fName: val });
  };

  handleLNameChange = (val: string) => {
    this.setState({ lName: val });
  };

  handleStreetChange = (val: string) => {
    this.setState({ street: val });
  };

  handleHouseNumberChange = (val: string) => {
    this.setState({ houseNumber: val });
  };

  handleZipcodeChange = (val: string) => {
    this.setState({ zipcode: val });
  };

  handleCityChange = (val: string) => {
    this.setState({ city: val });
  };

  handlePhoneNumberChange = (val: string) => {
    this.setState({ phoneNumber: val });
  };

  handleBirthDateChange = (e: any) => {
    this.setState({ birthDate: e.target.value });
  };

  handleConfirm = () => {
    this.setState({ loading: true });
    if (!this.state.loading) {
      fetchRetry(
        "createFamilyAccountNew",
        {
          fName: this.state.fName,
          lName: this.state.lName,
          address: {
            city: this.state.city,
            zipcode: this.state.zipcode,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
          },
          phoneNumber: this.state.phoneNumber,
          birthDate: reformatDate(this.state.birthDate),
          clubId: this.context.clubId,
        },
        1,
        5
      )
        .then(this.handleSuccessUpdatedUserData)
        .catch(this.handleError);
    }
  };

  handleSuccessUpdatedUserData = ({ data }: any) => {
    this.setState({ loading: false });
    if (data.success) {
      this.props.handleClose();
      this.context.requestOwnUserData();
    } else {
      this.setState({ errorMsg: data.errorMsgDe });
    }
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          contentLabel="create Branch"
          onRequestClose={() => {}}
          className="modal-size modal-background"
          ariaHideApp={false}
        >
          <div className="user-modal-inner">
            <div
              className="modal-cross-container"
              onClick={this.props.handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-title-container">
              <span className="modal-title">Familienmitglied hinzufügen</span>
            </div>
            <SubTitle title="Name" />
            <div className="course-time-input-container">
              <Input
                value={this.state.fName}
                onChange={this.handleFNameChange}
                placeholder="Vorname"
              />
              <Input
                value={this.state.lName}
                onChange={this.handleLNameChange}
                placeholder="Nachname"
              />
            </div>
            <SubTitle title="Adresse" />
            <div className="course-time-input-container">
              <Input
                value={this.state.street}
                onChange={this.handleStreetChange}
                placeholder="Straße"
                className="street-input"
              />
              <Input
                value={this.state.houseNumber}
                onChange={this.handleHouseNumberChange}
                placeholder="Nr."
                className="house-number-input"
              />
            </div>
            <div className="profile-location-container">
              <Input
                value={this.state.zipcode}
                onChange={this.handleZipcodeChange}
                placeholder="PLZ"
                className="zipcode-input"
              />
              <Input
                value={this.state.city}
                onChange={this.handleCityChange}
                placeholder="Stadt"
                className="city-input"
              />
            </div>
            <SubTitle title="Telefonnummer" />
            <Input
              value={this.state.phoneNumber}
              onChange={this.handlePhoneNumberChange}
              placeholder="Telefonnummer"
            />
            <SubTitle title="Geburtsdatum" />
            <input
              type="date"
              className="input-container"
              value={this.state.birthDate}
              onChange={this.handleBirthDateChange}
            />
            <ErrorMessage message={this.state.errorMsg} />
            <SecButton
              change={true}
              color="green"
              loading={this.state.loading}
              onClick={() => {
                this.handleConfirm();
              }}
              title={"Familienmitglied hinzufügen"}
            />
          </div>
        </Modal>
      </>
    );
  }
}
