import React from "react";
import { RouteComponentProps, Route, Redirect } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import "./Branch.css";
import TopMenuBar from "../components/TopMenuBar";
import Booking from "./BranchSubScreens/Booking";
import Course from "./BranchSubScreens/Course";
import EditCourse from "./BranchSubScreens/EditCourse";
import SingleCourse from "./BranchSubScreens/SingleCourse";
import BranchNavbar from "../components/navbars/BranchNavbar";
import Footer from "../components/Footer";
import MyCourses from "./MyCourses";
import CreateCourse from "./BranchSubScreens/CreateCourse";
import CourseParticipants from "./BranchSubScreens/CourseParticipants";
import CourseBookingConfirmation from "./BranchSubScreens/CourseBookingConfirmation";
import Profile from "./Profile";
import { HashLoader } from "react-spinners";
import BookParticipant from "./BranchSubScreens/BookParticipant";
import CourseParticipant from "./BranchSubScreens/CourseParticipant";
import ManageCourses from "./BranchSubScreens/ManageCourses";
import CourseBooking from "./BranchSubScreens/CourseBooking";
import QrCodeScanner from "./BranchSubScreens/QrCodeScanner";
import EditSubscription from "./BranchSubScreens/EditSubscription";
import CreateSubscription from "./BranchSubScreens/CreateSubscription";
import MySubscriptions from "./MySubscriptions";
import SingleSubscription from "./BranchSubScreens/SingleSubscription";
import SubscriptionBookingConfirmation from "./BranchSubScreens/SubscriptionBookingConfirmation";
import SubscriptionBooking from "./BranchSubScreens/SubscriptionBooking";
import SubscriptionParticipants from "./BranchSubScreens/SubscriptionParticipants";
import SubscriptionParticipant from "./BranchSubScreens/SubscriptionParticipant";
import BookSubscriptionParticipant from "./BranchSubScreens/BookSubscriptionParticipant";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    site: string;
  }>;

export default class Branch extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    showMobileNav: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      showMobileNav: false,
    };
  }

  componentDidMount = () => {
    this.context.requestClub(this.state.clubId);
    this.context.requestBranch(this.state.clubId, this.state.branchId);
  };

  handleShowMobileNav = () => {
    this.setState({ showMobileNav: true });
  };

  handleHideMobileNav = () => {
    this.setState({ showMobileNav: false });
  };

  handleError = (err: any) => {
    console.error(err);
  };

  render() {
    return (
      <div className="br-main-container">
        <BranchNavbar
          selectedScreen={this.props.match.params.site}
          clubId={this.state.clubId}
          branchId={this.state.branchId}
          isAdmin={
            this.context.branch?.user?.role === "Owner" || this.context.isAdmin
          }
          isViewOnly={this.context.branch?.user?.role === "ViewOnly"}
          showMobile={this.state.showMobileNav}
          hideMobileNav={this.handleHideMobileNav}
          useBooking={this.context.branch?.settings?.useBooking}
          useCourse={this.context.branch?.settings?.useCourse}
          useSubscriptions={this.context.branch?.settings?.useSubscriptions}
        />
        <TopMenuBar handleShowMobileNav={this.handleShowMobileNav} />
        <div
          className="br-inner-container"
          id="scroll-container"
          onScroll={this.context.onScroll}
        >
          <div className="br-padding-container">
            {!this.context.branchLoaded ? (
              <>
                <div className="loading-container">
                  <HashLoader color={"#c31924"} size={100} loading={true} />
                </div>
              </>
            ) : (
              <>
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/booking"
                  component={Booking}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/courseBooking/:courseBookingId"
                  component={CourseBookingConfirmation}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/subscriptionBooking/:subscriptionBookingId"
                  component={SubscriptionBookingConfirmation}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/courseBooking/:courseBookingId/:clientKey"
                  component={CourseBookingConfirmation}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/manage-course"
                  component={ManageCourses}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/qr-scanner"
                  component={QrCodeScanner}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/mycourses/:courseId/courseBooking/:courseBookingId/:clientKey"
                  component={CourseBooking}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/mycourses/:courseId/courseBooking/:courseBookingId"
                  component={CourseBooking}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/mycourses/:subscriptionId/subscriptionBooking/:subscriptionBookingId"
                  component={SubscriptionBooking}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/course"
                  component={Course}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/edit-subscription/:subscriptionId"
                  component={EditSubscription}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/mysubscriptions/:subscriptionId/subscriptionBooking/:subscriptionBookingId"
                  component={SubscriptionBooking}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/mysubscriptions"
                  component={MySubscriptions}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/createSubscription"
                  component={CreateSubscription}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/createCourse/:courseId"
                  component={CreateCourse}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/mycourses"
                  component={MyCourses}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/createCourse"
                  component={CreateCourse}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/course/:courseId"
                  component={SingleCourse}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/subscription/:subscriptionId"
                  component={SingleSubscription}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/edit-course/:courseId"
                  component={EditCourse}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/participants/:courseId/book-participant"
                  component={BookParticipant}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/subscription-participants/:subscriptionId/book-participant"
                  component={BookSubscriptionParticipant}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/participants/:courseId/participant/:courseParticipantId"
                  component={CourseParticipant}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/participants/:courseId"
                  component={CourseParticipants}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/subscription-participants/:subscriptionId/participant/:subscriptionParticipantId"
                  component={SubscriptionParticipant}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/subscription-participants/:subscriptionId"
                  component={SubscriptionParticipants}
                />
                <Route
                  exact
                  path="/club/:clubId/branch/:branchId/profile"
                  component={Profile}
                />
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
